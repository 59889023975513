import axios from 'axios';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
// import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const ACCESS_KEY_ID1 = process.env.ACCESS_KEY_ID;
// const SECERT_ACCESS_KEY1 = process.env.SECERT_ACCESS_KEY;

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Define common API methods
const _get = (url, config = {}) => {
  return apiClient.get(url, config);
};

const _delete = (url, config = {}) => {
  return apiClient.delete(url, config);
};

const _put = (url, data = {}, config = {}) => {
  return apiClient.put(url, data, config);
};

const _post = (url, data = {}, config = {}) => {
  return apiClient.post(url, data, config);
};

// const [progress, setProgress] = useState(0);
const S3UploadApi = (target) => {
  try {
    const cred = {
      accessKeyId: 'AKIARPIW6FQBBZSWL2PA', secretAccessKey: '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',
    };

    console.log("********",cred)
    const parallelUploads3 = new Upload({
      client: new S3Client({
        region: 'ap-south-1',
        credentials: cred,

      }),
      headers: { "Access-Control-Allow-Origin": "*" },
      params: target,
      leavePartsOnError: false,
    });
    console.log("&&&&&&&")
    parallelUploads3.on("httpUploadProgress", (progress) => {
      console.log(progress);
      // setProgress(Math.round((progress.loaded / progress.total) * 100));
    });

    parallelUploads3.done();
    return true;
    // setSuccess(true);
    // setError(null);
  } catch (e) {
    console.error(e);
    return false;
    // setError("Upload failed, please try again.");
    // setSuccess(false);
    // setUploading(false);
  }
};

// Export API methods
export { _get, _delete, _put, _post, S3UploadApi };