import React, { useEffect, useReducer, useState } from 'react'
import { _get } from '../utills/apiClient';
import { FETCH_MAILING_ADDRESS, FETCH_TAXPAYER } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import { phoneNoFOrmat, ssnPinFOrmat } from './commonFunction';
import DatePipe from './DatePipe';
import Loader from './Loader';

const ReviewProfile = ({ onLoaded }) => {
    const showSnackbar = useSnackbar();
    const [taxPayerValues, setTaxPayerValues] = useState();
    const [mailingValue, setMailingValue] = useState();

    // useEffect(() => {
    //     getTaxPayer(user?.profile_id);
    //     getMailingAddress(user?.profile_id);
    // }, []);


    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('taxData'));
        const fetchAllApiData = async () => {
            try {
                // Call both APIs concurrently using Promise.all
                const [taxPayerApi, mailingApi] = await Promise.all([
                    getTaxPayer(user?.profile_id),
                    getMailingAddress(user?.profile_id)
                ]);
                // Update state with data from both API calls
                setTaxPayerValues(taxPayerApi);
                setMailingValue(mailingApi);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                // Hide the loader after both APIs are done
                onLoaded();
            }
        };
        if (user) {
            fetchAllApiData();
        }
    }, []);

    const getTaxPayer = async (profile_id) => {
        try {
            const response = await _get(`${FETCH_TAXPAYER}${profile_id}`);
            return response.data;
            //setTaxPayerValues(response.data);
        } catch (error) {
            showSnackbar("Getting error in getTaxPayer", 'error');
            console.error('Error adding data:', error);
        }
    };

    const getMailingAddress = async (profile_id) => {
        try {
            const response = await _get(`${FETCH_MAILING_ADDRESS}${profile_id}`);
            if (response.status == 200 && response.data?.MailAddress) {
                return response.data;
                // setMailingValue(response.data);
            }
        } catch (error) {
            showSnackbar("Getting error in getMailingAddress", 'error');
            console.error('Error adding data:', error);
        }
    };

    const [accordion, setAccordion] = useState(true);
    const handleAccordion = (status) => {
        setAccordion(!status);
    }

    return (
        <>
            <div className='order__tabscontent accordion'>
                <div className={accordion ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                    <div className='order__contentcard accordion'>
                        <div className='order__contentcard--header' onClick={() => handleAccordion(accordion)}>
                            <div className='flex gap-8'>
                                <p className='order__contentcard--title'>Profile Details</p>
                            </div>
                            {accordion && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' /></button>}
                            {!accordion && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' /></button>}
                        </div>
                        {accordion &&
                            <>
                                {/* <div className=''> */}

                                <div className='order__contentcard--content-inner'>
                                    <p className='order__contentcard--title px-4'>Tax Payer</p>
                                    <div className='order__contentcard--content accordion'>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{ssnPinFOrmat(taxPayerValues?.Taxpayer?.SSN_ITIN)}</span>
                                            <span className='text-sm italic font-light'>SSN/ITIN</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>
                                                <DatePipe date={taxPayerValues?.Taxpayer?.date_of_birth} /></span>
                                            <span className='text-sm italic font-light'>Date of Birth</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Taxpayer?.email}</span>
                                            <span className='text-sm italic font-light'>E Mail</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Taxpayer?.first_name}</span>
                                            <span className='text-sm italic font-light '>First Name</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Taxpayer?.middle_initial ? taxPayerValues?.Taxpayer?.middle_initial : '-'}</span>
                                            <span className='text-sm italic font-light'>Middle Initial</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Taxpayer?.last_name}</span>
                                            <span className='text-sm italic font-light'>Last Name</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Taxpayer?.occupation}</span>
                                            <span className='text-sm italic font-light'>Occupation</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{phoneNoFOrmat(taxPayerValues?.Taxpayer?.phone_number)}</span>
                                            <span className='text-sm italic font-light'>Mobile Number</span>
                                        </div>
                                    </div>
                                </div>

                                {taxPayerValues?.Spouse && <div className='order__contentcard--content-inner'>
                                    <p className='order__contentcard--title px-4'>Spouse</p>
                                    <div className='order__contentcard--content accordion'>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{ssnPinFOrmat(taxPayerValues?.Spouse?.SSN_ITIN)}</span>
                                            <span className='text-sm italic font-light'>SSN/ITIN</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>
                                                <DatePipe date={taxPayerValues?.Spouse?.date_of_birth} /></span>
                                            <span className='text-sm italic font-light'>Date of Birth</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Spouse?.email}</span>
                                            <span className='text-sm italic font-light'>E Mail</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Spouse?.first_name}</span>
                                            <span className='text-sm italic font-light '>First Name</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Spouse?.middle_initial ? taxPayerValues?.Spouse?.middle_initial : '-'}</span>
                                            <span className='text-sm italic font-light'>Middle Initial</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{taxPayerValues?.Spouse?.last_name}</span>
                                            <span className='text-sm italic font-light'>Last Name</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{phoneNoFOrmat(taxPayerValues?.Spouse?.phone)}</span>
                                            <span className='text-sm italic font-light'>Mobile Number</span>
                                        </div>
                                    </div>
                                </div>}


                                <div className='order__contentcard--content-inner'>
                                    <p className='order__contentcard--title px-4'>Mailing Address</p>
                                    <div className='order__contentcard--content accordion'>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{mailingValue?.MailAddress?.street_address}</span>
                                            <span className='text-sm italic font-light'>Street Address</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{mailingValue?.MailAddress?.city}</span>
                                            <span className='text-sm italic font-light'>City</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{mailingValue?.MailAddress?.state}</span>
                                            <span className='text-sm italic font-light'>State</span>
                                        </div>
                                        <div className='order__contentcard--details'>
                                            <span className='text-sm'>{mailingValue?.MailAddress?.zipcode}</span>
                                            <span className='text-sm italic font-light '>Zip Code</span>
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>
            </div >
        </>
    )
}

export default ReviewProfile