import React, { useState, useEffect } from 'react';

const NotificationPopup = ({ }) => {
    const [notifications, setNotifications] = useState([]);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
        const sortedNotifications = storedNotifications.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setNotifications(sortedNotifications);


        const updatedNotifications = storedNotifications.map(notification => ({
            ...notification,
            read: true
        }));
        localStorage.setItem("notifications", JSON.stringify(updatedNotifications));




    }, []);



    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };


    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
        <div className="fixed top-12 right-5 w-80 bg-white shadow-lg rounded-lg p-4 z-50 ">
            <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Notifications </h2>
                <button onClick={handleClose} className=" w-6 h-6 text-red-600   bg-white rounded-full text-lg font-extrabold hover:text-red-300 ml-2">
                    X
                </button>
            </div>
            <div className="mt-2 max-h-96 overflow-y-auto">
                {notifications.map((notification, index) => (
                    <div key={index} className="flex justify-between items-center border-b border-gray-200 py-2">
                        <p className="text-sm text-gray-800">{notification.message}</p>
                        <span className="text-xs text-gray-500">{formatDate(notification.timestamp)}</span>
                    </div>
                ))}
            </div>
            {/* <button className="mt-2 w-full text-blue-600 text-sm font-medium hover:underline">
        Mark all as read
      </button> */}
        </div>
    );
};

export default NotificationPopup;