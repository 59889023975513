import React, { useEffect, useState } from 'react'
import { _get, _post } from '../utills/apiClient';
import { FETCH_DOCUMENTS, GET_SIGNED_DOC_URL } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import DownloadZip from './DownloadZip';
import Loader from './Loader';
import FileViewer from './FileViewer';

const OrderDocuments = (props) => {
    const showSnackbar = useSnackbar();
    const [docValues, setDocValues] = useState();
    const [signedUrls, setSignedUrls] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        getDocuments(props?.orderData?.file_no);
    }, [props?.orderData]);
    const getDocuments = async (file_no) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_DOCUMENTS}${file_no}`);
            if (response.data?.getDocumentList && response.data?.getDocumentList.length > 0) {
                setDocValues(response.data?.getDocumentList);
                let payload = {
                    key: [],
                    "operation": "getObject"
                }
                response.data?.getDocumentList.map((data) => {
                    payload.key.push(data.file_no + "_" + data.document_name);
                })
                getSignedUrl(payload);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getDocumentList", 'error');
            console.error('Error adding data:', error);
        }
    }

    const getSignedUrl = async (payload) => {
        try {
            setIsLoading(true);
            const response = await _post(`${GET_SIGNED_DOC_URL}`, payload);
            if (response.status == 200 && response.data?.getDocumentUrlList && response.data?.getDocumentUrlList.length > 0) {
                setSignedUrls(response.data?.getDocumentUrlList);
            } else {
                setSignedUrls([]);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getSignedUrl", 'error');
            console.error('Error adding data:', error);
        }
    };

    const documentTypeLableName = {
        'W2': 'Employment',
        '1099-MISC': 'Self Employment (1099-MISC)',
        '1099-NEC': 'Self Employment (1099-NEC)',
        'Schedule K1': 'Self Employment (Schedule K1)',
        '1099K': 'Self Employment (1099K)',
        '1099-R': 'Retirement (401K/IRA (1099R))',
        '1099-SSA': 'Retirement (Social Security (1099-SSA))',
        'W2-G': 'Gambling(W2-G)',
        '1099-SA': 'HSA Distribution (1099-SA)',
        '5498-SA': 'HSA/IRA Contribution(5498-SA)',
        '1099-G': 'Unemployment/State Refund (1099G)',
        '1095-A': 'ACA/Obamacare Subsidy(1095A)',
        '1095-B': 'Other Healthcare (1095B)',
        '1095-C': 'Other Healthcare (1095C)',
        '1098-INT/1099-DIV': `Financial Institution Interest/Dividend (1098-INT/1099-DIV)`,
        '1099-B': `Stock Sale/Tradings (1099-B)`,
        '3922': 'ESPP Letter (From 3922)',
        '1042-S': 'Non-residents(1042-S)',
        '1098': 'Mortgage Interest (1098)',
        '1098-T': 'Tuition Payments (1098-T)',
        '1098-E': 'Student Loan Intrest (1098-E)',
        '1099-S': 'Home Sale (1099-S)',
        '1099-Q': '529 Plan Withdrawal (1099-Q)',
    };

    const [fileToShow, setFileToShow] = useState(null);
    const [fileExt, setFileExt] = useState(null);
    const handleCloseFileViewer = () => {
        setFileToShow(null);
    };
    const getIndiVidualSignedUrl = async (item) => {
        let payload = {
            key: [`${item.file_no}_${item.document_name}`],
            "operation": "getObject"
        }
        try {
            const response = await _post(GET_SIGNED_DOC_URL, payload);
            if (response.status == 200 && response.data?.getDocumentUrlList && response.data?.getDocumentUrlList.length > 0) {
                // setSignedUrls(response.data?.getDocumentUrlList);
                if (response.data?.getDocumentUrlList.length > 0) {
                    console.log(response.data?.getDocumentUrlList[0].url);
                    response.data?.getDocumentUrlList[0].url && setFileToShow(response.data?.getDocumentUrlList[0].url)
                    const parts = item.document_name.split('.');
                    let ext = parts.length > 1 ? `${parts.pop()}` : '';
                    setFileExt(ext);
                }

            }
        } catch (error) {
            showSnackbar("Getting error in getSignedUrl", 'error');
        }
    };

    return (
        <>
            {isLoading && <Loader />}

            {docValues && docValues.length > 0 && (
                <>
                    <div className='order__singleview--right'>
                        <div className='order__document--container'>
                            <div className='order__document--header'>
                                <div className='order__document--header-left'>
                                    <img src='/assets/images/document-icon.svg' alt='document' />
                                    <p className='text-sm font-semibold'>Documents</p>
                                </div>
                                <div className='order__document--header-right'>
                                    {signedUrls.length > 0 && <DownloadZip signedUrls={signedUrls} page="Agent" orderData={props?.orderData} />}
                                </div>
                            </div>
                            {docValues.map((docData, index) => (
                                <div className='order__document--section'>
                                    <p className='text-xs'>{documentTypeLableName[docData.document_type]}</p>
                                    {/* <div className='order__document--section-field'>
                                        <span className='text-[#6D6E71] text-xs'>W2</span>
                                        <img src='/assets/images/document-fullview.svg' alt='document' />
                                    </div> */}
                                    <div className='order__document--section-field'>
                                        <span className='text-[#6D6E71] text-xs'>{docData.document_type}</span>
                                        <img src='/assets/images/document-fullview.svg' alt='document' onClick={() => getIndiVidualSignedUrl(docData)}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {fileToShow && (
                <FileViewer file={fileToShow} fileExt={fileExt} handleClose={handleCloseFileViewer} />
            )}

                </>
            )}
        </>
    )
}

export default OrderDocuments