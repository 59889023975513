import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _post } from '../utills/apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import { RESET_PWD } from '../constants/api.endpoint';
import { useSnackbar } from '../components/toaster';
import Loader from '../components/Loader';

const validationSchema = yup.object().shape({
    password: yup.string()
        .required('New password is required')
        .min(8, 'Password must be at least 8 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#\-])[A-Za-z\d@$!%*?&^#]{8,}$/,
            'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
          ),

    confirmPassword: yup.string()
        .required('Confirm new password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
});


const ReserPwd = () => {
    const { id } = useParams();
    const showSnackbar = useSnackbar();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    });
    const navigate = useNavigate();
    const resetInitState = {
        password: "",
        confirmPassword: "",
    };
    const [resetValues, setResetValues] = useState(resetInitState);
    const [isLoading, setIsLoading] = useState(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setResetValues({
            ...resetValues,
            [name]: value,
        });
    };

    const onSubmit = (data) => {
        let payload = {
            password: resetValues.password,
            cus_id: sessionStorage.getItem("createdFileno")
        }
        resetPwd(payload);
    };

    const resetPwd = async (payload) => {
        try {
            setIsLoading(true);
            const response = await _post(RESET_PWD, payload);
            console.log('response', response);
            if (response.status == 200 && response.data) {
                showSnackbar("Password Reset Successfully", 'success');
                navigate('/login');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in resetPwd. Please try again later", 'error');
            console.error('Error adding data:', error);
        }
    };



    return (
        <>
            {isLoading && <Loader />}
            <div className='user__container'>
                <form className='flex flex-col gap-8 w-full' onSubmit={handleSubmit(onSubmit)}>
                    <p className='text-2xl'>Reset Password</p>
                    <div className="user__inputgroup">
                        <div className='relative'>
                            <input
                                type="password"
                                id="password"
                                {...register('password')}
                                onChange={(e) => {
                                    register('password').onChange(e);
                                    handleInputChange(e);
                                }} value={resetValues.password} required
                            />
                            <label>New Password</label>
                        </div>
                        <p className='text-red-600'>{errors.password?.message}</p>
                    </div>
                    <div className="user__inputgroup">
                        <div className='relative'>
                            <input
                                type="password"
                                id="confirmPassword"
                                {...register('confirmPassword')}
                                onChange={(e) => {
                                    register('confirmPassword').onChange(e);
                                    handleInputChange(e);
                                }} value={resetValues.confirmPassword} required
                            />
                            <label>Confirm New Password</label>
                        </div>
                        <p className='text-red-600'>{errors.confirmPassword?.message}</p>
                    </div>

                    <button type='submit' className='btn btn__primary'>Reset Password</button>

                </form>
            </div>
        </>
    )
}

export default ReserPwd