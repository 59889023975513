import { useEffect, useState } from "react";
import { CREATE_OR_UPDATE_MAILING_ADDRESS, CREATE_OR_UPDATE_TAXPAYER, FETCH_BANK, FETCH_MAILING_ADDRESS, FETCH_TAXPAYER, SSN_PIN_VALIDATION } from "../constants/api.endpoint";
import { _get, _post } from "../utills/apiClient";
import { useSnackbar } from "./toaster";
import DatePipe from "./DatePipe";
import { formatSSNForDisplay, phoneNoFOrmat, ssnPinFOrmat } from "./commonFunction";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { CreateNotification } from "./commonFunction";

const profileSchema = yup.object().shape({
    SSN_ITIN_tax: yup.string()
        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
        .required('SSN is required'),
    first_name: yup.string().required('First Name is required'),
    middle_initial: yup.string(),
    last_name: yup.string().required('Last Name is required'),
    date_of_birth: yup.string().required('DOB is required'),
    occupation: yup.string().required('Occupation is required'),
    phone_number: yup.string()
        .required('Phone No. is required')
        .matches(/^[0-9-]+$/, "Must contain only digits")
        .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone No format must be XXX-XXX-XXXX or contain 10 digits'),
    email: yup.string().email('Please enter valid E-mail ID').required('Email is required'),
    SSN_ITIN_sp: yup.string()
        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
        .required('SSN is required'),
    first_name_sp: yup.string().required('First Name is required'),
    middle_initial_sp: yup.string(),
    last_name_sp: yup.string().required('Last Name is required'),
    date_of_birth_sp: yup.string().required('DOB is required'),
    phone: yup.string()
        .required('Phone No. is required')
        .matches(/^[0-9-]+$/, "Must contain only digits")
        .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone No format must be XXX-XXX-XXXX or contain 10 digits'),
    email_sp: yup.string().email('Please enter valid E-mail ID').required('Email is required'),
    street_address: yup.string().required('Street Address is required'),
    apt_home_unit: yup.string().required('APT/UNIT is required').matches(/^[0-9]+$/, "Must be only digits"),
    city: yup.string().required('City is required'),
    state: yup.string().required('State is required'),
    zipcode: yup.string().required('Zipcode is required').matches(/^[0-9]+$/, "Must be only digits"),
});


export default function OrderProfile(props) {

    let taxPayerinitState = {
        SSN_ITIN: "",
        SSN_ITIN_tax: "",
        first_name: '',
        last_name: '',
        middle_initial: "",
        date_of_birth: "",
        gender: "",
        occupation: "",
        phone_number: '',
        email: '',
        marital_status: "No",
        userId: "4322"
    };

    let spouseinitState = {
        SSN_ITIN_sp: "",
        first_name_sp: "",
        last_name_sp: "",
        middle_initial_sp: "",
        date_of_birth_sp: "",
        gender_sp: "female",
        relationship: "Spouse",
        phone: "",
        email_sp: "",
        employed_status: "",
    };

    let mailinginitState = {
        street_address: "",
        apt_home_unit: "",
        city: "",
        state: "",
        zipcode: ""
    };

    let bankInfoinitState = {
        bank_name: "",
        account_holder_name: "",
        routing_number: "",
        account_number: "",
        account_type: ""
    };

    const [taxPayerValues, setTaxPayerValues] = useState(taxPayerinitState);
    const [spouseValue, setSpouseValue] = useState(spouseinitState);
    const [mailingValue, setMailingValue] = useState(spouseinitState);
    const [bankInfoValue, setBankInfoValue] = useState(bankInfoinitState);
    const showSnackbar = useSnackbar();
    const [isFocused, setIsFocused] = useState(false);
    const [isSPFocused, setSPIsFocused] = useState(false);
    const [screenMode, setScreenMode] = useState();
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const [SSNError, setSSNError] = useState('');
    const [prfSSNValid, setPrfSSNValid] = useState(true);
    const [SpouseSSNError, setSpouseSSNError] = useState('');
    const [SpouseSSNValid, setSpouseSSNValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isSpouse, setIsSpouse] = useState(false);
    const [schema, setSchema] = useState(null);

    const handleCopyClick = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            showSnackbar("Text Copied", 'info');
        });
    };

    useEffect(() => {
        (async () => {
            if (!taxPayerValues.SSN_ITIN) {
                if (props?.orderData) {
                    getTaxPayer(props?.orderData?.profile_id);
                    getMailingAddress(props?.orderData?.profile_id);
                    getBankInfo(props?.orderData?.profile_id);
                }
                setScreenMode(props?.screenType);
            }
        })();
    }, [props]);


    const handleBlur = (value) => {
        setIsFocused(false);
    };

    const handleFocus = (value) => {
        setIsFocused(true);
    };

    const getFormattedValue = (value) => {
        if (value) {
            const formatted = value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
            return formatted;
        } else {
            return value;
        }
    }


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'SSN_ITIN_tax') {
            const raw = value.replace(/\D/g, '');
            if (raw.length <= 9) {
                setTaxPayerValues({
                    ...taxPayerValues,
                    [name]: getFormattedValue(raw),
                });
            }
        } else {
            setTaxPayerValues({
                ...taxPayerValues,
                [name]: value,
            });
        }
    };

    const handleMailingInputChange = (e) => {
        const { name, value } = e.target;
        setMailingValue({
            ...mailingValue,
            [name]: value,
        });
    };

    const handleSpouseInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'SSN_ITIN_sp') {
            const raw = value.replace(/\D/g, '');
            if (raw.length <= 10) {
                setSpouseValue({
                    ...spouseValue,
                    [name]: getFormattedValue(raw),
                });
            }
        } else {
            setSpouseValue({
                ...spouseValue,
                [name]: value,
            });
        }
    };

    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    const getMaskedValue = (value) => {
        if (value.length <= 4) return value;
        const maskedLength = value.length - 4;
        return '*'.repeat(maskedLength) + value.slice(maskedLength)
    }

    const getTaxPayer = async (profile_id) => {
        setIsLoading(true);
        try {
            const response = await _get(`${FETCH_TAXPAYER}${profile_id}`);
            if (response.status == 200 && response.data?.Taxpayer) {
                taxPayerinitState = { ...response.data?.Taxpayer };
                taxPayerinitState.SSN_ITIN_tax = ssnPinFOrmat(response.data?.Taxpayer.SSN_ITIN);
                taxPayerinitState.date_of_birth = formatDate(response.data?.Taxpayer.date_of_birth);
                taxPayerinitState.phone_number = phoneNoFOrmat(response.data?.Taxpayer.phone_number);
                setTaxPayerValues(taxPayerinitState);
            }
            if (response.status == 200 && response.data?.Spouse) {
                setIsSpouse(true);
                spouseinitState = { ...response.data?.Spouse };
                spouseinitState.SSN_ITIN_sp = ssnPinFOrmat(response.data?.Spouse?.SSN_ITIN);
                spouseinitState.first_name_sp = response.data?.Spouse?.first_name;
                spouseinitState.last_name_sp = response.data?.Spouse?.last_name;
                spouseinitState.middle_initial_sp = response.data?.Spouse?.middle_initial;
                spouseinitState.date_of_birth_sp = formatDate(response.data?.Spouse?.date_of_birth);
                spouseinitState.gender_sp = response.data?.Spouse?.gender;
                spouseinitState.email_sp = response.data?.Spouse?.email;
                spouseinitState.phone = phoneNoFOrmat(response.data?.Spouse?.phone);
                setSpouseValue(spouseinitState);

                const profileSchema = yup.object().shape({
                    SSN_ITIN_tax: yup.string()
                        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
                        .required('SSN is required'),
                    first_name: yup.string().required('First Name is required'),
                    middle_initial: yup.string(),
                    last_name: yup.string().required('Last Name is required'),
                    date_of_birth: yup.string().required('DOB is required'),
                    occupation: yup.string().required('Occupation is required'),
                    phone_number: yup.string()
                        .required('Phone No. is required')
                        .matches(/^[0-9-]+$/, "Must contain only digits")
                        .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone No format must be XXX-XXX-XXXX or contain 10 digits'),
                    email: yup.string().email('Please enter valid E-mail ID').required('Email is required'),
                    SSN_ITIN_sp: yup.string()
                        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
                        .required('SSN is required'),
                    first_name_sp: yup.string().required('First Name is required'),
                    middle_initial_sp: yup.string(),
                    last_name_sp: yup.string().required('Last Name is required'),
                    date_of_birth_sp: yup.string().required('DOB is required'),
                    phone: yup.string()
                        .required('Phone No. is required')
                        .matches(/^[0-9-]+$/, "Must contain only digits")
                        .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone No format must be XXX-XXX-XXXX or contain 10 digits'),
                    email_sp: yup.string().email('Please enter valid E-mail ID').required('Email is required'),
                    street_address: yup.string().required('Street Address is required'),
                    apt_home_unit: yup.string().required('APT/UNIT is required').matches(/^[0-9]+$/, "Must be only digits"),
                    city: yup.string().required('City is required'),
                    state: yup.string().required('State is required'),
                    zipcode: yup.string().required('Zipcode is required').matches(/^[0-9]+$/, "Must be only digits"),
                });
                setSchema(profileSchema);
            } else {
                setIsSpouse(false);
                const profileSchema = yup.object().shape({
                    SSN_ITIN_tax: yup.string()
                        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
                        .required('SSN is required'),
                    first_name: yup.string().required('First Name is required'),
                    middle_initial: yup.string(),
                    last_name: yup.string().required('Last Name is required'),
                    date_of_birth: yup.string().required('DOB is required'),
                    occupation: yup.string().required('Occupation is required'),
                    phone_number: yup.string()
                        .required('Phone No. is required')
                        .matches(/^[0-9-]+$/, "Must contain only digits")
                        .matches(/^\d{3}-\d{3}-\d{4}$/, 'Phone No format must be XXX-XXX-XXXX or contain 10 digits'),
                    email: yup.string().email('Please enter valid E-mail ID').required('Email is required'),
                    street_address: yup.string().required('Street Address is required'),
                    apt_home_unit: yup.string().required('APT/UNIT is required').matches(/^[0-9]+$/, "Must be only digits"),
                    city: yup.string().required('City is required'),
                    state: yup.string().required('State is required'),
                    zipcode: yup.string().required('Zipcode is required').matches(/^[0-9]+$/, "Must be only digits"),
                });
                setSchema(profileSchema);
            }
            const taxPayerObj = { ...taxPayerinitState, ...spouseinitState };
            Object.entries(taxPayerObj).map(([key, value]) => {
                setValue(key, value)
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getTaxPayer", 'error');
            console.error('Error adding data:', error);
        }
    };

    const getMailingAddress = async (profile_id) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_MAILING_ADDRESS}${profile_id}`);
            if (response.status == 200 && response.data?.MailAddress) {
                mailinginitState = response.data?.MailAddress;
                setMailingValue(response.data?.MailAddress);
            }
            const mailingObj = { ...mailinginitState };
            Object.entries(mailingObj).map(([key, value]) => {
                setValue(key, value)
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getMailingAddress", 'error');
            console.error('Error adding data:', error);
        }
    };

    const getBankInfo = async (profile_id) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_BANK}${profile_id}`);
            if (response.status == 200 && response.data?.bankInfo) {
                bankInfoinitState = response.data?.bankInfo;
                setBankInfoValue(response.data?.bankInfo);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getBankInfo", 'error');
            console.error('Error adding data:', error);
        }
    };

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
        // resolver: yupResolver(profileSchema),
        resolver: schema ? yupResolver(schema) : undefined,
        mode: 'all',
    });

    const handleSSNValidate = async (SSN_ITIN_tax, profile_id, type = null) => {
        if (SSN_ITIN_tax) {
            try {
                setIsLoading(true);
                let pinSSN_ITIN = SSN_ITIN_tax.replace(/\D/g, '');
                const response = await _post(SSN_PIN_VALIDATION, { SSN_ITIN: pinSSN_ITIN, profile_id });
                if (!response.data.SSNValidation) {
                    if (type) {
                        setSpouseSSNError('Spouse SSN/ITIN already exists.'); setSpouseSSNValid(false);
                    } else { setSSNError('SSN/ITIN already exists.'); setPrfSSNValid(false); }
                } else {
                    if (type) { setSpouseSSNValid(true); setSpouseSSNError(''); }
                    else { setPrfSSNValid(true); setSSNError(''); }
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                if (type) { setSpouseSSNValid(false); setSpouseSSNError('Error validating SSN/ITIN'); }
                else { setPrfSSNValid(false); setSSNError('Error validating SSN/ITIN'); }
            }
        }
    };


    const onSubmit = (data) => {
        console.log('data', data);

        let spouseNewValue = {};
        if (isSpouse && spouseValue) {
            spouseValue.SSN_ITIN_sp = spouseValue.SSN_ITIN_sp.replace(/-/g, '');
            spouseValue.phone = parseInt(spouseValue.phone.replace(/-/g, ''));
            spouseNewValue = { ...spouseValue };
            for (const key of Object.keys(spouseNewValue)) {
                if (key.includes('_sp')) {
                    spouseNewValue[key.replace('_sp', '')] = spouseNewValue[key];
                    delete spouseNewValue[key];
                }
            }
            console.log('new spo', spouseNewValue);
        }
        taxPayerValues.SSN_ITIN_tax = taxPayerValues.SSN_ITIN_tax.replace(/-/g, '');
        taxPayerValues.phone_number = taxPayerValues.phone_number.replace(/-/g, '');
        taxPayerValues.SSN_ITIN = taxPayerValues.SSN_ITIN_tax;
        const payload = {
            file_no: props?.orderData?.file_no,
            cus_id: props?.orderData?.cus_id,
            Taxpayer: taxPayerValues,
        }
        if (!isEmptyObject(spouseNewValue)) {
            payload.Spouse = spouseNewValue;
        }
        console.log('payload', payload);
        createOrUpdateTaxPayer(payload, 'Update');

        const payloadMail = {
            profile_id: props?.orderData?.profile_id,
            file_no: props?.orderData?.file_no,
            cus_id: props?.orderData?.cus_id,
            MailAddress: mailingValue,
        }
        createOrUpdateMailingAddress(payloadMail, 'Update');
    }
    const createOrUpdateTaxPayer = async (payload, type) => {
        setIsLoading(true);
        try {
            const response = await _post(CREATE_OR_UPDATE_TAXPAYER, payload);
            console.log('createProfile', response);
            if (response.status == 200 && response.data?.profile) {
                console.log('Profile Updated Successfully', response.data);
                CreateNotification('Profile Updated Successfully', 'success');
                showSnackbar('Profile Updated Successfully', 'success');
                setScreenMode('View');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let errorMessage = "Getting error in createOrUpdateTaxPayer";
            if (error.response && error.response.data && error.response.data.message) {
                // If the error has a response message, use it
                errorMessage = error.response.data.message;
            }
            showSnackbar(errorMessage, 'error');
            console.error('Error adding data:', error);
        }
    };


    const createOrUpdateMailingAddress = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_OR_UPDATE_MAILING_ADDRESS, payload);
            console.log('createOrUpdateMailingAddress', response);
            if (response.status == 200 && response.data?.createAddress) {
                console.log('Mailing Address Updated Successfully', response.data);
                CreateNotification('Mailing Address Updated Successfully', 'success');
                showSnackbar('Mailing Address Updated Successfully', 'success');
                setScreenMode('View');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateMailingAddress", 'error');
            console.error('Error adding data:', error);
        }
    };

    const cancel = () => {
        setScreenMode('View');
    }
    const edit = () => {
        setScreenMode('Edit');
    }


    return (
        <>
            {isLoading && <Loader />}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'order__tabscontent ' + (screenMode == 'Edit' ? 'grid-cols-1' : '')}>
                    <div className='order__tabcontent'>
                        <div className='order__contentcard'>
                            <div className='order__contentcard--header'>
                                <p className='order__contentcard--title'>Tax Payer</p>
                                <div className='order__contentcard--actions'>
                                    {screenMode == 'View' && <div className='order__contentcard--action' onClick={() => edit()}>
                                        <img src='/assets/images/order-info-edit.svg' alt='edit' />
                                        <span className='text-sm text-[#6D6E71]'>Edit</span>

                                    </div>}
                                    {screenMode == 'Edit' && <div className='order__contentcard--action' onClick={() => cancel()}>
                                        <img src='/assets/images/fullview-icon.svg' alt='edit' />
                                        <span className='text-sm text-[#6D6E71]'>View</span>

                                    </div>}
                                    {/* <div className='order__contentcard--action'>
                                    <img src='/assets/images/order-copyall.svg' alt='edit' />
                                    <span className='text-sm text-[#6D6E71]'>Copy All</span>
                                </div> */}
                                </div>
                            </div>
                            <div className='order__contentcard--content'>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{formatSSNForDisplay(taxPayerValues?.SSN_ITIN)}</span>
                                            <button className='order__contentcard--details-copy'>
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>}
                                    {screenMode == 'Edit' && <>

                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder='SSN/ITIN' {...register('SSN_ITIN_tax')} name="SSN_ITIN_tax"
                                                value={isFocused ? taxPayerValues.SSN_ITIN_tax : formatSSNForDisplay(taxPayerValues.SSN_ITIN_tax)}
                                                onChange={(e) => {
                                                    register('SSN_ITIN_tax').onChange(e);
                                                    handleInputChange(e);
                                                }}
                                                onBlur={(e) => {
                                                    register('SSN_ITIN_tax').onBlur(e);
                                                    handleBlur(taxPayerValues.SSN_ITIN_tax);
                                                    handleSSNValidate(e.target.value, props?.orderData?.profile_id);
                                                }}
                                                onFocus={(e) => {
                                                    handleFocus(taxPayerValues.SSN_ITIN_tax,);
                                                }}

                                            // value={isFocused ? ssnPinFOrmat(taxPayerValues.SSN_ITIN_tax) : getMaskedValue(taxPayerValues.SSN_ITIN_tax)}
                                            />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues.SSN_ITIN_tax)} className='order__contentcard--details-copy'>
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>

                                        <p className='text-red-600'>{errors.SSN_ITIN_tax?.message}</p>
                                        {SSNError && <p className='text-red-600'> {SSNError} </p>}

                                    </>}
                                    <span className='text-sm italic font-light'>SSN/ITIN</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>  <DatePipe date={taxPayerValues?.date_of_birth} /></span>
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.date_of_birth)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="date" placeholder="DD/MM/YYYY" {...register('date_of_birth')} name="date_of_birth" onChange={(e) => {
                                                register('date_of_birth').onChange(e);
                                                handleInputChange(e);
                                            }} value={taxPayerValues.date_of_birth} />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.date_of_birth)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.date_of_birth?.message}</p>
                                    </>
                                    }
                                    <span className='text-sm italic font-light'>Date of Birth</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{taxPayerValues?.email}</span>
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.email)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="E Mail ID" {...register('email')} name="email" onChange={(e) => {
                                                register('email').onChange(e);
                                                handleInputChange(e);
                                            }} value={taxPayerValues.email} />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues.email)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.email?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>E Mail</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{taxPayerValues?.first_name}</span>
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.first_name)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" {...register('first_name')} name="first_name" onChange={(e) => {
                                                register('first_name').onChange(e);
                                                handleInputChange(e);
                                            }} value={taxPayerValues.first_name} />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues.first_name)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600' >{errors.first_name?.message}</p> </>}
                                    <span className='text-sm italic font-light '>First Name</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{taxPayerValues?.middle_initial}</span>
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.middle_initial)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>}
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Middle Initial" {...register('middle_initial')} name="middle_initial" onChange={(e) => {
                                                register('middle_initial').onChange(e);
                                                handleInputChange(e);
                                            }} value={taxPayerValues.middle_initial} />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues.middle_initial)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.middle_initial?.message}</p> </>}
                                    <span className='text-sm italic font-light'>Middle Initial</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{taxPayerValues?.last_name}</span>
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.last_name)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Last Name"  {...register('last_name')} name="last_name" onChange={(e) => {
                                                register('last_name').onChange(e);
                                                handleInputChange(e);
                                            }} value={taxPayerValues.last_name} />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues.last_name)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.last_name?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Last Name</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{taxPayerValues?.occupation}</span>
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.occupation)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Occupation" {...register('occupation')} name="occupation" onChange={(e) => {
                                                register('occupation').onChange(e);
                                                handleInputChange(e);
                                            }} value={taxPayerValues.occupation} />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues.occupation)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.occupation?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Occupation</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{phoneNoFOrmat(taxPayerValues?.phone_number)}</span>
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues?.phone_number)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>}
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Mobile number"{...register('phone_number')} name="phone_number" onChange={(e) => {
                                                register('phone_number').onChange(e);
                                                handleInputChange(e);
                                            }} value={phoneNoFOrmat(taxPayerValues.phone_number)} />
                                            <button type="button" onClick={() => handleCopyClick(taxPayerValues.phone_number)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.phone_number?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Mobile Number</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isSpouse && <div className='order__tabcontent'>
                        <div className='order__contentcard'>
                            <div className='order__contentcard--header'>
                                <p className='order__contentcard--title'>Spouse</p>
                            </div>
                            <div className='order__contentcard--content'>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{ssnPinFOrmat(spouseValue?.SSN_ITIN_sp)}</span>
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.SSN_ITIN_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>}
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder='SSN/ITIN' {...register('SSN_ITIN_sp')} name="SSN_ITIN_sp" onChange={(e) => {
                                                register('SSN_ITIN_sp').onChange({ target: { value: e.target.value.slice(0, 12) } });
                                                handleSpouseInputChange(e);
                                            }}
                                                onFocus={() => setSPIsFocused(true)}
                                                onBlur={(e) => {
                                                    setSPIsFocused(false)
                                                    handleSSNValidate(e.target.value, props?.orderData?.profile_id, 'spouse')
                                                }}

                                                value={isSPFocused ? ssnPinFOrmat(spouseValue.SSN_ITIN_sp) : getMaskedValue(spouseValue.SSN_ITIN_sp)} />
                                            <button type="button" onClick={() => handleCopyClick(spouseValue.SSN_ITIN_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.SSN_ITIN_sp?.message}</p></>}
                                    {SpouseSSNError && <p className='text-red-600'> {SpouseSSNError} </p>}

                                    <span className='text-sm italic font-light'>SSN/ITIN</span>

                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>  <DatePipe date={spouseValue?.date_of_birth_sp} /></span>
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.date_of_birth_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>}
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="date" placeholder="DD/MM/YYYY" {...register('date_of_birth_sp')} name="date_of_birth_sp" onChange={(e) => {
                                                register('date_of_birth_sp').onChange(e);
                                                handleSpouseInputChange(e);
                                            }} value={spouseValue.date_of_birth_sp} />
                                            <button type="button" onClick={() => handleCopyClick(spouseValue.date_of_birth_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.date_of_birth_sp?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Date of Birth</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{spouseValue?.email_sp}</span>
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.email_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>}
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="E Mail" {...register('email_sp')} name="email_sp" onChange={(e) => {
                                                register('email_sp').onChange(e);
                                                handleSpouseInputChange(e);
                                            }} value={spouseValue.email_sp} />
                                            <button type="button" onClick={() => handleCopyClick(spouseValue.email_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.email_sp?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>E Mail</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{spouseValue?.first_name_sp}</span>
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.first_name_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>}
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="First Name" {...register('first_name_sp')} name="first_name_sp" onChange={(e) => {
                                                register('first_name_sp').onChange(e);
                                                handleSpouseInputChange(e);
                                            }} value={spouseValue.first_name_sp} />
                                            <button type="button" onClick={() => handleCopyClick(spouseValue.first_name_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.first_name_sp?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light '>First Name</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{spouseValue?.middle_initial_sp}</span>
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.middle_initial_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Middle Initial" {...register('middle_initial_sp')} name="middle_initial_sp" onChange={(e) => {
                                                register('middle_initial_sp').onChange(e);
                                                handleSpouseInputChange(e);
                                            }} value={spouseValue.middle_initial_sp} />
                                            <button type="button" onClick={() => handleCopyClick(spouseValue.middle_initial_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.middle_initial_sp?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Middle Initial</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{spouseValue?.last_name_sp}</span>
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.last_name_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Last Name" {...register('last_name_sp')} name="last_name_sp" onChange={(e) => {
                                                register('last_name_sp').onChange(e);
                                                handleSpouseInputChange(e);
                                            }} value={spouseValue.last_name_sp} />
                                            <button type="button" onClick={() => handleCopyClick(spouseValue.last_name_sp)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.last_name_sp?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Last Name</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{phoneNoFOrmat(spouseValue?.phone)}</span>
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.phone)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Mobile number"  {...register('phone')} name="phone" onChange={(e) => {
                                                register('phone').onChange({ target: { value: e.target.value.slice(0, 12) } });
                                                handleSpouseInputChange(e);
                                            }} value={phoneNoFOrmat(spouseValue?.phone)} />
                                            <button type="button" onClick={() => handleCopyClick(spouseValue?.phone)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.phone?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Mobile Number</span>
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className='order__tabcontent'>
                        <div className='order__contentcard'>
                            <div className='order__contentcard--header'>
                                <p className='order__contentcard--title'>Mailing Address</p>
                            </div>
                            <div className='order__contentcard--content'>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{mailingValue?.street_address}</span>
                                            <button type="button" onClick={() => handleCopyClick(mailingValue?.street_address)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Street Address" name="street_address" {...register('street_address')} onChange={(e) => {
                                                register('street_address').onChange(e);
                                                handleMailingInputChange(e);
                                            }} value={mailingValue.street_address} />
                                            <button type="button" onClick={() => handleCopyClick(mailingValue.street_address)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.street_address?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>Street Address</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{mailingValue?.city}</span>
                                            <button type="button" onClick={() => handleCopyClick(mailingValue?.city)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="City" name="city" {...register('city')} onChange={(e) => {
                                                register('city').onChange(e);
                                                handleMailingInputChange(e);
                                            }} value={mailingValue.city} />
                                            <button type="button" onClick={() => handleCopyClick(mailingValue?.city)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.city?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>City</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{mailingValue?.state}</span>
                                            <button type="button" onClick={() => handleCopyClick(mailingValue?.state)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="State" name="state" {...register('state')} onChange={(e) => {
                                                register('state').onChange(e);
                                                handleMailingInputChange(e);
                                            }} value={mailingValue.state} />
                                            <button type="button" onClick={() => handleCopyClick(mailingValue.state)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.state?.message}</p>
                                    </>}
                                    <span className='text-sm italic font-light'>State</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{mailingValue?.zipcode}</span>
                                            <button type="button" onClick={() => handleCopyClick(mailingValue?.zipcode)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    {screenMode == 'Edit' && <>
                                        <div className="order__contentcard--details-input">
                                            <input type="text" placeholder="Zip Code" name="zipcode" {...register('zipcode')}
                                                onChange={(e) => {
                                                    register('zipcode').onChange(e);
                                                    handleMailingInputChange(e);
                                                }}
                                                value={mailingValue.zipcode} />
                                            <button type="button" onClick={() => handleCopyClick(mailingValue.zipcode)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <p className='text-red-600'>{errors.zipcode?.message}</p></>}
                                    <span className='text-sm italic font-light '>Zip Code</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {screenMode == 'View' && <div className='order__tabcontent'>
                        <div className='order__contentcard'>
                            <div className='order__contentcard--header'>
                                <p className='order__contentcard--title'>Bank Information</p>
                            </div>
                            <div className='order__contentcard--content'>
                                <div className='order__contentcard--details'>
                                    <div className="order__contentcard--details-input">
                                        <span className='text-sm'>{bankInfoValue?.bank_name}</span>
                                        <button type="button" onClick={() => handleCopyClick(bankInfoValue?.bank_name)} className='order__contentcard--details-copy' >
                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                        </button>
                                    </div>
                                    <span className='text-sm italic font-light'>Bank Name</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{bankInfoValue?.account_holder_name}</span>
                                            <button type="button" onClick={() => handleCopyClick(bankInfoValue?.account_holder_name)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    <span className='text-sm italic font-light'>Account Holder Name</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{bankInfoValue?.routing_number}</span>
                                            <button type="button" onClick={() => handleCopyClick(bankInfoValue?.routing_number)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    <span className='text-sm italic font-light'>Routing Number</span>
                                </div>
                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{bankInfoValue?.account_number}</span>
                                            <button type="button" onClick={() => handleCopyClick(bankInfoValue?.account_number)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    <span className='text-sm italic font-light '>Account Number</span>
                                </div>

                                <div className='order__contentcard--details'>
                                    {screenMode == 'View' &&
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{bankInfoValue?.account_type}</span>
                                            <button type="button" onClick={() => handleCopyClick(bankInfoValue?.account_type)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                    }
                                    <span className='text-sm italic font-light '>Account Type</span>
                                </div>


                            </div>
                        </div>
                    </div>}

                </div>
                {screenMode == 'Edit' &&
                    <div className="buttons">
                        <button type='button' onClick={() => cancel()} className="buttons__btn buttons__btn--cancel">
                            Cancel
                        </button>

                        <button type='submit' disabled={!prfSSNValid || !SpouseSSNValid} className="buttons__btn buttons__btn--save">
                            Update
                        </button>
                        {/* <button type='button' disabled={!userData.profile_id} className="buttons__btn buttons__btn--save" onClick={() => nextPage()}>
                    Next
                </button> */}
                    </div>
                }
            </form>
        </>
    );
}