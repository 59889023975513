import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { _get, _post, _put } from '../utills/apiClient';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from './toaster';

const schema = yup.object().shape({
  SSN_ITIN: yup.number().typeError('SSN/ITIN is required').integer().positive().required('SSN/ITIN is required'),
  first_name: yup.string().required('First Name is required'),
  middle_initial: yup.string().required('Middle Initial is required'),
  last_name: yup.string().required('Last Name is required'),
  date_of_birth: yup.string().required('DOB is required'),
  occupation: yup.string().required('Occupation is required'),
  phone_number: yup.number().integer().positive().required('Phone no is required'),
  email: yup.string().email('Please enter valid E-mail ID').required('Email is required'),
  gender: yup.string().required('Gender is required'),
  marital_status: yup.string(),
  SSN_ITIN_sp: yup.string().when("marital_status", {
    is: (marital_status) => marital_status && marital_status == 'married',
    then: (schema) => schema.required('SSN/ITIN is required'),
    otherwise: (schema) => schema,
  }),
  first_name_sp: yup.string().when("marital_status", {
    is: (marital_status) => marital_status && marital_status == 'married',
    then: (schema) => schema.required('First Name is required'),
    otherwise: (schema) => schema,
  }),
  middle_initial_sp: yup.string().when("marital_status", {
    is: (marital_status) => marital_status && marital_status == 'married',
    then: (schema) => schema.required('Middle Initial is required'),
    otherwise: (schema) => schema,
  }),
  last_name_sp: yup.string().when("marital_status", {
    is: (marital_status) => marital_status && marital_status == 'married',
    then: (schema) => schema.required('Last Name is required'),
    otherwise: (schema) => schema,
  }),
  date_of_birth_sp: yup.string().when("marital_status", {
    is: (marital_status) => marital_status && marital_status == 'married',
    then: (schema) => schema.required('DOB is required'),
    otherwise: (schema) => schema,
  }),
  relationship: yup.string().when("marital_status", {
    is: (marital_status) => marital_status && marital_status == 'married',
    then: (schema) => schema.required('Relationship is required'),
    otherwise: (schema) => schema,
  }),
  // phone: yup.string().when("marital_status", {
  //   is: (marital_status) => marital_status && marital_status == 'married',
  //   then: (schema) => schema.required('Phone no is required'),
  //   otherwise: (schema) => schema,
  // }),
  gender_sp: yup.string().when("marital_status", {
    is: (marital_status) => marital_status && marital_status == 'married',
    then: (schema) => schema.required('Email is required'),
    otherwise: (schema) => schema,
  }),

  bank_name: yup.string().required('Bank Name is required'),
  account_holder_name: yup.string().required('Account Holder Name is required'),
  routing_number: yup.number().integer().positive().required('Routing no is required'),
  account_number: yup.number().integer().positive().required('Account no is required'),
  account_type: yup.string().required('Account Type is required'),

});


function EditProfile({ history, match }) {
  const { id } = useParams();
  const isAddMode = !id;
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  let taxPayerinitState = {
    SSN_ITIN: "",
    first_name: "",
    last_name: "",
    middle_initial: "",
    date_of_birth: "",
    gender: "",
    occupation: "",
    phone_number: "",
    email: "",
    marital_status: "",
    userId: ""
  };

  let spouseinitState = {
    SSN_ITIN_sp: "",
    first_name_sp: "",
    last_name_sp: "",
    middle_initial_sp: "",
    date_of_birth_sp: "",
    gender_sp: "female",
    relationship: "Spouse",
    phone: ""
  };

  let mailinginitState = {
    street_address: "",
    apt_home_unit: "",
    city: "",
    state: "",
    zipcode: ""
  };

  let dayCareinitState = {
    daycare_provider: "",
    EIN_SSN: "",
    address: "",
    phone_number: "",
    amount_paid: ""
  };

  let bankInfoinitState = {
    bank_name: "",
    account_holder_name: "",
    routing_number: "",
    account_number: "",
    account_type: ""
  };

  const [dependentFields, setDependentFields] = useState([
    { dependentCount: '', first_name: '', middle_initial: '', last_name: '', SSN_ITIN: '', gender: '', relationship: '', date_of_birth: '' },
  ]);

  const [taxPayerValues, setTaxPayerValues] = useState(taxPayerinitState);
  const [spouseValue, setSpouseValue] = useState(spouseinitState);
  const [mailingValue, setMailingValue] = useState(mailinginitState);
  const [dayCareValue, setDayCareValue] = useState(dayCareinitState);
  const [bankInfoValue, setBankInfoValue] = useState(bankInfoinitState);
  const [userData, setUserData] = useState();
  let [updateFieldsData, setUpdateFieldsData] = useState({});

  useEffect(() => {
    (async () => {
      setUserData(JSON.parse(sessionStorage.getItem('taxData')));
      if (!isAddMode) {
        try {

          const response = await _get(`/profile/fetchUserProfile?id=${id}`);

          if (response.data?.Taxpayer) {
            taxPayerinitState = { ...response.data?.Taxpayer };
            taxPayerinitState.date_of_birth = formatDate(taxPayerinitState.date_of_birth);
            taxPayerinitState.phone_number = parseInt(taxPayerinitState.phone_number, 10);
            setTaxPayerValues(taxPayerinitState);
          }
          if (response.data?.spouse && response.data?.spouse[0]) {
            spouseinitState = { ...response.data?.spouse[0] };
            spouseinitState.SSN_ITIN_sp = response.data?.spouse[0]?.SSN_ITIN;
            spouseinitState.first_name_sp = response.data?.spouse[0]?.first_name;
            spouseinitState.last_name_sp = response.data?.spouse[0]?.last_name;
            spouseinitState.middle_initial_sp = response.data?.spouse[0]?.middle_initial;
            spouseinitState.date_of_birth_sp = formatDate(response.data?.spouse[0]?.date_of_birth);
            spouseinitState.gender_sp = response.data?.spouse[0]?.gender;
            setSpouseValue(spouseinitState);
          }
          if (response.data?.mailingAddress[0]) {
            mailinginitState = response.data?.mailingAddress[0];
            setMailingValue(response.data?.mailingAddress[0]);
          }
          if (response.data?.dayCare) {
            dayCareinitState = response.data?.dayCare;
            setDayCareValue(response.data?.dayCare);
          }
          if (response.data?.bankInfo[0]) {
            bankInfoinitState = response.data?.bankInfo[0];
            setBankInfoValue(response.data?.bankInfo[0]);
          }
          if (response.data?.dependent && response.data?.dependent.length > 0) {
            response.data?.dependent.map((data) => {
              data.date_of_birth = formatDate(data.date_of_birth)
            })
            setDependentFields([...response.data?.dependent])
          }
          const allFieldsObg = { ...taxPayerinitState, ...spouseinitState, ...dayCareinitState, ...mailinginitState, ...bankInfoinitState };
          setUpdateFieldsData({
            Taxpayer: taxPayerinitState,
            spouse: spouseinitState,
            mailingAddress: mailinginitState,
            dayCare: dayCareinitState,
            bankInfo: bankInfoinitState,
            dependent: response.data?.dependent,
          });

          Object.entries(allFieldsObg).map(([key, value]) => {
            setValue(key, value)
          });

        } catch (error) {
          showSnackbar('Getting Error on fetchUserProfile', 'error');
          console.error('Error fetching data:', error);
        }
      }
    })();
  }, []);

  const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  });

  const addFields = () => {
    let newfield = { dependentCount: '', first_name: '', middle_initial: '', last_name: '', SSN_ITIN: '', gender: '', relationship: '', date_of_birth: '' };
    setDependentFields([...dependentFields, newfield])
  }
  const removeFields = (index) => {
    let data = [...dependentFields];
    data.splice(index, 1)
    setDependentFields(data)
  }

  const formatDate = (isoDate) => {
    return isoDate.split('T')[0];
  };

  const handleReset = () => {
    setTaxPayerValues(taxPayerinitState);
    setDayCareValue(dayCareinitState);
    setBankInfoValue(bankInfoinitState);
    setSpouseValue(spouseinitState);
    setMailingValue(mailinginitState);
    setDependentFields([{ dependentCount: '', first_name: '', middle_initial: '', last_name: '', SSN_ITIN: '', gender: '', relationship: '', date_of_birth: '' }])
    reset();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaxPayerValues({
      ...taxPayerValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setTaxPayerValues({
      ...taxPayerValues,
      marital_status: checked ? 'married' : '',
    });
  };

  const handleSpouseInputChange = (e) => {
    const { name, value } = e.target;
    setSpouseValue({
      ...spouseValue,
      [name]: value,
    });
  };

  const handleMailingInputChange = (e) => {
    const { name, value } = e.target;
    setMailingValue({
      ...mailingValue,
      [name]: value,
    });
  };

  const handleDayCareInputChange = (e) => {
    const { name, value } = e.target;
    setDayCareValue({
      ...dayCareValue,
      [name]: value,
    });
  };

  const handleBankInfoInputChange = (e) => {
    const { name, value } = e.target;
    setBankInfoValue({
      ...bankInfoValue,
      [name]: value,
    });
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const onSubmit = (data) => {

    let spouseNewValue = {};
    if (taxPayerValues.marital_status == 'married' && spouseValue) {
      spouseNewValue = { ...spouseValue };
      for (const key of Object.keys(spouseNewValue)) {
        if (key.includes('_sp')) {
          spouseNewValue[key.replace('_sp', '')] = spouseNewValue[key];
          delete spouseNewValue[key];
        }
      }
    }
    const payload = {
      file_no: userData?.file_no,
      Taxpayer: taxPayerValues,
      mailingAddress: mailingValue,
      dependent: dependentFields,
      dayCare: dayCareValue,
      bankInfo: bankInfoValue,
    }

    if (!isEmptyObject(spouseNewValue)) {
      payload.spouse = spouseNewValue;
    }

    if (isAddMode) {
      createProfile(payload);
    } else {
      if (!('profile_id' in payload.Taxpayer)) {
        payload.Taxpayer.profile_id = updateFieldsData.Taxpayer.profile_id;
      }
      if (payload?.spouse && !('dep_id' in payload.spouse)) {
        payload.spouse.dep_id = updateFieldsData.spouse.dep_id;
      }
      if (!('day_care_id' in payload.dayCare)) {
        payload.dayCare.day_care_id = updateFieldsData.dayCare.day_care_id;
      }
      if (!('address_id' in payload.mailingAddress)) {
        payload.mailingAddress.address_id = updateFieldsData.mailingAddress.address_id;
      }
      if (!('bank_id' in payload.bankInfo)) {
        payload.bankInfo.bank_id = updateFieldsData.bankInfo.bank_id;
      }
      if (payload.dependent && payload.dependent.length > 0) {
        payload.dependent.map((data, index) => {
          if (!('dep_id' in data)) {
            data.dep_id = updateFieldsData.dependent[0].dep_id;
          }
        })
        payload.bankInfo.bank_id = updateFieldsData.bankInfo.bank_id;
      }
      updateProfile(payload);
    }
  };

  const createProfile = async (payload) => {
    try {
      const response = await _post('/profile/createProfile', payload);
      console.log('createProfile', response);
      if (response.status == 200 && response.data?.profile) {
        const user = JSON.parse(sessionStorage.getItem('taxData'));
        user.profile_id = response.data?.profile;
        sessionStorage.setItem('taxData', JSON.stringify(user));
        showSnackbar('Profile Created Successfully', 'success');
        navigate(`/account/questionnaire`);
      }
    } catch (error) {
      showSnackbar("Getting error in createProfile", 'error');
      console.error('Error adding data:', error);
      // Handle errors
    }
  };

  const updateProfile = async (payload) => {
    try {
      const response = await _put('/profile/updateprofile', payload);
      if (response.status == 200 && response.data?.profile) {
        showSnackbar('Profile Updated Successfully', 'success');
        navigate(`/account/questionnaire}`);
      }
    } catch (error) {
      showSnackbar("Getting error in updateProfile", 'error');
      console.error('Error adding data:', error);
    }
  };

  const handleFormChange = (index, event) => {
    let data = [...dependentFields];
    data[index][event.target.name] = event.target.value;
    setDependentFields(data);
  }

  return (
    <div className='editprofile'>
      <h1 className='editprofile__title'>Complete Your Profile</h1>
      <form className='editprofile__content' onSubmit={handleSubmit(onSubmit)}>
        <div className='editprofile__tax--profilepic'>
          <img src='/assets/images/profile-pic-placeholder.svg' alt='profile pic'/>
        </div>
        <div className='flex flex-col gap-8'>
          <div className='editprofile__tax'>
            <h2 className='editprofile__tax--title'>Tax Payer</h2>
            <div className='editprofile__tax--form'>
              <div className='flex flex-col gap-4'>
                <input type='number' placeholder='*SSN / ITIN' {...register('SSN_ITIN')} name="SSN_ITIN" onChange={handleInputChange} value={taxPayerValues.SSN_ITIN} />
                <p className='text-red-600'>{errors.SSN_ITIN?.message}</p>
              </div>
              <div className='flex flex-col gap-4'>
                <input type='text' id="first_name" placeholder='*First Name' {...register('first_name')} name="first_name" onChange={handleInputChange} value={taxPayerValues.first_name}

                />
                <p className='text-red-600' >{errors.first_name?.message}</p>
              </div>
              <div className='flex flex-col gap-4'><input type='text' placeholder='*Middle Initial' {...register('middle_initial')} name="middle_initial" onChange={handleInputChange} value={taxPayerValues.middle_initial} /><p className='text-red-600'>{errors.middle_initial?.message}</p></div>
              <div className='flex flex-col gap-4'><input type='text' placeholder='*Last Name' {...register('last_name')} name="last_name" onChange={handleInputChange} value={taxPayerValues.last_name} /><p className='text-red-600'>{errors.last_name?.message}</p></div>
              <div className='flex flex-col gap-4'><input type='date' placeholder='*Date of Birth' {...register('date_of_birth')} name="date_of_birth" onChange={handleInputChange} value={taxPayerValues.date_of_birth} /><p className='text-red-600'>{errors.date_of_birth?.message}</p></div>
              <div className='flex flex-col gap-4'><input type='text' placeholder='*Occupation' {...register('occupation')} name="occupation" onChange={handleInputChange} value={taxPayerValues.occupation} /><p className='text-red-600'>{errors.occupation?.message}</p></div>
              <div className='flex flex-col gap-4'><input type='phone_number' placeholder='*Phone (Cell) #' {...register('phone_number')} name="phone_number" onChange={handleInputChange} value={taxPayerValues.phone_number} /><p className='text-red-600'>{errors.phone_number?.message}</p></div>
              <div className='flex flex-col gap-4'><input type='email' placeholder='*E Mail ID' {...register('email')} name="email" onChange={handleInputChange} value={taxPayerValues.email} /><p className='text-red-600'>{errors.email?.message}</p></div>
              <div className='flex flex-col gap-4'>
                <select name='gender' id='gender' placeholder='Gender' {...register('gender')} onChange={handleInputChange} value={taxPayerValues.gender}>
                  <option value="">Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <p className='text-red-600'>{errors.gender?.message}</p>
              </div>
              <div className='flex gap-4 items-center justify-start'>
                <input type='checkbox' className='w-5 h-5' name="marital_status" {...register('marital_status')} onChange={handleCheckboxChange} value={taxPayerValues.marital_status} />
                <label>Married</label>
              </div>
            </div>
          </div>

          {taxPayerValues.marital_status == 'married' ? (
            <div className='editprofile__tax'>
              <h2 className='editprofile__tax--title'>Spouse</h2>
              <div className='editprofile__tax--form'>
                <div className='flex flex-col gap-4'>
                  <input type='number' placeholder='*SSN / ITIN' {...register('SSN_ITIN_sp')} name="SSN_ITIN_sp" onChange={handleSpouseInputChange} value={spouseValue.SSN_ITIN_sp} />
                  <p className='text-red-600'>{errors.SSN_ITIN_sp?.message}</p>
                </div>
                <div className='flex flex-col gap-4'><input type='text' placeholder='*First Name' {...register('first_name_sp')} name="first_name_sp" onChange={handleSpouseInputChange} value={spouseValue.first_name_sp} /><p className='text-red-600'>{errors.first_name_sp?.message}</p></div>
                <div className='flex flex-col gap-4'><input type='text' placeholder='*Middle Initial' {...register('middle_initial_sp')} name="middle_initial_sp" onChange={handleSpouseInputChange} value={spouseValue.middle_initial_sp} /><p className='text-red-600'>{errors.middle_initial_sp?.message}</p></div>
                <div className='flex flex-col gap-4'><input type='text' placeholder='*Last Name' {...register('last_name_sp')} name="last_name_sp" onChange={handleSpouseInputChange} value={spouseValue.last_name_sp} /><p className='text-red-600'>{errors.last_name_sp?.message}</p></div>
                <div className='flex flex-col gap-4'><input type='date' placeholder='*Date of Birth' {...register('date_of_birth_sp')} name="date_of_birth_sp" onChange={handleSpouseInputChange} value={spouseValue.date_of_birth_sp} /><p className='text-red-600'>{errors.date_of_birth_sp?.message}</p></div>
                {/* <div className='flex flex-col gap-4'><input type='text' placeholder='*Relationship' {...register('relationship')} name="relationship" onChange={handleSpouseInputChange} value={spouseValue.relationship} /><p className='text-red-600'>{errors.relationship?.message}</p></div> */}
                {/* <div className='flex flex-col gap-4'>
                  <select name='gender_sp'
                    placeholder='Gender' {...register('gender_sp')}
                    onChange={handleSpouseInputChange} value={spouseValue.gender_sp} >
                    <option value="">Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <p className='text-red-600'>{errors.gender_sp?.message}</p>
                </div>
                <div className='flex flex-col gap-4'><input type='phone' placeholder='*Phone (Cell) #' {...register('phone')} name="phone" onChange={handleSpouseInputChange} value={spouseValue.phone} /><p className='text-red-600'>{errors.phone?.message}</p></div> */}

              </div>
            </div>
          ) : null}


          <div className='editprofile__tax'>
            <h2 className='editprofile__tax--title'>Mailing Address</h2>
            <div className='editprofile__tax--form'>
              <input type='text' placeholder='Street Address' name="street_address" onChange={handleMailingInputChange} value={mailingValue.street_address} />
              <input type='number' placeholder='APT / UNIT' name="apt_home_unit" onChange={handleMailingInputChange} value={mailingValue.apt_home_unit} />
              <input type='text' placeholder='City' name="city" onChange={handleMailingInputChange} value={mailingValue.city} />
              <input type='text' placeholder='State' name="state" onChange={handleMailingInputChange} value={mailingValue.state} />
              <input type='number' placeholder='ZIP Code' name="zipcode" onChange={handleMailingInputChange} value={mailingValue.zipcode} />
            </div>
          </div>
          <div className='editprofile__tax'>
            <h2 className='editprofile__tax--title'>Dependent</h2>
            <table className="editprofile__tax--table">
              <thead>
                <tr>
                  <th>Dependent</th>
                  <th>First Name</th>
                  <th>M.I</th>
                  <th>Last Name</th>
                  <th>SSN/ITIN</th>
                  <th>Gender</th>
                  <th>Relationship</th>
                  <th>DOB</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {dependentFields.map((dependentField, index) => {
                  return (
                    <tr key={index}>

                      <td>Dependent {index + 1}</td>
                      <td><input
                        name='first_name'
                        placeholder='First Name'
                        value={dependentField.first_name}
                        onChange={event => handleFormChange(index, event)}
                      /></td>
                      <td><input
                        name='middle_initial'
                        placeholder='Middle Initial'
                        value={dependentField.middle_initial}
                        onChange={event => handleFormChange(index, event)}
                      /></td>
                      <td><input
                        name='last_name'
                        placeholder='Last Name'
                        value={dependentField.last_name}
                        onChange={event => handleFormChange(index, event)}
                      /></td>
                      <td><input
                        name='SSN_ITIN'
                        placeholder='SSN/ITIN'
                        value={dependentField.SSN_ITIN}
                        onChange={event => handleFormChange(index, event)}
                      /></td>
                      <td><select name='gender'
                        placeholder='Gender'
                        value={dependentField.gender}
                        onChange={event => handleFormChange(index, event)}>
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select></td>


                      <td><input
                        name='relationship'
                        placeholder='Relationship'
                        value={dependentField.relationship}
                        onChange={event => handleFormChange(index, event)}
                      /></td>
                      <td>
                        <input
                          type='date'
                          placeholder='DOB'
                          name="date_of_birth"
                          value={dependentField.date_of_birth}
                          onChange={event => handleFormChange(index, event)}
                        /></td>
                      <td>
                        <button type='button' onClick={() => removeFields(index)}>Remove</button>
                      </td>

                    </tr>
                  )
                })}
              </tbody>

            </table>
            <button type='button' onClick={addFields}>Add More..</button>
          </div>
          <div className='editprofile__tax'>
            <h2 className='editprofile__tax--title'>Day Care Information for Dependents (Child) aged between 1-12. Both parents MUST be working.</h2>
            <table className="editprofile__tax--table">
              <thead>
                <tr>
                  <th>Day Care Provider</th>
                  <th>EIN or SSN</th>
                  <th>Address</th>
                  <th>Phone Number</th>
                  <th>Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input
                    name='daycare_provider'
                    placeholder='Day Care Provider'
                    value={dayCareValue.daycare_provider}
                    onChange={handleDayCareInputChange}
                  /></td>
                  <td><input
                    name='EIN_SSN'
                    placeholder='EIN or SSN'
                    value={dayCareValue.EIN_SSN}
                    onChange={handleDayCareInputChange}
                  /></td>
                  <td><input
                    name='address'
                    placeholder='Address'
                    value={dayCareValue.address}
                    onChange={handleDayCareInputChange}
                  /></td>
                  <td><input
                    name='phone_number'
                    placeholder='Phone Number'
                    value={dayCareValue.phone_number}
                    onChange={handleDayCareInputChange}
                  /></td>
                  <td><input
                    name='amount_paid'
                    placeholder='Amount Paid'
                    value={dayCareValue.amount_paid}
                    onChange={handleDayCareInputChange}
                  /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='editprofile__tax'>
            <h2 className='editprofile__tax--title'>Bank Information for Direct Deposit (Refund) and Direct Debit (Balance Due)</h2>
            <table className="editprofile__tax--table">
              <thead>
                <tr>
                  <th>Bank Name</th>
                  <th>Account Holder Name</th>
                  <th>Routing Number</th>
                  <th>Account Number</th>
                  <th>Savings or Checking</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input
                    name='bank_name'
                    placeholder='Bank Name'
                    {...register('bank_name')}
                    value={bankInfoValue.bank_name}
                    onChange={handleBankInfoInputChange}
                  />
                    <p className='text-red-600'>{errors.bank_name?.message}</p>
                  </td>
                  <td><input
                    name='account_holder_name'
                    placeholder='Account Holder Name'
                    {...register('account_holder_name')}
                    value={bankInfoValue.account_holder_name}
                    onChange={handleBankInfoInputChange}
                  />
                    <p className='text-red-600'>{errors.account_holder_name?.message}</p>
                  </td>
                  <td><input
                    name='routing_number'
                    placeholder='Routing Number'
                    {...register('routing_number')}
                    value={bankInfoValue.routing_number}
                    onChange={handleBankInfoInputChange}
                  />
                    <p className='text-red-600'>{errors.routing_number?.message}</p>
                  </td>
                  <td><input
                    name='account_number'
                    placeholder='Account Number'
                    {...register('account_number')}
                    value={bankInfoValue.account_number}
                    onChange={handleBankInfoInputChange}
                  />
                    <p className='text-red-600'>{errors.account_number?.message}</p>
                  </td>
                  <td><input
                    name='account_type'
                    placeholder='Savings or Checking'
                    {...register('account_type')}
                    value={bankInfoValue.account_type}
                    onChange={handleBankInfoInputChange}
                  />
                    <p className='text-red-600'>{errors.account_type?.message}</p>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
          <div className='flex justify-center'>
            <div className="buttons">
              <Link className="buttons__btn buttons__btn--reset" onClick={() => handleReset()}>Reset</Link>
              <Link to="/account/profile" className="buttons__btn buttons__btn--cancel">Cancel</Link>
              <button type='submit' className="buttons__btn buttons__btn--save">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default EditProfile;
