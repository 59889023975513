import React, { useState, useEffect } from 'react';
import { _get, _post } from '../utills/apiClient';
import { FETCH_DOCUMENTS, GET_SIGNED_DOC_URL } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import FileViewer from './FileViewer';

const DocumentList = ({ uploadStatus }) => {
    const showSnackbar = useSnackbar();
    const [activeTab, setActiveTab] = useState('Income');
    const [incomeDocumentList, setIncomeDocumentList] = useState([])
    const [deductionDocumentList, setDeductionDocumentList] = useState([])
    const [informationalDocumentList, setInformationalDocumentList] = useState([])
    const [userData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const tabs = ['Income', 'Deductions', 'Informational Purpose'];
    const IncomedocumentType = ['W2', '1099-MISC','1099-NEC','Schedule K1', '1099K', '1099-R', '1099-SSA', 'W2-G']
    const DeductiondocumentType = ['1099-SA', '5498-SA', '1099-G','1095-A','1095-B','1095-C']
    const InformationdocumentType = ['1098-INT/1099-DIV','1099-B','3922','1042-S','1098','1098-T','1098-E','1099-S','1099-Q']
    const DocumentTypeLableName = {
        'W2': 'Employment',
        '1099-MISC': 'Self Employment (1099-MISC)',
        '1099-NEC': 'Self Employment (1099-NEC)',
        'Schedule K1': 'Self Employment (Schedule K1)',
        '1099K': 'Self Employment (1099K)',
        '1099-R': 'Retirement (401K/IRA (1099R))',
        '1099-SSA': 'Retirement (Social Security (1099-SSA))',
        'W2-G': 'Gambling(W2-G)',
        '1099-SA': 'HSA Distribution (1099-SA)',
        '5498-SA': 'HSA/IRA Contribution(5498-SA)',
        '1099-G': 'Unemployment/State Refund (1099G)',
        '1095-A': 'ACA/Obamacare Subsidy(1095A)',
        '1095-B': 'Other Healthcare (1095B)',
        '1095-C': 'Other Healthcare (1095C)',
        '1098-INT/1099-DIV': `Financial Institution Interest/Dividend (1098-INT/1099-DIV)`,
        '1099-B': `Stock Sale/Tradings (1099-B)`,
        '3922': 'ESPP Letter (From 3922)',
        '1042-S': 'Non-residents(1042-S)',
        '1098': 'Mortgage Interest (1098)',
        '1098-T': 'Tuition Payments (1098-T)',
        '1098-E': 'Student Loan Intrest (1098-E)',
        '1099-S': 'Home Sale (1099-S)',
        '1099-Q': '529 Plan Withdrawal (1099-Q)',
    };


    useEffect(() => {
        getDocuments();
    }, [])
    const getDocuments = async () => {
        try {
            const response = await _get(`${FETCH_DOCUMENTS}${userData.file_no}`);
            if (response.data?.getDocumentList && response.data?.getDocumentList.length > 0) {
                uploadStatus(true);

                const inDocList = response.data.getDocumentList.filter((document) =>
                    IncomedocumentType.includes(document.document_type)
                );
                inDocList.length && setIncomeDocumentList(inDocList);

                const deDocList = response.data.getDocumentList.filter((document) =>
                    DeductiondocumentType.includes(document.document_type)
                );
                deDocList.length && setDeductionDocumentList(deDocList);

                const infDocList = response.data.getDocumentList.filter((document) =>
                    InformationdocumentType.includes(document.document_type)
                );
                infDocList.length && setInformationalDocumentList(infDocList);


            } else {
                uploadStatus(false);
            }
        } catch (error) {
            showSnackbar("Getting error in getDocumentList", 'error');
            console.error('Error adding data:', error);
        }
    }
    const [fileToShow, setFileToShow] = useState(null);
    const [fileExt, setFileExt] = useState(null);

    const handleCloseFileViewer = () => {
        setFileToShow(null);
    };

    const getSignedUrl = async (item) => {
        let payload = {
            key: [`${item.file_no}_${item.document_name}`],
            "operation": "getObject"
        }
        try {
            const response = await _post(GET_SIGNED_DOC_URL, payload);
            if (response.status == 200 && response.data?.getDocumentUrlList && response.data?.getDocumentUrlList.length > 0) {
                // setSignedUrls(response.data?.getDocumentUrlList);
                if (response.data?.getDocumentUrlList.length > 0) {
                    console.log(response.data?.getDocumentUrlList[0].url);
                    response.data?.getDocumentUrlList[0].url && setFileToShow(response.data?.getDocumentUrlList[0].url)
                    const parts = item.document_name.split('.');
                    let ext = parts.length > 1 ? `${parts.pop()}` : '';
                    setFileExt(ext);
                }

            }
        } catch (error) {
            showSnackbar("Getting error in getSignedUrl", 'error');
        }
    };
    return (
        <div className="py-4 w-full mx-auto bg-white rounded-lg">
            <div className="border-b border-gray-200 mb-4">
                <ul className="flex justify-start">
                    {tabs.map((tab) => (
                        <li
                            key={tab}
                            className={`mr-6 cursor-pointer pb-2 ${activeTab === tab
                                ? 'border-b-[3px] border-blue-500 text-black font-medium text-sm'
                                : 'text-gray-600 text-sm font-normal'
                                }`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </li>
                    ))}
                </ul>
            </div>

            {/*Incode attachments*/}
            {activeTab === 'Income' && (
                incomeDocumentList.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                        {incomeDocumentList.map((item, index) => (
                            <div key={index} className="border-b-[3px] border-attach-border-blue p-4 shadow bg-gradient-to-b from-white to-regal-blue">
                                <p className="font-normal text-[12px] leading-[18px] mb-2">{DocumentTypeLableName[item.document_type]}</p>
                                <a href="#" className="text-attach-blue  text-[12px] leading-[18px]" onClick={() => getSignedUrl(item)}>
                                    {item.document_name}
                                </a>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-gray-600 text-sm">No Income Document uploaded yet.</div>
                )
            )}

            {/* Deduction Attachment */}
            {activeTab === 'Deductions' && (
                deductionDocumentList.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                        {deductionDocumentList.map((item, index) => (
                            <div key={index} className="border-b-[3px] border-attach-border-blue p-4 shadow bg-gradient-to-b from-white to-regal-blue">
                                <p className="font-normal  text-[12px] leading-[18px] mb-2">{DocumentTypeLableName[item.document_type]}</p>
                                <a href="#" className="text-attach-blue  text-[12px] leading-[18px]" onClick={() => getSignedUrl(item)}>
                                    {item.document_name}
                                </a>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-gray-600 text-sm">No Deduction uploaded yet.</div>
                )
            )}

            {/* Informational Attachment */}
            {activeTab === 'Informational Purpose' && (
                informationalDocumentList.length > 0 ? (
                    <div className="grid grid-cols-3 gap-4">
                        {informationalDocumentList.map((item, index) => (
                            <div key={index} className="border-b-[3px] border-attach-border-blue p-4 shadow bg-gradient-to-b from-white to-regal-blue">
                                <p className="font-normal mb-2  text-[12px] leading-[18px]">{DocumentTypeLableName[item.document_type]}</p>
                                <a href="#" className="text-attach-blue  text-[12px] leading-[18px]" onClick={() => getSignedUrl(item)}>
                                    {item.document_name}
                                </a>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="text-gray-600 text-sm">No Informational document uploaded yet.</div>
                )
            )}

            {fileToShow && (
                <FileViewer file={fileToShow} fileExt={fileExt} handleClose={handleCloseFileViewer} />
            )}
        </div>
    );
};

export default DocumentList;
