import React, { useState } from "react";
import Chat from "./Chat";

function UserChat() {
  const [userData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  return (
    <div className="h-[75vh] w-full">
      <Chat userName={userData?.first_name + ' ' + userData?.last_name} roomId={userData.room_id} customerFirstName={'Agent'} customerLastName={'Agent'} classStyle={'h-[75vh]'} />
    </div>
  );
}

export default UserChat;