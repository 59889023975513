import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DocumentList from './DocumentList'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { useDropdown } from '../reducer/DropdownReducer';
import { FETCH_BUSINESS, FETCH_DOCUMENTS, FETCH_HOME, FETCH_QUESTIONNAIRE, FETCH_RENTAL } from '../constants/api.endpoint';
import { _get } from '../utills/apiClient';
import { useSnackbar } from './toaster';
import Loader from './Loader';
const DashboardRight = (props) => {
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const { state, dispatch } = useDropdown();
    const [isLoading, setIsLoading] = useState(false);
    const handleDropdownChange = (e) => {
        dispatch({ type: 'SET_OPTION', payload: e.target.value });
    };
    const showSnackbar = useSnackbar();
    const [businessIncAndExpValues, setBusinessIncAndExpValues] = useState();
    const [rentalIncAndExpValues, setRentalIncAndExpValues] = useState();
    const [homeValues, setHomeValues] = useState();
    const navigate = useNavigate();
    const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);
    const location = useLocation();
    const path = location.pathname;
    const [businessValue, setBusinessValue] = useState()
    const [rentalValue, setRentalValue] = useState()
    const [homeValue, setHomeValue] = useState()

    useEffect(() => {

        const fetchAllApiData = async () => {
            try {
                // Call both APIs concurrently using Promise.all
                const [businessApi, homeApi, rentalApi] = await Promise.all([
                    getBusiness(),
                    getHome(),
                    getRental()
                ]);

                // Update state with data from both API calls
                setBusinessIncAndExpValues(businessApi);
                setHomeValues(homeApi);
                setRentalIncAndExpValues(rentalApi);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                // Hide the loader after both APIs are done
                setIsLoading(false);
            }
        };

        if (props?.data?.file_status) {
            setIsLoading(true);
            fetchAllApiData();
        }
        if (props?.data?.file_no) {
            getDocuments();
            getQuestionnaire();
        }
    }, [state, props]);

    // useEffect(() => {
    //     if (props?.data?.file_status) {
    //         getBusiness();
    //         getHome();
    //         getRental();
    //     }

    //     getDocuments();
    // }, [state, props]);

    const getBusiness = async () => {
        try {
            const response = await _get(`${FETCH_BUSINESS}${props?.data?.file_no}`);
            if (response.data?.BusinessIncomeAndExpenses && response.data?.BusinessIncomeAndExpenses.length > 0) {
                return response.data?.BusinessIncomeAndExpenses;
                // setBusinessIncAndExpValues(response.data?.BusinessIncomeAndExpenses);
            } else {
                return [];
            }
        } catch (error) {
            showSnackbar('Getting Error on getBusiness', 'error');
            console.error('Error fetching data:', error);
        }
    };

    const getHome = async () => {
        try {
            const response = await _get(`${FETCH_HOME}${props?.data?.file_no}`);
            if (response.data?.homeSaleDetails && response.data?.homeSaleDetails.length > 0) {
                return response.data?.homeSaleDetails;
                // setHomeValues(response.data?.homeSaleDetails);
            }
        } catch (error) {
            showSnackbar('Getting Error on getHome', 'error');
            console.error('Error fetching data:', error);
        }
    };

    const getRental = async () => {
        try {
            const response = await _get(`${FETCH_RENTAL}${props?.data?.file_no}`);
            if (response.data?.RentalIncomeAndExpenses && response.data?.RentalIncomeAndExpenses.length > 0) {
                return response.data?.RentalIncomeAndExpenses;
                // setRentalIncAndExpValues(response.data?.RentalIncomeAndExpenses);
            }
        } catch (error) {
            showSnackbar('Getting Error on getRental', 'error');
            console.error('Error fetching data:', error);
        }
    };

    const getDocuments = async () => {
        try {
            const response = await _get(`${FETCH_DOCUMENTS}${props?.data?.file_no}`);
            if (response.status == 200 && response.data?.getDocumentList && response.data?.getDocumentList.length > 0) {
                setIsDocumentUploaded(true);
            }
        } catch (error) {
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };

    const getQuestionnaire = async () => {
        try {
            //   setIsLoading(true);
            const response = await _get(`${FETCH_QUESTIONNAIRE}${props?.data?.file_no}`);
            if (response.data?.Questionnaire) {
                setBusinessValue(response.data?.Questionnaire?.business_income);
                setRentalValue(response.data?.Questionnaire?.rental_income);
                setHomeValue(response.data?.Questionnaire?.home_sale_status);
                // if (response.data?.Questionnaire?.business_income ||
                //     response.data?.Questionnaire?.rental_income ||
                //     response.data?.Questionnaire?.home_sale_status
                // ) {
                //     fetchAllApiData();
                // }
                // if (response.data?.Questionnaire?.business_income) {
                //   await getBusiness();
                // }
                // if (response.data?.Questionnaire?.rental_income) {
                //   await getRental();
                // }
                // if (response.data?.Questionnaire?.home_sale_status) {
                //   await getHome();
                // }
            }
            //   setIsLoading(false);
        } catch (error) {
            showSnackbar('Getting Error on getQuestionnaire', 'error');
            console.error('Error fetching data:', error);
        }
    };


    const businessRedirect = (businessId) => {
        navigate(`/account/orders/filling-details/business/${businessId}`);
    }
    const rentalRedirect = (rentalId) => {
        navigate(`/account/orders/filling-details/rental/${rentalId}`);
    }
    const homeRedirect = (homeId) => {
        navigate(`/account/orders/filling-details/home/${homeId}`);
    }
    const documentRedirect = () => {
        navigate(`/account/orders/document-checklist/upload`);
    }
    const handleDocuploadStatus = (status) => {
        // setIsDocumentUploaded(status);
    };
    // const questionnaireRedirect = () => {
    //     navigate(`/account/orders/questionnaire`);
    // }

    return (
        <>
            {props?.data?.file_status == 'Account Created' && !(props?.data?.profile_id && !props?.data?.qq_id) ?
                <div className='account__dashboard'>
                    <img src='/assets/images/looper-left.png' alt='looper left' className='account__dashboard--bg1' />
                    <img src='/assets/images/looper-right.png' alt='looper left' className='account__dashboard--bg2' />
                    <div>
                        <p className='account__dashboard--title'>Start your tax saving in simple steps</p>
                        <div className='account__dashboard--steps'>
                            {/* <Link to="/account/profile" className={"account__dashboard--step " + (!props?.data?.profile_id ? 'account__dashboard--step-incomplete' : '')}> */}
                            {!(props?.data?.profile_id) ? <Link to="/account/profile" className={"account__dashboard--step"}>
                                <div className='flex gap-4 items-center flex-col md:flex-row'>
                                    <img src='/assets/images/profile-dashboard-1.png' alt='Fill in your profile' />
                                    <img src='/assets/images/step-1.svg' />
                                </div>
                                <p className='account__dashboard--steptitle'>Fill in your profile</p>
                            </Link> : ''}
                            <Link to={!props?.data?.profile_id? path : '/account/orders/questionnaire'} className={"account__dashboard--step " + (!props?.data?.profile_id? 'account__dashboard--step-incomplete' : '')}>
                                <div className='flex gap-4 items-center flex-col md:flex-row'>
                                    <img src='/assets/images/profile-dashboard-2.png' alt='Answer questionnaire' />
                                    <img src='/assets/images/step-2.svg' className='rotate-[130deg] md:rotate-0' />
                                </div>
                                <p className='account__dashboard--steptitle'>Answer questionnaire</p>
                            </Link>
                            <Link to={(!props?.data?.qq_id) ? path : '/account/orders/filling-details'} className={"account__dashboard--step " + ((!props?.data?.qq_id) ? 'account__dashboard--step-incomplete' : '')}>
                                {/* <Link to={(!props?.data?.business_id && !props?.data?.rental_id && !props?.data?.home_id) ? path : '/account/orders/filling-details'} className={"account__dashboard--step " + ((!props?.data?.business_id && !props?.data?.rental_id && !props?.data?.home_id) ? 'account__dashboard--step-incomplete' : '')}> */}
                                {/* <Link to={ (!businessIncAndExpValues?.length > 0 && !rentalIncAndExpValues?.length > 0 && !homeValues?.length > 0)? path : '/account/orders/filling-details'} className={"account__dashboard--step " + ((!businessIncAndExpValues?.length > 0 && !rentalIncAndExpValues?.length > 0 && !homeValues?.length > 0) ? 'account__dashboard--step-incomplete' : '')}> */}
                                <div className='flex gap-4 items-center flex-col md:flex-row'>
                                    <img src='/assets/images/profile-dashboard-3.png' alt='Fill in details' />
                                    <img src='/assets/images/step-3.svg' className='rotate-45 md:rotate-0' />
                                </div>
                                <p className='account__dashboard--steptitle'>Fill in details</p>
                            </Link>
                            <Link to={!isDocumentUploaded ? path : '/account/orders/document-checklist'} className={"account__dashboard--step " + (!isDocumentUploaded ? 'account__dashboard--step-incomplete' : '')}>
                                <img src='/assets/images/profile-dashboard-4.png' alt='Upload documents' />
                                <p className='account__dashboard--steptitle'>Upload documents</p>
                            </Link>
                        </div>
                    </div>

                </div>
                : ''}

            {(props?.data?.profile_id && !props?.data?.qq_id) ? <div className='account__dashboard--allset'>
                <p className='account__dashboard--title'>You are all set now</p>
                <img src='/assets/images/profile-dashboard-3.png' className='account__dashboard--allset-img' />
                <Link to="/account/orders/questionnaire" className='buttons__btn buttons__btn--save px-3'>Start Filing your tax</Link>
            </div> : ''}

            {props?.data?.file_status != 'Account Created' &&

                <div>
                    {isLoading && <Loader />}
                    {/* <p className='text-[#8C8989] font-medium text-sm'>Financial Year 2024-25</p> */}
                    <div className="account__dashboard--right">
                        {/* Header Section */}
                        <div className="flex justify-between items-center p-4">
                            {/* Financial Year Text */}
                            <div className="flex items-center">
                                {/* <h1 className="text-[14px] font-semibold text-[#00599C]">Financial Year 2023–24</h1> */}
                                <select className={`text-[#00599C] font-semibold text-sm`}
                                    name='fiscalYear'
                                    value={state.selectedOption} onChange={handleDropdownChange}
                                >
                                    <option value="2023">Financial Year 2022-23</option>
                                    <option value="2024">Financial Year 2023-24</option>
                                </select>
                                <button className="ml-2 bg-blue-100 text-blue-800 px-2 py-1 rounded-full font-semibold text-sm">
                                    {props?.data?.file_status}
                                </button>
                                <span className="ml-2 bg-blue-100 text-blue-800 px-2 py-1 rounded-full font-semibold text-sm">File No : {props?.data?.file_no}</span>
                            </div>

                            {/* No Upcoming Schedule Section */}
                            {/* <div className="flex items-center space-x-2">
                                <button className="flex items-center bg-[#DDEBFF] text-[#2E43FF] px-4 py-2 rounded-md font-semibold text-sm">
                                    <AccessTimeIcon className="mr-2 w-[18px] h-[18px]" />
                                    No Upcoming Schedule
                                </button>
                                <AddCardOutlinedIcon className="mr-2 w-[18px] h-[18px]" />
                            </div> */}
                        </div>

                        {/* Grid Section */}
                        <div className="account__dashboard--right-cardsec">
                            {/* Business Income and Expenses */}
                            {(businessValue == 'Yes') && businessIncAndExpValues && businessIncAndExpValues?.length > 0 && <div className="account__dashboard--right-card">
                                <div className="flex items-center mb-4">
                                    <div className="account__dashboard--card-icon bg-[#D2E1FF]">
                                        <img src='/assets/images/dashboard-business.svg' alt='business' />

                                    </div>
                                    <h2 className="relative cursor-pointer ml-3 text-[14px] font-semibold text-black ">Business Income and Expenses</h2>
                                </div>
                                <p className="text-[#6D6E71] mb-4  text-[12px] leading-[18px]">
                                    Some content info about the business income and expenses file type and its use cases.
                                </p>


                                {businessIncAndExpValues.map((businessData, index) => (
                                    <>
                                        <a href="javascript:void(0);" 
                                        onClick={() => businessRedirect(businessData?.BusinessInfo.business_id)} className="text-[#3c38fe]   text-[12px] leading-[18px]">{businessData?.BusinessInfo?.business_name}</a><br />
                                    </>
                                ))}


                                {/* <a href="#" className="text-[#3c38fe]   text-[12px] leading-[18px]">Business Income 1</a><br />
                                <a href="#" className="text-[#3c38fe]   text-[12px] leading-[18px]">Business Income 2</a> */}
                            </div>}

                            {/* Rental Income and Expenses */}
                            {(rentalValue == 'Yes') && rentalIncAndExpValues && rentalIncAndExpValues?.length > 0 && <div className="account__dashboard--right-card">
                                <div className="flex items-center mb-4">
                                    <div className="account__dashboard--card-icon bg-[#D2E1FF]">
                                        <img src='/assets/images/dashboard-rental.svg' alt='rental' />
                                    </div>
                                    <h2 className="ml-3 text-[14px] font-semibold text-black">Rental Income and Expenses</h2>
                                </div>
                                <p className="text-[#6D6E71]  text-[12px] leading-[18px]">
                                    Some content info about the business income and expenses file type and its use cases.
                                </p>
                                {rentalIncAndExpValues.map((rentalData, index) => (
                                    <>
                                        <a href="javascript:void(0);" onClick={() => rentalRedirect(rentalData?.RentalInfo.rental_id)} className="text-[#3c38fe]   text-[12px] leading-[18px]">{rentalData?.RentalInfo?.rental_property_owner}</a><br />
                                    </>
                                ))}
                            </div>}

                            {/* Home Sale Details */}
                            {(homeValue == 'Yes') && homeValues && homeValues?.length > 0 && <div className="account__dashboard--right-card">
                                <div className="flex items-center mb-4">
                                    <div className="account__dashboard--card-icon bg-[#FDE5FE]">
                                        <img src='/assets/images/dashboard-home.svg' alt='home sale' />
                                    </div>
                                    <h2 className="ml-3 text-[14px] font-semibold text-black">Home Sale Details</h2>
                                </div>
                                <p className="text-[#6D6E71] text-[12px] leading-[18px]">
                                    Some content info about the business income and expenses file type and its use cases.
                                </p>
                                {homeValues.map((homeData, index) => (
                                    <>
                                        <a href="javascript:void(0);" onClick={() => homeRedirect(homeData?.home_id)} className="text-[#3c38fe]   text-[12px] leading-[18px]">Home {index + 1}</a><br />
                                    </>
                                ))}
                            </div>}
                        </div>

                        <div className='account__dashboard--doc'>
                            <div className="flex items-center mb-4 justify-between">
                                <div className='flex gap-2 items-center'>
                                    <div className="account__dashboard--card-icon bg-[#DED2FF]">
                                        <img src='/assets/images/dashboard-doc.svg' alt='home sale' />
                                    </div>
                                    <h2 className="ml-3 text-[14px] font-semibold text-black">Document Uploads</h2>
                                </div>
                                <button type='buttton' onClick={() => documentRedirect()} className="buttons__btn buttons__btn--save text-sm font-normal">
                                    Upload Your Documents
                                </button>
                            </div>
                            <DocumentList uploadStatus={handleDocuploadStatus} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default DashboardRight