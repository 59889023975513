import React, { useEffect, useState, useRef } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../components/Avatar";
import { _post } from "../utills/apiClient";
import Chat from "./Chat";
import { CUS_MASTER_LIST_BY_TYPE } from "../constants/api.endpoint";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BASE_URL); // Connect to server


function AgentChat() {
  const [userData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  const [users, setUsers] = useState([]);
  const [roomId, setRoomId] = useState(null)
  const [customerFirstName, setCustomerFirstName] = useState(null)
  const [customerLastName, setCustomerLastName] = useState(null)
  const roomIdRef = useRef(roomId); 

  useEffect(() => {
    roomIdRef.current = roomId; 
  }, [roomId]);

  useEffect(() => {
    const getUserList = async () => {
      const response = await _post(CUS_MASTER_LIST_BY_TYPE, { type: 'user' })
      if (response?.data?.length > 0) {
        let array = []
        response.data.map((row) => {
          row.avatar = "";
          row.showInfo = false;
          array.push(row)
        })
        setUsers(array)
      }
    }
    getUserList()
  }, [])

  const agentChatViewLastMsgUpdate = (roomId, message, time) =>{
    setUsers(prevData =>
      prevData.map(item =>
        item.room_id === roomId ? { ...item, msg: message, msgTime: time } : item
      )
    );
  }

  useEffect(() => {
    // Join as an agent
    
    socket.emit('agentJoin');

    // Listen for new customer messages
    socket.on('newCustomerMessage', (res) => {
      let data = res.data
      const currentRoomId = roomIdRef.current;
      
      
      if(currentRoomId){
      setUsers(prevData =>
        prevData.map(item =>
           (data.room == currentRoomId && data.room == item.room_id) 
              ? { ...item, msg: data.message, msgTime: data.time, showInfo: false } 
              : data.room == item.room_id ? { ...item, msg: data.message, msgTime: data.time, showInfo: true }
              : item
        )
      );
    }else{
      setUsers(prevData =>
        prevData.map(item =>
          item.room_id === data.room ? { ...item, msg: data.message, msgTime: data.time, showInfo: true } : item
        )
      );
    }
      
    });


    return () => {
        socket.disconnect();
    };
}, []);

  useEffect(()=>{
    setUsers(prevData =>
      prevData.map(item =>
        item.room_id === roomId ? { ...item,showInfo: false } : item
      )
    );
  }, [roomId])
  return (
    <div className="flex w-full h-[75vh] bg-gray-100 gap-6 p-6">
      {/* Sidebar */}
      <div className="w-1/3 max-w-md bg-white border rounded-lg overflow-hidden">
        <div className="bg-[#000AC7] p-4 text-white flex items-center gap-3">
          <div className="flex items-center gap-2 text-white">
            <img src='/assets/images/users-svgrepo-com.svg' width={32} height={32} />
            <span className="text-sm font-medium">Customers</span>
          </div>
        </div>
        <div className="h-[calc(100vh-64px)] max-h-[100%]   overflow-y-scroll custom-scrollbar">
          <div className="p-4 space-y-4">
            {users.sort((a, b) => new Date(b.msgTime) - new Date(a.msgTime)).map((row, key) => (
              <div key={key} className="flex items-start gap-3 cursor-pointer" title={row.room_id} onClick={() => { setRoomId(item => row.room_id); setCustomerFirstName(row.first_name); setCustomerLastName(row.last_name) }}>
                {row.avatar ? (
                  <Avatar className="h-10 w-10">
                    <AvatarImage src={row.avatar} alt={row.first_name + ' ' + row.last_name} />
                    {!row.avatar && (
                      <AvatarFallback>{row.first_name + ' ' + row.last_name}</AvatarFallback>
                    )}
                  </Avatar>) :
                  (
                    <div className="w-10 h-10 rounded-full bg-green-300 flex items-center justify-center">
                      <span className="text-white font-bold uppercase">{row.first_name?.charAt(0) + row.last_name?.charAt(0)}</span>
                    </div>
                  )
                }
                <div className="flex-1 min-w-0">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium">
                      {row.first_name + ' ' + row.last_name}
                    </span>
                    {row.msgTime &&
                      <span className="text-xs text-gray-500">
                        {new Date(row.msgTime).toLocaleTimeString()}
                      </span>
                    }
                  </div>
                  <div className="mt-1 text-sm text-gray-600 flex items-center gap-2 whitespace-nowrap">
                    <span>{ row?.msg?.length > 25 ? row.msg.substring(0, 25) + "..." : row.msg }</span>
                    {row.showInfo && <div className="w-2 h-2 rounded-full bg-blue-600 mt-2" /> }
                  </div>


                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Chat Section */}
      {roomId ?
        <Chat userName={userData?.first_name + ' ' + userData?.last_name} roomId={roomId} customerFirstName={customerFirstName} customerLastName={customerLastName} msgUpdateFunc={agentChatViewLastMsgUpdate} />
        :
        <div className="flex w-full  bg-gray-100 gap-6"> </div>
      }


    </div>
  );
}

export default AgentChat;
