import React, { useState } from 'react'
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Loader from './Loader';

const DownloadZip = ({ signedUrls, page, orderData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const downloadAndZipFiles = async () => {
    setIsLoading(true);
    const zip = new JSZip();
    // Download each file and add it to the ZIP
    await Promise.all(
      signedUrls.map(async ({ fileKey, url }) => {
        const response = await fetch(url);
        const blob = await response.blob();
        zip.file(fileKey, blob);
      })
    );

    // Generate the ZIP file and trigger the download
    const content = await zip.generateAsync({ type: "blob" });
    const user = JSON.parse(sessionStorage.getItem('taxData'));
    let fileName = '';
    if (page == 'Agent') {
      fileName = `${orderData?.first_name}${orderData?.last_name}_${orderData?.fiscal_year}.zip`;
    } else {
      fileName = `${user?.first_name}${user?.last_name}_${user?.fiscal_year}.zip`;
    }
    setIsLoading(false);
    saveAs(content, fileName);
  };

  return (<>
    {isLoading && <Loader />}
    {page == 'User' && <div>
      <button type='button' className='document__download' onClick={downloadAndZipFiles}>
        <img src="/assets/images/download-icon.svg" />
        <span>Download All</span>
      </button>
    </div>}
    {page == 'Agent' && <div>
      <div className='order__document--download' onClick={downloadAndZipFiles}>
        <img src='/assets/images/document-download.svg' alt='download' />
      </div>
    </div>}
  </>
  );
};

export default DownloadZip;