import React from 'react'
import FillingDetailsAdding from  '../components/FillingDetailsAdding'
import ProfileMenu from '../components/ProfileMenu'


const FillingDetailsAdd = () => {
    return (
      <>
        <FillingDetailsAdding/>
      </>
  )
}

export default FillingDetailsAdd 