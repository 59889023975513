import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import { _post } from "../utills/apiClient";
import { CHAT_HISTORY_APPLY, GET_CHAT_HISTORY_BY_CHAT_ROOMID } from "../constants/api.endpoint";


const fontStyle = {
  fontFamily: '"Titillium Web", sans-serif',
};
const socket = io(process.env.REACT_APP_BASE_URL); // Connect to server

function Chat({ userName, roomId, customerFirstName, customerLastName, msgUpdateFunc, classStyle }) { // Use roomId prop directly
  const [userData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  const [input, setInput] = useState("");
  const [username] = useState(userName);
  const messagesEndRef = useRef(null);

  const [chatHistory, setChatHistory] = useState([]);
  const [chatMsg, setChatMsg] = useState([]);
  const [isLoading, setLoader] = useState(false)
  async function getChatHistoryByRoomId() {
    try {
      const response = await _post(GET_CHAT_HISTORY_BY_CHAT_ROOMID, { room_id: roomId });
      setChatHistory(response?.data?.chatHistory);
      setLoader(false)
    } catch (error) {
      setChatHistory([])
      console.log("Chat History fetch issue", error);
      setLoader(false)
    }
  }

  useEffect(() => {
    if (roomId) {
      setLoader(true)
      getChatHistoryByRoomId();
    }
  }, [roomId]);

  useEffect(() => {
    const chatHistoryFormatChange = () => {
      const groupedChat = chatHistory.reduce((acc, chat) => {
        const date = chat.created.split("T")[0];
        if (!acc[date]) acc[date] = [];
        acc[date].push({
          id: chat.id,
          room_id: chat.room_id,
          cus_id: chat.cus_id,
          message: chat.message,
          created: chat.created,
          name: chat.name,
        });
        return acc;
      }, {});

      let chat = Object.keys(groupedChat).map((date) => ({ [date]: groupedChat[date] }));
      setChatMsg(chat);
    };

    chatHistoryFormatChange();
  }, [chatHistory]);

  // Store the previous room ID
  const prevRoomIdRef = useRef(null);

  useEffect(() => {
    // Leave the previous room if it exists
    if (prevRoomIdRef.current) {
      socket.emit("leave room", { room: prevRoomIdRef.current });
    }

    // Join the new room
    if (roomId) {
      socket.emit("join room", { user: username, room: roomId });
      prevRoomIdRef.current = roomId; // Update the previous room ID to the current one
    }

    // Listen for incoming messages
    socket.on("new message", (msg) => {
      const newMsg = {
        id: null,
        room_id: msg?.room,
        cus_id: msg?.cus_id,
        message: msg?.message,
        created: msg?.time,
        name: msg?.user,
      };
      if (userData.account_type) msgUpdateFunc(msg.room, msg.message, msg.time)
      setChatHistory((prevChat) => [...prevChat, newMsg]);
    });

    // Listen for typing notifications (optional)
    socket.on("user typing", (data) => {
      console.log(`${data.user} is typing...`);
    });

    return () => {
      socket.off("new message");
      socket.off("user typing");
      socket.off('room users');
    };
  }, [roomId, userName]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim()) {
      try {
        const payload = {
          room_id: roomId,
          cus_id: userData?.cus_id,
          message: input,
        };
        await _post(CHAT_HISTORY_APPLY, payload);

        socket.emit("message", {
          user: username,
          message: input,
          cus_id: userData?.cus_id,
          room: roomId,
          time: new Date(),
        });
      } catch (error) {
        console.log("chat apply error", error);
      }

      setInput("");
    }
  };

  return (
    <div className={ classStyle ?`${classStyle} flex w-full bg-gray-100 gap-6` : 'flex w-full bg-gray-100 gap-6'}>
      <div
        className="w 1/2 flex-1 flex flex-col border rounded-lg overflow-hidden"
        style={fontStyle}
      >
        < div className="p-1 pl-2 bg-[#000AC7] text-white">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-full bg-green-300 flex items-center justify-center">
              <span className="text-white font-bold uppercase">{customerFirstName?.charAt(0) + customerLastName?.charAt(0)}</span>
            </div>
            <div>
              <h2 className="text-lg font-semibold">{customerFirstName + ' ' + customerLastName}</h2>
              <span className="text-sm text-green-200">Online</span>
            </div>
          </div>
        </div>
        {isLoading ? (<><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', minHeight: '100%' }}><div className='loader' style={{ height: '50px' }}></div></div></>) :
          (
            <>
              <div
                className="flex-1 p-4 overflow-y-auto space-y-4 custom-scrollbar"
                style={{
                  backgroundImage: "url('/assets/images/bg.png')",
                  backgroundSize: '100% auto',
                  backgroundPosition: 'center center',
                  backgroundRepeat: 'no-repeat',
                  filter: 'brightness(100%) blur(0px) contrast(100%)',
                  imageRendering: 'auto',
                  '--scrollbar-width': '4px', // Customize the width of the scrollbar
                }}
              >
                {chatMsg.map((row, dateKey) => (
                  <>
                    <div key={dateKey} className="flex flex-col items-center space-y-1">
                      <div className="text-[#555555] pl-2 pr-2 bg-[#D9D9D9] rounded-lg text-xs mb-2">{Object.keys(row)[0]}</div> {/* Display the date */}
                    </div>
                    {row[Object.keys(row)[0]].map((msg, msgKey) => (
                      <div key={msgKey} className={`flex flex-col  ${msg.cus_id === userData?.cus_id ? 'items-end' : 'items-start'}`}>
                        <div className={`${msg.cus_id === userData?.cus_id ? 'bg-[#000AC7] ' : 'bg-[#888888]'} text-white rounded-lg p-2 max-w-xs`}>
                          {msg.message}
                        </div>
                        <span className="text-xs text-gray-500">{new Date(msg.created).toLocaleTimeString()}</span>
                      </div>
                    ))}
                  </>
                ))}
                <div ref={messagesEndRef} />
              </div>

              <div className="pl-2 pr-2 pb-4">
                <form onSubmit={sendMessage} className="flex items-center rounded-full border border-[#000AC7] bg-white pr-2">
                  <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Message..."
                    className="flex-1 rounded-l-full px-4 py-3 text-sm outline-none placeholder:text-gray-500"
                  />
                  <button
                    className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#000AC7] text-white transition-colors hover:bg-blue-700"
                    type="submit"
                  >
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      stroke="none"
                    >
                      <path d="M9.78 18.65l.28-4.23 7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3 3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z" />
                    </svg>
                  </button>
                </form>
              </div>
            </>
          )
        }
      </div>
    </div >
  );
}

export default Chat;
