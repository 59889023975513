import React, { useState } from 'react';
import Button from '../components/Button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _post } from '../utills/apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import { VALIDATE_OTP, SEND_OTP } from '../constants/api.endpoint';
import { useSnackbar } from '../components/toaster';
import Loader from '../components/Loader';

const schema = yup.object().shape({
  otp: yup.string()
    .required('OTP is required')
    .matches(/^[0-9-]+$/, "Must be only digits"),
});


const OTPVerification = () => {
  const { id } = useParams();
  const showSnackbar = useSnackbar();
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const navigate = useNavigate();
  const [otpValue, setOtpValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (e) => {
    const { value } = e.target;
    setOtpValue(value);
  };

  const onSubmit = (data) => {
    let payload = {
      otp: otpValue,
      file_no: sessionStorage.getItem("createdFileno"),
      cus_id: sessionStorage.getItem("createdFileno")
    }
    validateOtp(payload);
  };

  const validateOtp = async (payload) => {
    try {
      setIsLoading(true);
      const response = await _post(VALIDATE_OTP, payload);
      console.log('response', response);
      if (response.status == 200 && response.data?.otpstatus != 'Invalid OTP' && response.data?.taxData?.length > 0) {
        if (id != 'F') {
          sessionStorage.setItem('taxData', JSON.stringify(response.data?.taxData[0]));
          navigate('/authentication/success');
        } else {
          navigate('/reset-password');
        }
      } else {
        showSnackbar('Invalid OTP. Please try with Valid', 'error');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in validateOtp. Please try again later", 'error');
      console.error('Error adding data:', error);
    }
  };

  const resendOtp = async () => {
    setIsLoading(true);
    let payload = {
      file_no: sessionStorage.getItem("createdFileno"),
      cus_id: sessionStorage.getItem("createdFileno")
    }
    try {
      const response = await _post(SEND_OTP, payload);
      if (response.status == 200 && response.data?.otpstatus) {
        showSnackbar('OTP was Resend Successfully', 'success');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in resendOtp. Please try again later", 'error');
      console.error('Error adding data:', error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className='user__container'>
        <form className='flex flex-col gap-8 w-full' onSubmit={handleSubmit(onSubmit)}>
          {id == 'R' && <p className='text-2xl'>OTP Verification</p>}
          {id == 'L' && <p className='text-2xl'>Account Authentication</p>}
          {id == 'F' && <p className='text-2xl'>Forgot Password OTP Verification</p>}
          {/* <p className='text-2xl'>OTP Verification</p> */}
          <div className="user__inputgroup">
            <div className='relative'>
              <input type='phone' {...register('otp')} name="otp" onChange={(e) => {
                register('otp').onChange(e);
                handleInputChange(e);
              }} value={otpValue} required />
              <label>Enter OTP</label>
            </div>
            <p className='text-red-600'>{errors.otp?.message}</p>
          </div>
          {/* <Button link="/authentication/success" text="Get OTP" /> */}
          <button type='submit' className='btn btn__primary'>Verify</button>
          <p className='text-[#6D6E71] text-center'>Don’t Receive OTP?  <span className='text-[#00599C] font-bold' onClick={() => resendOtp()}>Resend</span></p>
        </form>
      </div>
    </>
  )
}

export default OTPVerification