import { useContext, useEffect, useState } from "react";
import { CREATE_BUSINESS, FETCH_BUSINESS } from "../constants/api.endpoint";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from "./toaster";
import { _get, _post } from "../utills/apiClient";
import { AppContext } from "../utills/AppContext";
import { formatSSNForDisplay, CreateNotification } from "./commonFunction";
import Loader from "./Loader";
import { useNavigate, useParams } from "react-router-dom";

const schema = yup.object().shape({
    business_name: yup.string().required('Business name is required'),
    EIN_SSN: yup.string()
        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
        .required('SSN is required'),
    business_address: yup.string().required('Business Address 1 is required'),
    business_address2: yup.string(),
    business_address3: yup.string(),
    zipcode: yup.string().required('Zipcode is required').matches(/^[0-9]+$/, "Must be only digits"),
    started_current_year: yup.string().required('Started Current Year Status is required'),
    gross_receipt_sale: yup.string().required('Gross Receipt or Sale is required').matches(/^[0-9]+$/, "Must be only digits"),
    returns_allowances: yup.string().required('Returns Allowances is required').matches(/^[0-9]+$/, "Must be only digits"),
    other_income: yup.string().test("is-digits","Must be only digits",value => !value || /^[0-9]+$/.test(value)),
});


export default function BusinessIncomeRight(props) {

    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const showSnackbar = useSnackbar();
    let [updateFieldsData, setUpdateFieldsData] = useState({});
    const { setValueFromChild } = useContext(AppContext);
    const currentYear = new Date().getFullYear();
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let { id } = useParams();
    console.log('Business Income and Expenses Details 1', id, props?.selectedBusinessId)

    useEffect(()=>{
        if(props?.selectedBusinessId) id = props?.selectedBusinessId
    },[props?.selectedBusinessId])
    
    const navigate = useNavigate();

    let businessIncomeAndExpenses = {
        BusinessInfo: {
            business_name: "",
            EIN_SSN: "",
            business_address: "",
            business_address2: "",
            business_address3: "",
            zipcode: "",
            started_current_year: "",
            gross_receipt_sale: "",
            returns_allowances: "",
            other_income: "",
        }, Expenses: {
            advertising: "",
            commission_fees: "",
            mortgage_interest: "",
            labour_wages: "",
            non_health_insurance: "",
            legal_professional_fees: "",
            office_expenses: "",
            rental_lease: "",
            repairs_maintenance: "",
            supplies: "",
            taxes_licenses: "",
            travel_expense: "",
            meals: "",
            utilities: "",
        },
        AutoMobileExpenses: {
            purchase_date: "",
            dateplaced_service: "",
            purchase_price: "",
            business_meal: "",
            communte_miles: "",
            businessuse_percentage: "",
        },
        HomeOfficeExpenses: {
            purchase_price: "",
            purchase_price_hoe: "",
            land_value: "",
            purchase_date: "",
            purchase_date_hoe: "",
            office_squarefoot: "",
            home_squarefoot: "",
            home_office_percentage: "",
        }

    }
    const [businessIncAndExpValues, setBusinessIncAndExpValues] = useState(businessIncomeAndExpenses);


    useEffect(() => {
        (async () => {
            if (userData?.file_no && props?.selectedBusinessId) {
                try {
                    setIsLoading(true);
                    const response = await _get(`${FETCH_BUSINESS}${userData?.file_no}`);
                    if (response.data?.BusinessIncomeAndExpenses && response.data?.BusinessIncomeAndExpenses.length > 0) {
                        let allBusiness = response.data?.BusinessIncomeAndExpenses;
                        let selectedBusiness = allBusiness.find((data) => data.BusinessInfo.business_id == props.selectedBusinessId);
                        businessIncomeAndExpenses = { ...selectedBusiness };
                        businessIncomeAndExpenses.AutoMobileExpenses.purchase_date = formatDate(businessIncomeAndExpenses.AutoMobileExpenses.purchase_date);
                        businessIncomeAndExpenses.AutoMobileExpenses.dateplaced_service = formatDate(businessIncomeAndExpenses.AutoMobileExpenses.dateplaced_service);
                        businessIncomeAndExpenses.HomeOfficeExpenses.purchase_date_hoe = formatDate(businessIncomeAndExpenses.HomeOfficeExpenses.purchase_date);
                        businessIncomeAndExpenses.HomeOfficeExpenses.purchase_price_hoe = businessIncomeAndExpenses.HomeOfficeExpenses.purchase_price;
                        setBusinessIncAndExpValues(businessIncomeAndExpenses);
                        setUpdateFieldsData(businessIncomeAndExpenses);
                        Object.entries(businessIncomeAndExpenses).map(([key, value]) => {
                            Object.entries(value).map(([key1, value1]) => {
                                setValue(key1, value1)
                            });
                        });
                    }
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    showSnackbar('Getting Error on fetchQuestionnaire', 'error');
                    console.error('Error fetching data:', error);
                }
            }

        })();
    }, []);

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
        resolver: yupResolver(schema),
        mode: 'all'
    });

    const handleBlur = (value) => {
        setIsFocused(false);
    };

    const handleFocus = (value) => {
        setIsFocused(true);
    };

    const getFormattedValue = (value) => {
        if (value) {
            const formatted = value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
            return formatted;
        } else {
            return value;
        }
    }

    const handleBusinessInfoChange = (e, i) => {
        const { name, value } = e.target;
        if (name == 'EIN_SSN') {
            const raw = value.replace(/\D/g, '');
            if (raw.length <= 9) {
                let businessIncAndExp = { ...businessIncAndExpValues }
                businessIncAndExp.BusinessInfo[e.target.name] = getFormattedValue(raw);
                setBusinessIncAndExpValues(businessIncAndExp);
            }
        } else {
            let businessIncAndExp = { ...businessIncAndExpValues }
            businessIncAndExp.BusinessInfo[e.target.name] = e.target.value;
            setBusinessIncAndExpValues(businessIncAndExp);
        }

    }

    const handleExpensesChange = (e, i) => {
        let businessIncAndExp = { ...businessIncAndExpValues }
        businessIncAndExp.Expenses[e.target.name] = e.target.value
        setBusinessIncAndExpValues(businessIncAndExp);
    }

    const handleAutoMobileExpensesChange = (e) => {
        const { name, value } = e.target;
        let businessIncAndExp = { ...businessIncAndExpValues };
        
        
        businessIncAndExp.AutoMobileExpenses[name] = value;
    
        
        const { business_meal, communte_miles } = businessIncAndExp.AutoMobileExpenses;
        if (business_meal && communte_miles) {
            const businessMiles = parseFloat(business_meal) || 0;
            const commuteMiles = parseFloat(communte_miles) || 0;
            
            if (businessMiles + commuteMiles > 0) {
                businessIncAndExp.AutoMobileExpenses.businessuse_percentage = (businessMiles / (businessMiles + commuteMiles)).toFixed(2);
            } else {
                businessIncAndExp.AutoMobileExpenses.businessuse_percentage = "0.00";
            }
        } else {
            businessIncAndExp.AutoMobileExpenses.businessuse_percentage = "0.00";
        }
    
        setBusinessIncAndExpValues(businessIncAndExp);
    };
    

    const handleHomeOfficeExpensesChange = (e) => {
        const { name, value } = e.target;
        let businessIncAndExp = { ...businessIncAndExpValues };
        businessIncAndExp.HomeOfficeExpenses[name] = value;
        const { office_squarefoot, home_squarefoot } = businessIncAndExp.HomeOfficeExpenses;
        if (home_squarefoot && office_squarefoot) {
            businessIncAndExp.HomeOfficeExpenses.home_office_percentage = (office_squarefoot / home_squarefoot).toFixed(2);
        } else {
            businessIncAndExp.HomeOfficeExpenses.home_office_percentage = 0;
        }

        setBusinessIncAndExpValues(businessIncAndExp);
    }

    const handleReset = () => {
        setBusinessIncAndExpValues(businessIncomeAndExpenses);
        reset();
    };

    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    const onSubmit = (data) => {
        console.log('businessIncAndExpValues', businessIncAndExpValues);
        if (businessIncAndExpValues) {
            for (const key of Object.keys(businessIncAndExpValues.HomeOfficeExpenses)) {
                if (key.includes('_hoe')) {
                    businessIncAndExpValues.HomeOfficeExpenses[key.replace('_hoe', '')] = businessIncAndExpValues.HomeOfficeExpenses[key];
                    // delete businessIncAndExpValues.HomeOfficeExpenses[key];
                }
            }
        }

        const payload = {
            file_no: userData?.file_no,
            cus_id: userData?.cus_id,
            BusinessIncomeAndExpenses: [businessIncAndExpValues],
        }

        if (!updateFieldsData?.BusinessInfo?.business_id) {
            createOrUpdateBusiness(payload, 'Create');
        } else {
            if (!('business_id' in payload.BusinessIncomeAndExpenses[0].BusinessInfo)) {
                payload.BusinessIncomeAndExpenses[0].BusinessInfo.business_id = updateFieldsData.BusinessInfo.business_id;
            }
            createOrUpdateBusiness(payload, 'Update');
        }

    };


    const createOrUpdateBusiness = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_BUSINESS, payload);
            console.log('createOrUpdateBusiness', response);
            if (response.status == 200 && response.data?.createBusinessData) {
                if (type == "Create") {
                    CreateNotification("Business Created Successfully",'success');
                    showSnackbar('Business Created Successfully', 'success');
                    const user = JSON.parse(sessionStorage.getItem('taxData'));
                    user.business_id = response.data?.createBusinessData[0].LV_NEWBUSINESS_ID;
                    sessionStorage.setItem('taxData', JSON.stringify(user));
                    setUserData(user);
                } else {
                    CreateNotification("Business Updated Successfully",'success');
                    showSnackbar('Business Updated Successfully', 'success');
                }
                // setValueFromChild('fillingCompleted');
                setValueFromChild(prevState => ({
                    ...prevState,
                    value: 'fillingCompleted'
                }));
                props.onBusinessBack();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateBusiness", 'error');
            console.error('Error adding data:', error);
        }
    };

    const handleBack = (e) => {
        if (id) {
            navigate('/account/dashboard');
        } else {
            props.onBusinessBack();
        }
    }
    return (
        <>
            {isLoading && <Loader />}
            <div className='flex flex-col gap-8'>
                <h1 className="account__profile--right-formtitle"><img src="/assets/images/left-arrow.svg" onClick={(e) => handleBack(e)} className="pr-4 cursor-pointer" />Business Income and Expenses Details 1</h1>
                <div className="w-[80%] mx-auto">
                    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
                        <div className='updateprofile__formrow'>
                            <h2 className='updateprofile__title'>Business Info</h2>
                            <div className='updateprofile__form'>
                                <div className="updateprofile__formfield">
                                    <p className="updateprofile__inputlabel">Name</p>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Name" name="business_name" {...register('business_name')}
                                        onChange={(e) => {
                                            register('business_name').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value={businessIncAndExpValues.BusinessInfo.business_name} />
                                    <p className='text-red-600'>{errors.business_name?.message}</p>
                                </div>
                                <div className="updateprofile__formfield">
                                    <p className="updateprofile__inputlabel">EIN/SSN*</p>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="EIN/SSN Number" name="EIN_SSN" {...register('EIN_SSN')}
                                        onChange={(e) => {
                                            register('EIN_SSN').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        onBlur={(e) => {
                                            register('EIN_SSN').onBlur(e);
                                            handleBlur(businessIncAndExpValues.BusinessInfo.EIN_SSN);
                                        }}
                                        onFocus={(e) => {
                                            handleFocus(businessIncAndExpValues.BusinessInfo.EIN_SSN);
                                        }}
                                        value={isFocused ? businessIncAndExpValues.BusinessInfo.EIN_SSN : formatSSNForDisplay(businessIncAndExpValues.BusinessInfo.EIN_SSN)}
                                    // value={businessIncAndExpValues.BusinessInfo.EIN_SSN}
                                    />
                                    <p className='text-red-600'>{errors.EIN_SSN?.message}</p>
                                </div>
                                {/* <div className="updateprofile__formfield">
                                    <p className="updateprofile__inputlabel">Business name*</p>
                                    <input type='text' placeholder="Name of your business" name="business_name" {...register('business_name')} onChange={handleBusinessInfoChange} value={businessIncAndExpValues.BusinessInfo.business_name} />
                                </div> */}
                                <div className="updateprofile__formfield">
                                    <p className="updateprofile__inputlabel">Address 1*</p>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Business Address (name of the buildling)" name="business_address" {...register('business_address')}
                                        onChange={(e) => {
                                            register('business_address').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value={businessIncAndExpValues.BusinessInfo.business_address} />
                                    <p className='text-red-600'>{errors.business_address?.message}</p>
                                </div>
                                <div className="updateprofile__formfield">
                                    <p className="updateprofile__inputlabel">Address 2</p>
                                    <input type='text' disabled={id ? true : false} placeholder="Door No, Street name" name="business_address2" {...register('business_address2')}
                                        onChange={(e) => {
                                            register('business_address2').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value={businessIncAndExpValues.BusinessInfo.business_address2} />
                                    <p className='text-red-600'>{errors.business_address2?.message}</p>
                                </div>
                                <div className="updateprofile__formfield">
                                    <p className="updateprofile__inputlabel">Address 3</p>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="City, Town" name="business_address3" {...register('business_address3')}
                                        onChange={(e) => {
                                            register('business_address3').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value={businessIncAndExpValues.BusinessInfo.business_address3} />
                                    <p className='text-red-600'>{errors.business_address3?.message}</p>
                                </div>
                                <div className="updateprofile__formfield">
                                    <p className="updateprofile__inputlabel">zipcode*</p>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="zipcode" name="zipcode" {...register('zipcode')}
                                        onChange={(e) => {
                                            register('zipcode').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value={businessIncAndExpValues.BusinessInfo.zipcode} />
                                    <p className='text-red-600'>{errors.zipcode?.message}</p>
                                </div>
                            </div>
                            <div className='flex gap-4'>
                                <p className="text-[#6D6E71]">Started this business in {currentYear}</p>
                                <div className='flex gap-3'>
                                    <input type='radio' disabled={id && userData?.file_status != "Revoke" ?   true : false} name='started_current_year' {...register('started_current_year')}
                                        onChange={(e) => {
                                            register('started_current_year').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value="y" checked={businessIncAndExpValues.BusinessInfo.started_current_year == 'y'} /> <label>Yes</label>
                                </div>
                                <div className='flex gap-3'>
                                    <input type='radio' disabled={id && userData?.file_status != "Revoke" ?   true : false} name='started_current_year' {...register('started_current_year')}
                                        onChange={(e) => {
                                            register('started_current_year').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value="n" checked={businessIncAndExpValues.BusinessInfo.started_current_year == 'n'} /> <label>No</label>
                                </div>
                                <p className='text-red-600'>{errors.started_current_year?.message}</p>
                            </div>
                        </div>
                        <div className='updateprofile__formrow updateprofile__formrow--bdrbtm'>
                            <h2 className='updateprofile__title'>Income</h2>
                            <div className='updateprofile__form'>
                                <div className='updateprofile__formfield'>
                                    <label className="updateprofile__inputlabel">Gross receipts / sales</label>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Gross receipts / sales*" name="gross_receipt_sale" {...register('gross_receipt_sale')}
                                        onChange={(e) => {
                                            register('gross_receipt_sale').onChange(e);
                                            handleBusinessInfoChange(e);
                                        }}
                                        value={businessIncAndExpValues.BusinessInfo.gross_receipt_sale} />
                                    <p className='text-red-600'>{errors.gross_receipt_sale?.message}</p>
                                </div>
                                <div className='updateprofile__formfield'>
                                    <label className="updateprofile__inputlabel">Returns and allowances</label>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} name="returns_allowances" {...register('returns_allowances')} onChange={(e) => {
                                        register('returns_allowances').onChange(e);
                                        handleBusinessInfoChange(e);
                                    }} value={businessIncAndExpValues.BusinessInfo.returns_allowances} placeholder="Amount in USD" />
                                    <p className='text-red-600'>{errors.returns_allowances?.message}</p>
                                </div>
                                <div className='updateprofile__formfield'>
                                    <label className="updateprofile__inputlabel">Other income</label>
                                    <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} name="other_income" {...register('other_income')} onChange={(e) => {
                                        register('other_income').onChange(e);
                                        handleBusinessInfoChange(e);
                                    }} value={businessIncAndExpValues.BusinessInfo.other_income} placeholder="Amount in USD" />
                                    <p className='text-red-600'>{errors.other_income?.message}</p>
                                </div>
                            </div>
                        </div>
                        <div className='updateprofile__formrow'>
                            <h2 className='updateprofile__title'>Expenses</h2>
                            <table className='updateprofile__table'>
                                <thead>
                                    <tr>
                                        <th>Expenses</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Advertising</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="advertising" {...register('advertising')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.advertising} /></td>
                                    </tr>
                                    <tr>
                                        <td>Commision and fees</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="commission_fees" {...register('commission_fees')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.commission_fees} /></td>
                                    </tr>
                                    <tr>
                                        <td>Contract labour and wages</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="labour_wages" {...register('labour_wages')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.labour_wages} /></td>
                                    </tr>
                                    <tr>
                                        <td>Insurance (Other than Health)</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="non_health_insurance" {...register('non_health_insurance')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.non_health_insurance} /></td>
                                    </tr>
                                    <tr>
                                        <td>Legal and professional services</td>
                                        <td><input type='number' disabled={id ? true : false} placeholder="Amount in USD" name="legal_professional_fees" {...register('legal_professional_fees')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.legal_professional_fees} /></td>
                                    </tr>
                                    <tr>
                                        <td>Office expenses</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="office_expenses" {...register('office_expenses')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.office_expenses} /></td>
                                    </tr>
                                    <tr>
                                        <td>Rent or lease (Vehicles/Equipments/OfficeItems)</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="rental_lease" {...register('rental_lease')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.rental_lease} /></td>
                                    </tr>
                                    <tr>
                                        <td>Repairs and maintenance</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="repairs_maintenance" {...register('repairs_maintenance')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.repairs_maintenance} /></td>
                                    </tr>
                                    <tr>
                                        <td>Supplies </td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="supplies" {...register('supplies')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.supplies} /></td>
                                    </tr>
                                    <tr>
                                        <td>Taxes and licenses</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="taxes_licenses" {...register('taxes_licenses')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.taxes_licenses} /></td>
                                    </tr>
                                    <tr>
                                        <td>Travel Expenses (Flight/Hotel/Toll/Parking)</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="travel_expense" {...register('travel_expense')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.travel_expense} /></td>
                                    </tr>
                                    <tr>
                                        <td>Meals (Business only)</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="meals" {...register('meals')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.meals} /></td>
                                    </tr>
                                    <tr>
                                        <td>Utilities</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="utilities" {...register('utilities')} onChange={handleExpensesChange} value={businessIncAndExpValues.Expenses.utilities} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='updateprofile__formrow'>
                            <h2 className='updateprofile__title'>Automobile Expenses</h2>
                            <table className='updateprofile__table'>
                                <thead>
                                    <tr>
                                        <th>Expenses</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Purchase Date</td>
                                        <td>
                                            <input type="date" disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="DD/MM/YYYY" {...register('purchase_date')} name="purchase_date" onChange={(e) => {
                                                register('purchase_date').onChange(e);
                                                handleAutoMobileExpensesChange(e);
                                            }} value={businessIncAndExpValues.AutoMobileExpenses.purchase_date} />

                                            {/* <input type='number' placeholder="Amount in USD" name="purchase_date" {...register('purchase_date')} onChange={handleAutoMobileExpensesChange} value={businessIncAndExpValues.AutoMobileExpenses.purchase_date} /> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Date Placed in Service</td>
                                        <td>
                                            <input type="date" disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="DD/MM/YYYY" {...register('dateplaced_service')} name="dateplaced_service" onChange={(e) => {
                                                register('dateplaced_service').onChange(e);
                                                handleAutoMobileExpensesChange(e);
                                            }} value={businessIncAndExpValues.AutoMobileExpenses.dateplaced_service} />
                                            {/* <input type='number' placeholder="Amount in USD" name="dateplaced_service" {...register('dateplaced_service')} onChange={handleAutoMobileExpensesChange} value={businessIncAndExpValues.AutoMobileExpenses.dateplaced_service} /> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Purchase Price (For Depreciation)</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="purchase_price" {...register('purchase_price')} onChange={handleAutoMobileExpensesChange} value={businessIncAndExpValues.AutoMobileExpenses.purchase_price} /></td>
                                    </tr>
                                    <tr>
                                        <td>Business Miles</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="business_meal" {...register('business_meal')} onChange={handleAutoMobileExpensesChange} value={businessIncAndExpValues.AutoMobileExpenses.business_meal} /></td>
                                    </tr>
                                    <tr>
                                        <td>Commute and Leisure Miles</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="communte_miles" {...register('communte_miles')} onChange={handleAutoMobileExpensesChange} value={businessIncAndExpValues.AutoMobileExpenses.communte_miles} /></td>
                                    </tr>
                                    <tr>
                                        <td>Percentage of Business Use </td>
                                        <td><input type='number' disabled={true} placeholder="Amount in USD" name="businessuse_percentage" {...register('businessuse_percentage')} onChange={handleAutoMobileExpensesChange} value={businessIncAndExpValues.AutoMobileExpenses.businessuse_percentage} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='updateprofile__formrow'>
                            <h2 className='updateprofile__title'>Home Office Expenses</h2>
                            <table className='updateprofile__table'>
                                <thead>
                                    <tr>
                                        <th>Expenses</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Purchase Price (For Depreciation)</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="purchase_price_hoe" {...register('purchase_price_hoe')} onChange={handleHomeOfficeExpensesChange} value={businessIncAndExpValues.HomeOfficeExpenses.purchase_price_hoe} /></td>
                                    </tr>
                                    <tr>
                                        <td>Land Value (if Known)</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="land_value" {...register('land_value')} onChange={handleHomeOfficeExpensesChange} value={businessIncAndExpValues.HomeOfficeExpenses.land_value} /></td>
                                    </tr>
                                    <tr>
                                        <td>Purchase Date</td>
                                        <td>
                                            <input type="date" disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="DD/MM/YYYY" {...register('purchase_date_hoe')} name="purchase_date_hoe" onChange={(e) => {
                                                register('purchase_date_hoe').onChange(e);
                                                handleHomeOfficeExpensesChange(e);
                                            }} value={businessIncAndExpValues.HomeOfficeExpenses.purchase_date_hoe} />
                                            {/* <input type='number' placeholder="Amount in USD" name="purchase_date_hoe" {...register('purchase_date_hoe')} onChange={handleHomeOfficeExpensesChange} value={businessIncAndExpValues.HomeOfficeExpenses.purchase_date_hoe} /> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Square Foot of Home Office</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="office_squarefoot" {...register('office_squarefoot')} onChange={handleHomeOfficeExpensesChange} value={businessIncAndExpValues.HomeOfficeExpenses.office_squarefoot} /></td>
                                    </tr>
                                    <tr>
                                        <td>Square foot of Whole House</td>
                                        <td><input type='number' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder="Amount in USD" name="home_squarefoot" {...register('home_squarefoot')} onChange={handleHomeOfficeExpensesChange} value={businessIncAndExpValues.HomeOfficeExpenses.home_squarefoot} /></td>
                                    </tr>
                                    <tr>
                                        <td>Percentage of Home office</td>
                                        <td><input type='number' disabled={true} placeholder="Amount in USD" name="home_office_percentage" {...register('home_office_percentage')} onChange={handleHomeOfficeExpensesChange} value={businessIncAndExpValues.HomeOfficeExpenses.home_office_percentage} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='flex justify-center'>
                            <div className="buttons">
                                {(userData?.file_status == "Revoke" || userData?.file_status == "Account Created")  && (<button type='button' onClick={() => handleReset()} className="buttons__btn buttons__btn--reset">
                                    Reset
                                </button>)}
                                <button type='button' onClick={(e) => handleBack(e)} className="buttons__btn buttons__btn--cancel">
                                    Cancel
                                </button>
                                {(userData?.file_status == "Revoke" || userData?.file_status == "Account Created") && (<button type='submit' className="buttons__btn buttons__btn--save">
                                    Save
                                    {console.log (id)}
                                    {console.log (userData?.file_status)}
                                    {console.log('Current file_status:', userData?.file_status)}
                                </button>)}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}