import React from 'react'
import Authentication from '../components/Authentication'

const AccAuth = () => {
  return (
    <div className='user__container'>
       <Authentication/>
    </div>
  )
}

export default AccAuth