import React from 'react'
import LoginLeft from '../components/LoginLeft'
import { Outlet } from 'react-router-dom'

const Login = () => {
  return (
    <div className='login'>
      <LoginLeft />
      <div className='login__right'>
        <div className='flex flex-col gap-12'>
          <p className='text-[#6D6E71] md:hidden py-8 text-center'>Welcome to <br /><span className='text-4xl font-bold text-[#00599C]'>OMMTAX</span></p>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default Login