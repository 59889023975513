import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { _get, _post, _put } from '../utills/apiClient';
import { useSnackbar } from './toaster';
import { CREATE_OR_UPDATE_MAILING_ADDRESS, FETCH_MAILING_ADDRESS } from '../constants/api.endpoint';
import Loader from './Loader';
import { CreateNotification } from './commonFunction';

const mailingAddSchema = Yup.object().shape({
  street_address: Yup.string().required('Street Address is required'),
  apt_home_unit: Yup.string(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zipcode is required').matches(/^[0-9]+$/, "Must be only digits"),
});

const MailingAddress = (props) => {
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  let mailinginitState = {
    street_address: "",
    apt_home_unit: "",
    city: "",
    state: "",
    zipcode: ""
  };

  const [mailingValue, setMailingValue] = useState(mailinginitState);
  let [updateFieldsData, setUpdateFieldsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isMailing, setIsMailing] = useState(false);
  const showSnackbar = useSnackbar();
  useEffect(() => {
    (async () => {
      if (userData.profile_id) {
        try {
          await getMailingAddress(userData?.profile_id);
        } catch (error) {
          showSnackbar('Getting Error on fetchUserProfile', 'error');
          console.error('Error fetching data:', error);
        }
      }
    })();
  }, []);

  const getMailingAddress = async (profile_id) => {
    setIsLoading(true);
    try {
      const response = await _get(`${FETCH_MAILING_ADDRESS}${profile_id}`);
      if (response.status == 200 && response.data?.MailAddress) {
        setIsMailing(true);
        mailinginitState = response.data?.MailAddress;
        setMailingValue(response.data?.MailAddress);
      }else{
        setIsMailing(false);
      }
      const mailingObj = { ...mailinginitState };
      setUpdateFieldsData({
        MailAddress: mailingObj,
      });
      Object.entries(mailingObj).map(([key, value]) => {
        setValue(key, value)
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in getMailingAddress", 'error');
      console.error('Error adding data:', error);
    }
  };

  const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
    resolver: yupResolver(mailingAddSchema),
    mode: 'onChange',
  });

  const handleReset = () => {
    setMailingValue(mailinginitState);
    reset();
  };

  const handleMailingInputChange = (e) => {
    const { name, value } = e.target;
    setMailingValue({
      ...mailingValue,
      [name]: value,
    });
  };

  const nextPage = () => {
    props.onMailCompleted('Next');
    //props.onMailCompleted(false);
  }

  const onSubmit = (data) => {

    const payload = {
      profile_id: userData?.profile_id,
      cus_id: userData?.cus_id,
      file_no: userData?.file_no,
      MailAddress: mailingValue,
    }

    if (!updateFieldsData.MailAddress.address_id) {
      createOrUpdateMailingAddress(payload, 'Create')
    } else {
      if (!('address_id' in payload.MailAddress)) {
        payload.MailAddress.address_id = updateFieldsData.MailAddress.address_id;
      }
      createOrUpdateMailingAddress(payload, 'Update');
    }
  };

  const createOrUpdateMailingAddress = async (payload, type) => {
    try {
      setIsLoading(true);
      const response = await _post(CREATE_OR_UPDATE_MAILING_ADDRESS, payload);
      console.log('createOrUpdateMailingAddress', response);
      if (response.status == 200 && response.data?.createAddress) {
        setIsMailing(true);
        if (type == "Create") {
          CreateNotification("Mailing Address Created Successfully",'success');
          showSnackbar('Mailing Address Created Successfully', 'success');
        } else {
          console.log('Mailing Address Updated Successfully', response.data);
          CreateNotification("Mailing Address Updated Successfully",'success');
          showSnackbar('Mailing Address Updated Successfully', 'success');
        }
       getMailingAddress(userData?.profile_id);
       props.onMailCompleted('Update');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in createOrUpdateMailingAddress", 'error');
      console.error('Error adding data:', error);
    }
  };
  return (
    <>
     {isLoading && <Loader />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='account__profile--right-formwrapper'>
          <h1 className="account__profile--right-formtitle">Mailing Address</h1>
          <div className="account__profile--right-form">
            <div className="account__profile--right-formfield">
              <p className="account__profile--right-inputlabel">Street Address *</p>
              <input className="account__profile--right-input" type="text" placeholder="Street Address" name="street_address" {...register('street_address')} onChange={(e) => {
                register('street_address').onChange(e);
                handleMailingInputChange(e);
              }} value={mailingValue.street_address} />
              <p className='text-red-600'>{errors.street_address?.message}</p>
            </div>
            <div className="account__profile--right-formfield">
              <p className="account__profile--right-inputlabel">APT/UNIT</p>
              <input className="account__profile--right-input" type="text" placeholder="APT/UNIT" name="apt_home_unit" {...register('apt_home_unit')} onChange={(e) => {
                register('apt_home_unit').onChange(e);
                handleMailingInputChange(e);
              }} value={mailingValue.apt_home_unit} />
              <p className='text-red-600'>{errors.apt_home_unit?.message}</p>
            </div>
            <div className="account__profile--right-formfield">
              <p className="account__profile--right-inputlabel">City *</p>
              <input className="account__profile--right-input" type="text" placeholder="City" name="city" {...register('city')} onChange={(e) => {
                register('city').onChange(e);
                handleMailingInputChange(e);
              }} value={mailingValue.city} />
              <p className='text-red-600'>{errors.city?.message}</p>
            </div>
            <div className="account__profile--right-formfield">
              <p className="account__profile--right-inputlabel">State *</p>
              <input className="account__profile--right-input" type="text" placeholder="State" name="state" {...register('state')} onChange={(e) => {
                register('state').onChange(e);
                handleMailingInputChange(e);
              }} value={mailingValue.state} />
              <p className='text-red-600'>{errors.state?.message}</p>
            </div>
            <div className="account__profile--right-formfield">
              <p className="account__profile--right-inputlabel">Zipcode *</p>
              <input className="account__profile--right-input" type="text" placeholder="Zipcode" name="zipcode" {...register('zipcode')}
                onChange={(e) => {
                  register('zipcode').onChange(e);
                  handleMailingInputChange(e);
                }}
                value={mailingValue.zipcode} />
              <p className='text-red-600'>{errors.zipcode?.message}</p>
            </div>
          </div>
        </div>
        <div className="buttons">
          <Link className="buttons__btn buttons__btn--reset" onClick={() => handleReset()}>Reset</Link>
          <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link>
          <button type='submit' className="buttons__btn buttons__btn--save">
            {updateFieldsData?.MailAddress?.address_id ? 'Update' : 'Save'}
          </button>
          <button type='button' disabled={!isMailing} className={`buttons__btn buttons__btn--next ${(!isMailing) ? "disabled" : ""}`} onClick={() => nextPage()}>
            Next
          </button>
        </div>
      </form>
    </>
  )
}

export default MailingAddress