import React, { useEffect, useReducer, useState } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import Topbar from '../components/Topbar'
import TopNavBar from '../components/TopNavBar';
import { useDropdown } from '../reducer/DropdownReducer';
import Loader from '../components/Loader';
const Account = () => {
  const location = useLocation();
  const path = location.pathname;
  // const pagename = path.substring(path.lastIndexOf('/') + 1);
  const [pagename, setPagename] = useState();
  const { state, dispatch } = useDropdown();
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  const handleDropdownChange = (e) => {
    dispatch({ type: 'SET_OPTION', payload: e.target.value });
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (path.includes('/orders/Edit') || path.includes('/orders/View')) {
        setPagename('File Details');
      } else {
        setPagename(path.substring(path.lastIndexOf('/') + 1));
      }
    })();
  }, [path]);
  return (
    <>
      {isLoading && <Loader />}
      <Topbar />
      {/* <div className='container'>
        <div className='profile__content'>
          {path === '/dashboard' && <UserDashboard/>}
          {path === "/myprofile" && <CompleteProfile/>}
          {path === "/myprofile/edit" && <EditProfile/>}
          {path.startsWith('/update-profile') && <UpdateProfile/>}
        </div>
    </div> */}
      <div className='account'>
        <div className='account__topnav'>
          <TopNavBar />
        </div>
        <div className='account__container'>
          <div className='account__breadcrumb'>
            <div className='account__breadcrumb--left'>
              {userData?.account_type == 'agent' && <NavLink to='/agent/dashboard'>Home</NavLink>}
              {userData?.account_type != 'agent' && <NavLink to='/account/dashboard'>Home</NavLink>}
              <img src="/assets/images/chevron-right.svg" alt='chevron' />
              <NavLink className="text-[#A7A7A7] capitalize">{pagename}</NavLink>
            </div>
            {/* <p className='text-[#00599C] font-semibold text-sm'>Financial Year 2023-24</p> */}

            {userData?.account_type == 'agent' &&
              <select className={`text-[#00599C] font-semibold text-sm bg-transparent`}
                name='fiscalYear'
                value={state.selectedOption} onChange={handleDropdownChange}
              >
                <option value="2023">Financial Year 2022-23</option>
                <option value="2024">Financial Year 2023-24</option>
              </select>
            }
          </div>
          <div className='account__content'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default Account