import React, { useEffect, useState } from 'react'
import { _get, _post } from '../utills/apiClient';
import { CREATE_RENTAL, FETCH_RENTAL } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import { ssnPinFOrmat } from './commonFunction';
import Loader from './Loader';
import { CreateNotification } from './commonFunction';


const OrderRental = (props) => {
    const showSnackbar = useSnackbar();
    const [rentalIncAndExpValues, setRentalIncAndExpValues] = useState();
    const currentYear = new Date().getFullYear();
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const [screenMode, setScreenMode] = useState();
    const [accordion, setAccordion] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Avoid state changes or operations that unnecessarily trigger re-renders
        // unless specifically required
    }, []);

    useEffect(() => {
        getRental(props?.orderData?.file_no);
        setScreenMode(props?.screenType);
    }, [props?.orderData?.file_no, props?.screenType]);

    const getRental = async (file_no) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_RENTAL}${file_no}`);
            if (response.data?.RentalIncomeAndExpenses && response.data?.RentalIncomeAndExpenses.length > 0) {
                let accValues = [];
                response.data?.RentalIncomeAndExpenses.map((data) => {
                    data.AutoMobileExpenses.purchase_date = formatDate(data.AutoMobileExpenses.purchase_date);
                    data.AutoMobileExpenses.dateplaced_service = formatDate(data.AutoMobileExpenses.dateplaced_service);
                    data.HomeOfficeExpenses.purchase_date = formatDate(data.HomeOfficeExpenses.purchase_date);
                    accValues.push(false);
                });
                setAccordion([...accValues]);
                setRentalIncAndExpValues(response.data?.RentalIncomeAndExpenses);


            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar('Getting Error on getRental', 'error');
            console.error('Error fetching data:', error);
        }
    };

    const handleAccordion = (status, index) => {
        const newItems = accordion.map((item, i) =>
            i === index ? !status : item
        );
        setAccordion(newItems);
    }
    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };
    const cancel = () => {
        setScreenMode('View');
    }
    const edit = () => {
        setScreenMode('Edit');
    }

    const handleRentalInfoChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...rentalIncAndExpValues];
        newItems[index]['RentalInfo'][name] = value;
        setRentalIncAndExpValues(newItems);
        validateField(index, name, value);
    }

    const handleExpensesChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...rentalIncAndExpValues];
        newItems[index]['Expenses'][name] = value;
        setRentalIncAndExpValues(newItems);
    }

    const handleAutoMobileExpensesChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...rentalIncAndExpValues];
        newItems[index]['AutoMobileExpenses'][name] = value;
        setRentalIncAndExpValues(newItems);
    }

    const handleHomeOfficeExpensesChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...rentalIncAndExpValues];
        newItems[index]['HomeOfficeExpenses'][name] = value;
        setRentalIncAndExpValues(newItems);
    }

    // Validate a single field
    const validateField = (index, name, value) => {
        let error = '';
        if (name === 'rental_property_owner' && !value) {
            error = 'Rental Property Owner is required';
        }
        if (name === 'EIN_SSN') {

            if (!value) {
                error = 'EIN/SSN is required';
            }
            // else if (!/^[0-9]+$/i.test(value)) {
            //     error = 'Must be only digits';
            // } 
            else if (!/^\d{3}-\d{2}-\d{4}$/i.test(ssnPinFOrmat(value))) {
                error = 'EIN/SSN must be exactly 9 digits';
            }

        }
        if (name === 'property_address' && !value) {
            error = 'Property Address 1 is required';
        }
        if (name === 'property_address2' && !value) {
            error = 'Property Address 2 is required';
        }
        if (name === 'property_address3' && !value) {
            error = 'Property Address 3 is required';
        }
        if (name === 'gross_rent') {
            if (!value) {
                error = 'Gross Rent is required';
            } else if (!/^[0-9]+$/i.test(value)) {
                error = 'Must be only digits';
            }
        }
        // if (name === 'other_income') {
        //     if (!value) {
        //         error = 'Other Income is required';
        //     } else if (!/^[0-9]+$/i.test(value)) {
        //         error = 'Must be only digits';
        //     }
        // }
        const updatedErrors = [...errors];
        if (!updatedErrors[index]) {
            updatedErrors[index] = {};
        }
        updatedErrors[index][name] = error;
        setErrors(updatedErrors);
    };

    // Validate the entire form on submit
    const validateForm = () => {
        const formErrors = rentalIncAndExpValues.map((item, index) => {
            const itemErrors = {};
            if (!item.RentalInfo.rental_property_owner) {
                itemErrors.rental_property_owner = 'Rental Property Owner is required';
            }
            if (!item.RentalInfo.EIN_SSN || !(/^\d{3}-\d{2}-\d{4}$/i.test(ssnPinFOrmat(item.RentalInfo.EIN_SSN)))) {
                itemErrors.EIN_SSN = 'EIN_SSN is required and Must 10 digits';
            }
            if (!item.RentalInfo.property_address) {
                itemErrors.property_address = 'Property Address is required';
            }
            if (!item.RentalInfo.property_address2) {
                itemErrors.property_address2 = 'Property Address 2 is required';
            }
            if (!item.RentalInfo.property_address3) {
                itemErrors.property_address3 = 'Property Address 3 is required';
            }
            if (!item.RentalInfo.gross_rent || !(/^[0-9]+$/i.test(item.RentalInfo.gross_rent))) {
                itemErrors.gross_rent = 'Gross Rent is required and Must be only digits';
            }
            // if (!item.RentalInfo.other_income || !(/^[0-9]+$/i.test(item.RentalInfo.other_income))) {
            //     itemErrors.other_income = 'Other Income is required and Must be only digits';
            // }
            console.log('itemErrors', itemErrors);
            return itemErrors;
        });
        setErrors(formErrors);
        return formErrors.every(itemErrors => Object.keys(itemErrors).length === 0);
    };

    const onSubmit = (e, index) => {
        e.preventDefault();
        if (validateForm()) {
            rentalIncAndExpValues[index].RentalInfo.EIN_SSN = rentalIncAndExpValues[index].RentalInfo.EIN_SSN.replace(/-/g, '');
            const payload = {
                file_no: userData?.file_no,
                cus_id: userData?.cus_id,
                RentalIncomeAndExpenses: [rentalIncAndExpValues[index]],
            }
            createOrUpdateRental(payload);
        } else {
            console.log('Validation failed');
        }
    }

    const createOrUpdateRental = async (payload) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_RENTAL, payload);
            console.log('createOrUpdateRental', response);
            if (response.status == 200 && response.data?.rental_id) {
                CreateNotification('Rental Updated Successfully', 'success');
                showSnackbar('Rental Updated Successfully', 'success');
                setScreenMode('View');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateRental", 'error');
            console.error('Error adding data:', error);
        }
    };

    // Function to copy text to clipboard
    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showSnackbar('Text Copied', 'info');
                //console.log('Text copied to clipboard');
            })
            .catch((err) => {
                // console.error('Failed to copy text: ', err);
            });
    };


    return (
        <>
            {isLoading && <Loader />}
            {rentalIncAndExpValues && rentalIncAndExpValues.length > 0 && (
                <>
                    {rentalIncAndExpValues.map((rentalData, index) => (
                        <div className='order__tabscontent accordion'>
                            <div className={accordion[index] ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                                <div className='order__contentcard accordion'>
                                    <div className='order__contentcard--header' >
                                        <div className='flex gap-8'>
                                            <p className='order__contentcard--title'>{rentalData?.RentalInfo?.property_name}</p>
                                            <div className='order__contentcard--actions'>
                                                {screenMode == 'View' && <div className='order__contentcard--action' onClick={() => edit()}>
                                                    <img src='/assets/images/order-info-edit.svg' alt='edit' />
                                                    <span className='text-sm text-[#6D6E71]'>Edit</span>
                                                </div>}
                                                {screenMode == 'Edit' && <div className='order__contentcard--action' onClick={() => cancel()}>
                                                    <img src='/assets/images/fullview-icon.svg' alt='edit' />
                                                    <span className='text-sm text-[#6D6E71]'>View</span>

                                                </div>}
                                                {/* <div className='order__contentcard--action'>
                                                    <img src='/assets/images/order-copyall.svg' alt='edit' />
                                                    <span className='text-sm text-[#6D6E71]'>Copy All</span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {accordion[index] && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' onClick={() => handleAccordion(accordion[index], index)} /></button>}
                                        {!accordion[index] && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' onClick={() => handleAccordion(accordion[index], index)} /></button>}
                                    </div>
                                    {accordion[index] &&
                                        <>
                                            <form onSubmit={(e) => onSubmit(e, index)}>
                                                <div className='order__contentcard--content accordion'>

                                                    <div className='order__contentcard--details'>
                                                        {screenMode == 'View' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.rental_property_owner}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.rental_property_owner)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                        {screenMode == 'Edit' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="Property Owner" name="rental_property_owner"
                                                                        onChange={(e) => {
                                                                            handleRentalInfoChange(e, index);
                                                                        }}
                                                                        value={rentalData?.RentalInfo.rental_property_owner} />
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.rental_property_owner)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.rental_property_owner && <p className='text-red-600'>{errors[index].rental_property_owner}</p>}
                                                            </>}
                                                        <span className='text-sm italic font-light'>Rental Property Owner's Name</span>
                                                    </div>

                                                    <div className='order__contentcard--details'>
                                                        {screenMode == 'View' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{ssnPinFOrmat(rentalData?.RentalInfo?.EIN_SSN)}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.EIN_SSN)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                        {screenMode == 'Edit' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="EIN/SSN Number" name="EIN_SSN"
                                                                        onChange={(e) => {
                                                                            handleRentalInfoChange(e, index);
                                                                        }}
                                                                        value={ssnPinFOrmat(rentalData?.RentalInfo.EIN_SSN)} />
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo.EIN_SSN)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.EIN_SSN && <p className='text-red-600'>{errors[index].EIN_SSN}</p>}
                                                            </>}
                                                        <span className='text-sm italic font-light'>EIN/SSN</span>
                                                    </div>

                                                    <div className='order__contentcard--details'>
                                                        {screenMode == 'View' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_name}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.property_name)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                        {screenMode == 'Edit' && <>
                                                            <div className="order__contentcard--details-input">
                                                                <input type='text' placeholder="Property Name" name="property_name"
                                                                    onChange={(e) => {
                                                                        handleRentalInfoChange(e, index);
                                                                    }}
                                                                    value={rentalData?.RentalInfo.property_name} />
                                                                <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo.property_name)} className='order__contentcard--details-copy'>
                                                                    <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                </button>
                                                            </div>
                                                            {errors[index]?.property_name && <p className='text-red-600'>{errors[index].property_name}</p>}
                                                        </>}
                                                        <span className='text-sm italic font-light'>Property Name (if any)</span>
                                                    </div>

                                                    <div className='order__contentcard--details'>
                                                        {screenMode == 'View' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_address}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.property_address)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                        {screenMode == 'Edit' && <>
                                                            <div className="order__contentcard--details-input">
                                                                <input type='text' placeholder="Address" name="property_address"
                                                                    onChange={(e) => {
                                                                        handleRentalInfoChange(e, index);
                                                                    }}
                                                                    value={rentalData?.RentalInfo.property_address} />
                                                                <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo.property_address)} className='order__contentcard--details-copy'>
                                                                    <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                </button>
                                                            </div>
                                                            {errors[index]?.property_address && <p className='text-red-600'>{errors[index].property_address}</p>}
                                                        </>}
                                                        <span className='text-sm italic font-light '>Property Address</span>
                                                    </div>
                                                    <div className='order__contentcard--details'>
                                                        {screenMode == 'View' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_address2}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.property_address2)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                        {screenMode == 'Edit' && <>
                                                            <div className="order__contentcard--details-input">
                                                                <input type='text' placeholder="Address" name="property_address2"
                                                                    onChange={(e) => {
                                                                        handleRentalInfoChange(e, index);
                                                                    }}
                                                                    value={rentalData?.RentalInfo.property_address2} />
                                                                <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo.property_address2)} className='order__contentcard--details-copy'>
                                                                    <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                </button>
                                                            </div>
                                                            {errors[index]?.property_address2 && <p className='text-red-600'>{errors[index].property_address2}</p>}
                                                        </>}
                                                        <span className='text-sm italic font-light'>Address 2</span>
                                                    </div>
                                                    <div className='order__contentcard--details'>
                                                        {screenMode == 'View' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_address3}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.property_address3)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                        {screenMode == 'Edit' && <>
                                                            <div className="order__contentcard--details-input">
                                                                <input type='text' placeholder="Address" name="property_address3"
                                                                    onChange={(e) => {
                                                                        handleRentalInfoChange(e, index);
                                                                    }}
                                                                    value={rentalData?.RentalInfo.property_address3} />
                                                                <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo.property_address3)} className='order__contentcard--details-copy'>
                                                                    <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                </button>
                                                            </div>
                                                            {errors[index]?.property_address3 && <p className='text-red-600'>{errors[index].property_address3}</p>}
                                                        </>}
                                                        <span className='text-sm italic font-light'>Address 3</span>
                                                    </div>

                                                    <div className='order__contentcard--details'>
                                                        {screenMode == 'View' &&
                                                            <>
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.started_current_year}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.started_current_year)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }
                                                        {screenMode == 'Edit' && <>
                                                            <div className='flex gap-3'>
                                                                <input type='radio' name='started_current_year'
                                                                    onChange={(e) => {

                                                                        handleRentalInfoChange(e, index);
                                                                    }}
                                                                    value="y" checked={rentalData?.RentalInfo.started_current_year == 'y'} /> <label>Yes</label>
                                                            </div>
                                                            <div className='flex gap-3'>
                                                                <input type='radio' name='started_current_year'
                                                                    onChange={(e) => {

                                                                        handleRentalInfoChange(e, index);
                                                                    }}
                                                                    value="n" checked={rentalData?.RentalInfo.started_current_year == 'n'} /> <label>No</label>
                                                            </div></>}
                                                        <span className='text-sm italic font-light'>Started this Rental in {currentYear}</span>
                                                    </div>
                                                </div>
                                                <div className='order__contentcard--content-inner'>
                                                    <p className='order__contentcard--title px-4'>Income</p>
                                                    <div className='order__contentcard--content accordion'>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <>
                                                                    <div className="order__contentcard--details-input">
                                                                        <span className='text-sm'>{rentalData?.RentalInfo?.gross_rent}</span>

                                                                        <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.gross_rent)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="Amount in USD" name="gross_rent"
                                                                        onChange={(e) => {
                                                                            handleRentalInfoChange(e, index);
                                                                        }}
                                                                        value={rentalData?.RentalInfo.gross_rent} />

                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo.gross_rent)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.gross_rent && <p className='text-red-600'>{errors[index].gross_rent}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Gross receipts / Sales</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <>
                                                                    <div className="order__contentcard--details-input">
                                                                        <span className='text-sm'>{rentalData?.RentalInfo?.other_income}</span>
                                                                        <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo?.other_income)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="Amount in USD" name="other_income"
                                                                        onChange={(e) => {
                                                                            handleRentalInfoChange(e, index);
                                                                        }}
                                                                        value={rentalData?.RentalInfo.other_income} />
                                                                    <button type='button' onClick={() => handleCopyClick(rentalData?.RentalInfo.other_income)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.other_income && <p className='text-red-600'>{errors[index].other_income}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Other Income</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='order__contentcard--content-inner'>
                                                    <p className='order__contentcard--title px-4'>Expenses</p>
                                                    <div className='order__contentcard--content grid-cols-1'>
                                                        <table className='order__contentcard--table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Expenses</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Advertising</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="advertising"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.advertising} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.advertising)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Commision and fees</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="commission_fees"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.commission_fees} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.commission_fees)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mortgage Interest</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="mortgage_interest"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.mortgage_interest} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.mortgage_interest)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Property/Real EstateTax</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="property_tax"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.property_tax} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.property_tax)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>HOA</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="HOA"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.HOA} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.HOA)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PMI</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="PMI"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.PMI} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.PMI)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Landscapping</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="landscapping"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.landscapping} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.advertising)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Painting</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="painting"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.painting} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.painting)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Other Taxes and licenses</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="other_taxes"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.other_taxes} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.other_taxes)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Home Upgrades (For Rental Purposes)</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="home_upgrade"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.home_upgrade} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.home_upgrade)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Travel Expenses (For Rental Purposes)</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="travel_expenses"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.travel_expenses} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.travel_expenses)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Meals (For Rental Purposes)</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="meals"
                                                                        onChange={(e) => {

                                                                            handleExpensesChange(e, index);
                                                                        }} value={rentalData?.Expenses?.meals} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.Expenses?.meals)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='order__contentcard--content-inner'>
                                                    <p className='order__contentcard--title px-4'>Automobile Expenses</p>
                                                    <div className='order__contentcard--content grid-cols-1'>
                                                        <table className='order__contentcard--table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Expenses</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Purchase Date</td>
                                                                    <td><input type='date' disabled={screenMode == 'View'} name="purchase_date"
                                                                        onChange={(e) => {
                                                                            handleAutoMobileExpensesChange(e, index);
                                                                        }} value={rentalData?.AutoMobileExpenses?.purchase_date} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.AutoMobileExpenses?.purchase_date)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Date Placed in Service</td>
                                                                    <td><input type='date' disabled={screenMode == 'View'} name="dateplaced_service"
                                                                        onChange={(e) => {
                                                                            handleAutoMobileExpensesChange(e, index);
                                                                        }} value={rentalData?.AutoMobileExpenses?.dateplaced_service} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.AutoMobileExpenses?.dateplaced_service)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Purchase Price (For Depreciation)</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="purchase_price"
                                                                        onChange={(e) => {
                                                                            handleAutoMobileExpensesChange(e, index);
                                                                        }} value={rentalData?.AutoMobileExpenses?.purchase_price} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.AutoMobileExpenses?.purchase_price)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Business Miles</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="business_meal"
                                                                        onChange={(e) => {
                                                                            handleAutoMobileExpensesChange(e, index);
                                                                        }} value={rentalData?.AutoMobileExpenses?.business_meal} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.AutoMobileExpenses?.business_meal)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Commute and Leisure Miles</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="communte_miles"
                                                                        onChange={(e) => {
                                                                            handleAutoMobileExpensesChange(e, index);
                                                                        }} value={rentalData?.AutoMobileExpenses?.communte_miles} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.AutoMobileExpenses?.communte_miles)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Percentage of Business Use</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="businessuse_percentage"
                                                                        onChange={(e) => {
                                                                            handleAutoMobileExpensesChange(e, index);
                                                                        }} value={rentalData?.AutoMobileExpenses?.businessuse_percentage} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.AutoMobileExpenses?.businessuse_percentage)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='order__contentcard--content-inner'>
                                                    <p className='order__contentcard--title px-4'>Home Office Expenses</p>
                                                    <div className='order__contentcard--content grid-cols-1'>
                                                        <table className='order__contentcard--table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Expenses</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Purchase Price (For Depreciation)</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="purchase_price"
                                                                        onChange={(e) => {
                                                                            handleHomeOfficeExpensesChange(e, index);
                                                                        }} value={rentalData?.HomeOfficeExpenses?.purchase_price} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.HomeOfficeExpenses?.purchase_price)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Land Value (if Known)</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="land_value"
                                                                        onChange={(e) => {
                                                                            handleHomeOfficeExpensesChange(e, index);
                                                                        }} value={rentalData?.HomeOfficeExpenses?.land_value} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.HomeOfficeExpenses?.land_value)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Purchase Date</td>
                                                                    <td><input type='date' disabled={screenMode == 'View'} name="purchase_date"
                                                                        onChange={(e) => {
                                                                            handleHomeOfficeExpensesChange(e, index);
                                                                        }} value={rentalData?.HomeOfficeExpenses?.purchase_date} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.HomeOfficeExpenses?.purchase_date)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Square Foot of Home Office</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="office_squarefoot"
                                                                        onChange={(e) => {
                                                                            handleHomeOfficeExpensesChange(e, index);
                                                                        }} value={rentalData?.HomeOfficeExpenses?.office_squarefoot} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.HomeOfficeExpenses?.office_squarefoot)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Square foot of Whole House</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="home_squarefoot"
                                                                        onChange={(e) => {
                                                                            handleHomeOfficeExpensesChange(e, index);
                                                                        }} value={rentalData?.HomeOfficeExpenses?.home_squarefoot} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.HomeOfficeExpenses?.home_squarefoot)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Percentage of Home office</td>
                                                                    <td><input type='text' disabled={screenMode == 'View'} name="home_office_percentage"
                                                                        onChange={(e) => {
                                                                            handleHomeOfficeExpensesChange(e, index);
                                                                        }} value={rentalData?.HomeOfficeExpenses?.home_office_percentage} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(rentalData?.HomeOfficeExpenses?.home_office_percentage)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {screenMode == 'Edit' &&
                                                    <div className="buttons">
                                                        <button type='button' onClick={() => cancel()} className="buttons__btn buttons__btn--cancel">
                                                            Cancel
                                                        </button>
                                                        {/* <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link> */}
                                                        <button type='submit' className="buttons__btn buttons__btn--save">
                                                            Update
                                                        </button>

                                                    </div>
                                                }
                                            </form>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
    )
}

export default OrderRental