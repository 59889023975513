import React, { useEffect, useState } from 'react'
import { _get } from '../utills/apiClient';
import { FETCH_HOME } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';

const ReviewHome = ({ onLoaded }) => {
    const showSnackbar = useSnackbar();
    const [homeValues, setHomeValues] = useState();

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('taxData'));
        getHome(user?.file_no);
    }, []);


    const getHome = async (file_no) => {
        try {
            const response = await _get(`${FETCH_HOME}${file_no}`);
            if (response.data?.homeSaleDetails && response.data?.homeSaleDetails.length > 0) {
                let accValues = [];
                response.data?.homeSaleDetails.map((data) => {
                    accValues.push(false);
                });
                setInnerAccordion([...accValues]);
                setHomeValues(response.data?.homeSaleDetails);
            }
            onLoaded();
        } catch (error) {
            onLoaded();
            showSnackbar('Getting Error on getRental', 'error');
            console.error('Error fetching data:', error);
        }
    };
    const [accordion, setAccordion] = useState(true);
    const handleAccordion = (status) => {
        setAccordion(!status);
    }
    const [innerAccordion, setInnerAccordion] = useState([]);
    const handleInnerAccordion = (status, index) => {
        const newItems = innerAccordion.map((item, i) =>
            i === index ? !status : item
        );
        setInnerAccordion(newItems);
    }
    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    return (
        <>
            {homeValues && homeValues.length > 0 && (
                <div className='order__tabscontent accordion'>
                    <div className={accordion ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                        <div className='order__contentcard accordion'>
                            <div className='order__contentcard--header' onClick={() => handleAccordion(accordion)}>
                                <div className='flex gap-8'>
                                    <p className='order__contentcard--title'>Home Details</p>
                                </div>
                                {accordion && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' /></button>}
                                {!accordion && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' /></button>}
                            </div>
                            {accordion &&
                                <>
                                    {homeValues.map((homeData, index) => (
                                        <div className='order__tabscontent accordion'>
                                            <div className={innerAccordion[index] ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                                                <div className='order__contentcard accordion'>
                                                    <div className='order__contentcard--header' onClick={() => handleInnerAccordion(innerAccordion[index], index)}>
                                                        <div className='flex gap-8'>
                                                            <p className='order__contentcard--title'>Home {index + 1}</p>
                                                        </div>
                                                        {innerAccordion[index] && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' /></button>}
                                                        {!innerAccordion[index] && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' /></button>}
                                                    </div>
                                                    {innerAccordion[index] &&
                                                        <>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Home Info</p>
                                                                <div className='order__contentcard--content accordion'>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{formatDate(homeData?.purchased_date)}</span>
                                                                        <span className='text-sm italic font-light'>Date of Home was Purchased</span>
                                                                    </div>

                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{formatDate(homeData?.occupied_date)}</span>
                                                                        <span className='text-sm italic font-light'>Date of Home Occupied</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{formatDate(homeData?.rented_date)}</span>
                                                                        <span className='text-sm italic font-light '>Date of Home Rented</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{formatDate(homeData?.rental_end_date)}</span>
                                                                        <span className='text-sm italic font-light'>Date of Home Rental Ended</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{formatDate(homeData?.sold_date)}</span>
                                                                        <span className='text-sm italic font-light'>Date of Home Sold</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Basis of Home</p>
                                                                <div className='order__contentcard--content accordion'>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{homeData?.purchase_price}</span>
                                                                        <span className='text-sm italic font-light'>Purchase price of home</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{homeData?.closing_cost}</span>
                                                                        <span className='text-sm italic font-light'>Purchase Closing Cost (do not include Seller/Developer portion)</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{homeData?.upgrade_cost}</span>
                                                                        <span className='text-sm italic font-light'>Cost of Capital Improvements or Home Upgrades</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Basis of Sale</p>
                                                                <div className='order__contentcard--content accordion'>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{homeData?.selling_price}</span>
                                                                        <span className='text-sm italic font-light'>Selling price of home</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{homeData?.selling_closing_cost}</span>
                                                                        <span className='text-sm italic font-light'>Selling Closing Cost (Do not include Buyer portions)</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>}
                        </div>
                    </div>
                </div >
            )}
        </>
    )
}

export default ReviewHome