import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loader from './Loader';
import NotificationPopup from './Notification';

const Topbar = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  const [profilePanel, setProfilePanel] = useState(false);
  const [notificationPopup, setNotificationPopup] = useState(false);
  // const [NotificationCount, setNotificationCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {

    // const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
    // const unreadNotifications = storedNotifications.filter(notification => !notification.read).length;
    //     setUnreadCount(unreadNotifications);


    const fetchUnreadCount = () => {
      const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
      const unreadNotifications = storedNotifications.filter(notification => !notification.read).length;
      setUnreadCount(unreadNotifications);
    };


    fetchUnreadCount();


    const handleNewNotification = () => fetchUnreadCount();

    window.addEventListener('newNotification', handleNewNotification);

    return () => {
      window.removeEventListener('newNotification', handleNewNotification);
    };
  }, [notificationPopup]);
  // },[localStorage.getItem("notifications")])

  const logout = async () => {
    setIsLoading(true);
    sessionStorage.clear();
    setIsLoading(false);
    navigate('/login');
  };

  const toggleNotificationPopup = () => {
    setNotificationPopup(!notificationPopup);

    if (!notificationPopup) {
      const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
      const updatedNotifications = storedNotifications.map(notification => ({ ...notification, read: true }));
      localStorage.setItem("notifications", JSON.stringify(updatedNotifications));


      setUnreadCount(0);
    }
  };

  // const handleReadAllNotifications = () => {
  //   setUnreadCount(0);
  // };


  return (
    <>
      <div className='topbar'>
        {isLoading && <Loader />}
        <div className='topbar__container'>
          <Link to="/account/dashboard" className='topbar__logo'>OMMTAX</Link>
          <div className='topbar__right'>
            <div className='flex gap-3 justify-center items-center'>
              {/* <Link><img src="/assets/images/settings-icon.svg" alt='settings' /></Link> */}
              <div className='relative cursor-pointer' onClick={toggleNotificationPopup}>
                <img src="/assets/images/notifications-icon.svg" alt='notifications' className={unreadCount > 0 ? 'text-red-500' : ''} />
                {unreadCount > 0 && (
                  <span className='absolute top-0 right-0 h-3 w-3 bg-red-500 rounded-full'>

                  </span>

                )}
              </div>


            </div>
            <div className='topbar__profile'>
              <p className='topbar__profilepic' onClick={() => setProfilePanel(!profilePanel)}>
                {userData?.first_name?.charAt(0)?.toUpperCase()}{userData?.last_name?.charAt(0)?.toUpperCase()}
              </p>
              {profilePanel && (
                <div className='topbar__profile--panel'>
                  <button className='topbar__profile--panel-item' onClick={logout}>
                    <img src='/assets/images/logout-icon.svg' alt='logout' />
                    <span>Logout</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {notificationPopup && <NotificationPopup />}
    </>

  );
};

export default Topbar;