import React from 'react'
import Submit from '../components/SubmitFiling'
import ProfileMenu from '../components/ProfileMenu'



const SubmitIncome = () => {
    return (
      <>
      <Submit/>
      </>
  )
}
export default SubmitIncome