import { Link } from "react-router-dom";
import Button from "./Button";

export default function Authentication() {
  return (
    <>
      <div className="auth">
        <p className="auth__title">Account Authentication</p>
        <div className="user__inputgroup">
          <div className='relative'>
            <input type='number' required />
            <label>One Time Password</label>
          </div>
        </div>
        <Button link="/authentication/success" text="Verify" />
        <p className="auth__resend">Didn’t Receive OTP ? <a href='/authentication' className='text-[#00599C]'>Resend</a></p>
      </div>
    </>
  )
}

