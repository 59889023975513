import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
export default function OrdersStatusPie(props) {

    console.log(props);

    const handleClick = (event, itemIdentifier, item) => {
        props.pieFilterFunction(item.id)
        console.log(item.id)
    };


    return (
        <>
            <PieChart
                series={[
                    {
                        innerRadius: 85,
                        data: props?.data,
                        // getLabelText: (item, index) => ({
                        //     value: `${item.label}: ${item.value}`,
                        //     color: labelColors[index % labelColors.length], // Change label color based on index
                        // }),
                    },
                ]}
                onItemClick={handleClick}

                width={700}
                height={230}
            />

        </>
    );
}
