import React, { useEffect, useState } from 'react'
import { _get } from '../utills/apiClient';
import { FETCH_BUSINESS } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import { ssnPinFOrmat } from './commonFunction';

const ReviewBusiness = ({ onLoaded }) => {
    const showSnackbar = useSnackbar();
    const [businessIncAndExpValues, setBusinessIncAndExpValues] = useState();
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('taxData'));
        getBusiness(user?.file_no);
    }, []);


    const getBusiness = async (file_no) => {
        try {
            const response = await _get(`${FETCH_BUSINESS}${file_no}`);
            if (response.data?.BusinessIncomeAndExpenses && response.data?.BusinessIncomeAndExpenses.length > 0) {
                let accValues = [];
                response.data?.BusinessIncomeAndExpenses.map((data) => {
                    accValues.push(false);
                });
                setInnerAccordion([...accValues]);
                setBusinessIncAndExpValues(response.data?.BusinessIncomeAndExpenses);
            }
            onLoaded();
        } catch (error) {
            onLoaded();
            showSnackbar('Getting Error on getBusiness', 'error');
            console.error('Error fetching data:', error);
        }
    };
    const [accordion, setAccordion] = useState(true);
    const handleAccordion = (status) => {
        setAccordion(!status);
    }
    const [innerAccordion, setInnerAccordion] = useState([]);
    const handleInnerAccordion = (status, index) => {
        const newItems = innerAccordion.map((item, i) =>
            i === index ? !status : item
        );
        setInnerAccordion(newItems);
    }
    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    return (
        <>
            {businessIncAndExpValues && businessIncAndExpValues.length > 0 && (
                <div className='order__tabscontent accordion'>
                    <div className={accordion ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                        <div className='order__contentcard accordion'>
                            <div className='order__contentcard--header' onClick={() => handleAccordion(accordion)}>
                                <div className='flex gap-8'>
                                    <p className='order__contentcard--title'>Business Details</p>
                                </div>
                                {accordion && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' /></button>}
                                {!accordion && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' /></button>}
                            </div>
                            {accordion &&
                                <>
                                    {businessIncAndExpValues.map((businessData, index) => (
                                        <div className='order__tabscontent accordion'>
                                            <div className={innerAccordion[index] ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                                                <div className='order__contentcard accordion'>
                                                    <div className='order__contentcard--header' onClick={() => handleInnerAccordion(innerAccordion[index], index)}>
                                                        <div className='flex gap-8'>
                                                            <p className='order__contentcard--title'>{businessData?.BusinessInfo?.business_name}</p>
                                                        </div>
                                                        {innerAccordion[index] && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' /></button>}
                                                        {!innerAccordion[index] && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' /></button>}
                                                    </div>
                                                    {innerAccordion[index] &&
                                                        <>
                                                            <div className='order__contentcard--content accordion'>

                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_name}</span>
                                                                    <span className='text-sm italic font-light'>Name</span>
                                                                </div>

                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{ssnPinFOrmat(businessData?.BusinessInfo?.EIN_SSN)}</span>
                                                                    <span className='text-sm italic font-light'>EIN/SSN</span>
                                                                </div>

                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_name}</span>
                                                                    <span className='text-sm italic font-light'>Business Name</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_address}</span>
                                                                    <span className='text-sm italic font-light '>Address 1</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_address2}</span>
                                                                    <span className='text-sm italic font-light'>Address 2</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_address3}</span>
                                                                    <span className='text-sm italic font-light'>Address 3</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_name}</span>
                                                                    <span className='text-sm italic font-light'>ZIP Code</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.started_current_year}</span>
                                                                    <span className='text-sm italic font-light'>Business Started in {currentYear}</span>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Income</p>
                                                                <div className='order__contentcard--content accordion'>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{businessData?.BusinessInfo?.gross_receipt_sale}</span>
                                                                        <span className='text-sm italic font-light'>Gross receipts / Sales</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{businessData?.BusinessInfo?.returns_allowances}</span>
                                                                        <span className='text-sm italic font-light'>Returns and allowances</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{businessData?.BusinessInfo?.other_income}</span>
                                                                        <span className='text-sm italic font-light'>Other Income</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Expenses</p>
                                                                <div className='order__contentcard--content grid-cols-1'>
                                                                    <table className='order__contentcard--table'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Expenses</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Advertising</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.advertising} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Commision and fees</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.commission_fees} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Contract labour and wages</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.labour_wages} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Insurance (Other than Health)</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.non_health_insurance} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Interest (Other than Mortgage)</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.mortgage_interest} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Legal and professional services</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.legal_professional_fees} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Office expenses</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.office_expenses} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Rent or lease (Vehicles/Equipments/OfficeItems)</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.rental_lease} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Repairs and maintenance</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.repairs_maintenance} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Supplies</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.supplies} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Taxes and licenses</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.taxes_licenses} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Travel Expenses (Flight/Hotel/Toll/Parking)</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.travel_expense} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Meals (Business only)</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.meals} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Utilities</td>
                                                                                <td><input type='text' value={businessData?.Expenses?.utilities} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Automobile Expenses</p>
                                                                <div className='order__contentcard--content grid-cols-1'>
                                                                    <table className='order__contentcard--table'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Expenses</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Purchase Date</td>
                                                                                <td><input type='text' value={formatDate(businessData?.AutoMobileExpenses?.purchase_date)} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Date Placed in Service</td>
                                                                                <td><input type='text' value={formatDate(businessData?.AutoMobileExpenses?.dateplaced_service)} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Purchase Price (For Depreciation)</td>
                                                                                <td><input type='text' value={businessData?.AutoMobileExpenses?.purchase_price} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Business Miles</td>
                                                                                <td><input type='text' value={businessData?.AutoMobileExpenses?.business_meal} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Commute and Leisure Miles</td>
                                                                                <td><input type='text' value={businessData?.AutoMobileExpenses?.communte_miles} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Percentage of Business Use</td>
                                                                                <td><input type='text' value={businessData?.AutoMobileExpenses?.businessuse_percentage} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Home Office Expenses</p>
                                                                <div className='order__contentcard--content grid-cols-1'>
                                                                    <table className='order__contentcard--table'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Expenses</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Purchase Price (For Depreciation)</td>
                                                                                <td><input type='text' value={businessData?.HomeOfficeExpenses?.purchase_price} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Land Value (if Known)</td>
                                                                                <td><input type='text' value={businessData?.HomeOfficeExpenses?.land_value} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Purchase Date</td>
                                                                                <td><input type='text' value={formatDate(businessData?.HomeOfficeExpenses?.purchase_date)} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Square Foot of Home Office</td>
                                                                                <td><input type='text' value={businessData?.HomeOfficeExpenses?.office_squarefoot} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Square foot of Whole House</td>
                                                                                <td><input type='text' value={businessData?.HomeOfficeExpenses?.home_squarefoot} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Percentage of Home office</td>
                                                                                <td><input type='text' value={businessData?.HomeOfficeExpenses?.home_office_percentage} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>}
                        </div>
                    </div>
                </div >
            )}
        </>
    )
}

export default ReviewBusiness