import { Link, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Tab, Box } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Buttons from './Buttons';
import BusinessIncomeRight from './BusinessIncomeRight';
import RentalRight from './RentalRight';
import HomeSaleDetails from './HomeSaleDetails';
import { useSnackbar } from './toaster';
import { DELETE_BUSINESS, DELETE_HOME, DELETE_RENTAL, FETCH_BUSINESS, FETCH_HOME, FETCH_QUESTIONNAIRE, FETCH_RENTAL } from '../constants/api.endpoint';
import { _post, _get } from '../utills/apiClient';
import Loader from './Loader';
import { AppContext } from '../utills/AppContext';

export default function FillingDetailsLogin() {
  const { screenType } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();

  const [businessList, setBusinessList] = useState([]);
  const [rentalList, setRentalList] = useState([]);
  const [homeList, setHomeList] = useState([]);

  const [selectedBusinessId, setSelectedBusinessId] = useState("");
  const [selectedRentalId, setSelectedRentalId] = useState("");
  const [selectedHomeId, setSelectedHomeId] = useState("");

  const [filling, setFilling] = useState(true);
  const [business, setBusiness] = useState(false);
  const [rental, setRental] = useState(false);
  const [home, setHome] = useState(false);

  const [businessValue, setBusinessValue] = useState()
  const [rentalValue, setRentalValue] = useState()
  const [homeValue, setHomeValue] = useState()

  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  const showSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [isNextEnable, setIsNextEnable] = useState(false);
  const { setValueFromChild } = useContext(AppContext);

  const handleBusiness = (businessId) => {
    setSelectedBusinessId(businessId);
    setFillingSteps(true, false, false, false);

  }
  const handleRental = (rentalId) => {
    setSelectedRentalId(rentalId);
    setFillingSteps(false, true, false, false);
  }
  const handleHome = (homeId) => {
    setSelectedHomeId(homeId);
    setFillingSteps(false, false, true, false);
  }

  const setFillingSteps = (busiStatus, Renttatus, hmeStatus, fillStatus) => {
    setBusiness(busiStatus);
    setRental(Renttatus);
    setHome(hmeStatus);
    setFilling(fillStatus);
  }

  useEffect(() => {
    (async () => {
      if (userData.qq_id) {
        await getQuestionnaire(userData.qq_id);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (screenType && id) {
        if (screenType == 'business') {
          handleBusiness(id);
        }
        if (screenType == 'rental') {
          handleRental(id);
        }
        if (screenType == 'home') {
          handleHome(id);
        }
      }
    })();
  }, [screenType, id]);

  const getQuestionnaire = async (qq_id) => {
    try {
      setIsLoading(true);
      const response = await _get(`${FETCH_QUESTIONNAIRE}${userData?.file_no}`);
      if (response.data?.Questionnaire) {
        setBusinessValue(response.data?.Questionnaire?.business_income);
        setRentalValue(response.data?.Questionnaire?.rental_income);
        setHomeValue(response.data?.Questionnaire?.home_sale_status);
        if (response.data?.Questionnaire?.business_income) {
          await getBusiness();
        }
        if (response.data?.Questionnaire?.rental_income) {
          await getRental();
        }
        if (response.data?.Questionnaire?.home_sale_status) {
          await getHome();
        }
      }
      setIsLoading(false);
    } catch (error) {
      showSnackbar('Getting Error on fetchQuestionnaire', 'error');
      console.error('Error fetching data:', error);
    }
  };

  const handleBusinessAddDetails = async () => {
    // await getBusiness();
    setBusinessList([]);
    setSelectedBusinessId('');
    setFillingSteps(true, false, false, false);
  }

  const getBusiness = async () => {
    try {
      setIsLoading(true);
      const response = await _get(`${FETCH_BUSINESS}${userData?.file_no}`);
      if (response.data?.BusinessIncomeAndExpenses && response.data?.BusinessIncomeAndExpenses.length > 0) {
        setBusinessList([...response.data?.BusinessIncomeAndExpenses]);
      }
      else {
        setBusinessList([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar('Getting Error on getBusiness', 'error');
      console.error('Error fetching data:', error);
    }
  };

  const handleRentalAddDetails = async () => {
    // await getRental();
    setRentalList([]);
    setSelectedRentalId('');
    setFillingSteps(false, true, false, false);
  }

  const getRental = async () => {
    try {
      setIsLoading(true);
      const response = await _get(`${FETCH_RENTAL}${userData?.file_no}`);
      if (response.data?.RentalIncomeAndExpenses && response.data?.RentalIncomeAndExpenses.length > 0) {
        setRentalList([...response.data?.RentalIncomeAndExpenses]);
      }
      else {
        setRentalList([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar('Getting Error on getRental', 'error');
      console.error('Error fetching data:', error);
    }
  };

  const handleHomeAddDetails = async () => {
    // await getHome();
    setHomeList([]);
    setSelectedHomeId('');
    setFillingSteps(false, false, true, false);
  }

  const getHome = async () => {
    try {
      setIsLoading(true);
      const response = await _get(`${FETCH_HOME}${userData?.file_no}`);
      if (response.data?.homeSaleDetails && response.data?.homeSaleDetails.length > 0) {
        setHomeList([...response.data?.homeSaleDetails]);
      }
      else {
        setHomeList([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar('Getting Error on getHome', 'error');
      console.error('Error fetching data:', error);
    }
  };


  const handleBack = async () => {
    setFillingSteps(false, false, false, true);
    await getBusiness();
    await getRental();
    await getHome();
    setUserData(JSON.parse(sessionStorage.getItem('taxData')));

  }


  useEffect(() => {
    if (businessValue == 'Yes' && rentalValue == 'Yes' && homeValue == 'Yes') {
      if (businessList && businessList.length > 0 && rentalList && rentalList.length > 0 &&
        homeList && homeList.length > 0) {
        setIsNextEnable(true);
      } else {
        setIsNextEnable(false);
      }
    } else if (businessValue == 'Yes' && rentalValue == 'Yes') {
      if (businessList && businessList.length > 0 && rentalList && rentalList.length > 0) {
        setIsNextEnable(true);
      } else {
        setIsNextEnable(false);
      }
    } else if (businessValue == 'Yes' && homeValue == 'Yes') {
      if (businessList && businessList.length > 0 &&
        homeList && homeList.length > 0) {
        setIsNextEnable(true);
      } else {
        setIsNextEnable(false);
      }
    } else if (rentalValue == 'Yes' && homeValue == 'Yes') {
      if (rentalList && rentalList.length > 0 &&
        homeList && homeList.length > 0) {
        setIsNextEnable(true);
      } else {
        setIsNextEnable(false);
      }
    } else if (businessValue == 'Yes') {
      if (businessList && businessList.length > 0) {
        setIsNextEnable(true);
      } else {
        setIsNextEnable(false);
      }
    } else if (rentalValue == 'Yes') {
      if (rentalList && rentalList.length > 0) {
        setIsNextEnable(true);
      } else {
        setIsNextEnable(false);
      }
    } else if (homeValue == 'Yes') {
      if (homeList && homeList.length > 0) {
        setIsNextEnable(true);
      } else {
        setIsNextEnable(false);
      }
    }
  }, [businessValue, rentalValue, rentalValue, businessList, rentalList, homeList, isNextEnable]);

  const nextPage = () => {
    navigate('/account/orders/document-checklist');
  }

  const deleteFilling = (id, index, type) => {
    let payload = {
      cus_id: userData?.cus_id
    }
    if (type == 'business') {
      payload.business_id = id;
      deleteBusiness(payload);
    }
    if (type == 'rental') {
      payload.rental_id = id;
      deleteRental(payload);
    }
    if (type == 'home') {
      payload.home_id = id;
      deleteHome(payload);
    }
  }

  const deleteBusiness = async (payload) => {
    setIsLoading(true);
    try {
      const response = await _post(`${DELETE_BUSINESS}`, payload);
      if (response?.data?.business) {
        showSnackbar("Business Deleted Successfully", 'success');
        getBusiness();
      } else {
        setIsLoading(false);
      }
      setValueFromChild(prevState => ({
        ...prevState,
        value: 'fillingDeleted'
      }));
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in deleteBusiness", 'error');
      console.error('Error adding data:', error);
    }
  };

  const deleteRental = async (payload) => {
    setIsLoading(true);
    try {
      const response = await _post(`${DELETE_RENTAL}`, payload);
      if (response?.data?.rental) {
        showSnackbar("Rental Deleted Successfully", 'success');
        getRental();
      } else {
        setIsLoading(false);
      }
      setValueFromChild(prevState => ({
        ...prevState,
        value: 'fillingDeleted'
      }));
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in deleteRental", 'error');
      console.error('Error adding data:', error);
    }
  };

  const deleteHome = async (payload) => {
    setIsLoading(true);
    try {
      const response = await _post(`${DELETE_HOME}`, payload);
      if (response?.data?.home_sale) {
        showSnackbar("Home Deleted Successfully", 'success');
        getHome();
      } else {
        setIsLoading(false);
      }
      setValueFromChild(prevState => ({
        ...prevState,
        value: 'fillingDeleted'
      }));
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in deleteHome", 'error');
      console.error('Error adding data:', error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="fillingDetails__container">
        {filling &&
          <>
            <h1 className="account__orders--right-formtitle pb-4">Filing Details</h1>
            {(businessValue == 'Yes') && <div className='fillingDetails__row'>
              <div className='fillingDetails__row--content'>
                <img src="/assets/images/BusinessIncome-icon.svg" alt='business' className='fillingDetails__row--icon' />
                <div className='fillingDetails__row--titlesection'>
                  <p className='fillingDetails__row--title'>Business Income and Expenses</p>
                  <p className='fillingDetails__row--desc'>Some content info about the business income and expenses file type and its use cases</p>

                  {businessList.length > 0 && (
                    <div>
                      {businessList.map((businessData, index) => (
                        <div className='flex flex-col gap-3 py-2'>
                          <div className='flex gap-3'>
                            <div className='fillingDetails__row--link cursor-pointer' onClick={() => handleBusiness(businessData?.BusinessInfo.business_id)}>
                              <p className='fillingDetails__row--linktext' title={businessData?.BusinessInfo.business_name}>{businessData?.BusinessInfo.business_name}</p>
                              <button className='fillingDetails__row--editbtn'>
                                <img src="/assets/images/Eye-icon.svg" alt='view/edit' />
                                View/Edit
                              </button>
                            </div>

                            <button type='button' onClick={() => deleteFilling(businessData?.BusinessInfo.business_id, index, 'business')} className="buttons__btn delete-btn buttons__btn--delete">Delete</button>
                          </div>

                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <button type='button' className='buttons__btn buttons__btn--outlined' onClick={() => handleBusinessAddDetails()}>Add Business</button>
            </div>}
            {(rentalValue == 'Yes') && <div className='fillingDetails__row'>
              <div className='fillingDetails__row--content'>
                <img src="/assets/images/RentalIncome-icon.svg" alt='business' className='fillingDetails__row--icon' />
                <div className='fillingDetails__row--titlesection'>
                  <p className='fillingDetails__row--title'>Rental Income and Expenses</p>
                  <p className='fillingDetails__row--desc'>Some content info about the business income and expenses file type and its use cases</p>
                  {rentalList.length > 0 && (
                    <div>
                      {rentalList.map((rentalData, index) => (
                        <div className='flex flex-col gap-3 py-2'>
                          <div className='flex gap-3'>
                            <div className='fillingDetails__row--link cursor-pointer' onClick={() => handleRental(rentalData?.RentalInfo.rental_id)}>
                              <p className='fillingDetails__row--linktext'>{rentalData?.RentalInfo.rental_property_owner}</p>
                              <button className='fillingDetails__row--editbtn'>
                                <img src="/assets/images/Eye-icon.svg" alt='view/edit' />
                                View/Edit
                              </button>
                            </div>
                            <button type='button' onClick={() => deleteFilling(rentalData?.RentalInfo.rental_id, index, 'rental')} className="buttons__btn delete-btn buttons__btn--delete">Delete</button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <button type='button' className='buttons__btn buttons__btn--outlined' onClick={() => handleRentalAddDetails()}>Add Rental</button>
            </div>}
            {(homeValue == 'Yes') && <div className='fillingDetails__row'>
              <div className='fillingDetails__row--content'>
                <img src="/assets/images/HomeSales-icon.svg" alt='business' className='fillingDetails__row--icon' />
                <div className='fillingDetails__row--titlesection'>
                  <p className='fillingDetails__row--title'>Home Sale Details</p>
                  <p className='fillingDetails__row--desc'>Some content info about the business income and expenses file type and its use cases</p>
                  {homeList.length > 0 && (
                    <div>
                      {homeList.map((homeData, index) => (
                        <div className='flex flex-col gap-3 py-2'>
                          <div className='flex gap-3'>
                            <div className='fillingDetails__row--link cursor-pointer' onClick={() => handleHome(homeData?.home_id)}>
                              <p className='fillingDetails__row--linktext'>Home {index + 1}</p>
                              <button className='fillingDetails__row--editbtn'>
                                <img src="/assets/images/Eye-icon.svg" alt='view/edit' />
                                View/Edit
                              </button>
                            </div>
                            <button type='button' onClick={() => deleteFilling(homeData?.home_id, index, 'home')} className="buttons__btn delete-btn buttons__btn--delete">Delete</button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <button type='button' className='buttons__btn buttons__btn--outlined' onClick={() => handleHomeAddDetails()}>Add Home</button>
            </div>}

            <div className="buttons">
              <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link>
              <button type='button' disabled={!isNextEnable} className={`buttons__btn buttons__btn--next ${(!isNextEnable) ? "disabled" : ""}`} onClick={() => nextPage()}>
                Next
              </button>
            </div>

          </>}
        {business && <BusinessIncomeRight selectedBusinessId={selectedBusinessId} onBusinessBack={handleBack} />}
        {rental && <RentalRight selectedRentalId={selectedRentalId} onRentalBack={handleBack} />}
        {home && <HomeSaleDetails selectedHomeId={selectedHomeId} onHomeBack={handleBack} />}
      </div>
    </>
  )

}