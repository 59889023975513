import React from 'react'
import AuthenticationSuccess from '../components/AuthenticationSuccess'

const AccAuthSuccess = () => {
  return (
    <div className='user__container'>
      <AuthenticationSuccess/>
    </div>
  )
}

export default AccAuthSuccess