import React from 'react'
import OrdersList from '../components/OrdersList'

const OrdersListPage = () => {
  return (
    <div className='order__card'>
        <OrdersList/>
    </div>
  )
}

export default OrdersListPage