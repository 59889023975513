import React, { createContext, useReducer, useContext } from 'react';

const initialState = {
    selectedOption: '2024', 
};

function dropdownReducer(state, action) {
    switch (action.type) {
        case 'SET_OPTION':
            return {
                ...state,
                selectedOption: action.payload,
            };
        default:
            return state;
    }
}

const DropdownContext = createContext();

export const DropdownProvider = ({ children }) => {
    const [state, dispatch] = useReducer(dropdownReducer, initialState);

    return (
        <DropdownContext.Provider value={{ state, dispatch }}>
            {children}
        </DropdownContext.Provider>
    );
};


export const useDropdown = () => useContext(DropdownContext);