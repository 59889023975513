import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import { useNavigate } from 'react-router-dom';

const AuthenticationSuccess = () => {
  const navigate = useNavigate();
  const dashboardRedirect = async () => {
    const user = JSON.parse(sessionStorage.getItem('taxData'));
    console.log('user', user);
    if (user?.account_type == 'agent') {
      navigate('/agent/dashboard');
    } else {
      navigate('/account/dashboard');
    }
  };

  return (
    <div className="accauth__success">
      <img src="/assets/images/success-icon.svg" className='accauth__success--icon' />
      <p className="text-2xl">Authentication Success</p>
      {/* <Button text="Proceed to Portal" onClick={() => dashboardRedirect()} /> */}
      <button type='button' onClick={() => dashboardRedirect()} className='btn btn__primary'>Proceed to Portal</button>
    </div>
  )
}

export default AuthenticationSuccess