export function ssnPinFOrmat(value) {
    if(!value) return value;

    const digits = value.replace(/\D/g, '');
    if (digits.length <= 3) return digits;
    if (digits.length <= 5) return `${digits.slice(0, 3)}-${digits.slice(2)}`;
    return (`${digits.slice(0, 3)}-${digits.slice(3, 5)}-${digits.slice(5, 9)}`).slice(0,11);

}

export function phoneNoFOrmat(value) {
    if (!value) return value;
    const digits = String(value).replace(/\D/g, '');
    if (digits.length <= 3) return digits;
    if (digits.length <= 6) return `${digits.slice(0, 3)}-${digits.slice(3)}`;
    return (`${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`).slice(0, 12);

}

export function getSSNMasked(value){
    if (value.length <= 4) return value;
    let maskedLength = 0;
    if(value.length > 11) value = value.slice(0, 11); maskedLength = value.length - 4;
    if(value.length == 11) maskedLength = value.length - 3;
    return '*'.repeat(maskedLength) + value.slice(maskedLength)
}

export function getPhoneMasked(value){
    if (value.length <= 3) return value;
    let maskedLength = 0;
    if(value.length > 11) value = value.slice(0, 11); maskedLength = value.length - 4;
    if(value.length == 11) maskedLength = value.length - 3;
    return '*'.repeat(maskedLength) + value.slice(maskedLength)
}

export const formatSSNForDisplay = (ssn) => {

        console.log(ssn)
    
    if (ssn && ssn.length === 11) {
        return `***-**-${ssn.slice(-4)}`;
    }
    return ssn;
}

export const CreateNotification = (message, type) => {
    const notification = {
        id: Math.floor(Math.random() * 1000000),
        message: message,
        type: type,
        read: false,
        timestamp: new Date().toISOString()
    }

    let notifications = JSON.parse(localStorage.getItem("notifications")) || [];
    notifications.push(notification);
    localStorage.setItem("notifications", JSON.stringify(notifications));

    const event = new Event('newNotification');
    window.dispatchEvent(event);
};


