import React, { useEffect, useState } from 'react'
import { _get, _post } from '../utills/apiClient';
import { CREATE_HOME, FETCH_HOME } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import Loader from './Loader';
import { CreateNotification } from './commonFunction';

const OrderHome = (props) => {
    const showSnackbar = useSnackbar();
    const [homeValues, setHomeValues] = useState([]);
    const currentYear = new Date().getFullYear();
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const [screenMode, setScreenMode] = useState();
    const [accordion, setAccordion] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleCopyClick = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
            showSnackbar("Text Copied", 'info');
        });
    };

    useEffect(() => {
        console.log(homeValues)
        if (homeValues.length == 0) {
            getHome(props?.orderData?.file_no);
            setScreenMode(props?.screenType);
        }
    }, [props]);


    const getHome = async (file_no) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_HOME}${file_no}`);
            if (response.data?.homeSaleDetails && response.data?.homeSaleDetails.length > 0) {
                let accValues = [];
                response.data?.homeSaleDetails.map((data) => {
                    data.occupied_date = formatDate(data.occupied_date);
                    data.purchased_date = formatDate(data.purchased_date);
                    data.rental_end_date = formatDate(data.rental_end_date);
                    data.rented_date = formatDate(data.rented_date);
                    data.sold_date = formatDate(data.sold_date);
                    accValues.push(false);
                });
                setAccordion([...accValues]);
                setHomeValues(response.data?.homeSaleDetails);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar('Getting Error on getRental', 'error');
            console.error('Error fetching data:', error);
        }
    };
    const handleAccordion = (status, index) => {
        const newItems = accordion.map((item, i) =>
            i === index ? !status : item
        );
        console.log('new items', newItems);

        setAccordion(newItems);
    }
    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    const cancel = () => {
        setScreenMode('View');
    }
    const edit = () => {
        setScreenMode('Edit');
    }

    const handleHomeChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...homeValues];
        newItems[index][name] = value;
        setHomeValues(newItems);
        validateField(index, name, value);
    }

    // Validate a single field
    const validateField = (index, name, value) => {
        let error = '';
        if (name === 'purchased_date' && !value) {
            error = 'Date of Home was Purchased is required';
        }
        if (name === 'occupied_date' && !value) {
            error = 'Date of Home Occupied is required';
        }
        if (name === 'sold_date' && !value) {
            error = 'Date of Home Sold is required';
        }
        if (name === 'purchase_price') {
            if (!value) {
                error = 'Purchase price of home is required';
            } else if (!/^[0-9]+$/i.test(value)) {
                error = 'Must be only digits';
            }
        }
        if (name === 'selling_price') {
            if (!value) {
                error = 'Selling price of home is required';
            } else if (!/^[0-9]+$/i.test(value)) {
                error = 'Must be only digits';
            }
        }
        if (name === 'selling_closing_cost') {
            if (!value) {
                error = 'Selling Closing Cost is required';
            } else if (!/^[0-9]+$/i.test(value)) {
                error = 'Must be only digits';
            }
        }
        const updatedErrors = [...errors];
        if (!updatedErrors[index]) {
            updatedErrors[index] = {};
        }
        updatedErrors[index][name] = error;
        setErrors(updatedErrors);
    };

    // Validate the entire form on submit
    const validateForm = () => {
        const formErrors = homeValues.map((item, index) => {
            const itemErrors = {};
            if (!item.purchased_date) {
                itemErrors.purchased_date = 'Date of Home was Purchased is required';
            }
            if (!item.occupied_date) {
                itemErrors.occupied_date = 'Date of Home Occupied is required';
            }
            if (!item.sold_date) {
                itemErrors.sold_date = 'Date of Home Sold is required';
            }
            if (!item.purchase_price || !(/^[0-9]+$/i.test(item.purchase_price))) {
                itemErrors.purchase_price = 'Purchase price of home is required and Must be only digits';
            }
            if (!item.selling_price || !(/^[0-9]+$/i.test(item.selling_price))) {
                itemErrors.selling_price = 'Selling price of home is required and Must be only digits';
            }
            if (!item.selling_closing_cost || !(/^[0-9]+$/i.test(item.selling_closing_cost))) {
                itemErrors.selling_closing_cost = 'Selling Closing Cost is required and Must be only digits';
            }
            return itemErrors;
        });
        setErrors(formErrors);
        return formErrors.every(itemErrors => Object.keys(itemErrors).length === 0);
    };

    const onSubmit = (e, index) => {
        e.preventDefault();
        if (validateForm()) {

            const payload = {
                file_no: userData?.file_no,
                cus_id: userData?.cus_id,
                homeSaleDetails: [homeValues[index]],
            }
            createOrUpdateHome(payload);
        } else {
            console.log('Validation failed');
        }
    }

    const createOrUpdateHome = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_HOME, payload);
            console.log('createOrUpdateHome', response);
            if (response.status == 200 && response.data?.home_status) {
                CreateNotification('Home Details Updated Successfully', 'success');
                showSnackbar('Home Details Updated Successfully', 'success');
                setScreenMode('View');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateHome", 'error');
            console.error('Error adding data:', error);
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            {homeValues && homeValues.length > 0 && (
                <>
                    {homeValues.map((homeData, index) => (
                        <div className='order__tabscontent accordion'>
                            <div className={accordion[index] ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                                <div className='order__contentcard accordion'>
                                    <div className='order__contentcard--header'>
                                        <div className='flex gap-8'>
                                            <p className='order__contentcard--title'>Home {index + 1}</p>
                                            <div className='order__contentcard--actions'>
                                                {screenMode == 'View' && <div className='order__contentcard--action' onClick={() => edit()}>
                                                    <img src='/assets/images/order-info-edit.svg' alt='edit' />
                                                    <span className='text-sm text-[#6D6E71]'>Edit</span>
                                                </div>}
                                                {screenMode == 'Edit' && <div className='order__contentcard--action' onClick={() => cancel()}>
                                                    <img src='/assets/images/fullview-icon.svg' alt='edit' />
                                                    <span className='text-sm text-[#6D6E71]'>View</span>

                                                </div>}
                                                {/* <div className='order__contentcard--action'>
                                                    <img src='/assets/images/order-copyall.svg' alt='edit' />
                                                    <span className='text-sm text-[#6D6E71]'>Copy All</span>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* {console.log(accordion[index])} */}
                                        {accordion[index] && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' onClick={() => handleAccordion(accordion[index], index)} /></button>}
                                        {!accordion[index] && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' onClick={() => handleAccordion(accordion[index], index)} /></button>}
                                    </div>
                                    {accordion[index] &&
                                        <>
                                            <form onSubmit={(e) => onSubmit(e, index)}>
                                                <div className='order__contentcard--content-inner'>
                                                    <p className='order__contentcard--title px-4'>Home Info</p>
                                                    <div className='order__contentcard--content accordion'>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">

                                                                    <span className='text-sm'>{formatDate(homeData?.purchased_date)}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.purchased_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }

                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='date' placeholder="purchased date" name="purchased_date"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.purchased_date} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.purchased_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.purchased_date && <p className='text-red-600'>{errors[index].purchased_date}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Date of Home was Purchased</span>
                                                        </div>

                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{formatDate(homeData?.occupied_date)}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.occupied_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }

                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='date' placeholder="occupied date" name="occupied_date"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.occupied_date} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.occupied_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.occupied_date && <p className='text-red-600'>{errors[index].occupied_date}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Date of Home Occupied</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{formatDate(homeData?.rented_date)}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.rented_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='date' placeholder="rented date" name="rented_date"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.rented_date} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.rented_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.rented_date && <p className='text-red-600'>{errors[index].rented_date}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light '>Date of Home Rented</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{formatDate(homeData?.rental_end_date)}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.rental_end_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='date' placeholder="rental end date" name="rental_end_date"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.rental_end_date} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.rental_end_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.rental_end_date && <p className='text-red-600'>{errors[index].rental_end_date}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Date of Home Rental Ended</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{formatDate(homeData?.sold_date)}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.sold_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='date' placeholder="sold date" name="sold_date"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.sold_date} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.sold_date)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.sold_date && <p className='text-red-600'>{errors[index].sold_date}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Date of Home Sold</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='order__contentcard--content-inner'>
                                                    <p className='order__contentcard--title px-4'>Basis of Home</p>
                                                    <div className='order__contentcard--content accordion'>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{homeData?.purchase_price}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.purchase_price)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="purchase price" name="purchase_price"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.purchase_price} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.purchase_price)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.purchase_price && <p className='text-red-600'>{errors[index].purchase_price}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Purchase price of home</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{homeData?.closing_cost}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.closing_cost)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="closing cost" name="closing_cost"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.closing_cost} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.closing_cost)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.closing_cost && <p className='text-red-600'>{errors[index].closing_cost}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Purchase Closing Cost (do not include Seller/Developer portion)</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{homeData?.upgrade_cost}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.upgrade_cost)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="upgrade cost" name="upgrade_cost"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.upgrade_cost} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.upgrade_cost)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.upgrade_cost && <p className='text-red-600'>{errors[index].upgrade_cost}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Cost of Capital Improvements or Home Upgrades</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='order__contentcard--content-inner'>
                                                    <p className='order__contentcard--title px-4'>Basis of Sale</p>
                                                    <div className='order__contentcard--content accordion'>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{homeData?.selling_price}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.selling_price)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="selling price" name="selling_price"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.selling_price} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.selling_price)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.selling_price && <p className='text-red-600'>{errors[index].selling_price}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Selling price of home</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{homeData?.selling_closing_cost}</span>
                                                                    <button type="button" onClick={() => handleCopyClick(homeData?.selling_closing_cost)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>

                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="selling closing cost" name="selling_closing_cost"
                                                                        onChange={(e) => {
                                                                            handleHomeChange(e, index);
                                                                        }}
                                                                        value={homeData.selling_closing_cost} />
                                                                    <button type="button" onClick={() => handleCopyClick(homeData.selling_closing_cost)} className='order__contentcard--details-copy' >
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.selling_closing_cost && <p className='text-red-600'>{errors[index].selling_closing_cost}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Selling Closing Cost (Do not include Buyer portions)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {screenMode == 'Edit' &&
                                                    <div className="buttons my-4">
                                                        <button type='button' onClick={() => cancel()} className="buttons__btn buttons__btn--cancel">
                                                            Cancel
                                                        </button>
                                                        {/* <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link> */}
                                                        <button type='submit' className="buttons__btn buttons__btn--save">
                                                            Update
                                                        </button>

                                                    </div>
                                                }
                                            </form>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
    )
}

export default OrderHome