import React, { useState } from 'react';
import Button from '../components/Button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _post } from '../utills/apiClient';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { VALIDATE_OTP, SEND_OTP, VALIDATE_EMAIL } from '../constants/api.endpoint';
import { useSnackbar } from '../components/toaster';
import Loader from '../components/Loader';

const schema = yup.object().shape({
    email: yup.string().email('Please enter valid E-mail ID').required('Email is required'),
});


const ForgotPwd = () => {
    const { id } = useParams();
    const showSnackbar = useSnackbar();
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleInputChange = (e) => {
        const { value } = e.target;
        setEmail(value);
    };

    const onSubmit = (data) => {
        let payload = {
            email: email,
            //   file_no: sessionStorage.getItem("createdFileno"),
            //   cus_id: sessionStorage.getItem("createdFileno")
        }
        validateEmail(payload);
    };

    const validateEmail = async (payload) => {
        try {
            setIsLoading(true);
            const response = await _post(VALIDATE_EMAIL, payload);
            console.log('response', response);
            if (response.status == 200 && response.data?.status && response.data?.cus_id) {
                sessionStorage.setItem("createdFileno", response.data?.cus_id);
                navigate(`/login/otp-verification/F`);
            } else {
                showSnackbar("Invalid Email Id.", 'error');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in validateOtp. Please try again later", 'error');
            console.error('Error adding data:', error);
        }
    };



    return (
        <> {isLoading && <Loader />}
            <div className='user__container'>
                <form className='flex flex-col gap-8 w-full' onSubmit={handleSubmit(onSubmit)}>
                    {/* {id == 'R' && <p className='text-2xl'>OTP Verification</p>}
        {id == 'L' && <p className='text-2xl'>Account Authentication</p>} */}
                    <p className='text-2xl'>Email Verification</p>
                    <div className="user__inputgroup">
                        <div className='relative'>
                            <input type='text' {...register('email')} name="email" onChange={(e) => {
                                register('email').onChange(e);
                                handleInputChange(e);
                            }} value={email} required />
                            <label>Enter Email</label>
                        </div>
                        <p className='text-red-600'>{errors.email?.message}</p>
                    </div>
                    {/* <Button link="/authentication/success" text="Get OTP" /> */}
                    <button type='submit' className='btn btn__primary'>Verify</button>
                    <p className='text-sm text-center'><Link to="/login" className='text-[#00599C] font-bold'>Log in</Link></p>
                    {/* <p className='text-[#6D6E71] text-center'>Don’t Receive OTP?  <span className='text-[#00599C] font-bold' onClick={() => resendOtp()}>Resend</span></p> */}
                </form>
            </div>
        </>
    )
}

export default ForgotPwd