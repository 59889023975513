import React, { useEffect, useState } from 'react'
import SingelOrderHeader from '../components/SingelOrderHeader'
import { useParams } from 'react-router-dom'
import { FETCH_AGENT_DASHBOARD, FETCH_MAILING_ADDRESS, FETCH_TAXPAYER } from '../constants/api.endpoint'
import { _get } from '../utills/apiClient'
import { useSnackbar } from '../components/toaster'
import { useDropdown } from '../reducer/DropdownReducer'
import { phoneNoFOrmat, ssnPinFOrmat } from '../components/commonFunction'
import DatePipe from '../components/DatePipe'
import OrderProfile from '../components/OrderProfile'
import OrderBusiness from '../components/OrderBusiness'
import OrderRental from '../components/OrderRental'
import OrderHome from '../components/OrderHome'
import OrderDocuments from '../components/OrderDocuments'
import Loader from '../components/Loader'
import OrderQuestionnaire from '../components/OrderQuestionnaire'

const SingleOrderView = () => {
  const [profile, setProfile] = useState(true);
  const [business, setBusiness] = useState(false);
  const [rental, setRental] = useState(false);
  const [home, setHome] = useState(false);
  const [questionnaire, setQuestionnaire] = useState(false);
  const [accordion, setAccordion] = useState(false);
  const handleProfile = () => {
    setProfile(true);
    setBusiness(false);
    setRental(false);
    setHome(false);
    setQuestionnaire(false);
  }
  const handleBusiness = () => {
    setProfile(false);
    setBusiness(true);
    setRental(false);
    setHome(false);
    setQuestionnaire(false);
  }
  const handleRental = () => {
    setProfile(false);
    setBusiness(false);
    setRental(true);
    setHome(false);
    setQuestionnaire(false);
  }
  const handleHome = () => {
    setProfile(false);
    setBusiness(false);
    setRental(false);
    setHome(true);
    setQuestionnaire(false);
  }
  const handleQuestionnaire = () => {
    setProfile(false);
    setBusiness(false);
    setRental(false);
    setHome(false);
    setQuestionnaire(true);
  }
  const handleAccordion = () => {
    setAccordion(!accordion)
  }

  const { screenType } = useParams();
  const { id } = useParams();
  const currentYear = new Date().getFullYear();
  const showSnackbar = useSnackbar();
  const { state } = useDropdown();
  const [orderData, setOrderData] = useState()
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (screenType && id) {
        getAgentDashboardData(id);
      }
    })();
  }, [screenType, id]);

  const getAgentDashboardData = async (id) => {
    try {
      setIsLoading(true);
      const response = await _get(`${FETCH_AGENT_DASHBOARD}${currentYear}`);
      if (response.status == 200 && response.data?.fetchAgentDashboard && response.data?.fetchAgentDashboard.length > 0) {
        let res = response.data?.fetchAgentDashboard.filter((data) => data.fiscal_year == state.selectedOption && data.account_type != 'agent' && data.file_no == id);
        console.log('res', res);
        if (res && res.length > 0) {
          if (res[0].file_status == 'Account Created' || res[0].file_status == 'New') {
            res[0].statusClassName = 'order__header--status-new';
          } else if (res[0].file_status == 'Under Review') {
            res[0].statusClassName = 'order__header--status-review';
          } else if (res[0].file_status == 'Upload Pending') {
            res[0].statusClassName = 'order__header--status-pending';
          } else if (res[0].file_status == 'Claim Initiated') {
            res[0].statusClassName = 'order__header--status-initiated';
          } else if (res[0].file_status == 'Claim Refunded') {
            res[0].statusClassName = 'order__header--status-refunded';
          }else if (res[0].file_status == 'Revoke') {
            res[0].statusClassName = 'order__header--status-revoke';
          }

          setOrderData(res[0]);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in getAgentDashboardForSingleOrder", 'error');
      console.error('Error adding data:', error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className='order'>
        <div className='agent__card'>
          <SingelOrderHeader data={orderData} />
          <div className='order__singleview'>
            <div className='order__singleview--left'>
              <div className='order__tabs'>
                <div className='order__tabcontainer' onClick={handleProfile}>
                  <p className={profile ? 'order__tab active' : 'order__tab'}>Profile Info</p>
                </div>
                <div className='order__tabcontainer' onClick={handleQuestionnaire}>
                  <p className={questionnaire ? 'order__tab active' : 'order__tab'}>Questionnaire</p>
                </div>
                {orderData?.business_id && <div className='order__tabcontainer' onClick={handleBusiness}>
                  <p className={business ? 'order__tab active' : 'order__tab'}>Business Income and Expenses</p>
                </div>}
                {orderData?.rental_id && <div className='order__tabcontainer' onClick={handleRental}>
                  <p className={rental ? 'order__tab active' : 'order__tab'}>Rental Income and Expenses</p>
                </div>}
                {orderData?.home_id && <div className='order__tabcontainer' onClick={handleHome}>
                  <p className={home ? 'order__tab active' : 'order__tab'}>Home Sale Details</p>
                </div>}
              </div>
              {profile && <OrderProfile screenType={screenType} id={id} orderData={orderData} />
              }
              {questionnaire && <OrderQuestionnaire screenType={screenType} id={id} orderData={orderData} />
              }
              {business &&
                <OrderBusiness screenType={screenType} id={id} orderData={orderData} />}
              {rental &&
                <OrderRental screenType={screenType} id={id} orderData={orderData} />}
              {home &&
                <OrderHome screenType={screenType} id={id} orderData={orderData} />}
            </div>
            <OrderDocuments orderData={orderData} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleOrderView