import React from 'react'
import ProfileLeft from '../components/ProfileLeft'
import ProfileRight from '../components/ProfileRight'

const Profile = () => {
  return (
    <div className='account__profile'>
        {/* <ProfileLeft/> */}
        <ProfileRight/>
    </div>
  )
}

export default Profile