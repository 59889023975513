import React, { useEffect, useState } from 'react'
import { _get, _post } from '../utills/apiClient';
import { FETCH_QUESTIONNAIRE } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import Loader from './Loader';


const OrderQuestionnaire = (props) => {
    const showSnackbar = useSnackbar();
    let questionnaireInitState = {
        residency_status: "",
        foreign_account_status: "",
        crypto_status: "",
        stock_trading: "",
        business_income: "",
        rental_income: "",
        home_sale_status: "",
        other_details: ""
    }
    const [questionnaireValues, setQuestionnaireValues] = useState(questionnaireInitState);
    const currentYear = new Date().getFullYear();
    const [screenMode, setScreenMode] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Avoid state changes or operations that unnecessarily trigger re-renders
        // unless specifically required
    }, []);

    useEffect(() => {
        getQuestionnaire(props?.orderData?.file_no);
        setScreenMode(props?.screenType);
    }, [props?.orderData?.file_no, props?.screenType]);

    const getQuestionnaire = async (file_no) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_QUESTIONNAIRE}${file_no}`);
            if (response.data?.Questionnaire) {
                questionnaireInitState = { ...response.data?.Questionnaire };
                setQuestionnaireValues(questionnaireInitState);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar('Getting Error on fetchQuestionnaire', 'error');
            console.error('Error fetching data:', error);
        }
    };

    // Function to copy text to clipboard
    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showSnackbar('Text Copied', 'info');
                //console.log('Text copied to clipboard');
            })
            .catch((err) => {
                // console.error('Failed to copy text: ', err);
            });
    };

    return (
        <>
            {isLoading && <Loader />}
            {questionnaireValues && (
                <>
                    <div className={'order__tabscontent ' + (screenMode == 'Edit' ? 'grid-cols-1' : '')}>
                        {/* <div className='order__tabcontent'>
                            <div className='order__contentcard'>
                                <div className='order__contentcard--header'>
                                    <p className='order__contentcard--title'>Questionnaire</p>
                                </div>
                                <div className='order__contentcard--content'>
                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.residency_status}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.residency_status)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light'>What is your residency status?</span>
                                    </div>
                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.foreign_account_status}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.foreign_account_status)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light'>Do you have any Foreign Accounts and had more than USD10K at any point of time?</span>
                                    </div>
                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.crypto_status}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.crypto_status)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light'>Did you trade any Cryptocurrency (Buy/Sell/Convert)?</span>
                                    </div>
                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.stock_trading}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.stock_trading)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light '>Did you do any stock trading in {currentYear}?</span>
                                    </div>

                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.business_income}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.business_income)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light '> Did you do have business income in {currentYear}?</span>
                                    </div>

                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.rental_income}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.rental_income)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light '> Did you do have rental income in {currentYear}?</span>
                                    </div>

                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.home_sale_status}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.home_sale_status)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light '> Did you sell any homes in {currentYear}?</span>
                                    </div>

                                    <div className='order__contentcard--details'>
                                        <div className="order__contentcard--details-input">
                                            <span className='text-sm'>{questionnaireValues?.other_details}</span>
                                            <button type="button" onClick={() => handleCopyClick(questionnaireValues?.other_details)} className='order__contentcard--details-copy' >
                                                <img src='/assets/images/table-copy.svg' alt='copy' />
                                            </button>
                                        </div>
                                        <span className='text-sm italic font-light '> Please add any other details that you want to be made aware such as Solar Panels/Electric Car etc</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='order__contentcard--content-inner'>
                            {/* <p className='order__contentcard--title px-4'>Expenses</p> */}
                            <div className='order__contentcard--content grid-cols-1'>
                                <table className='order__contentcard--table'>
                                    <thead>
                                        <tr>
                                            <th>Questionnaire</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>What is your residency status?</td>
                                            <td>
                                                {/* <input type='text' disabled name="residency_status"
                                                value={questionnaireValues?.residency_status} /> */}
                                                <span>{questionnaireValues?.residency_status}</span>
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.residency_status)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>
                                        <tr>
                                            <td>Do you have any Foreign Accounts and had more than USD10K at any point of time?</td>
                                            <td>
                                                <span>{questionnaireValues?.foreign_account_status}</span>
                                                {/* <input type='text' disabled name="foreign_account_status"
                                                value={questionnaireValues?.foreign_account_status} /> */}
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.foreign_account_status)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>
                                        <tr>
                                            <td>Did you trade any Cryptocurrency (Buy/Sell/Convert)?</td>
                                            <td>
                                                <span>{questionnaireValues?.crypto_status}</span>
                                                {/* <input type='text' disabled name="crypto_status"
                                                value={questionnaireValues?.crypto_status} /> */}
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.crypto_status)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>
                                        <tr>
                                            <td>Did you do any stock trading in {currentYear}?</td>
                                            <td>
                                                <span>{questionnaireValues?.stock_trading}</span>
                                                {/* <input type='text' disabled name="stock_trading"
                                                value={questionnaireValues?.stock_trading} /> */}
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.stock_trading)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>
                                        <tr>
                                            <td>Did you do have business income in {currentYear}?</td>
                                            <td>
                                                <span>{questionnaireValues?.business_income}</span>
                                                {/* <input type='text' disabled name="business_income"
                                                value={questionnaireValues?.business_income} /> */}
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.business_income)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>
                                        <tr>
                                            <td>Did you do have rental income in {currentYear}?</td>
                                            <td>
                                                <span>{questionnaireValues?.rental_income}</span>
                                                {/* <input type='text' disabled name="rental_income"
                                                value={questionnaireValues?.rental_income} /> */}
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.rental_income)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>
                                        <tr>
                                            <td>Did you sell any homes in {currentYear}?</td>
                                            <td>  <span>{questionnaireValues?.home_sale_status}</span>
                                                {/* <input type='text' disabled name="home_sale_status"
                                                value={questionnaireValues?.home_sale_status} /> */}
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.home_sale_status)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>
                                        <tr>
                                            <td>Other Details</td>
                                            <td>  <span>{questionnaireValues?.other_details}</span>
                                                {/* <input type='text' disabled name="other_details"
                                                value={questionnaireValues?.other_details} /> */}
                                                <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(questionnaireValues?.other_details)} alt='copy' className='order__contentcard--table-copy' /></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default OrderQuestionnaire