import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from './toaster';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CREATE_OR_UPDATE_TAXPAYER, FETCH_TAXPAYER, SSN_PIN_VALIDATION, FETCH_DEPENDENT_DAYCARE, DELETE_DEPENDENT, DELETE_DAYCARE } from '../constants/api.endpoint';
import { _get, _post, _delete } from '../utills/apiClient';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateNotification, getPhoneMasked, getSSNMasked, phoneNoFOrmat, ssnPinFOrmat } from './commonFunction';
import Loader from './Loader';
import { create } from '@mui/material/styles/createTransitions';


const taxPayerSchema = yup.object().shape({
    SSN_ITIN_tax: yup.string()
        // .matches(/^\d{10}$/, "SSN must be exactly 10 digits")
        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
        .required('SSN is required'),
    first_name: yup.string().required('First Name is required')
        .matches(/^[A-Za-z\s]+$/, 'First name should only contain alphabets'),
    middle_initial: yup.string(),
    last_name: yup.string().required('Last Name is required')
        .matches(/^[A-Za-z\s]+$/, 'First name should only contain alphabets'),
    date_of_birth: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('DOB is required').max(new Date(), 'Date cannot be in the future')
        .min(new Date('1900-01-01'), 'Date is too far in the past'),
    occupation: yup.string().required('Occupation is required')
        .matches(/^[A-Za-z\s]+$/, 'First name should only contain alphabets'),
    phone_number: yup.string()
        .required('Phone No. is required')
        .matches(/^[0-9-]+$/, "Must be only digits"),
    // .matches(/^\d{3}-\d{3}-\d{4}$/, 'SSN/ITIN format must be XXX-XXX-XXX or contain 9 digits'),
    email: yup.string().required('Email is required').email('Please enter valid E-mail ID'),
    gender: yup.string().required('Gender is required'),
    marital_status: yup.string(),
    dependent_status: yup.string(),
    SSN_ITIN_sp: yup.string().when("marital_status", {
        is: (marital_status) => marital_status && marital_status == 'Yes',
        then: (schema) => schema.required('SSN/ITIN is required')
            // .matches(/^[0-9-]+$/, "Must be only digits"),
            .matches(/^\d{3}-\d{2}-\d{4}$/, 'SSN/ITIN must be in the format ***-**-****'),
        otherwise: (schema) => schema,
    }),
    first_name_sp: yup.string().when("marital_status", {
        is: (marital_status) => marital_status && marital_status == 'Yes',
        then: (schema) => schema.required('First Name is required')
            .matches(/^[A-Za-z\s]+$/, 'First name should only contain alphabets'),
        otherwise: (schema) => schema,

    }),
    // middle_initial_sp: yup.string().when("marital_status", {
    //     is: (marital_status) => marital_status && marital_status == 'Yes',
    //     then: (schema) => schema.required('Middle Initial is required'),
    //     otherwise: (schema) => schema,
    // }),
    last_name_sp: yup.string().when("marital_status", {
        is: (marital_status) => marital_status && marital_status == 'Yes',
        then: (schema) => schema.required('Last Name is required')
            .matches(/^[A-Za-z\s]+$/, 'First name should only contain alphabets'),
        otherwise: (schema) => schema,
    }),
    date_of_birth_sp: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).when("marital_status", {
        is: (marital_status) => marital_status && marital_status == 'Yes',
        then: (schema) => schema.required('DOB is required').max(new Date(), 'Date cannot be in the future')
            .min(new Date('1900-01-01'), 'Date is too far in the past'),
        otherwise: (schema) => schema,
    }),
    phone: yup.string().when("marital_status", {
        is: (marital_status) => marital_status && marital_status == 'Yes',
        then: (schema) => schema.required('Phone No. is required')
            .matches(/^[0-9-]+$/, "Must contain only digits"),
        // .matches(/^\d{3}-\d{3}-\d{3}$/, 'Phone No format must be XXX-XXX-XXX or contain 9 digits'),
        otherwise: (schema) => schema,
    }),
    email_sp: yup.string().when("marital_status", {
        is: (marital_status) => marital_status && marital_status == 'Yes',
        then: (schema) => schema.email('Please enter valid E-mail ID'),
        otherwise: (schema) => schema,
    }),
    employed_status: yup.string().when("marital_status", {
        is: (marital_status) => marital_status && marital_status == 'Yes',
        then: (schema) => schema.required('is Employed or not required'),
        otherwise: (schema) => schema,
    }),
});


const TaxPayerProfile = (props) => {
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();
    const [isFocused, setIsFocused] = useState(false);
    const [isSPFocused, setSPFFocused] = useState(false);
    const [prfSSNValid, setPrfSSNValid] = useState(true);
    const [is_PH_Focused, setIs_PH_Focused] = useState(false);
    const [isSP_PH_Focused, setIsSP_PH_Focused] = useState(false);
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const [SSNError, setSSNError] = useState('');
    const [SpouseSSNError, setSpouseSSNError] = useState('')
    const [SpouseSSNValid, setSpouseSSNValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    let taxPayerinitState = {
        SSN_ITIN: "",
        SSN_ITIN_tax: "",
        first_name: userData?.first_name || '',
        last_name: userData?.last_name || '',
        middle_initial: "",
        date_of_birth: "",
        gender: "",
        occupation: "",
        phone_number: userData?.phone || '',
        email: userData?.email || '',
        marital_status: "No",
        dependent_status: "No",
        userId: "4322"
    };

    let spouseinitState = {
        SSN_ITIN_sp: "",
        first_name_sp: "",
        last_name_sp: "",
        middle_initial_sp: "",
        date_of_birth_sp: "",
        gender_sp: "female",
        relationship: "Spouse",
        phone: "",
        email_sp: "",
        employed_status: "",
    };
    const [taxPayerValues, setTaxPayerValues] = useState(taxPayerinitState);
    const [spouseValue, setSpouseValue] = useState(spouseinitState);
    let [updateFieldsData, setUpdateFieldsData] = useState({});

    useEffect(() => {
        (async () => {
            if (userData.profile_id) {
                try {
                    await getTaxPayer(userData?.profile_id);
                } catch (error) {
                    showSnackbar('Getting Error on fetchUserProfile', 'error');
                    console.error('Error fetching data:', error);
                }
            }
        })();
    }, []);

    const getTaxPayer = async (profile_id) => {
        setIsLoading(true);
        try {
            const response = await _get(`${FETCH_TAXPAYER}${profile_id}`);
            if (response.status == 200 && response.data?.Taxpayer) {
                taxPayerinitState = { ...response.data?.Taxpayer };
                taxPayerinitState.SSN_ITIN_tax = ssnPinFOrmat(response.data?.Taxpayer.SSN_ITIN);
                // taxPayerinitState.SSN_ITIN_tax = response.data?.Taxpayer.SSN_ITIN;
                taxPayerinitState.date_of_birth = formatDate(response.data?.Taxpayer.date_of_birth);
                taxPayerinitState.phone_number = phoneNoFOrmat(response.data?.Taxpayer.phone_number);
                setTaxPayerValues(taxPayerinitState);
            }
            if (response.status == 200 && response.data?.Spouse) {
                spouseinitState = { ...response.data?.Spouse };
                spouseinitState.SSN_ITIN_sp = ssnPinFOrmat(response.data?.Spouse?.SSN_ITIN);
                spouseinitState.first_name_sp = response.data?.Spouse?.first_name;
                spouseinitState.last_name_sp = response.data?.Spouse?.last_name;
                spouseinitState.middle_initial_sp = response.data?.Spouse?.middle_initial;
                spouseinitState.date_of_birth_sp = formatDate(response.data?.Spouse?.date_of_birth);
                spouseinitState.gender_sp = response.data?.Spouse?.gender;
                spouseinitState.email_sp = response.data?.Spouse?.email;
                spouseinitState.phone = phoneNoFOrmat(response.data?.Spouse?.phone);
                setSpouseValue(spouseinitState);
            }

            const taxPayerObj = { ...taxPayerinitState, ...spouseinitState };
            setUpdateFieldsData({
                Taxpayer: taxPayerinitState,
                Spouse: spouseinitState,
            });

            Object.entries(taxPayerObj).map(([key, value]) => {
                setValue(key, value)
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getTaxPayer", 'error');
            console.error('Error adding data:', error);
        }
    };

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
        resolver: yupResolver(taxPayerSchema),
        mode: 'all'
    });

    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    const handleReset = () => {
        setTaxPayerValues(taxPayerinitState);
        setSpouseValue(spouseinitState);
        reset();
    };

    const formatSSNForDisplay = (ssn) => {
        console.log(ssn.length)
        if (ssn && ssn.length === 11) {
            console.log(ssn)
            return `***-**-${ssn.slice(-4)}`;
        }
        return ssn;
    };

    const handleBlur = (value) => {
        setIsFocused(false);
    };

    const handleFocus = (value) => {
        setIsFocused(true);
    };

    const handleSPBlur = (value) => {
        setSPFFocused(false)
    };

    const handleSPFocus = (value) => {
        setSPFFocused(true);
    };

    const getFormattedValue = (value) => {
        if (value) {
            const formatted = value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
            console.log("########")
            console.log(formatted)
            return formatted;
        } else {
            return value;
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'SSN_ITIN_tax') {
            const raw = value.replace(/\D/g, '');
            if (raw.length <= 9) {
                setTaxPayerValues({
                    ...taxPayerValues,
                    [name]: getFormattedValue(raw),
                });
            }
        } else {
            setTaxPayerValues({
                ...taxPayerValues,
                [name]: value,
            });
        }
    };

    const handleSpouseInputChange = (e) => {
        const { name, value } = e.target;
        if (name == 'SSN_ITIN_sp') {
            const raw = value.replace(/\D/g, '');
            if (raw.length <= 9) {
                setSpouseValue({
                    ...spouseValue,
                    [name]: getFormattedValue(raw),
                });
            }
        } else {
            setSpouseValue({
                ...spouseValue,
                [name]: value,
            });
        }
    };

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const nextPage = () => {
        props.onTaxCompleted(false);
    };

    const handleSSNValidation = async (SSN_ITIN_tax, profile_id, type = null) => {
        if (SSN_ITIN_tax) {
            setIsLoading(true);
            try {
                let pinSSN_ITIN = SSN_ITIN_tax.replace(/\D/g, '');
                const response = await _post(SSN_PIN_VALIDATION, { SSN_ITIN: pinSSN_ITIN, profile_id });
                if (!response.data.SSNValidation) {
                    if (type) {
                        setSpouseSSNError('Spouse SSN/ITIN already exists.'); setSpouseSSNValid(false);
                    } else { setSSNError('SSN/ITIN already exists.'); setPrfSSNValid(false); }
                } else {
                    if (type) { setSpouseSSNValid(true); setSpouseSSNError(''); }
                    else { setPrfSSNValid(true); setSSNError(''); }
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                if (type) { setSpouseSSNValid(false); setSpouseSSNError('Error validating SSN/ITIN'); }
                else { setPrfSSNValid(false); setSSNError('Error validating SSN/ITIN'); }
            }
        }
    };







    const onSubmit = (data) => {

        let spouseNewValue = {};

        if (taxPayerValues.marital_status === 'Yes' && spouseValue) {
            const phoneValue = spouseValue.phone ? String(spouseValue.phone).replace(/\D/g, '').slice(0, 10) : '';
            spouseNewValue = { ...spouseValue, phone: phoneValue };
            spouseNewValue.SSN_ITIN_sp = spouseValue.SSN_ITIN_sp.replace(/-/g, '').slice(0, 10);
            for (const key of Object.keys(spouseNewValue)) {
                if (key.includes('_sp')) {
                    spouseNewValue[key.replace('_sp', '')] = spouseNewValue[key];
                    delete spouseNewValue[key];
                }
            }
        }

        // taxPayerValues.SSN_ITIN = taxPayerValues.SSN_ITIN.replace(/-/g, '');
        taxPayerValues.SSN_ITIN_tax = (taxPayerValues.SSN_ITIN_tax.replace(/-/g, '')).slice(0, 10);
        taxPayerValues.phone_number = taxPayerValues.phone_number.replace(/-/g, '').slice(0, 10);
        taxPayerValues.SSN_ITIN = taxPayerValues.SSN_ITIN_tax;
        const payload = {
            file_no: userData?.file_no,
            cus_id: userData?.cus_id,
            Taxpayer: taxPayerValues,
        }
        if (!isEmptyObject(spouseNewValue)) {
            payload.Spouse = spouseNewValue;
        }

        if (!userData.profile_id) {
            console.log('userData.profile_id', payload?.Spouse);
            createOrUpdateTaxPayer(payload, 'Create')
        } else {
            if (!('profile_id' in payload.Taxpayer)) {
                payload.Taxpayer.profile_id = updateFieldsData.Taxpayer.profile_id;
            }
            if (payload?.Spouse && updateFieldsData.Taxpayer.profile_id) {
                payload.Spouse.profile_id = updateFieldsData.Taxpayer.profile_id;
            }
            if (payload?.Spouse && !('dep_id' in payload.Spouse) && updateFieldsData.Spouse.dep_id) {
                payload.Spouse.dep_id = updateFieldsData.Spouse.dep_id;
            }
            createOrUpdateTaxPayer(payload, 'Update');
        }
    };

    const createOrUpdateTaxPayer = async (payload, type) => {
        setIsLoading(true);
        console.log('payload', payload);
        try {
            const response = await _post(CREATE_OR_UPDATE_TAXPAYER, payload);
            if (response.status == 200 && response.data?.profile) {
                if (type == "Create") {
                    const user = JSON.parse(sessionStorage.getItem('taxData'));
                    user.profile_id = response.data?.profile;
                    user.dependent_status = payload?.Taxpayer?.dependent_status;
                    sessionStorage.setItem('taxData', JSON.stringify(user));
                    setUserData(user);
                    CreateNotification("Profile Created Successfully", 'success');
                    showSnackbar('Profile Created Successfully', 'success');
                } else {

                    const user = JSON.parse(sessionStorage.getItem('taxData'));
                    user.dependent_status = payload?.Taxpayer?.dependent_status;
                    sessionStorage.setItem('taxData', JSON.stringify(user));

                    if (user?.dependent_status === "No") {

                        const response = await _get(`${FETCH_DEPENDENT_DAYCARE}${user.profile_id}`);



                        if (response.data?.Dependent && response.data?.Dependent.length > 0) {
                            let filteredData = response.data.Dependent.filter((data) => data.relationship !== 'Spouse');
                            const deleteRequests = filteredData.map(async (dependent) => {
                                try {
                                    const response = await _delete(`${DELETE_DEPENDENT}${dependent.dep_id}`);
                                    console.log(`Deleted dependent with dep_id: ${dependent.dep_id}`, response);
                                } catch (error) {
                                    console.error(`Error deleting dependent with dep_id: ${dependent.dep_id}`, error);
                                }
                            });

                            await Promise.all(deleteRequests);

                        }
                        if (response.data?.DayCare && response.data?.DayCare.length > 0) {
                            let filtDayCaredata = response.data?.DayCare
                            console.log([...filtDayCaredata]);
                            const deleteRequests = filtDayCaredata.map(async (daycare) => {
                                try {
                                    const response = await _delete(`${DELETE_DAYCARE}${daycare.day_care_id}`);
                                    console.log(`Deleted daycare with day_care_id: ${daycare.day_care_id}`, response);
                                } catch (error) {
                                    console.error(`Error deleting daycare with day_care_id: ${daycare.day_care_id}`, error);
                                }
                            });

                            await Promise.all(deleteRequests);

                        }
                    }




                    CreateNotification("Profile Updated Successfully", 'success');
                    showSnackbar('Profile Updated Successfully', 'success');
                }
                //  props.onTaxCompleted(false);
                getTaxPayer(response.data?.profile);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let errorMessage = "Getting error in createOrUpdateTaxPayer";
            if (error.response && error.response.data && error.response.data.message) {
                // If the error has a response message, use it
                errorMessage = error.response.data.message;
            }
            showSnackbar(errorMessage, 'error');
            console.error('Error adding data:', error);
        }
    };

    return (<>
        {isLoading && <Loader />}
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-8'>

                <div className='account__profile--right-formwrapper'>
                    <h1 className="account__profile--right-formtitle">Tax Payer</h1>
                    <div className="account__profile--right-form">
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">SSN/ITIN *</p>

                            {/* <input className="account__profile--right-input" type="text" placeholder='SSN/ITIN' {...register('SSN_ITIN_tax')} name="SSN_ITIN_tax" onChange={(e) => {
                            register('SSN_ITIN_tax').onChange(e);
                            handleInputChange(e);
                        }}
                        value={ssnPinFOrmat(taxPayerValues.SSN_ITIN_tax) } /> */}
                            {/* <span className='account__profile--right-inputspan'>654-454-4512</span> */}
                            {/* onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}
                            value={isFocused ? ssnPinFOrmat(taxPayerValues.SSN_ITIN_tax) : getSSNMasked(taxPayerValues.SSN_ITIN_tax)} /> */}
                            {/* {isFocused && <input className="account__profile--right-input" type="text" placeholder='SSN/ITIN' {...register('SSN_ITIN_tax')} name="SSN_ITIN_tax" onChange={(e) => {
                            register('SSN_ITIN_tax').onChange(e);
                            handleInputChange(e);
                        }}
                            onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}
                            value={ssnPinFOrmat(taxPayerValues.SSN_ITIN_tax)} />}

                        {!isFocused && <span onClick={() => setIsFocused(true)} className='account__profile--right-inputspan'>{getSSNMasked(taxPayerValues.SSN_ITIN_tax) }</span>} */}

                            {/* <input className="account__profile--right-input" type="text" placeholder='SSN/ITIN' {...register('SSN_ITIN_tax')} name="SSN_ITIN_tax" onChange={(e) => {
                            register('SSN_ITIN_tax').onChange({ target: { value: e.target.value } });
                            handleInputChange(e);
                        }}
                            onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}
                            value={isFocused ? ssnPinFOrmat(taxPayerValues.SSN_ITIN_tax) : getSSNMasked(taxPayerValues.SSN_ITIN_tax)} /> */}

                            <input
                                className="account__profile--right-input" type="text"
                                id="SSN_ITIN_tax"
                                {...register('SSN_ITIN_tax')}
                                // value={displaySSN}
                                value={isFocused ? taxPayerValues.SSN_ITIN_tax : formatSSNForDisplay(taxPayerValues.SSN_ITIN_tax)}
                                placeholder="SSN/ITIN"
                                name="SSN_ITIN_tax"
                                onChange={(e) => {
                                    register('SSN_ITIN_tax').onChange(e);
                                    handleInputChange(e);
                                }}
                                onBlur={(e) => {
                                    register('SSN_ITIN_tax').onBlur(e);
                                    handleBlur(taxPayerValues.SSN_ITIN_tax);
                                    handleSSNValidation(e.target.value, userData?.profile_id);
                                }}
                                onFocus={(e) => {
                                    handleFocus(taxPayerValues.SSN_ITIN_tax);
                                }}
                                on
                            />

                            <p className='text-red-600'>{errors.SSN_ITIN_tax?.message}</p>
                            {SSNError && <p className='text-red-600'> {SSNError} </p>}

                        </div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">First Name *</p>
                            <input className="account__profile--right-input" type="text" placeholder="First Name" {...register('first_name')} name="first_name" onChange={(e) => {
                                register('first_name').onChange(e);
                                handleInputChange(e);
                            }} value={taxPayerValues.first_name} />
                            <p className='text-red-600' >{errors.first_name?.message}</p>
                        </div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Middle Initial</p>
                            <input className="account__profile--right-input" type="text" placeholder="Middle Initial" {...register('middle_initial')} name="middle_initial" onChange={(e) => {
                                register('middle_initial').onChange(e);
                                handleInputChange(e);
                            }} value={taxPayerValues.middle_initial} />
                            <p className='text-red-600'>{errors.middle_initial?.message}</p>
                        </div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Last Name *</p>
                            <input className="account__profile--right-input" type="text" placeholder="Last Name"  {...register('last_name')} name="last_name" onChange={(e) => {
                                register('last_name').onChange(e);
                                handleInputChange(e);
                            }} value={taxPayerValues.last_name} /><p className='text-red-600'>{errors.last_name?.message}</p></div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Date Of Birth *</p>
                            <input className="account__profile--right-input text-black calendar__text" type="date" placeholder="DD/MM/YYYY" {...register('date_of_birth')} name="date_of_birth" onChange={(e) => {
                                register('date_of_birth').onChange(e);
                                handleInputChange(e);
                            }} value={taxPayerValues.date_of_birth} /><p className='text-red-600'>{errors.date_of_birth?.message}</p></div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Occupation *</p>
                            <input className="account__profile--right-input" type="text" placeholder="Occupation" {...register('occupation')} name="occupation" onChange={(e) => {
                                register('occupation').onChange(e);
                                handleInputChange(e);
                            }} value={taxPayerValues.occupation} /><p className='text-red-600'>{errors.occupation?.message}</p></div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Phone(Cell) *</p>
                            <input className="account__profile--right-input" type="text" placeholder="Mobile number"{...register('phone_number')} name="phone_number" onChange={(e) => {
                                register('phone_number').onChange({ target: { value: e.target.value } });
                                handleInputChange(e);
                            }}
                                // onFocus={() => setIs_PH_Focused(true)} onBlur={() => setIs_PH_Focused(false)}
                                value={phoneNoFOrmat(taxPayerValues.phone_number)} /><p className='text-red-600'>{errors.phone_number?.message}</p></div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">E-mail ID *</p>
                            <input className="account__profile--right-input" type="text" placeholder="E Mail ID" {...register('email')} name="email" onChange={(e) => {
                                register('email').onChange(e);
                                handleInputChange(e);
                            }} value={taxPayerValues.email} /><p className='text-red-600'>{errors.email?.message}</p></div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Gender *</p>
                            <select className="account__profile--right-input" name='gender' id='gender' placeholder='Gender' {...register('gender')} onChange={(e) => {
                                register('gender').onChange(e);
                                handleInputChange(e);
                            }} value={taxPayerValues.gender}>
                                <option value="">Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                            <p className='text-red-600'>{errors.gender?.message}</p>
                        </div>
                    </div>
                </div>
                <div className='flex gap-16'>
                    <div className="flex gap-4 items-center">
                        <p className="font-medium text-sm text-secondary-500">Married *:</p>
                        <div className="flex gap-8 items-center">
                            <div className="flex gap-4">
                                <input type="radio" name='marital_status' value='Yes' {...register('marital_status')} onChange={(e) => {
                                    register('marital_status').onChange(e);
                                    handleInputChange(e);
                                }} checked={taxPayerValues.marital_status == 'Yes'} />
                                <span className="text-sm">Yes</span>
                            </div>
                            <div className="flex gap-4 items-center">
                                <input type="radio" name='marital_status' value='No' {...register('marital_status')} onChange={(e) => {
                                    register('marital_status').onChange(e);
                                    handleInputChange(e);
                                    setSpouseSSNError('');
                                    setSpouseSSNValid(true);
                                }} checked={taxPayerValues.marital_status == 'No'} />
                                <span className="text-sm">No</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-4 items-center">
                        <p className="font-medium text-sm text-secondary-500">Dependent *:</p>
                        <div className="flex gap-8 items-center">
                            <div className="flex gap-4">
                                <input type="radio" name='dependent_status' value='Yes' {...register('dependent_status')} onChange={(e) => {
                                    register('dependent_status').onChange(e);
                                    handleInputChange(e);
                                }} checked={taxPayerValues.dependent_status == 'Yes'} />
                                <span className="text-sm">Yes</span>
                            </div>
                            <div className="flex gap-4 items-center">
                                <input type="radio" name='dependent_status' value='No' {...register('dependent_status')} onChange={(e) => {
                                    register('dependent_status').onChange(e);
                                    handleInputChange(e);

                                }} checked={taxPayerValues.dependent_status == 'No'} />
                                <span className="text-sm">No</span>
                            </div>
                        </div>
                    </div>
                </div>
                {taxPayerValues.marital_status == 'Yes' &&
                    <div className='account__profile--right-formwrapper'>
                        <h1 className="account__profile--right-formtitle ">Spouse</h1>
                        <div className="account__profile--right-form">
                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">SSN/ITIN *</p>
                                {/* <input className="account__profile--right-input" type="text" placeholder='123-456-7890' {...register('SSN_ITIN_sp')} name="SSN_ITIN_sp" onChange={(e) => {
                                register('SSN_ITIN_sp').onChange(e);
                                handleSpouseInputChange(e);
                            }}
                                value={ssnPinFOrmat(spouseValue.SSN_ITIN_sp)} /> */}
                                {/* onFocus={() => setSPFFocused(true)} onBlur={() => setSPFFocused(false)}
                                value={ isSPFocused ? ssnPinFOrmat(spouseValue.SSN_ITIN_sp) : getSSNMasked(spouseValue.SSN_ITIN_sp)} /> */}

                                <input
                                    className="account__profile--right-input" type="text"
                                    id="SSN_ITIN_tax"
                                    {...register('SSN_ITIN_sp')}
                                    value={isSPFocused ? spouseValue.SSN_ITIN_sp : formatSSNForDisplay(spouseValue.SSN_ITIN_sp)}
                                    placeholder="SSN/ITIN"
                                    name="SSN_ITIN_sp"
                                    onChange={(e) => {
                                        register('SSN_ITIN_sp').onChange(e);
                                        handleSpouseInputChange(e);
                                    }}
                                    onBlur={(e) => {
                                        register('SSN_ITIN_sp').onBlur(e);
                                        handleSPBlur(spouseValue.SSN_ITIN_sp);
                                        handleSSNValidation(e.target.value, userData?.profile_id, 'spouse')
                                    }}
                                    onFocus={(e) => {
                                        handleSPFocus(spouseValue.SSN_ITIN_sp);
                                    }}
                                />
                                <p className='text-red-600'>{errors.SSN_ITIN_sp?.message}</p>
                                {SpouseSSNError && <p className='text-red-600'> {SpouseSSNError} </p>}
                            </div>
                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">First Name *</p>
                                <input className="account__profile--right-input" type="text" placeholder="First Name" {...register('first_name_sp')} name="first_name_sp" onChange={(e) => {
                                    register('first_name_sp').onChange(e);
                                    handleSpouseInputChange(e);
                                }} value={spouseValue.first_name_sp} /><p className='text-red-600'>{errors.first_name_sp?.message}</p></div>
                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">Middle Initial</p>
                                <input className="account__profile--right-input" type="text" placeholder="Middle Initial" {...register('middle_initial_sp')} name="middle_initial_sp" onChange={(e) => {
                                    register('middle_initial_sp').onChange(e);
                                    handleSpouseInputChange(e);
                                }} value={spouseValue.middle_initial_sp} /><p className='text-red-600'>{errors.middle_initial_sp?.message}</p></div>
                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">Last Name *</p>
                                <input className="account__profile--right-input" type="text" placeholder="Last Name" {...register('last_name_sp')} name="last_name_sp" onChange={(e) => {
                                    register('last_name_sp').onChange(e);
                                    handleSpouseInputChange(e);
                                }} value={spouseValue.last_name_sp} /><p className='text-red-600'>{errors.last_name_sp?.message}</p></div>
                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">Date Of Birth *</p>
                                <input className="account__profile--right-input text-black calendar__text" type="date" placeholder="DD/MM/YYYY" {...register('date_of_birth_sp')} name="date_of_birth_sp" onChange={(e) => {
                                    register('date_of_birth_sp').onChange(e);
                                    handleSpouseInputChange(e);
                                }} value={spouseValue.date_of_birth_sp} /><p className='text-red-600'>{errors.date_of_birth_sp?.message}</p></div>

                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">Phone(Cell) *</p>
                                <input className="account__profile--right-input" type="text" placeholder="Mobile number"  {...register('phone')} name="phone" onChange={(e) => {
                                    register('phone').onChange(e);
                                    handleSpouseInputChange(e);
                                }}
                                    value={phoneNoFOrmat(spouseValue.phone)} />
                                {/* onFocus={() => setIsSP_PH_Focused(true)} onBlur={() => setIsSP_PH_Focused(false)}
                            value={isSP_PH_Focused ? phoneNoFOrmat(spouseValue.phone) : getPhoneMasked(spouseValue.phone)} /> */}
                                <p className='text-red-600'>{errors.phone?.message}</p></div>
                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">E-mail ID</p>
                                <input className="account__profile--right-input" type="text" placeholder="E-mail ID" {...register('email_sp')} name="email_sp" onChange={(e) => {
                                    register('email_sp').onChange(e);
                                    handleSpouseInputChange(e);
                                }} value={spouseValue.email_sp} /><p className='text-red-600'>{errors.email_sp?.message}</p></div>
                            <div className="account__profile--right-formfield">
                                <p className="account__profile--right-inputlabel">Employed Or Not *</p>
                                <select className="account__profile--right-input" name='employed_status' id='employed_status' placeholder='Employed Or Not' {...register('employed_status')} onChange={(e) => {
                                    register('employed_status').onChange(e);
                                    handleSpouseInputChange(e);
                                }} value={spouseValue.employed_status}>
                                    <option value="">Employed or Not</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <p className='text-red-600'>{errors.employed_status?.message}</p>
                            </div>
                        </div>
                    </div>}
                <div className="buttons">
                    <Link className="buttons__btn buttons__btn--reset" onClick={() => handleReset()}>Reset</Link>
                    <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link>
                    <button type='submit' disabled={!prfSSNValid || !SpouseSSNValid} className="buttons__btn buttons__btn--save">
                        {userData.profile_id ? 'Update' : 'Save'}
                    </button>
                    <button type='button' disabled={!userData.profile_id || !prfSSNValid || !SpouseSSNValid} className={`buttons__btn buttons__btn--next ${(!userData.profile_id || !prfSSNValid || !SpouseSSNValid) ? "disabled" : ""}`} onClick={() => nextPage()}>

                        Next
                    </button>
                </div>
            </form>
        </div>
    </>
    )
}

export default TaxPayerProfile