import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { _get, _post, _put } from '../utills/apiClient';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from './toaster';
import { CREATE_OR_UPDATE_BANK, FETCH_BANK } from '../constants/api.endpoint';
import Loader from './Loader';
import { CreateNotification } from './commonFunction';

const mailingAddSchema = yup.object().shape({
    bank_name: yup.string(),
    account_holder_name: yup.string(),
    routing_number: yup.string().test("is-digits","Must be only digits",value => !value || /^[0-9]+$/.test(value)),
    account_number: yup.string().test("is-digits","Must be only digits",value => !value || /^[0-9]+$/.test(value)),
    account_type: yup.string(),
});

const BankInfo = () => {
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    let bankInfoinitState = {
        bank_name: "",
        account_holder_name: "",
        routing_number: "",
        account_number: "",
        account_type: ""
    };

    const [bankInfoValue, setBankInfoValue] = useState(bankInfoinitState);
    let [updateFieldsData, setUpdateFieldsData] = useState({});
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData.profile_id) {
                try {
                    await getBankInfo(userData?.profile_id);
                } catch (error) {
                    showSnackbar('Getting Error on getBankInfo', 'error');
                    console.error('Error fetching data:', error);
                }
            }
        })();
    }, []);

    const getBankInfo = async (profile_id) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_BANK}${profile_id}`);
            if (response.status == 200 && response.data?.bankInfo) {
                bankInfoinitState = response.data?.bankInfo;
                setBankInfoValue(response.data?.bankInfo);
            }
            const bankInfoObj = { ...bankInfoinitState };
            setUpdateFieldsData({
                BankInfo: bankInfoObj,
            });
            Object.entries(bankInfoObj).map(([key, value]) => {
                setValue(key, value)
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getBankInfo", 'error');
            console.error('Error adding data:', error);
        }
    };

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
        resolver: yupResolver(mailingAddSchema),
        mode: 'onChange'
    });

    const handleReset = () => {
        setBankInfoValue(bankInfoinitState);
        reset();
    };

    const handleBankInfoInputChange = (e) => {
        const { name, value } = e.target;
        setBankInfoValue({
            ...bankInfoValue,
            [name]: value,
        });
    };

    const nextPage = () => {

        if (userData?.file_status == 'Revoke') navigate(`/account/orders/questionnaire`);
        // if (userData?.file_status == 'New') navigate('/account/dashboard'); 
        else navigate('/account/dashboard');
    }

    const onSubmit = (data) => {
        const payload = {
            profile_id: userData?.profile_id,
            file_no: userData?.file_no,
            cus_id: userData?.cus_id,
            BankInfo: bankInfoValue,
        }

        if (!userData.bank_id) {
            createOrUpdateBankInfo(payload, 'Create')
        } else {
            if (!('bank_id' in payload.BankInfo)) {
                payload.BankInfo.bank_id = updateFieldsData.BankInfo.bank_id;
            }
            createOrUpdateBankInfo(payload, 'Update');
        }
    };

    const createOrUpdateBankInfo = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_OR_UPDATE_BANK, payload);
            console.log('createOrUpdateBankInfo', response);
            if (response.status == 200 && response.data?.bank_id) {
                if (type == "Create") {
                    const user = JSON.parse(sessionStorage.getItem('taxData'));
                    user.bank_id = response.data?.bank_id;
                    sessionStorage.setItem('taxData', JSON.stringify(user));
                    setUserData(user);
                    CreateNotification("Bank Info Created Successfully", 'success');
                    showSnackbar('Bank Info Created Successfully', 'success');
                } else {
                    console.log('Bank Info Updated Successfully', response.data);
                    CreateNotification("Bank Info Updated Successfully", 'success');
                    showSnackbar('Bank Info Updated Successfully', 'success');
                }
                // getBankInfo(userData?.profile_id);
                //    navigate(`/account/orders/questionnaire`);
                navigate(`/account/dashboard`);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateBankInfo", 'error');
            console.error('Error adding data:', error);
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='account__profile--right-formwrapper'>
                    <h1 className="account__profile--right-formtitle">Bank Information</h1>
                    <p className='text-grey'>For Direct Deposit(Refund) and Direct Debit (Balance Due) </p>
                    <div className="account__profile--right-form">
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Bank Name *</p>
                            <input className="account__profile--right-input" type="text" placeholder='Bank Name' name='bank_name'
                                {...register('bank_name')}
                                value={bankInfoValue.bank_name}
                                onChange={(e) => {
                                    register('bank_name').onChange(e);
                                    handleBankInfoInputChange(e);
                                }}
                            />
                            <p className='text-red-600'>{errors.bank_name?.message}</p>
                        </div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Account Holder Name *</p>
                            <input className="account__profile--right-input" type="text" name='account_holder_name'
                                placeholder='Account Holder Name'
                                {...register('account_holder_name')}
                                value={bankInfoValue.account_holder_name}
                                onChange={(e) => {
                                    register('account_holder_name').onChange(e);
                                    handleBankInfoInputChange(e);
                                }}
                            />
                            <p className='text-red-600'>{errors.account_holder_name?.message}</p>
                        </div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Routing Number *</p>
                            <input className="account__profile--right-input" type="text" name='routing_number'
                                placeholder='Routing Number'
                                {...register('routing_number')}
                                value={bankInfoValue.routing_number}
                                onChange={(e) => {
                                    register('routing_number').onChange(e);
                                    handleBankInfoInputChange(e);
                                }}
                            />
                            <p className='text-red-600'>{errors.routing_number?.message}</p>
                        </div>
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Account Number *</p>
                            <input className="account__profile--right-input" type="text" name='account_number'
                                placeholder='Account Number'
                                {...register('account_number')}
                                value={bankInfoValue.account_number}
                                onChange={(e) => {
                                    register('account_number').onChange(e);
                                    handleBankInfoInputChange(e);
                                }}
                            />
                            <p className='text-red-600'>{errors.account_number?.message}</p>
                        </div>
                        {/* <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Account Type *</p>
                            <input className="account__profile--right-input" type="text" name='account_type'
                                placeholder='Savings or Checking'
                                {...register('account_type')}
                                value={bankInfoValue.account_type}
                                onChange={(e) => {
                                    register('account_type').onChange(e);
                                    handleBankInfoInputChange(e);
                                }}
                            />
                            <p className='text-red-600'>{errors.account_type?.message}</p>
                        </div> */}
                        <div className="account__profile--right-formfield">
                            <p className="account__profile--right-inputlabel">Account Type *</p>
                            <select className="account__profile--right-input" name='account_type' id='account_type' placeholder='Savings or Checking' {...register('account_type')} onChange={(e) => {
                                register('account_type').onChange(e);
                                handleBankInfoInputChange(e);
                            }} value={bankInfoValue.account_type}>
                                <option value="">AccountType</option>
                                <option value="Savings">Savings</option>
                                <option value="Checking">Checking</option>
                            </select>
                            <p className='text-red-600'>{errors.account_type?.message}</p>
                        </div>

                    </div>
                </div>
                <div className="buttons mt-4">
                    <Link className="buttons__btn buttons__btn--reset" onClick={() => handleReset()}>Reset</Link>
                    <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link>
                    <button type='submit' className="buttons__btn buttons__btn--save">
                        {userData.bank_id ? 'Update' : 'Save'}
                    </button>
                    <button type='button' className={`buttons__btn buttons__btn--next ${(!userData.bank_id)}`} onClick={() => nextPage()}>
                        Next
                        {console.log (userData.bank_id)}
                    </button>
                    
                </div>
            </form>

        </>
    )
}

export default BankInfo