import React, { useEffect, useState } from 'react'
import { _get, _post } from '../utills/apiClient';
import { CREATE_BUSINESS, FETCH_BUSINESS } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import { ssnPinFOrmat } from './commonFunction';
import Loader from './Loader';
import { CreateNotification } from './commonFunction';

const OrderBusiness = (props) => {
    const showSnackbar = useSnackbar();
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const [businessIncAndExpValues, setBusinessIncAndExpValues] = useState();
    const [screenMode, setScreenMode] = useState();
    const currentYear = new Date().getFullYear();
    const [accordion, setAccordion] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Avoid state changes or operations that unnecessarily trigger re-renders
        // unless specifically required
    }, []);

    useEffect(() => {
        getBusiness(props?.orderData?.file_no);
        setScreenMode(props?.screenType);
    }, [props?.orderData?.file_no, props?.screenType]);

    const getBusiness = async (file_no) => {
        setIsLoading(true);
        try {
            const response = await _get(`${FETCH_BUSINESS}${file_no}`);
            if (response.data?.BusinessIncomeAndExpenses && response.data?.BusinessIncomeAndExpenses.length > 0) {
                let accValues = [];
                response.data?.BusinessIncomeAndExpenses.map((data) => {
                    data.AutoMobileExpenses.purchase_date = formatDate(data.AutoMobileExpenses.purchase_date);
                    data.AutoMobileExpenses.dateplaced_service = formatDate(data.AutoMobileExpenses.dateplaced_service);
                    data.HomeOfficeExpenses.purchase_date = formatDate(data.HomeOfficeExpenses.purchase_date);
                    accValues.push(false);
                });
                setAccordion([...accValues]);
                setBusinessIncAndExpValues(response.data?.BusinessIncomeAndExpenses);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar('Getting Error on getBusiness', 'error');
            console.error('Error fetching data:', error);
        }
    };

    const handleAccordion = (status, index) => {
        const newItems = accordion.map((item, i) =>
            i === index ? !status : item
        );
        setAccordion(newItems);
    }
    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };
    const cancel = () => {
        setScreenMode('View');
    }
    const edit = () => {
        setScreenMode('Edit');
    }


    const handleBusinessInfoChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...businessIncAndExpValues];
        newItems[index]['BusinessInfo'][name] = value;
        setBusinessIncAndExpValues(newItems);
        validateField(index, name, value);
    }

    const handleExpensesChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...businessIncAndExpValues];
        newItems[index]['Expenses'][name] = value;
        setBusinessIncAndExpValues(newItems);
    }

    const handleAutoMobileExpensesChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...businessIncAndExpValues];
        newItems[index]['AutoMobileExpenses'][name] = value;
        setBusinessIncAndExpValues(newItems);
    }

    const handleHomeOfficeExpensesChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = [...businessIncAndExpValues];
        newItems[index]['HomeOfficeExpenses'][name] = value;
        setBusinessIncAndExpValues(newItems);
    }


    // Validate a single field
    const validateField = (index, name, value) => {
        let error = '';
        if (name === 'business_name' && !value) {
            error = 'Business name is required';
        }
        if (name === 'EIN_SSN') {

            if (!value) {
                error = 'EIN/SSN is required';
            }
            // else if (!/^[0-9]+$/i.test(value)) {
            //     error = 'Must be only digits';
            // } 
            else if (!/^\d{3}-\d{2}-\d{4}$/i.test(ssnPinFOrmat(value))) {
                error = 'EIN/SSN must be exactly 9 digits';
            }

        }
        if (name === 'business_address' && !value) {
            error = 'Business Address 1 is required';
        }
        if (name === 'business_address2' && !value) {
            error = 'Business Address 2 is required';
        }
        if (name === 'business_address3' && !value) {
            error = 'Business Address 3 is required';
        }
        if (name === 'gross_receipt_sale') {
            if (!value) {
                error = 'Gross Receipt or Sale is required';
            } else if (!/^[0-9]+$/i.test(value)) {
                error = 'Must be only digits';
            }
        }
        if (name === 'returns_allowances') {
            if (!value) {
                error = 'Returns Allowances is required';
            } else if (!/^[0-9]+$/i.test(value)) {
                error = 'Must be only digits';
            }
        }
        if (name === 'other_income') {
            if (!value) {
                error = 'Other Income is required';
            } else if (!/^[0-9]+$/i.test(value)) {
                error = 'Must be only digits';
            }
        }
        const updatedErrors = [...errors];
        if (!updatedErrors[index]) {
            updatedErrors[index] = {};
        }
        updatedErrors[index][name] = error;
        setErrors(updatedErrors);
    };


    // Validate the entire form on submit
    const validateForm = () => {
        const formErrors = businessIncAndExpValues.map((item, index) => {
            const itemErrors = {};
            if (!item.BusinessInfo.business_name) {
                itemErrors.business_name = 'Name is required';
            }
            if (!item.BusinessInfo.EIN_SSN || !(/^\d{3}-\d{2}-\d{4}$/i.test(ssnPinFOrmat(item.BusinessInfo.EIN_SSN)))) {
                itemErrors.EIN_SSN = 'EIN_SSN is required and Must 10 digits';
            }
            if (!item.BusinessInfo.business_address) {
                itemErrors.business_address = 'Business Address is required';
            }
            if (!item.BusinessInfo.business_address2) {
                itemErrors.business_address2 = 'Business Address 2 is required';
            }
            if (!item.BusinessInfo.business_address3) {
                itemErrors.business_address3 = 'Business Address 3 is required';
            }
            if (!item.BusinessInfo.gross_receipt_sale || !(/^[0-9]+$/i.test(item.BusinessInfo.gross_receipt_sale))) {
                itemErrors.gross_receipt_sale = 'Gross Receipt or Sale is required and Must be only digits';
            }
            if (!item.BusinessInfo.returns_allowances || !(/^[0-9]+$/i.test(item.BusinessInfo.returns_allowances))) {
                itemErrors.returns_allowances = 'Returns Allowances is required and Must be only digits';
            }
            if (!item.BusinessInfo.other_income || !(/^[0-9]+$/i.test(item.BusinessInfo.other_income))) {
                itemErrors.other_income = 'Other Income is required and Must be only digits';
            }
            return itemErrors;
        });
        setErrors(formErrors);
        return formErrors.every(itemErrors => Object.keys(itemErrors).length === 0);
    };

    const onSubmit = (e, index) => {
        e.preventDefault();
        if (validateForm()) {
            businessIncAndExpValues[index].BusinessInfo.EIN_SSN = businessIncAndExpValues[index].BusinessInfo.EIN_SSN.replace(/-/g, '');
            const payload = {
                file_no: userData?.file_no,
                cus_id: userData?.cus_id,
                BusinessIncomeAndExpenses: [businessIncAndExpValues[index]],
            }
            createOrUpdateBusiness(payload);
        } else {
            console.log('Validation failed');
        }
    }

    const createOrUpdateBusiness = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_BUSINESS, payload);
            console.log('createOrUpdateBusiness', response);
            if (response.status == 200 && response.data?.createBusinessData) {
                CreateNotification('Business Updated Successfully', 'success')
                showSnackbar('Business Updated Successfully', 'success');
                setScreenMode('View');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateBusiness", 'error');
            console.error('Error adding data:', error);
        }
    };

    // Function to copy text to clipboard
    const handleCopyClick = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                showSnackbar('Text Copied', 'info');
            })
            .catch((err) => {
                // console.error('Failed to copy text: ', err);
            });
    };

    return (
        <>
            {isLoading && <Loader />}
            <div>


                {businessIncAndExpValues && businessIncAndExpValues.length > 0 && (
                    <>
                        {businessIncAndExpValues.map((businessData, index) => (
                            <div className='order__tabscontent accordion'>
                                <div className={accordion[index] ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                                    <div className='order__contentcard accordion'>
                                        <div className='order__contentcard--header' >
                                            <div className='flex gap-8'>
                                                <p className='order__contentcard--title'>{businessData?.BusinessInfo?.business_name}</p>
                                                <div className='order__contentcard--actions'>
                                                    {screenMode == 'View' && <div className='order__contentcard--action' onClick={() => edit()}>
                                                        <img src='/assets/images/order-info-edit.svg' alt='edit' />
                                                        <span className='text-sm text-[#6D6E71]'>Edit</span>
                                                    </div>}
                                                    {screenMode == 'Edit' && <div className='order__contentcard--action' onClick={() => cancel()}>
                                                        <img src='/assets/images/fullview-icon.svg' alt='edit' />
                                                        <span className='text-sm text-[#6D6E71]'>View</span>

                                                    </div>}
                                                    {/* <div className='order__contentcard--action'>
                                                    <img src='/assets/images/order-copyall.svg' alt='edit' />
                                                    <span className='text-sm text-[#6D6E71]'>Copy All</span>
                                                </div> */}
                                                </div>
                                            </div>
                                            {accordion[index] && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' onClick={() => handleAccordion(accordion[index], index)} /></button>}
                                            {!accordion[index] && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' onClick={() => handleAccordion(accordion[index], index)} /></button>}
                                        </div>
                                        {accordion[index] &&
                                            <>
                                                <form onSubmit={(e) => onSubmit(e, index)}>
                                                    <div className='order__contentcard--content accordion'>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_name}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.business_name)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="Name" name="business_name"
                                                                        onChange={(e) => {
                                                                            handleBusinessInfoChange(e, index);
                                                                        }}
                                                                        value={businessData?.BusinessInfo.business_name} />
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo.business_name)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.business_name && <p className='text-red-600'>{errors[index].business_name}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Business Name</span>
                                                        </div>

                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{ssnPinFOrmat(businessData?.BusinessInfo?.EIN_SSN)}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.EIN_SSN)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="EIN/SSN Number" name="EIN_SSN"
                                                                        onChange={(e) => {
                                                                            handleBusinessInfoChange(e, index);
                                                                        }}
                                                                        value={ssnPinFOrmat(businessData.BusinessInfo.EIN_SSN)} />
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo.EIN_SSN)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.EIN_SSN && <p className='text-red-600'>{errors[index].EIN_SSN}</p>} </>}
                                                            <span className='text-sm italic font-light'>EIN/SSN</span>
                                                        </div>


                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_address}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.business_address)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                            }
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="Name" name="business_address"
                                                                        onChange={(e) => {
                                                                            handleBusinessInfoChange(e, index);
                                                                        }}
                                                                        value={businessData?.BusinessInfo.business_address} />
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo.business_address)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.business_address && <p className='text-red-600'>{errors[index].business_address}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light '>Address 1</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_address2}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.business_address2)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>}
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="City, Town" name="business_address2"
                                                                        onChange={(e) => {
                                                                            handleBusinessInfoChange(e, index);
                                                                        }}
                                                                        value={businessData?.BusinessInfo.business_address2} />
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo.business_address2)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.business_address2 && <p className='text-red-600'>{errors[index].business_address2}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Address 2</span>
                                                        </div>
                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' &&
                                                                <div className="order__contentcard--details-input">
                                                                    <span className='text-sm'>{businessData?.BusinessInfo?.business_address3}</span>
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.business_address3)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>}
                                                            {screenMode == 'Edit' && <>
                                                                <div className="order__contentcard--details-input">
                                                                    <input type='text' placeholder="Door No, Street name" name="business_address3"
                                                                        onChange={(e) => {
                                                                            handleBusinessInfoChange(e, index);
                                                                        }}
                                                                        value={businessData?.BusinessInfo.business_address3} />
                                                                    <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo.business_address3)} className='order__contentcard--details-copy'>
                                                                        <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                    </button>
                                                                </div>
                                                                {errors[index]?.business_address3 && <p className='text-red-600'>{errors[index].business_address3}</p>}
                                                            </>}
                                                            <span className='text-sm italic font-light'>Address 3</span>
                                                        </div>

                                                        <div className='order__contentcard--details'>
                                                            {screenMode == 'View' && <span className='text-sm'>{businessData?.BusinessInfo?.started_current_year}</span>}
                                                            {screenMode == 'Edit' && <>
                                                                <div className='flex gap-3'>
                                                                    <div className='flex gap-3'>
                                                                        <input type='radio' name='started_current_year'
                                                                            onChange={(e) => {

                                                                                handleBusinessInfoChange(e, index);
                                                                            }}
                                                                            value="y" checked={businessData.BusinessInfo.started_current_year == 'y'} /> <label>Yes</label>
                                                                    </div>
                                                                    <div className='flex gap-3'>
                                                                        <input type='radio' name='started_current_year'
                                                                            onChange={(e) => {

                                                                                handleBusinessInfoChange(e, index);
                                                                            }}
                                                                            value="n" checked={businessData.BusinessInfo.started_current_year == 'n'} /> <label>No</label>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            }

                                                            <span className='text-sm italic font-light'>Business Started in {currentYear}</span>
                                                        </div>
                                                    </div>
                                                    <div className='order__contentcard--content-inner'>
                                                        <p className='order__contentcard--title px-4'>Income</p>
                                                        <div className='order__contentcard--content accordion'>
                                                            <div className='order__contentcard--details'>
                                                                {screenMode == 'View' &&
                                                                    <div className="order__contentcard--details-input">
                                                                        <span className='text-sm'>{businessData?.BusinessInfo?.gross_receipt_sale}</span>
                                                                        <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.gross_receipt_sale)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {screenMode == 'Edit' && <>
                                                                    <div className="order__contentcard--details-input">
                                                                        <input type='text' placeholder="Door No, Street name" name="gross_receipt_sale"
                                                                            onChange={(e) => {
                                                                                handleBusinessInfoChange(e, index);
                                                                            }}
                                                                            value={businessData?.BusinessInfo.gross_receipt_sale} />
                                                                        <button type='button' onClick={() => handleCopyClick(businessData.BusinessInfo?.gross_receipt_sale)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>
                                                                    {errors[index]?.gross_receipt_sale && <p className='text-red-600'>{errors[index].gross_receipt_sale}</p>}
                                                                </>}
                                                                {/* <img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.BusinessInfo.gross_receipt_sale)} alt='copy' className='order__contentcard--table-copy' /> */}
                                                                <span className='text-sm italic font-light'>Gross receipts / Sales</span>
                                                            </div>
                                                            <div className='order__contentcard--details'>
                                                                {screenMode == 'View' &&
                                                                    <div className="order__contentcard--details-input">
                                                                        <span className='text-sm'>{businessData?.BusinessInfo?.returns_allowances}</span>
                                                                        <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.returns_allowances)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {screenMode == 'Edit' && <>
                                                                    <div className="order__contentcard--details-input">
                                                                        <input type='text' placeholder="Door No, Street name" name="returns_allowances"
                                                                            onChange={(e) => {
                                                                                handleBusinessInfoChange(e, index);
                                                                            }}
                                                                            value={businessData?.BusinessInfo.returns_allowances} />
                                                                        <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo.returns_allowances)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>
                                                                    {errors[index]?.returns_allowances && <p className='text-red-600'>{errors[index].returns_allowances}</p>}
                                                                </>}
                                                                <span className='text-sm italic font-light'>Returns and allowances</span>
                                                            </div>
                                                            <div className='order__contentcard--details'>
                                                                {screenMode == 'View' &&
                                                                    <div className="order__contentcard--details-input">
                                                                        <span className='text-sm'>{businessData?.BusinessInfo?.other_income}</span>
                                                                        <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo?.other_income)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {screenMode == 'Edit' && <>
                                                                    <div className="order__contentcard--details-input">
                                                                        <input type='text' placeholder="Door No, Street name" name="other_income"
                                                                            onChange={(e) => {
                                                                                handleBusinessInfoChange(e, index);
                                                                            }}
                                                                            value={businessData?.BusinessInfo.other_income} />
                                                                        <button type='button' onClick={() => handleCopyClick(businessData?.BusinessInfo.other_income)} className='order__contentcard--details-copy'>
                                                                            <img src='/assets/images/table-copy.svg' alt='copy' />
                                                                        </button>
                                                                    </div>

                                                                    {errors[index]?.other_income && <p className='text-red-600'>{errors[index].other_income}</p>}
                                                                </>}
                                                                <span className='text-sm italic font-light'>Other Income</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='order__contentcard--content-inner'>
                                                        <p className='order__contentcard--title px-4'>Expenses</p>
                                                        <div className='order__contentcard--content grid-cols-1'>
                                                            <table className='order__contentcard--table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Expenses</th>
                                                                        <th>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Advertising</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="advertising"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.advertising} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.advertising)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Commision and fees</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="commission_fees"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.commission_fees} /><img src='/assets/images/table-copy.svg'
                                                                                onClick={() => handleCopyClick(businessData?.Expenses?.commission_fees)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Contract labour and wages</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="labour_wages"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.labour_wages} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.labour_wages)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Insurance (Other than Health)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="non_health_insurance"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.non_health_insurance} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.non_health_insurance)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Interest (Other than Mortgage)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="mortgage_interest"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.mortgage_interest} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.mortgage_interest)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Legal and professional services</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="legal_professional_fees"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.legal_professional_fees} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.legal_professional_fees)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Office expenses</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="office_expenses"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.office_expenses} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.office_expenses)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Rent or lease (Vehicles/Equipments/OfficeItems)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="rental_lease"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.rental_lease} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.rental_lease)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Repairs and maintenance</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="repairs_maintenance"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.repairs_maintenance} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.repairs_maintenance)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Supplies</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="supplies"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.supplies} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.supplies)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Taxes and licenses</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="taxes_licenses"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.taxes_licenses} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.taxes_licenses)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Travel Expenses (Flight/Hotel/Toll/Parking)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="travel_expense"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.travel_expense} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.travel_expense)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Meals (Business only)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="meals"
                                                                            onChange={(e) => {

                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.meals} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.meals)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Utilities</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="utilities"
                                                                            onChange={(e) => {
                                                                                handleExpensesChange(e, index);
                                                                            }} value={businessData?.Expenses?.utilities} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.Expenses?.utilities)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='order__contentcard--content-inner'>
                                                        <p className='order__contentcard--title px-4'>Automobile Expenses</p>
                                                        <div className='order__contentcard--content grid-cols-1'>
                                                            <table className='order__contentcard--table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Expenses</th>
                                                                        <th>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Purchase Date</td>
                                                                        <td><input type='date' disabled={screenMode == 'View'} name="purchase_date"
                                                                            onChange={(e) => {
                                                                                handleAutoMobileExpensesChange(e, index);
                                                                            }} value={businessData?.AutoMobileExpenses?.purchase_date} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.AutoMobileExpenses?.purchase_date)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Date Placed in Service</td>
                                                                        <td><input type='date' disabled={screenMode == 'View'} name="dateplaced_service"
                                                                            onChange={(e) => {
                                                                                handleAutoMobileExpensesChange(e, index);
                                                                            }} value={businessData?.AutoMobileExpenses?.dateplaced_service} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.AutoMobileExpenses?.dateplaced_service)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Purchase Price (For Depreciation)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="purchase_price"
                                                                            onChange={(e) => {
                                                                                handleAutoMobileExpensesChange(e, index);
                                                                            }} value={businessData?.AutoMobileExpenses?.purchase_price} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.AutoMobileExpenses?.purchase_price)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Business Miles</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="business_meal"
                                                                            onChange={(e) => {
                                                                                handleAutoMobileExpensesChange(e, index);
                                                                            }} value={businessData?.AutoMobileExpenses?.business_meal} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.AutoMobileExpenses?.business_meal)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Commute and Leisure Miles</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="communte_miles"
                                                                            onChange={(e) => {
                                                                                handleAutoMobileExpensesChange(e, index);
                                                                            }} value={businessData?.AutoMobileExpenses?.communte_miles} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.AutoMobileExpenses?.communte_miles)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Percentage of Business Use</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="businessuse_percentage"
                                                                            onChange={(e) => {
                                                                                handleAutoMobileExpensesChange(e, index);
                                                                            }} value={businessData?.AutoMobileExpenses?.businessuse_percentage} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.AutoMobileExpenses?.businessuse_percentage)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='order__contentcard--content-inner'>
                                                        <p className='order__contentcard--title px-4'>Home Office Expenses</p>
                                                        <div className='order__contentcard--content grid-cols-1'>
                                                            <table className='order__contentcard--table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Expenses</th>
                                                                        <th>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Purchase Price (For Depreciation)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="purchase_price"
                                                                            onChange={(e) => {
                                                                                handleHomeOfficeExpensesChange(e, index);
                                                                            }} value={businessData?.HomeOfficeExpenses?.purchase_price} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.HomeOfficeExpenses?.purchase_price)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Land Value (if Known)</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="land_value"
                                                                            onChange={(e) => {
                                                                                handleHomeOfficeExpensesChange(e, index);
                                                                            }} value={businessData?.HomeOfficeExpenses?.land_value} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.HomeOfficeExpenses?.land_value)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Purchase Date</td>
                                                                        <td><input type='date' disabled={screenMode == 'View'} name="purchase_date"
                                                                            onChange={(e) => {
                                                                                handleHomeOfficeExpensesChange(e, index);
                                                                            }} value={businessData?.HomeOfficeExpenses?.purchase_date} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.HomeOfficeExpenses?.purchase_date)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Square Foot of Home Office</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="office_squarefoot"
                                                                            onChange={(e) => {
                                                                                handleHomeOfficeExpensesChange(e, index);
                                                                            }} value={businessData?.HomeOfficeExpenses?.office_squarefoot} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.HomeOfficeExpenses?.office_squarefoot)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Square foot of Whole House</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="home_squarefoot"
                                                                            onChange={(e) => {
                                                                                handleHomeOfficeExpensesChange(e, index);
                                                                            }} value={businessData?.HomeOfficeExpenses?.home_squarefoot} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.HomeOfficeExpenses?.home_squarefoot)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Percentage of Home office</td>
                                                                        <td><input type='text' disabled={screenMode == 'View'} name="home_office_percentage"
                                                                            onChange={(e) => {
                                                                                handleHomeOfficeExpensesChange(e, index);
                                                                            }} value={businessData?.HomeOfficeExpenses?.home_office_percentage} /><img src='/assets/images/table-copy.svg' onClick={() => handleCopyClick(businessData?.HomeOfficeExpenses?.home_office_percentage)} alt='copy' className='order__contentcard--table-copy' /></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {screenMode == 'Edit' &&
                                                        <div className="buttons mb-4">
                                                            <button type='button' onClick={() => cancel()} className="buttons__btn buttons__btn--cancel">
                                                                Cancel
                                                            </button>
                                                            {/* <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link> */}
                                                            <button type='submit' className="buttons__btn buttons__btn--save">
                                                                Update
                                                            </button>

                                                        </div>
                                                    }
                                                </form>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    )
}

export default OrderBusiness