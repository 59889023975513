import React from 'react'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import AccAuth from './pages/AccAuth'
import AccAuthSuccess from './pages/AccAuthSuccess'
import Profile from './pages/Profile'
import UserRegistration from './components/UserRegistration'
import Signin from './pages/Signin'
import OTPVerification from './pages/OTPVerification'
import Account from './pages/Account'
import EditProfile from './components/EditProfile'
import { SnackbarProvider } from './components/toaster'
import Orders from './pages/Orders'
import Questionnaire from './components/Questionnaire'
import SubmitIncome from './pages/Submitpage'
import DocumentChecklist from './pages/DocumentChecklist'
import FillingDetailsAdd from './pages/FillingDetailsAdd'
import BusinessIncome from './pages/BusinessIncome'
import AuthGuard from './utills/auth.quard'
import { AppProvider } from './utills/AppContext'
import AgentDashboard from './pages/AgentDashboard'
import SingleOrderView from './pages/SingleOrderView'
import AgentGuard from './utills/agent.quard'
import { DropdownProvider } from './reducer/DropdownReducer'
import OrdersListPage from './pages/OrdersListPage'
import ForgotPwd from './pages/ForgotPwd'
import ReserPwd from './pages/ReserPwd'
import ScrollTop from './components/ScrollTop'
import UserChat from './pages/UserChat'
import AgentChat from './pages/AgentChat'
import Chat from './pages/Chat'
const App = () => {
  return (
    <BrowserRouter>
    <ScrollTop />
      <SnackbarProvider>
        <AppProvider>
          <DropdownProvider>
            <Routes>
              <Route path='/' element={<Login />}>
                <Route path='/' element={<UserRegistration />} />
                <Route path='authentication' element={<AccAuth />} />
                <Route path='authentication/success' element={<AccAuthSuccess />} />
                <Route path='login' element={<Signin />} />
                <Route path='login/otp-verification/:id' element={<OTPVerification />} />
                <Route path='forgot-password' element={<ForgotPwd />} />
                <Route path='reset-password' element={<ReserPwd />} />
              </Route>
              <Route path='/chats' element={<Chat />} />

              {/* <Route path='/account' element={<Account />}> */}
              <Route path='/account' element={<AuthGuard component={<Account />} />}>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='profile' element={<Profile />} />
                <Route path='profileAdd' element={<EditProfile />} />
                <Route path='chats' element={<UserChat />} />
                <Route path='profileEdit/:id' element={<EditProfile />} />
                {/* <Route path='questionnaire/:id' element={<Questionnaire/>}/>
            <Route path='filling-details/:id/:business/:home' element={<FillingDetailsAdd/>}/>
            <Route path='document-checklist/:id' element={<FillingDetailsAdd/>}/> */}
                <Route path='orders' element={<Orders />}>
                  <Route path='questionnaire' element={<Questionnaire />} />
                  <Route path='filling-details' element={<FillingDetailsAdd />} />
                  <Route path='filling-details/:screenType/:id' element={<FillingDetailsAdd />} />
                  <Route path='document-checklist' element={<DocumentChecklist />} />
                  <Route path='document-checklist/:action' element={<DocumentChecklist />} />
                  <Route path='review-submit' element={<SubmitIncome />} />
                </Route>

                <Route path='uploads' element={<Dashboard />} />
                {/* <Route path='agent' element={<AgentDashboard/>}/> */}
              </Route>


              <Route path='/agent' element={<AgentGuard component={<Account />} />}>
                <Route path='chats' element={<AgentChat />} />
                <Route path='dashboard' element={<AgentDashboard />} />
                <Route path='orders/:screenType/:id' element={<SingleOrderView />} />
                <Route path='orders-list' element={<OrdersListPage />} />
              </Route>
            </Routes>
          </DropdownProvider>
        </AppProvider>
      </SnackbarProvider>
    </BrowserRouter>
  )
}

export default App