import React, { useContext, useEffect, useState } from 'react'
import { Tab, Box } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
// import { Upload } from "@aws-sdk/lib-storage";
// import { S3Client } from "@aws-sdk/client-s3";
import { _get, _post, S3UploadApi } from '../utills/apiClient';
//Data
import { DEDUCTIONSLIST, INCOMELIST, INFORMATIONALLIST } from '../data/data'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CREATE_DOCUMENT, FETCH_DOCUMENTS, GET_SIGNED_DOC_URL } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import { AppContext } from '../utills/AppContext';
import DocumentListTable from './DocumentListTable';
import DownloadZip from './DownloadZip';
import Loader from './Loader';
import { CreateNotification } from './commonFunction';

const DocChecklist = () => {
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const showSnackbar = useSnackbar();
    const [value, setValue] = useState('1');
    const [fileName, setFileName] = useState('');
    let currentpath = window.location.hash.replace("#", "");
    // const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    // const [message, setMessage] = useState("");
    // const [showDefault, setShowDefault] = useState(false);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { setValueFromChild } = useContext(AppContext);
    const navigate = useNavigate();
    const changeValue = (e, newValue) => {
        setValue(newValue);
    };
    const [isDocumentCompleted, setIsDocumentCompleted] = useState(false);

    const [incomeEmpFile, setIncomeEmpFile] = useState('');
    const [incomeSelfEmpFile, setIncomeSelfEmpFile] = useState('');
    const [incomeSelfEmpLLCFile, setIncomeSelfEmpLLCFile] = useState('');
    const [incomeSelfEmpLFile, setIncomeSelfEmpLFile] = useState('');
    const [incomeSelfEmpLCFile, setIncomeSelfEmpLCFile] = useState('');
    const [retirementFile, setRetirementFile] = useState('');
    const [retirementSocialFile, setRetirementSocialFile] = useState('');
    const [gamblingFile, setGamblingFile] = useState('');
    const [careFile, setCareFile] = useState('');
    const [othercareFile, setOtherCareFile] = useState('');
    const [otherhealthcareFile, setOtherHealthCareFile] = useState('');




    const [contributionFile, setContributionFile] = useState('');
    const [traditionalDeduFile, setTraditionalDeduFile] = useState('');
    const [hsaDeduFile, setHsaDeduFile] = useState('');

    const [transferLetterFile, setTransferLetterFile] = useState('');
    const [stockFile, setStockFile] = useState('');
    const [letterFile, setLetterFile] = useState('');
    const [residentFile, setResidentFile] = useState('');
    const [mortFile, setMortFile] = useState('');
    const [tuitionFile, setTuitionFile] = useState('');
    const [loanFile, setLoanFile] = useState('');
    const [homeFile, setHomeFile] = useState('');
    const [planFile, setPlanFile] = useState('');


    const [documentList, setDocumentList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { action } = useParams();

    useEffect(() => {
        getDocuments();
    }, []);

    const handleIncomeEmpFileChange = (event, title) => {
        if (event.target.files.length > 0) {
            setIncomeEmpFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('W2', event.target.files[0].name, title)
            }
        }
    };

    const handleIncomeSelfEmpFileChange = (event, title) => {
        if (event.target.files.length > 0) {
            setIncomeSelfEmpFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-MISC', event.target.files[0].name, title)
            }
        }
    };

    const handleIncomeSelfEmpLLC = (event, title) => {
        if (event.target.files.length > 0) {
            setIncomeSelfEmpLLCFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-NEC', event.target.files[0].name, title)
            }
        }
    };

    const handleIncomeSelfEmpL = (event, title) => {
        if (event.target.files.length > 0) {
            setIncomeSelfEmpLFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('Schedule K1', event.target.files[0].name, title)
            }
        }
    };

    const handleIncomeSelfEmpLC = (event, title) => {
        if (event.target.files.length > 0) {
            setIncomeSelfEmpLCFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099K', event.target.files[0].name, title)
            }
        }
    };


    const handleRetirementChange = (event, title) => {
        if (event.target.files.length > 0) {
            setRetirementFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-R', event.target.files[0].name, title)
            }
        }
    };

    const handleRetirementSocialChange = (event, title) => {
        if (event.target.files.length > 0) {
            setRetirementSocialFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-SSA', event.target.files[0].name, title)
            }
        }
    };

    const handleGamblingChange = (event, title) => {
        if (event.target.files.length > 0) {
            setGamblingFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('W2-G', event.target.files[0].name, title)
            }
        }
    };


    const handleContributionChange = (event, title) => {
        if (event.target.files.length > 0) {
            setContributionFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-SA', event.target.files[0].name, title)
            }
        }
    };

    const handletraditionalDeduChange = (event, title) => {
        if (event.target.files.length > 0) {
            setTraditionalDeduFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('5498-SA', event.target.files[0].name, title)
            }
        }
    };

    const handleHsaDeduChange = (event, title) => {
        if (event.target.files.length > 0) {
            setHsaDeduFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-G', event.target.files[0].name, title)
            }
        }
    };

    const handlecareChange = (event, title) => {
        if (event.target.files.length > 0) {
            setCareFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1095-A', event.target.files[0].name, title)
            }
        }
    };

    const handlehealthcareChange = (event, title) => {
        if (event.target.files.length > 0) {
            setOtherCareFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1095-B', event.target.files[0].name, title)
            }
        }
    };

    const handleothercareChange = (event, title) => {
        if (event.target.files.length > 0) {
            setOtherHealthCareFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1095-C', event.target.files[0].name, title)
            }
        }
    };

    const handleTransferLetterChange = (event, title) => {
        if (event.target.files.length > 0) {
            setTransferLetterFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1098-INT/1099-DIV', event.target.files[0].name, title)
            }
        }
    };

    const handleStockChange = (event, title) => {
        if (event.target.files.length > 0) {
            setStockFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-B', event.target.files[0].name, title)
            }
        }
    };

    const handleLetterChange = (event, title) => {
        if (event.target.files.length > 0) {
            setLetterFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('3922', event.target.files[0].name, title)
            }
        }
    };

    const handleresidentChange = (event, title) => {
        if (event.target.files.length > 0) {
            setResidentFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1042-S', event.target.files[0].name, title)
            }
        }
    };

    const handleMortagaChange = (event, title) => {
        if (event.target.files.length > 0) {
            setMortFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1098', event.target.files[0].name, title)
            }
        }
    };

    const handlePaymentChange = (event, title) => {
        if (event.target.files.length > 0) {
            setTuitionFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1098-T', event.target.files[0].name, title)
            }
        }
    };

    const handleLoanChange = (event, title) => {
        if (event.target.files.length > 0) {
            setLoanFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1098-E', event.target.files[0].name, title)
            }
        }
    };

    const handleHomeChange = (event, title) => {
        if (event.target.files.length > 0) {
            setHomeFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-S', event.target.files[0].name, title)
            }
        }
    };

    const handlePlanChange = (event, title) => {
        if (event.target.files.length > 0) {
            setPlanFile(event.target.files[0].name);
            const target = {
                Bucket: "ommtax",
                Key: userData?.file_no + "_" + event.target.files[0].name,
                Body: event.target.files[0],

            };
            const response = S3UploadApi(target);
            if (response) {
                createDocument('1099-Q', event.target.files[0].name, title)
            }
        }
    };


    const createDocument = async (docType, docName, title) => {
        setSocUploadStatus(false);
        setIsLoading(true);
        try {
            let payload = {
                file_no: userData?.file_no,
                cus_id: userData?.cus_id,
                document_type: docType,
                document_name: docName
            }
            const response = await _post(`${CREATE_DOCUMENT}`, payload);
            if (response.data?.status) {
                CreateNotification(`${title} Document Updated Successfully`,'success');
                showSnackbar(`${title} Document Uploaded Successfully`, 'success');
                // setValueFromChild('documentCompleted');
                setValueFromChild(prevState => ({
                    ...prevState,
                    value: 'documentCompleted'
                }));
                setSocUploadStatus(true);
                getDocuments();
                // navigate(`/account/orders/review-submit`);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar('Getting Error on createDocument', 'error');
            console.error('Error fetching data:', error);
        }
    };

    const getDocuments = async () => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_DOCUMENTS}${userData?.file_no}`);
            if (response.status == 200 && response.data?.getDocumentList && response.data?.getDocumentList.length > 0) {
                setDocumentList(response.data?.getDocumentList);
                setIsDocumentCompleted(true);
                let payload = {
                    key: [],
                    "operation": "getObject"
                }
                response.data?.getDocumentList.map((data) => {
                    payload.key.push(data.file_no + "_" + data.document_name);
                })
                getSignedUrl(payload);
            } else {
                setIsDocumentCompleted(false);
                setDocumentList([]);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };


    const getSignedUrl = async (payload) => {
        try {
            setIsLoading(true);
            const response = await _post(`${GET_SIGNED_DOC_URL}`, payload);
            if (response.status == 200 && response.data?.getDocumentUrlList && response.data?.getDocumentUrlList.length > 0) {
                setSignedUrls(response.data?.getDocumentUrlList);
            } else {
                setSignedUrls([]);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getSignedUrl", 'error');
            console.error('Error adding data:', error);
        }
    };

    const nextPage = () => {
        navigate(`/account/orders/review-submit`);
    }
    let incomeTypeDoc = ["W2", "1099-MISC","1099-NEC","Schedule K1", "1099K", "1099-R", "1099-SSA", "W2-G"];
    let deductionsTypeDoc = ["1099-SA", "5498-SA", "1099-G","1095-A","1095-B","1095-C"];
    let infoTypeDoc = ["1098-INT/1099-DIV","1099-B","3922","1042-S","1098","1098-T","1098-E","1099-S","1099-Q"];
    const [docUploadStatus, setSocUploadStatus] = useState(false);
    const [signedUrls, setSignedUrls] = useState([]);

    const cancelPage = () => {
        navigate('/account/dashboard');
    }

    const incomeDelete = async (docData) => {
        console.log('docId11', docData);
        if (docData && docData?.document_type == 'W2') {
            setIncomeEmpFile('');
        } else if (docData && docData?.document_type == '1099-MISC') {
            setIncomeSelfEmpFile('');
        }else if (docData && docData?.document_type == '1099-NEC') {
            setIncomeSelfEmpLLCFile('');       
        }else if (docData && docData?.document_type == 'Schedule K1') {
            setIncomeSelfEmpLFile('');        
        } else if (docData && docData?.document_type == '1099K') {
            setIncomeSelfEmpLCFile('');
        } else if (docData && docData?.document_type == '1099-R') {
            setRetirementFile('');
        } else if (docData && docData?.document_type == '1099-SSA') {
            setRetirementSocialFile('');
        } else if (docData && docData?.document_type == 'W2-G') {
            setGamblingFile('');
        }
        setValueFromChild(prevState => ({
            ...prevState,
            value: 'documentDeleted'
        }));
        getDocuments();
    }
    const deductionDelete = async (docData) => {
        if (docData && docData?.document_type == '1099-SA') {
            setContributionFile('');
        } else if (docData && docData?.document_type == '5498-SA') {
            setTraditionalDeduFile('');
        } else if (docData && docData?.document_type == '1099-G') {
            setHsaDeduFile('');  
        }else if (docData && docData?.document_type == '1095-A') {
            setCareFile(''); 
        }else if (docData && docData?.document_type == '1095-B') {
            setOtherCareFile('');
        }else if (docData && docData?.document_type == '1095-C') {
            setOtherHealthCareFile('');
        }

        setValueFromChild(prevState => ({
            ...prevState,
            value: 'documentDeleted'
        }));
        getDocuments();
    }
    const infoPurposeDelete = async (docData) => {
        if (docData && docData?.document_type == '1098-INT/1099-DIV') {
            setTransferLetterFile('');
        }else if (docData && docData?.document_type == '1099-B') {
            setStockFile('');
        }else if (docData && docData?.document_type == '3922') {
            setLetterFile('');
        }else if (docData && docData?.document_type == '1042-S') {
            setResidentFile('');
        }else if (docData && docData?.document_type == '1098') {
            setMortFile('');
        }else if (docData && docData?.document_type == '1098-T') {
            setTuitionFile('');
        }else if (docData && docData?.document_type == '1098-E') {
            setLoanFile('');
        }else if (docData && docData?.document_type == '1099-S') {
            setHomeFile('');
        }else if (docData && docData?.document_type == '1099-Q') {
            setPlanFile('');
        }
        setValueFromChild(prevState => ({
            ...prevState,
            value: 'documentDeleted'
        }));
        getDocuments();
    }
    return (
        <>
            {isLoading && <Loader />}
            <div className='document__header'>
                <h1 className="account__profile--right-formtitle">Documents Check List</h1>
                {signedUrls.length > 0 && <DownloadZip signedUrls={signedUrls} page="User" />}
            </div>

            <div className='py-10'>
                <TabContext value={value}>
                    <Box>
                        <TabList onChange={changeValue}>
                            <Tab label='Income' value='1' />
                            <Tab label='Deductions' value='2' />
                            <Tab label='Informational Purpose' value='3' />
                        </TabList>
                    </Box>
                    <TabPanel value='1'>
                        <div className='document__tabcontainer'>
                            <div className='document__boxcontainer' key="Employment(W2)">
                                <div className='document__boxheader'>
                                    <p>Employment (W2)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleIncomeEmpFileChange(e, 'Employment (W2)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {incomeEmpFile && <p>Selected file: {incomeEmpFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, talk to the employer</p>
                                </div>
                            </div>
                            <div className='document__boxcontainer' key="Self Employment (1099-MISC)">
                                <div className='document__boxheader'>
                                    <p>Self Employment (1099-MISC)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleIncomeSelfEmpFileChange(e, 'Self Employment (1099-MISC)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {incomeSelfEmpFile && <p>Selected file: {incomeSelfEmpFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received , report as 'Other Income</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Self Employment (1099-NEC)">
                                <div className='document__boxheader'>
                                    <p>Self Employment (1099-NEC)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleIncomeSelfEmpLLC(e, 'Self Employment (1099-NEC)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {incomeSelfEmpLLCFile && <p>Selected file: {incomeSelfEmpLLCFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received , report as 'Other Income</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Self Employment (Schedule K1)">
                                <div className='document__boxheader'>
                                    <p>Self Employment (Schedule K1)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleIncomeSelfEmpL(e, 'Self Employment (Schedule K1)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {incomeSelfEmpLFile && <p>Selected file: {incomeSelfEmpLFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received , report as 'Other Income</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Self Employment (1099K)">
                                <div className='document__boxheader'>
                                    <p>Self Employment (1099K)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleIncomeSelfEmpLC(e, 'Self Employment (1099K)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {incomeSelfEmpLCFile && <p>Selected file: {incomeSelfEmpLCFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received , report as 'Other Income</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Retirement (401K/IRA)">
                                <div className='document__boxheader'>
                                    <p>Retirement (401K/IRA (1099R))</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleRetirementChange(e, 'Retirement (401K/IRA)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {retirementFile && <p>Selected file: {retirementFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>Upon retirement or early withdrawal or rollover or back-door IRA</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Retirement (Social Security)">
                                <div className='document__boxheader'>
                                    <p>Retirement (Social Security (1099-SSA))</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleRetirementSocialChange(e, 'Retirement (Social Security)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {retirementSocialFile && <p>Selected file: {retirementSocialFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>Upon retirement or death benefits of spouse</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Gambling(W2-G)">
                                <div className='document__boxheader'>
                                    <p>Gambling(W2-G)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInputEmployment"
                                        className="hidden-file-input"
                                        onChange={(e) => handleGamblingChange(e, 'Gambling (W2-G)')}
                                    />
                                    <label htmlFor="fileInputEmployment" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {gamblingFile && <p>Selected file: {gamblingFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>Issued if the winnings are more than $600</p>
                                </div>
                            </div>

                        </div>
                        <DocumentListTable data={incomeTypeDoc} uploadStatus={docUploadStatus} deleteCompleted={incomeDelete} />
                    </TabPanel>
                    <TabPanel value='2'>
                        <div className='document__tabcontainer'>

                            <div className='document__boxcontainer' key='HSA Distribution (1099-SA)'>
                                <div className='document__boxheader'>
                                    <p>HSA Distribution (1099-SA)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleContributionChange(e, 'HSA Distribution (1099-SA)')}
                                    />
                                    <label htmlFor="fileInput" id="fileName" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {contributionFile && <p>Selected file: {contributionFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, would be already taken care in W2 (Code W in Box 12)</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key='HSA/IRA Contribution(5498-SA)'>
                                <div className='document__boxheader'>
                                    <p>HSA/IRA Contribution(5498-SA)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handletraditionalDeduChange(e, 'HSA/IRA Contribution(5498-SA)')}
                                    />
                                    <label htmlFor="fileInput" id="fileName" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {traditionalDeduFile && <p>Selected file: {traditionalDeduFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, bank statements can be used</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key='Unemployment/State Refund (1099G)'>
                                <div className='document__boxheader'>
                                    <p>Unemployment/State Refund (1099G)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleHsaDeduChange(e, 'Unemployment/State Refund (1099G)')}
                                    />
                                    <label htmlFor="fileInput" id="fileName" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {hsaDeduFile && <p>Selected file: {hsaDeduFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, would be already taken care in W2 (Code W in Box 12)</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key='ACA/Obamacare Subsidy(1095A)'>
                                <div className='document__boxheader'>
                                    <p>ACA/Obamacare Subsidy(1095A) </p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handlecareChange(e, 'ACA/Obamacare Subsidy(1095A)')}
                                    />
                                    <label htmlFor="fileInput" id="fileName" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {careFile && <p>Selected file: {careFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, would be already taken care in W2 (Code W in Box 12)</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key='Other Healthcare (1095B)'>
                                <div className='document__boxheader'>
                                    <p>Other Healthcare (1095B) </p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handlehealthcareChange(e, 'Other Healthcare (1095B)')}
                                    />
                                    <label htmlFor="fileInput" id="fileName" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {othercareFile&& <p>Selected file: {othercareFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, would be already taken care in W2 (Code W in Box 12)</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key='Other Healthcare (1095C)'>
                                <div className='document__boxheader'>
                                    <p>Other Healthcare (1095C) </p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleothercareChange(e, 'Other Healthcare (1095C)')}
                                    />
                                    <label htmlFor="fileInput" id="fileName" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {otherhealthcareFile && <p>Selected file: {otherhealthcareFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, would be already taken care in W2 (Code W in Box 12)</p>
                                </div>
                            </div>

                            {/* {
                                DEDUCTIONSLIST.map((list) => {
                                    return (
                                        <div className='document__boxcontainer' key={list.title}>
                                            <div className='document__boxheader'>
                                                <p>{list.title}</p>
                                                <img src="/assets/images/success-icon.svg" alt='success' />
                                            </div>
                                            <div className='document__upload'>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    className="hidden-file-input"
                                                    onChange={handleFileChange}
                                                />
                                                <label htmlFor="fileInput" id="fileName" className="custom-file-upload">
                                                    Upload File
                                                </label>
                                                {fileName && <p>Selected file: {fileName}</p>}
                                                <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                            </div>
                                            <div className='document__boxfooter'>
                                                <img src="/assets/images/info-icon.svg" alt='info' />
                                                <p>{list.info}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            } */}
                        </div>
                        <DocumentListTable data={deductionsTypeDoc} uploadStatus={docUploadStatus} deleteCompleted={deductionDelete} />
                    </TabPanel>
                    <TabPanel value='3'>
                        <div className='document__tabcontainer'>
                            <div className='document__boxcontainer' key="Financial Institution Interest/Dividend (1098-INT/1099-DIV)">
                                <div className='document__boxheader'>
                                    <p>Financial Institution Interest/Dividend (1098-INT/1099-DIV)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleTransferLetterChange(e, 'Financial Institution Interest/Dividend (1098-INT/1099-DIV)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {transferLetterFile && <p>Selected file: {transferLetterFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>
                        

                        
                            <div className='document__boxcontainer' key="Stock Sale/Tradings (1099-B)">
                                <div className='document__boxheader'>
                                    <p>Stock Sale/Tradings (1099-B)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleStockChange(e, 'Stock Sale/Tradings (1099-B)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {stockFile && <p>Selected file: {stockFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>
                        
                            <div className='document__boxcontainer' key="ESPP Letter (From 3922)">
                                <div className='document__boxheader'>
                                    <p>ESPP Letter (From 3922)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleLetterChange(e, 'ESPP Letter (From 3922)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {letterFile && <p>Selected file: {letterFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>


                            <div className='document__boxcontainer' key="Non-residents(1042-S)">
                                <div className='document__boxheader'>
                                    <p>Non-residents(1042-S)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) =>handleresidentChange (e, 'Non-residents(1042-S)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {residentFile && <p>Selected file: {residentFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Mortgage Interest (1098)">
                                <div className='document__boxheader'>
                                    <p>Mortgage Interest (1098)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleMortagaChange(e, 'Mortgage Interest (1098)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {mortFile && <p>Selected file: {mortFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Tuition Payments (1098-T)">
                                <div className='document__boxheader'>
                                    <p>Tuition Payments (1098-T)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handlePaymentChange(e, 'Tuition Payments (1098-T)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {tuitionFile && <p>Selected file: {tuitionFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Student Loan Intrest (1098-E)">
                                <div className='document__boxheader'>
                                    <p>Student Loan Intrest (1098-E)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) =>  handleLoanChange(e, 'Student Loan Intrest (1098-E)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {loanFile && <p>Selected file: {loanFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="Home Sale (1099-S)">
                                <div className='document__boxheader'>
                                    <p>Home Sale (1099-S)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handleHomeChange(e, 'Home Sale (1099-S)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {homeFile && <p>Selected file: {homeFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>

                            <div className='document__boxcontainer' key="529 Plan Withdrawal (1099-Q)">
                                <div className='document__boxheader'>
                                    <p>529 Plan Withdrawal (1099-Q)</p>
                                    <img src="/assets/images/success-icon.svg" alt='success' />
                                </div>
                                <div className='document__upload'>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden-file-input"
                                        onChange={(e) => handlePlanChange (e, '529 Plan Withdrawal (1099-Q)')}
                                    />
                                    <label htmlFor="fileInput" className="custom-file-upload">
                                        Upload File
                                    </label>
                                    {planFile && <p>Selected file: {planFile}</p>}
                                    <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                </div>
                                <div className='document__boxfooter'>
                                    <img src="/assets/images/info-icon.svg" alt='info' />
                                    <p>If not received, you can get it from your brokerage but not necessarily required</p>
                                </div>
                            </div>

                        </div>
                        


                            {/* {
                                INFORMATIONALLIST.map((list) => {
                                    return (
                                        <div className='document__boxcontainer' key={list.title}>
                                            <div className='document__boxheader'>
                                                <p>{list.title}</p>
                                                <img src="/assets/images/success-icon.svg" alt='success' />
                                            </div>
                                            <div className='document__upload'>
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    className="hidden-file-input"
                                                    onChange={handleFileChange}
                                                />
                                                <label htmlFor="fileInput" className="custom-file-upload">
                                                    Upload File
                                                </label>
                                                {fileName && <p>Selected file: {fileName}</p>}
                                                <img src="/assets/images/cloud-arrow-up.svg" alt='upload' />
                                            </div>
                                            <div className='document__boxfooter'>
                                                <img src="/assets/images/info-icon.svg" alt='info' />
                                                <p>{list.info}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            } */}
                        
                        <DocumentListTable data={infoTypeDoc} uploadStatus={docUploadStatus} deleteCompleted={infoPurposeDelete} />
                    </TabPanel>
                </TabContext>
            </div>
            <div className='flex justify-center'>
                <div className="buttons">
                    <button type='button' className="buttons__btn buttons__btn--cancel" onClick={() => cancelPage()}>
                        Cancel
                    </button>
                    {!action && <button type='button' className={`buttons__btn buttons__btn--next ${(!isDocumentCompleted) ? "disabled" : ""}`} disabled={!isDocumentCompleted} onClick={() => nextPage()}>
                        Next
                    </button>}
                </div>
            </div>
        </>
    )
}

export default DocChecklist