import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkToken();
  }, [component]);

  const checkToken = async () => {
    try {
      let user = JSON.parse(sessionStorage.getItem('taxData'));
      if (!user?.cus_id || user?.account_type == 'agent') {
        sessionStorage.clear();
        navigate(`/login`);
      }
      setStatus(true);
      return;
    } catch (error) {
      sessionStorage.clear();
      navigate(`/login`);
    }
  }

  return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default AuthGuard;