import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { _delete, _get, _post, _put } from '../utills/apiClient';
import { useSnackbar } from './toaster';
import { CREATE_OR_UPDATE_DEPENDENT_DAYCARE, DELETE_DAYCARE, DELETE_DEPENDENT, FETCH_DEPENDENT_DAYCARE, DEPENDENT_SSN_PIN_VALIDATION, DAYCARE_SSN_PIN_VALIDATION } from '../constants/api.endpoint';
import { phoneNoFOrmat, ssnPinFOrmat, CreateNotification } from './commonFunction';
import Loader from './Loader';
import { useNavigate } from "react-router-dom";

const DependentDaycare = (props) => {

    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    let [dependentFields, setDependentFields] = useState([
        { first_name: '', middle_initial: '', last_name: '', SSN_ITIN: '', SSN_Valid: true, gender: '', relationship: '', date_of_birth: '' }
    ]);
    const [DepenSSNError] = useState('SSN already exit');
    const [DepenSSNValidate, setDepenSSNValidate] = useState(true);
    const [DaycareEINError] = useState('EIN already exit');
    const [DaycareEINValidate, setDaycareEINValidate] = useState(true);
    const [isFocused, setIsFocused] = useState(true);
    const [dayCareFields, setDayCareFields] = useState([
        { daycare_provider: '', EIN_SSN: '', EIN_Valid: false, address: '', phone_number: '', amount_paid: '' }
    ]);
    const [isSFocused, setIsSFocused] = useState(true);
    let [updateFieldsData, setUpdateFieldsData] = useState({});
    const showSnackbar = useSnackbar();
    let [isEmployed, setIsEmployed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (userData.profile_id) {
                try {
                    await getDependentDayCare(userData?.profile_id);
                } catch (error) {
                    showSnackbar('Getting Error on fetchUserProfile', 'error');
                    console.error('Error fetching data:', error);
                }
            }
        })();
    }, []);

    const getDependentDayCare = async (profile_id) => {
        setIsLoading(true);
        try {
            const response = await _get(`${FETCH_DEPENDENT_DAYCARE}${profile_id}`);
            if (response.data?.Dependent && response.data?.Dependent.length > 0) {
                let spouseData = response.data?.Dependent.find((data) => data.relationship == 'Spouse');
                if (spouseData && spouseData?.employed_status) {
                    setIsEmployed(spouseData?.employed_status == 'yes');
                }
                let filteredData = response.data?.Dependent.filter((data) => data.relationship != 'Spouse');
                if (filteredData && filteredData.length > 0) {
                    filteredData.map((data) => {
                        data.date_of_birth = formatDate(data.date_of_birth);
                        data.dependentAge = calculate_age(data.date_of_birth);
                        data.SSN_Valid = true;
                    });
                    setDependentFields([...filteredData]);
                }

            }
            if (response.data?.DayCare && response.data?.DayCare.length > 0) {
                let filtDayCaredata = response.data?.DayCare
                filtDayCaredata.map((data) => {
                    data.EIN_Valid = true;
                });
                setDayCareFields([...filtDayCaredata])
            }
            setUpdateFieldsData({
                DayCare: response.data?.DayCare,
                Dependent: response.data?.Dependent,
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getDependentDayCare", 'error');
            console.error('Error adding data:', error);
        }
    };

    const handleReset = () => {
        setDependentFields([{ first_name: '', middle_initial: '', last_name: '', SSN_ITIN: '', SSN_Valid: true, gender: '', relationship: '', date_of_birth: '' }]);
        setDayCareFields([{ daycare_provider: '', EIN_SSN: '', EIN_Valid: true, address: '', phone_number: '', amount_paid: '' }])
    };

    const handleSSNchange = async (index) => {
        const SSN_ITIN = dependentFields[index].SSN_ITIN;
        let newDpet = [...dependentFields]
        if (SSN_ITIN) {
            try {
                setIsLoading(true);
                let new_ssn_pin = SSN_ITIN.replace(/\D/g, '')
                const response = await _post(DEPENDENT_SSN_PIN_VALIDATION, { 'SSN_ITIN': new_ssn_pin, dep_id: dependentFields[index].dep_id });
                const { SSNValidation } = response.data;

                if (SSNValidation) {
                    newDpet[index].SSN_Valid = true;
                } else {
                    newDpet[index].SSN_Valid = false;
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                newDpet[index].SSN_Valid = false;
            }
        } else {
            newDpet[index].SSN_Valid = false;
        }
        setDependentFields(newDpet)
        console.log(newDpet)
    };


    useEffect(() => {
        let depFIlt = dependentFields.some(item => item.SSN_Valid === false)
        // console.log('dep ', depFIlt);

        setDepenSSNValidate(!depFIlt)
    }, [dependentFields])



    const handleEINchange = async (index) => {
        const EIN_SSN = dayCareFields[index].EIN_SSN;
        let newDayCare = [...dayCareFields]
        if (EIN_SSN) {
            try {
                let new_ssn_pin = EIN_SSN.replace(/\D/g, '')
                let payload = {
                    'SSN_ITIN': new_ssn_pin,
                    day_care_id: dayCareFields[index]?.day_care_id ? dayCareFields[index]?.day_care_id : null
                }
                const response = await _post(DAYCARE_SSN_PIN_VALIDATION, payload);
                const { SSNValidation } = response.data;

                if (SSNValidation) {
                    newDayCare[index].EIN_Valid = true;
                } else {
                    newDayCare[index].EIN_Valid = false;
                }
            } catch (error) {
                newDayCare[index].EIN_Valid = false;
            }
        } else {
            newDayCare[index].EIN_Valid = false;
        }
        setDayCareFields(newDayCare)
        console.log(newDayCare)
    };


    useEffect(() => {
        let depFIlt = dayCareFields.some(item => item.EIN_Valid === false)
        // console.log('day ', depFIlt);
        setDaycareEINValidate(!depFIlt)
    }, [dayCareFields])



    // const handleFormChange = (index, event) => {
    //     const { value } = event.target;
    //     if (event.target.name === 'SSN_ITIN') {

    //         const digitsOnly = value.replace(/\D/g, '');

    //         const tenDigitRegex = /^\d{0,10}$/;

    //         if (tenDigitRegex.test(digitsOnly)) {

    //             const formattedValue = ssnPinFOrmat(digitsOnly);


    //             event.target.value = formattedValue;
    //             depSetValue(index, event);
    //         }

    //         else {
    //             showSnackbar("SSN/ITIN must be exactly 9 digits", 'error');
    //         }
    //     } else {
    //         depSetValue(index, event);
    //     }
    // };

    const handleFormChange = (index, event) => {
        const { name, value } = event.target;

        if (name === 'SSN_ITIN') {
            const digitsOnly = value.replace(/\D/g, '');
            const tenDigitRegex = /^\d{0,10}$/;

            if (tenDigitRegex.test(digitsOnly)) {
                const formattedValue = ssnPinFOrmat(digitsOnly);
                event.target.value = formattedValue;
                depSetValue(index, event);
            } else {
                showSnackbar("SSN/ITIN must be exactly 9 digits", 'error');
            }
            return;
        }


        const alphaRegex = /^[A-Za-z]*$/;

        if (
            name === 'first_name' ||
            name === 'middle_initial' ||
            name === 'last_name'
        ) {

            if (!alphaRegex.test(value)) {
                showSnackbar("Only alphabets are allowed", 'error');
                return;
            }
        }


        depSetValue(index, event);
    };


    const depSetValue = (index, event) => {
        let data = [...dependentFields];
        data[index][event.target.name] = event.target.value;
        setDependentFields(data);
    };

    const handleDateChange = (index, event) => {
        let dependentData = [...dependentFields];
        dependentData[index][event.target.name] = event.target.value;
        dependentData[index].dependentAge = calculate_age(event.target.value);
        setDependentFields(dependentData);
    }
    const getMaskValue = (value) => {
        if (value.length <= 4) return value;
        const maskedLength = value.length - 4;
        return '*'.repeat(maskedLength) + value.slice(maskedLength)
    };

    // const handleDayCareChange = (index, event) => {
    //     const { value, name } = event.target;


    //     if (name === 'EIN_SSN' || name === 'amount_paid') {
    //         const digitsOnly = value.replace(/\D/g, '');
    //         if (digitsOnly.length <= 10) {
    //             let formattedValue = digitsOnly;
    //             if (name === 'EIN_SSN') {
    //                 formattedValue = ssnPinFOrmat(digitsOnly);
    //             }
    //             event.target.value = formattedValue;
    //             dayCareSetValue(index, event);
    //         }
    //         else {
    //             showSnackbar("EIN_SSN must be only 10 digits", 'error')

    //         }
    //     } else if (name == 'phone_number') {
    //         const digitsOnly = value.replace(/\D/g, '');
    //         if (digitsOnly.length <= 10) {
    //             event.target.value = phoneNoFOrmat(digitsOnly);
    //             dayCareSetValue(index, event);
    //         } else {
    //             showSnackbar("Phone number must be only 10 digits", 'error')
    //         }
    //     } else {
    //         dayCareSetValue(index, event);
    //     }
    // };

    const handleDayCareChange = (index, event) => {
        const { value, name } = event.target;


        if (name === 'EIN_SSN' || name === 'amount_paid') {
            const digitsOnly = value.replace(/\D/g, '');
            if (digitsOnly.length <= 10) {
                let formattedValue = digitsOnly;
                if (name === 'EIN_SSN') {
                    formattedValue = ssnPinFOrmat(digitsOnly);
                }
                event.target.value = formattedValue;
                dayCareSetValue(index, event);
            } else {
                showSnackbar("EIN_SSN must be only 10 digits", 'error');
            }
        }

        else if (name === 'phone_number') {
            const digitsOnly = value.replace(/\D/g, '');
            if (digitsOnly.length <= 10) {
                event.target.value = phoneNoFOrmat(digitsOnly);
                dayCareSetValue(index, event);
            } else {
                showSnackbar("Phone number must be only 10 digits", 'error');
            }
        }

        else if (name === 'daycare_provider') {
            const alphaRegex = /^[A-Za-z\s]*$/;
            if (alphaRegex.test(value)) {
                dayCareSetValue(index, event);
            } else {
                showSnackbar("Only alphabets are allowed", 'error');
                return;
            }
        }

        else {
            dayCareSetValue(index, event);
        }
    };




    const dayCareSetValue = (index, event) => {
        let data = [...dayCareFields];
        data[index][event.target.name] = String(event.target.value);
        setDayCareFields(data);
    }

    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    const nextPage = () => {
      //  props.onDependentDaycareCompleted(false);
        if (userData?.file_status == 'Revoke') {
            navigate('/account/orders/questionnaire');
        } else {
            navigate('/account/dashboard');
        }
    }

    const addFields = () => {
        let newDependentfield = { first_name: '', middle_initial: '', last_name: '', SSN_ITIN: '', SSN_Valid: true, gender: '', relationship: '', date_of_birth: '' };
        setDependentFields([...dependentFields, newDependentfield]);
        // let newDayCare = { daycare_provider: '', EIN_SSN: '', address: '', phone_number: '', amount_paid: '' };
        // setDayCareFields([...dayCareFields, newDayCare]);
    }

    const addDayCareFields = () => {
        let newDayCare = { daycare_provider: '', EIN_SSN: '', EIN_Valid: true, address: '', phone_number: '', amount_paid: '' };
        setDayCareFields([...dayCareFields, newDayCare]);
    }

    const removeFields = (index, dep_id) => {
        if (dep_id) {
            deleteDependent(dep_id, index);
        } else {
            let data = [...dependentFields];
            data.splice(index, 1)
            setDependentFields(data);
        }
    }

    useEffect(() => {
        dependentFields.length == 0 && addFields()
        dayCareFields.length == 0 && addDayCareFields()
        console.log(dependentFields.some(person => calculate_age(person.date_of_birth) < 12));
        console.log(isEmployed)
        console.log((!dependentFields.some(person => calculate_age(person.date_of_birth) < 12) && isEmployed));

        if (!(dependentFields.some(person => calculate_age(person.date_of_birth) < 12)) && isEmployed) {
            setDayCareFields([{ daycare_provider: '', EIN_SSN: '', EIN_Valid: true, address: '', phone_number: '', amount_paid: '' }])
        }
    }, [dependentFields, dayCareFields])
    const removeDayCareFields = (index, day_care_id) => {
        if (day_care_id) {
            deleteDaycare(day_care_id, index);
        } else {
            let dayCareData = [...dayCareFields];
            dayCareData.splice(index, 1)
            setDayCareFields(dayCareData);
        }
    }



    const deleteDependent = async (dep_id, index) => {
        setIsLoading(true);
        try {
            const response = await _delete(`${DELETE_DEPENDENT}${dep_id}`);
            if (response?.data?.Dependent) {
                showSnackbar("Dependent Deleted Successfully", 'success');
                let data = [...dependentFields];
                data.splice(index, 1)
                setDependentFields(data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };

    const deleteDaycare = async (day_care_id, index) => {
        setIsLoading(true);
        try {
            const response = await _delete(`${DELETE_DAYCARE}${day_care_id}`);
            if (response?.data?.DayCare) {
                showSnackbar("Daycare Deleted Successfully", 'success');
                let dayCareData = [...dayCareFields];
                dayCareData.splice(index, 1)
                setDayCareFields(dayCareData);
            }
            setIsLoading(false);

        }
        catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };
    const calculate_age = (dob1) => {
        var today = new Date();
        var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
        var age_now = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
        }
        return age_now;
    }

    // const handleSubmit = (e) => {

    //     // dependentFields.map((data, index) => {
    //     //     if (calculate_age(data.date_of_birth) > 13) {
    //     //         dayCareFields.splice(index, 1);
    //     //     }
    //     // });
    //     const payload = {
    //         profile_id: userData?.profile_id,
    //         file_no: userData?.file_no,
    //         cus_id: userData?.cus_id,
    //         // Dependent: dependentFields,
    //         // DayCare: dayCareFields,
    //     }
    //     const fieldsToCheck = ['first_name', 'middle_initial', 'last_name', 'SSN_ITIN', 'gender', 'relationship', 'date_of_birth'];
    //     const hasNonNullInSelectedFields = dependentFields.some(person =>
    //         Object.keys(person)
    //             .filter(key => fieldsToCheck.includes(key))
    //             .every(key => person[key] !== null && person[key] !== undefined && person[key] != '')
    //     );
    //     if (hasNonNullInSelectedFields) {
    //         if (dependentFields.length > 0) {
    //             dependentFields.map((row, index) => {
    //                 const SSN_ITIN_NO = dependentFields[index].SSN_ITIN;
    //                 if (typeof SSN_ITIN_NO === 'string') dependentFields[index].SSN_ITIN = SSN_ITIN_NO.replace(/-/g, '');
    //                 else dependentFields[index].SSN_ITIN = String(SSN_ITIN_NO).replace(/-/g, '');
    //             })
    //         }
    //         console.log('dependentFields', dependentFields);
    //         payload.Dependent = dependentFields;
    //         // const payload = {
    //         //     profile_id: userData?.profile_id,
    //         //     file_no: userData?.file_no,
    //         //     cus_id: userData?.cus_id,
    //         //     Dependent: dependentFields,
    //         //     // DayCare: dayCareFields,
    //         // }
    //         if ((dependentFields.some(person => calculate_age(person.date_of_birth) < 12) && isEmployed)) {
    //             const hasAtLeastOneValue = dayCareFields.some(person =>
    //                 Object.values(person).some(value => value !== null && value !== undefined && value !== '')
    //             );
    //             console.log('hasAtLeastOneValue', hasAtLeastOneValue);
    //             if (hasAtLeastOneValue) {
    //                 const fieldsToCheckDayCare = ['daycare_provider', 'EIN_SSN', 'address', 'phone_number', 'amount_paid'];
    //                 const hasNonNullInDayCare = dayCareFields.some(person =>
    //                     Object.keys(person)
    //                         .filter(key => fieldsToCheckDayCare.includes(key))
    //                         .every(key => person[key] !== null && person[key] !== undefined && person[key] !== '')
    //                 );
    //                 console.log('hasNonNullInDayCare', hasNonNullInDayCare);
    //                 if (hasNonNullInDayCare) {
    //                     if (dayCareFields.length > 0) {
    //                         dayCareFields.map((row, index) => {
    //                             const EIN_SSN_NO = dayCareFields[index].EIN_SSN;
    //                             if (typeof EIN_SSN_NO === 'string') dayCareFields[index].EIN_SSN = EIN_SSN_NO.replace(/-/g, '');
    //                             else dayCareFields[index].EIN_SSN = String(EIN_SSN_NO).replace(/-/g, '');

    //                             const phoneNumber = dayCareFields[index].phone_number;
    //                             if (typeof phoneNumber === 'string') dayCareFields[index].phone_number = phoneNumber.replace(/-/g, '');
    //                             else dayCareFields[index].phone_number = String(phoneNumber).replace(/-/g, '');
    //                         })
    //                     }
    //                     payload.DayCare = dayCareFields;
    //                     console.log('payload', payload);
    //                 } else {
    //                     showSnackbar("Please fill the DayCare details", 'warning');
    //                     return false;
    //                 }
    //             }
    //         }
    //         console.log('updateFieldsData?.Dependent', updateFieldsData?.Dependent);
    //         if (!(updateFieldsData?.Dependent && updateFieldsData?.Dependent?.length > 0 && updateFieldsData?.Dependent[0]?.dep_id && updateFieldsData?.Dependent[0]?.relationship != 'Spouse')) {
    //             createOrUpdateDependentDaycare(payload, 'Create');
    //         } else {
    //             console.log('payload', payload);
    //                 // if (updateFieldsData?.Dependent && updateFieldsData?.Dependent?.length > 0 && updateFieldsData?.Dependent[0]?.dep_id) {
    //                 if (payload.Dependent && payload.Dependent.length > 0) {
    //                     payload.Dependent.map((data, index) => {
    //                     //     console.log('11111',!('dep_id' in data));
    //                     //     if (!('dep_id' in data)) {
    //                     //         if (updateFieldsData.Dependent[index] && updateFieldsData.Dependent[index].dep_id) {
    //                     //         data.dep_id = updateFieldsData.Dependent[0].dep_id;
    //                     //     }
    //                     // }
    //                     })

    //                 }
    //             // }
    //             if ((updateFieldsData?.DayCare && updateFieldsData?.DayCare?.length > 0 && updateFieldsData?.DayCare[0]?.day_care_id)) {
    //                 if (payload.DayCare && payload.DayCare.length > 0) {
    //                     payload.DayCare.map((data, index) => {
    //                         if (!('day_care_id' in data)) {

    //                             data.dep_id = updateFieldsData.DayCare[0].day_care_id;
    //                         }
    //                     })
    //                 }
    //             }
    //             // console.log('payload11',payload);
    //               createOrUpdateDependentDaycare(payload, 'Update');
    //         }
    //     } else {
    //         showSnackbar("Please fill the Dependent details", 'warning');
    //     }


    // };

    const handleSubmit = (e) => {

        const fieldsToCheck = ['first_name', 'last_name', 'SSN_ITIN', 'gender', 'relationship', 'date_of_birth'];
        const isAnyFieldEmpty = dependentFields.some(person =>
            fieldsToCheck.some(field => {
                const value = person[field];
                return value === null || value === undefined || (typeof value === 'string' && value.trim() === '');
            })
        );

        if (isAnyFieldEmpty) {
            showSnackbar("Please fill all Dependent details", 'warning');
            return false;
        }


        const isSSNInvalid = dependentFields.some(person => {
            const SSN_ITIN_NO = person.SSN_ITIN ? person.SSN_ITIN.replace(/-/g, '') : '';
            return SSN_ITIN_NO.length !== 10;
        });

        if (isSSNInvalid) {
            showSnackbar("SSN/ITIN must be exactly 9 digits", 'warning');
            return false;
        }

        // if ((dependentFields.some(person => calculate_age(person.date_of_birth) < 12) && isEmployed)){
        // if (dayCareFields.length > 0) {

        const fieldsToCheckDayCare = ['daycare_provider', 'EIN_SSN', 'address', 'phone_number', 'amount_paid'];
        const isDayCareCompletelyEmpty = dayCareFields.every(person =>
            fieldsToCheckDayCare.every(field => {
                const value = person[field];
                return value === null || value === undefined || value.trim() === '';
            })
        );


        if (isDayCareCompletelyEmpty) {
            const payload = {
                profile_id: userData?.profile_id,
                file_no: userData?.file_no,
                cus_id: userData?.cus_id,
                Dependent: dependentFields.map(person => ({
                    ...person,
                    SSN_ITIN: person.SSN_ITIN ? person.SSN_ITIN.replace(/-/g, '') : '', // Remove hyphens before saving
                })),
            };
            createOrUpdateDependentDaycare(payload, 'Create');
            return;
        }


        const isAnyDayCareFieldFilled = dayCareFields.some(person =>
            fieldsToCheckDayCare.some(field => {
                const value = person[field];
                return value !== null && value !== undefined && value.trim() !== '';
            })
        );

        if (isAnyDayCareFieldFilled) {
            const isDayCareFieldEmpty = dayCareFields.some(person =>
                fieldsToCheckDayCare.some(field => {
                    const value = person[field];
                    return value === null || value === undefined || (typeof value === 'string' && value.trim() === '');
                })
            );

            if (isDayCareFieldEmpty) {
                showSnackbar("Please fill all DayCare details", 'warning');
                return false;
            }


            const isEINInvalid = dayCareFields.some(person => {
                const EIN_SSN_NO = person.EIN_SSN ? person.EIN_SSN.replace(/-/g, '') : '';
                return EIN_SSN_NO.length !== 10;
            });

            if (isEINInvalid) {
                showSnackbar("EIN must be exactly 10 digits", 'warning');
                return false;
            }


            const isPhoneNumberInvalid = dayCareFields.some(person => {
                const phoneNumber = person.phone_number ? person.phone_number.replace(/-/g, '') : '';
                return phoneNumber.length !== 10;
            });

            if (isPhoneNumberInvalid) {
                showSnackbar("Phone number must be exactly 10 digits", 'warning');
                return false;
            }
        }


        const payload = {
            profile_id: userData?.profile_id,
            file_no: userData?.file_no,
            cus_id: userData?.cus_id,
            Dependent: dependentFields.map(person => ({
                ...person,
                SSN_ITIN: person.SSN_ITIN ? person.SSN_ITIN.replace(/-/g, '') : '',
            })),
            DayCare: dayCareFields.map(person => ({
                ...person,
                EIN_SSN: person.EIN_SSN ? person.EIN_SSN.replace(/-/g, '') : '',
                phone_number: person.phone_number ? person.phone_number.replace(/-/g, '') : '',
            })),
        };

        createOrUpdateDependentDaycare(payload, 'Create');
    };


    const createOrUpdateDependentDaycare = async (payload, type) => {
        setIsLoading(true);
        console.log('createOrUpdateMailingAddress', payload);
        try {
            const response = await _post(CREATE_OR_UPDATE_DEPENDENT_DAYCARE, payload);
            console.log('createOrUpdateMailingAddress', response);
            if (response.status == 200 && response.data?.Dependent) {
                if (type == "Create") {

                    CreateNotification("Dependent and Daycare Created Successfully", 'success');
                    showSnackbar('Dependent and Daycare Created Successfully', 'success');


                } else {
                    console.log('Dependent and Daycare Updated Successfully', response.data);
                    CreateNotification("Dependent and Daycare Updated Successfully", 'success');
                    showSnackbar('Dependent and Daycare Updated Successfully', 'success');
                }
                getDependentDayCare(userData?.profile_id);
                //props.onDependentDaycareCompleted(false);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateMailingAddress", 'error');
            console.error('Error adding data:', error);
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <form>
                <div className='account__profile--right-formwrapper'>
                    <h1 className="account__profile--right-formtitle">Dependent</h1>
                    <div className='grid gap-5'>
                        {dependentFields.map((dependentField, index) => {
                            return (
                                <table className="dependent__table ">
                                    <tbody>
                                        <tr key={index}>
                                            <td className=" dependent__tabledata text-center">{index + 1}</td>
                                            <td className="dependent__tabledata"><input className='dependent__tableinput' type='text' placeholder='First Name'
                                                name='first_name'
                                                value={dependentField.first_name}
                                                onChange={event =>
                                                    handleFormChange(index, event)}
                                            /></td>
                                            <td className="dependent__tabledata"><input className='dependent__tableinput' type='text' placeholder='Middle Name'
                                                name='middle_initial'
                                                value={dependentField.middle_initial}
                                                onChange={event =>
                                                    handleFormChange(index, event)}
                                            /></td>
                                            <td className="dependent__tabledata"><input className='dependent__tableinput' type='text' placeholder='Last Name'
                                                name='last_name'
                                                value={dependentField.last_name}
                                                onChange={event =>
                                                    handleFormChange(index, event)}
                                            /></td>
                                            <td className="dependent__tabledata"><input className='dependent__tableinput' type='text' placeholder='SSN/ITIN'
                                                name='SSN_ITIN'
                                                value={ssnPinFOrmat(dependentField.SSN_ITIN)}
                                                // value={isFocused ? ssnPinFOrmat(dependentField.SSN_ITIN) : getMaskedValue(dependentField.SSN_ITIN)}
                                                onChange={event => handleFormChange(index, event)}
                                                // onFocus={() => setIsFocused (true)}
                                                onBlur={() => handleSSNchange(index)}


                                            />

                                            </td>
                                            <td className="dependent__tabledata">

                                                <div className=" relative">
                                                    <select className={`dependent__tableselection text-black`}
                                                        name='gender'
                                                        value={dependentField.gender}
                                                        onChange={event => handleFormChange(index, event)}>
                                                        <option value="">Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                    </select>
                                                    {/* <div className=" absolute pl-20 top-[4px]">
                                                        <img src="/assets/images/Dropdown-icon.svg" alt='DropdownIcon' />
                                                    </div> */}
                                                </div>
                                            </td>

                                            <td className="dependent__tabledata">
                                                <div className=" relative">
                                                    <select className="dependent__tableselection text-black"
                                                        name='relationship'
                                                        value={dependentField.relationship}
                                                        onChange={event => handleFormChange(index, event)}>
                                                        <option value="">Relationship</option>
                                                        <option value="son">Son</option>
                                                        <option value="daughter">Daughter</option>
                                                        <option value="Parents">Parent</option>
                                                        <option value="siblings">Sibling</option>
                                                        <option value="other">Others</option>

                                                    </select>
                                                    {/* <div className="z-0 absolute pl-20 top-[4px]">
                                                        <img src="/assets/images/Dropdown-icon.svg" alt='DropdownIcon' />
                                                    // </div> */}
                                                </div>

                                            </td>
                                            <td className="dependent__tabledata p-2">

                                                {/* <div className='flex items-center w-20 gap-1'> */}
                                                <input className='dependent__tableinput' name="date_of_birth" type='date' placeholder='DOB'
                                                    value={dependentField.date_of_birth}
                                                    onChange={event => handleDateChange(index, event)}></input>
                                                {/* <img src="/assets/images/CalendarBusiness-icon.svg" alt="calendar" className=" " /> */}
                                                {/* </div> */}


                                            </td>
                                            {/* {(index != 0) || (index == 0 && dependentFields.length > 1) ? */}
                                            <td className="dependent__tabledata text-red-600 text-lg font-bold">
                                                <button type='button' onClick={() => removeFields(index, dependentField?.dep_id)}> x </button>
                                            </td>
                                            {/* : null} */}

                                        </tr>
                                        {dependentField.SSN_ITIN && !dependentField.SSN_Valid &&
                                            <tr><td colSpan={8}>
                                                <p className='text-red-600'>{DepenSSNError}</p>
                                            </td></tr>
                                        }
                                    </tbody>
                                </table>
                            )
                        })}
                    </div>
                    <div className='flex justify-end'>
                        <button type='button' className="dependent__addbtn" onClick={addFields}>Add</button>
                    </div>
                    {(dependentFields.some(person => calculate_age(person.date_of_birth) < 12) && isEmployed) && <>
                        {/* {( isEmployed) && <> */}
                        <div className='account__profile--right-inputlabel'>
                            <h1 className="account__profile--right-formtitle ">Day Care Information</h1>
                            <p className='text-grey'>Day Care Information for Dependents (Child) aged between 1-12.Both parents MUST be working. </p>
                        </div>
                        {dayCareFields.map((dayCareField, index) => {
                            return (
                                <table className="dependent__table ">

                                    <tbody>
                                        <tr>
                                            <td className=" dependent__tabledata p-2 text-center">{index + 1}</td>
                                            <td className="dependent__tabledata  ">
                                                <input className='dependent__tableinput' type='text' placeholder='Day Care Provider'
                                                    name='daycare_provider'
                                                    value={dayCareField.daycare_provider}
                                                    onChange={event => handleDayCareChange(index, event)}></input>
                                            </td>
                                            <td className="dependent__tabledata ">
                                                <input className='dependent__tableinput' type='text' placeholder='EIN/SSN'
                                                    name='EIN_SSN'
                                                    value={isSFocused ? ssnPinFOrmat(dayCareField.EIN_SSN) : getMaskValue(dayCareField.EIN_SSN)}
                                                    onChange={event => handleDayCareChange(index, event)}
                                                    // onFocus={() => setIsSFocused(true)}
                                                    onBlur={() => handleEINchange(index)}
                                                >

                                                </input>
                                            </td>
                                            <td className="dependent__tabledata  ">
                                                <input className='dependent__tableinput w-64' type='text' placeholder='Address'
                                                    name='address'
                                                    value={dayCareField.address}
                                                    onChange={event => handleDayCareChange(index, event)}></input>
                                            </td>
                                            <td className="dependent__tabledata ">
                                                <input className='dependent__tableinput' type='text' placeholder='Phone Number'
                                                    name='phone_number'
                                                    value={phoneNoFOrmat(dayCareField.phone_number)}
                                                    onChange={event => handleDayCareChange(index, event)}></input>
                                            </td>
                                            <td className="dependent__tabledata ">
                                                <input className='dependent__tableinput' type='text' placeholder='Amount Paid'
                                                    name='amount_paid'
                                                    value={dayCareField.amount_paid}
                                                    onChange={event => handleDayCareChange(index, event)}></input>
                                            </td>
                                            {/* {(index != 0) || (index == 0 && dayCareFields.length > 1) ? */}
                                            <td className="dependent__tabledata text-red-600 text-lg font-bold">
                                                <button type='button' onClick={() => removeDayCareFields(index, dayCareField.day_care_id)}> x </button>
                                            </td>
                                            {/* : null } */}
                                        </tr>
                                        {/* {dayCareField.EIN_SSN && !dayCareField.EIN_Valid && 
                                        <tr><td colSpan={8}>
                                            <p className='text-red-600'>{DaycareEINError}</p>
                                        </td></tr>
                                        } */}

                                    </tbody>

                                </table>
                            )
                        })}
                        <div className='flex justify-end'>
                            <button type='button' className="dependent__addbtn" onClick={() => addDayCareFields()}>Add</button>
                        </div>
                    </>}
                </div>
                <div className="buttons p-7">
                    <Link className="buttons__btn buttons__btn--reset" onClick={() => handleReset()}>Reset</Link>
                    <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link>

                    <button type='button' disabled={!DepenSSNValidate} className="buttons__btn buttons__btn--save" onClick={(e) => handleSubmit(e)}>
                        {updateFieldsData?.Dependent?.dep_id ? 'Update' : 'Save'}
                    </button>
                    <button type='button' disabled={!userData.profile_id || !DepenSSNValidate} className={`buttons__btn buttons__btn--next ${(!userData.profile_id) ? "disabled" : ""}`} onClick={() => nextPage()}>
                        Next
                    </button>
                </div>
            </form>
        </>
    )

}

export default DependentDaycare