export const FETCH_DASHBOARD = '/api/v1/auth/fetchUserDashboard?cus_id=';
export const VALIDATE_OTP = '/api/v1/auth/otpValidate';
export const SEND_OTP = '/api/v1/auth/sendOTP';
export const FETCH_QUESTIONNAIRE = '/api/v1/profile/fetchQuestionnaire?file_no=';
export const CREATE_QUESTIONNAIRE = '/api/v1/profile/createQuestionnaire';
export const FETCH_BUSINESS = '/api/v1/business/fetchBusiness?file_no=';
export const CREATE_BUSINESS = '/api/v1/business/createBusiness';

export const FETCH_TAXPAYER = '/api/v1/profile/fetchProfiles?profile_id=';
export const CREATE_OR_UPDATE_TAXPAYER = '/api/v1/profile/createProfile';
export const SSN_PIN_VALIDATION = '/api/v1/profile/ssnValidationProfile'; 
export const DEPENDENT_SSN_PIN_VALIDATION = '/api/v1/profile/ssnValidationDependent';
export const DAYCARE_SSN_PIN_VALIDATION = '/api/v1/profile/ssnDayCareValidation';
export const FETCH_MAILING_ADDRESS = '/api/v1/profile/fetchAddress?profile_id=';
export const CREATE_OR_UPDATE_MAILING_ADDRESS = '/api/v1/profile/createAddress';

export const FETCH_DEPENDENT_DAYCARE = '/api/v1/profile/fetchDependent?profile_id=';
export const CREATE_OR_UPDATE_DEPENDENT_DAYCARE = '/api/v1/profile/createDependent';
export const DELETE_DEPENDENT = '/api/v1/profile/deleteDependent?dep_id=';
export const DELETE_DAYCARE= '/api/v1/profile/deleteDaycare?day_care_id=';

export const FETCH_BANK = '/api/v1/profile/fetchBank?profile_id=';
export const CREATE_OR_UPDATE_BANK = '/api/v1/profile/createBank';

export const CREATE_ACCOUNT = '/api/v1/auth/createAccount';

export const FETCH_RENTAL = '/api/v1/rental/fetchRental?file_no=';
export const CREATE_RENTAL = '/api/v1/rental/createRental';

export const FETCH_HOME = '/api/v1/homesale/fetchDetails?file_no=';
export const CREATE_HOME = '/api/v1/homesale/createDetails';

export const SIGNIN = '/api/v1/auth/login';

export const CREATE_DOCUMENT = '/api/v1/returns/createDocument';
export const FETCH_DOCUMENTS = '/api/v1/returns/documentList?file_no=';
export const DELETE_DOCUMENTS = '/api/v1/returns/deleteDocument?document_id=';

export const UPDATE_FINAL_STATUS = '/api/v1/returns/updateFileStatus';

export const FETCH_AGENT_DASHBOARD = '/api/v1/auth/fetchAgentDashboard?fiscal_year=';

export const DELETE_BUSINESS = '/api/v1/business/deleteBusiness';
export const DELETE_RENTAL = '/api/v1/rental/deleteRental';
export const DELETE_HOME = '/api/v1/homesale/deleteDetails';

export const VALIDATE_EMAIL = '/api/v1/auth/emailValidate';
export const RESET_PWD = '/api/v1/auth/forgotPassword';
export const GET_SIGNED_DOC_URL = '/api/v1/returns/documentUrl';
export const GET_CHAT_HISTORY_BY_CHAT_ROOMID = '/api/v1/profile/chathistory';
export const CHAT_HISTORY_APPLY = '/api/v1/profile/chathistoryApply';
export const CUS_MASTER_LIST_BY_TYPE = '/api/v1/profile/customer/user';
