import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSnackbar } from './toaster';
import { FETCH_AGENT_DASHBOARD, FETCH_DOCUMENTS } from '../constants/api.endpoint';
import { useDropdown } from '../reducer/DropdownReducer';
import { _get } from '../utills/apiClient';
import Loader from './Loader';
import { phoneNoFOrmat } from './commonFunction';

const OrdersList = () => {
    const currentYear = new Date().getFullYear();
    const showSnackbar = useSnackbar();
    const [dashboardData, setDashboardData] = useState();
    const { state } = useDropdown();
    const documentTypes = ["W2", "1099-MISC", "1099-K", "1099-R", "1099-SSA", "W2G", "401K", "5498-IRA", "5498-SSA", "3922"];
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDashboard, setFilteredDashboard] = useState();
    const [isLoading, setIsLoading] = useState(false);
    // Pagination Fields
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const totalPages = Math.ceil(dashboardData?.length / itemsPerPage);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // let currentItems = dashboardData?.slice(
    //     (currentPage - 1) * itemsPerPage,
    //     currentPage * itemsPerPage
    // );

    // Filter data based on search term
    const filteredData = dashboardData?.filter((item) =>
        Object.values(item).some((val) =>
            val?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase())
        )
    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        getAgentDashboardData();
    }, [state]);

    // useEffect(() => {
    //     if (dashboardData) {
    //         // currentItems = dashboardData.filter((item) =>
    //         //     Object.values(item).some((val) =>
    //         //         val?.toString()?.toLowerCase()?.includes(searchTerm.toLowerCase())
    //         //     )
    //         // );
    //         // currentItems = currentItems?.slice(
    //         //     (currentPage - 1) * itemsPerPage,
    //         //     currentPage * itemsPerPage
    //         // );
    //         console.log('currentItems', currentItems)
    //         // setFilteredDashboard(filteredData);
    //     }
    // }, [searchTerm, dashboardData]);


    const getAgentDashboardData = async () => {
        setIsLoading(true);
        try {
            const response = await _get(`${FETCH_AGENT_DASHBOARD}${currentYear}`);
            if (response.status == 200 && response.data?.fetchAgentDashboard && response.data?.fetchAgentDashboard.length > 0) {
                let res = response.data?.fetchAgentDashboard.filter((data) => data.fiscal_year == state.selectedOption && data.account_type != 'agent' && data.file_status != 'Account Created');
                if (res && res.length > 0) {
                    const docApiPromises = res.map(async (data) => {
                        if ((data.business_id == null && data.rental_id == null && data.home_id == null)) {
                            data.isIndividualTax = true;
                        } else {
                            data.isIndividualTax = false;
                        }
                        if (data.business_id && (data.rental_id == null && data.home_id == null)) {
                            data.isBusinessTax = true;
                        } else {
                            data.isBusinessTax = false;
                        }
                        if (data.rental_id || data.home_id) {
                            data.isRentalHousingTax = true;
                        } else {
                            data.isRentalHousingTax = false;
                        }

                        const documents = await getDocuments(data.file_no);
                        data.documentUplaodCount = await documents.filter(item =>
                            documentTypes.includes(item.document_type)
                        ).length;
                    });
                    await Promise.all(docApiPromises);
                    setIsLoading(false);
                    setDashboardData(res);
                    // setTimeout(() => {
                    //     setDashboardData(res);
                    //     setIsLoading(false);
                    // }, 1000)
                }
            }
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in getDashboardData", 'error');
            console.error('Error adding data:', error);
        }
    };


    const getDocuments = async (file_no) => {
        try {
            const response = await _get(`${FETCH_DOCUMENTS}${file_no}`);
            if (response.status == 200 && response.data?.getDocumentList && response.data?.getDocumentList.length > 0) {
                return response.data?.getDocumentList;
            } else {
                return [];
            }
        } catch (error) {
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };

    // Function to handle search input
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page on a new search
    };

    return (
        <>
            <div className='flex justify-between items-center'>
                <div className='order__search'>
                    <input type='search' placeholder='Search by Name, File No, Mobile No' className='order__searchinput' value={searchTerm}
                        onChange={handleSearch} />
                    <button>

                        <img src='/assets/images/search-icon.svg' alt='search' /></button>
                </div>
                {/* <p className='text-sm'>Appointments Today : 2</p> */}
            </div>
            <div className='order__list'>
                <div className='order__list--table'>
                    {isLoading && <Loader />}
                    <table>
                        <thead>
                            <tr>
                                <th><div className='order__list--heading'>File No <button><img src='/assets/images/sort-icon.svg' alt='sort' /></button></div></th>
                                <th><div className='order__list--heading'>Client Name <button><img src='/assets/images/sort-icon.svg' alt='sort' /></button></div></th>
                                <th><div className='order__list--heading'>Mobile No <button><img src='/assets/images/sort-icon.svg' alt='sort' /></button></div></th>
                                <th><div className='order__list--heading'>Status <button><img src='/assets/images/sort-icon.svg' alt='sort' /></button></div></th>
                                <th><div className='order__list--heading'>Tax Type <button><img src='/assets/images/sort-icon.svg' alt='sort' /></button></div></th>
                                <th><div className='order__list--heading'>Documents Upload <button><img src='/assets/images/sort-icon.svg' alt='sort' /></button></div></th>
                                {/* <th><div className='order__list--heading'>Appointment <button><img src='/assets/images/sort-icon.svg' alt='sort' /></button></div></th> */}
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {currentItems && currentItems?.length > 0 && <>
                                {currentItems.map((dashboard, index) => (
                                    <tr>
                                        <td><Link to={`/agent/orders/View/${dashboard?.file_no}`}>FY{dashboard.fiscal_year.toString().slice(-2)}-{dashboard.file_no}</Link></td>
                                        <td>{dashboard.first_name} {dashboard.last_name}</td>
                                        <td>{phoneNoFOrmat(dashboard.phone)}</td>
                                        <td>{dashboard.file_status}</td>
                                        <td>
                                            {dashboard.isIndividualTax && 'Individual Tax'}
                                            {dashboard.isBusinessTax && 'Business Tax'}
                                            {dashboard.isRentalHousingTax && 'RentalHousing Tax'}
                                        </td>
                                        <td>{dashboard?.documentUplaodCount} Uploaded</td>
                                        {/* <td><Link>12:30 pm | 09/26/2024</Link></td> */}
                                        <td className='order__list--actions'>
                                            {/* <Link><img src='/assets/images/list-edit.svg' alt='edit' /></Link> */}
                                            <Link to={`/agent/orders/Edit/${dashboard?.file_no}`}><img src='/assets/images/list-edit.svg' alt='edit' /></Link>
                                            {/* <Link><img src='/assets/images/list-schedule.svg' alt='schedule' /></Link> */}
                                        </td>
                                    </tr>
                                ))}
                            </>
                            }
                        </tbody>
                    </table>
                </div>
                <div className='pagination'>
                    <div className='pagination__left'>
                        <p className='text-sm'>Showing {(currentPage * itemsPerPage) - itemsPerPage + 1} to {currentPage * itemsPerPage} of  {dashboardData?.length} entries</p>
                    </div>
                    {dashboardData?.length && <div className="pagination__right">
                        <button className='pagination__right--btn pagination__right--btn-prev' type='button'
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <img src='/assets/images/pagination-prev.svg' alt='previous' />
                        </button>

                        {Array.from({ length: totalPages }, (_, index) => (
                            <button type='button'
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={index + 1 === currentPage ? 'active pagination__right--btn' : 'pagination__right--btn'}
                            >
                                {index + 1}
                            </button>
                        ))}

                        <button className='pagination__right--btn pagination__right--btn-next'
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <img src='/assets/images/pagination-next.svg' alt='next' />
                        </button>
                    </div>
                    }
                </div>
            </div>
        </>
    )
}

export default OrdersList