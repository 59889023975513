import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { _get, _post } from '../utills/apiClient';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from './toaster';
import { CREATE_QUESTIONNAIRE, FETCH_QUESTIONNAIRE } from '../constants/api.endpoint';
import { AppContext } from '../utills/AppContext';
import Loader from './Loader';
import { CreateNotification } from './commonFunction';
// import Buttons from '../components/Buttons'

const schema = yup.object().shape({
    residency_status: yup.string().required('Residency Status is required'),
    foreign_account_status: yup.string().required('Foreign Accounts Status is required'),
    crypto_status: yup.string().required('Cryptocurrency Status is required'),
    stock_trading: yup.string().required('Stock trading Status is required'),
    business_income: yup.string().required('Business income is required'),
    rental_income: yup.string().required('Rental income is required'),
    home_sale_status: yup.string().required('Sell Home Status is required')
});


const Questionnaire = () => {
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();
    let [updateFieldsData, setUpdateFieldsData] = useState({});
    const { setValueFromChild } = useContext(AppContext);
    let [nextBtnPath, setNextBtnPath] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const currentYear = new Date().getFullYear();

    let questionnaireInitState = {
        residency_status: "",
        foreign_account_status: "",
        crypto_status: "",
        stock_trading: "",
        business_income: "",
        rental_income: "",
        home_sale_status: "",
        other_details: ""
    }
    const [questionnaireValues, setQuestionnaireValues] = useState(questionnaireInitState);
    useEffect(() => {
        (async () => {
            if (userData.qq_id) {
                getQuestionnaire(userData?.file_no);
            }

        })();
    }, []);


    const getQuestionnaire = async (file_no) => {
        try {
            setIsLoading(true);
            const response = await _get(`${FETCH_QUESTIONNAIRE}${file_no}`);
            if (response.data?.Questionnaire) {
                questionnaireInitState = { ...response.data?.Questionnaire };

                if (questionnaireInitState.business_income == 'Yes' || questionnaireInitState.home_sale_status == 'Yes'
                    || questionnaireInitState.rental_income == 'Yes'
                ) {
                    setNextBtnPath('/account/orders/filling-details');
                } else {
                    setNextBtnPath('/account/orders/document-checklist');
                }
                setQuestionnaireValues(questionnaireInitState);
                setUpdateFieldsData({
                    Questionnaire: questionnaireInitState,
                });
                Object.entries(questionnaireInitState).map(([key, value]) => {
                    setValue(key, value)
                });
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar('Getting Error on fetchQuestionnaire', 'error');
            console.error('Error fetching data:', error);
        }
    };

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
        resolver: yupResolver(schema),
        mode: 'all'
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQuestionnaireValues({
            ...questionnaireValues,
            [name]: value,
        });
    };
    const handleReset = () => {
        setQuestionnaireValues(questionnaireInitState);
        reset();
    };

    const onSubmit = (data) => {
        console.log('data', data);
        let payload = {
            cus_id: userData?.cus_id,
            file_no: userData?.file_no,
            Questionnaire: { ...questionnaireValues }
        }
        if (!userData.qq_id) {
            createOrUpdateQuestionnaire(payload, 'Create');
        } else {
            if (!('qq_id' in payload.Questionnaire)) {
                payload.Questionnaire.qq_id = updateFieldsData.Questionnaire.qq_id;
            }
            createOrUpdateQuestionnaire(payload, 'Update');
        }
    };

    const createOrUpdateQuestionnaire = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_QUESTIONNAIRE, payload);
            if (response.status == 200 && response.data && response.data?.questionaire) {
                const user = JSON.parse(sessionStorage.getItem('taxData'));
                if (type == "Create") {
                    console.log('Questionnaire Created Successfully', response.data);
                    user.qq_id = response.data?.questionaire;
                    sessionStorage.setItem('taxData', JSON.stringify(user));
                    setUserData(user);
                    CreateNotification("Questionnaire Created Successfully", 'success');
                    showSnackbar('Questionnaire Created Successfully', 'success');
                } else {
                    console.log('Questionnaire Updated Successfully', response.data);
                    CreateNotification("Questionnaire Updated Successfully", 'success');
                    showSnackbar('Questionnaire Updated Successfully', 'success');
                }
                if (questionnaireValues.business_income == 'Yes' || questionnaireValues.rental_income == 'Yes' || questionnaireValues.home_sale_status == 'Yes') {
                    // navigate('/account/orders/filling-details');
                    setNextBtnPath('/account/orders/filling-details');
                } else {
                    // navigate(`/account/orders/document-checklist`);
                    setNextBtnPath('/account/orders/document-checklist');
                }
                setValueFromChild(prevState => ({
                    ...prevState,
                    value: 'QuestionnaireCompleted'
                }));
                getQuestionnaire(user.file_no);
                //  setValueFromChild('QuestionnaireCompleted');
            }
            setIsLoading(false);
        } catch (error) {
            showSnackbar("Getting error in createOrUpdateQuestionnaire", 'error');
            console.error('Error adding data:', error);
        }
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className="account__orders--right">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid gap-11">
                        <h1 className="account__orders--right-formtitle">Complete the following Questionnaire</h1>
                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>1. What is your residency status?</h1>
                            <div className="account__orders--right-quesfield">
                                <div className='account__orders--right-selectbox'>
                                    <select className='account__orders--right-selectinput' name="residency_status" {...register('residency_status')}
                                        onChange={(e) => {
                                            register('residency_status').onChange(e);
                                            handleInputChange(e);
                                        }}
                                        value={questionnaireValues.residency_status}>
                                        <option value="">select</option>
                                        <option value="F1">F1</option>
                                        <option value="L1">L1</option>
                                        <option value="Green Card">Green Card</option>
                                        <option value="Citizen">Citizen</option>
                                        <option value="H1B">H1B</option>
                                        <option value="H4B">H4B</option>
                                        <option value="Others">Other</option>


                                    </select>
                                    <img src="/assets/images/Select-icon.svg" alt='SelectIcon' className='absolute right-6 pointer-events-none' />
                                </div>
                                <p className='text-red-600'>{errors.residency_status?.message}</p>
                            </div>
                            <div className="account__orders--right-quesinfo flex gap-4">
                                <img className="account__orders--right-quesicon" src="/assets/images/info-square-icon.svg" alt='QuestionnaireIcon' />
                                <p className="account__orders--right-questext">183 days of presence with Non F1 visa is required for "Resident Alien" status. GC, Citizen and more than 5 years on F1 are always "Resident Alien". </p>
                            </div>
                        </div>


                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>2. Do you have any Foreign Accounts and had more than USD10K at any point of time?</h1>
                            <div className="account__orders--right-quesfield">
                                <div className='account__orders--right-selectbox'>
                                    <select className='account__orders--right-selectinput' name="foreign_account_status" {...register('foreign_account_status')} onChange={(e) => {
                                        register('foreign_account_status').onChange(e);
                                        handleInputChange(e);
                                    }} value={questionnaireValues.foreign_account_status}>
                                        <option value="">select</option>
                                        <option value="Yes and No">Yes and No</option>
                                        <option value="Yes and Yes">Yes and Yes</option>
                                        <option value="No and No">No and No</option>
                                    </select>
                                    <img src="/assets/images/Select-icon.svg" alt='SelectIcon' className='absolute right-6 pointer-events-none' />
                                </div>
                                <p className='text-red-600'>{errors.foreign_account_status?.message}</p>
                            </div>
                            <div className="account__orders--right-quesinfo flex gap-4">
                                <div>
                                    <img className="account__orders--right-quesicon" src="/assets/images/info-square-icon.svg" alt='QuestionnaireIcon' />
                                </div>
                                <div className='grid '>
                                    <p className="account__orders--right-questext"><span className='text-black font-semibold'>Yes and No:</span> Declare any income from the accounts.</p>
                                    <p className="account__orders--right-questext"><span className='text-black font-semibold'>Yes and Yes:</span> Declare any income from the accounts and file FBAR at https://bsaefiling.fincen.treas.gov/NoRegFBARFiler.html </p>
                                    <p className="account__orders--right-questext"><span className='text-black font-semibold'>No and No:</span> No action required </p>
                                </div>
                            </div>
                        </div>

                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>3. Did you trade any Cryptocurrency (Buy/Sell/Convert)?</h1>
                            <div className="account__orders--right-quesfield">
                                <div className='account__orders--right-selectbox'>
                                    <select className='account__orders--right-selectinput' name="crypto_status" {...register('crypto_status')} onChange={(e) => {
                                        register('crypto_status').onChange(e);
                                        handleInputChange(e);
                                    }} value={questionnaireValues.crypto_status} >
                                        <option value="">select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <img src="/assets/images/Select-icon.svg" alt='SelectIcon' className='absolute right-6 pointer-events-none' />
                                </div>
                                <p className='text-red-600'>{errors.crypto_status?.message}</p>
                            </div>
                            <div className="account__orders--right-quesinfo flex gap-4">
                                <img className="account__orders--right-quesicon" src="/assets/images/info-square-icon.svg" alt='QuestionnaireIcon' />
                                <p className="account__orders--right-questext">Profit/Loss need to be declared for tax calculation </p>
                            </div>
                        </div>

                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>4. Did you do any stock trading in {currentYear}?</h1>
                            <div className="account__orders--right-quesfield">
                                <div className='account__orders--right-selectbox'>
                                    <select className='account__orders--right-selectinput' name="stock_trading" {...register('stock_trading')} onChange={(e) => {
                                        register('stock_trading').onChange(e);
                                        handleInputChange(e);
                                    }} value={questionnaireValues.stock_trading}>
                                        <option value="">select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <img src="/assets/images/Select-icon.svg" alt='SelectIcon' className='absolute right-6 pointer-events-none' />
                                </div>
                                <p className='text-red-600'>{errors.stock_trading?.message}</p>
                            </div>
                            <div className="account__orders--right-quesinfo flex gap-4">
                                <img className="account__orders--right-quesicon" src="/assets/images/info-square-icon.svg" alt='QuestionnaireIcon' />
                                <p className="account__orders--right-questext">If Yes, attach the 1099B from the brokerages and applicable Supplemantal Doc (especially Etrade) </p>
                            </div>
                        </div>

                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>5. Did you do have business income in {currentYear}?</h1>
                            <div className="account__orders--right-quesfield">
                                <div className='account__orders--right-selectbox'>
                                    <select className='account__orders--right-selectinput' name="business_income" {...register('business_income')} onChange={(e) => {
                                        register('residency_status').onChange(e);
                                        handleInputChange(e);
                                    }} value={questionnaireValues.business_income}>
                                        <option value="">select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <img src="/assets/images/Select-icon.svg" alt='SelectIcon' className='absolute right-6 pointer-events-none' />
                                </div>
                                <p className='text-red-600'>{errors.business_income?.message}</p>
                            </div>
                            <div className="account__orders--right-quesinfo flex gap-4">
                                <img className="account__orders--right-quesicon" src="/assets/images/info-square-icon.svg" alt='QuestionnaireIcon' />
                                <p className="account__orders--right-questext">If Yes (on any) please fill in the Business/Rental Income-Expenses spreasdheet.This can be submitted 2-3 days later after submitting this profile form. </p>
                            </div>
                        </div>

                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>5. Did you do have rental income in {currentYear}?</h1>
                            <div className="account__orders--right-quesfield">
                                <div className='account__orders--right-selectbox'>
                                    <select className='account__orders--right-selectinput' name="rental_income" {...register('rental_income')} onChange={(e) => {
                                        register('residency_status').onChange(e);
                                        handleInputChange(e);
                                    }} value={questionnaireValues.rental_income}>
                                        <option value="">select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <img src="/assets/images/Select-icon.svg" alt='SelectIcon' className='absolute right-6 pointer-events-none' />
                                </div>
                                <p className='text-red-600'>{errors.rental_income?.message}</p>
                            </div>
                            <div className="account__orders--right-quesinfo flex gap-4">
                                <img className="account__orders--right-quesicon" src="/assets/images/info-square-icon.svg" alt='QuestionnaireIcon' />
                                <p className="account__orders--right-questext">If Yes (on any) please fill in the Business/Rental Income-Expenses spreasdheet.This can be submitted 2-3 days later after submitting this profile form. </p>
                            </div>
                        </div>

                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>6. Did you sell any homes in {currentYear}?</h1>
                            <div className="account__orders--right-quesfield">
                                <div className='account__orders--right-selectbox'>
                                    <select className='account__orders--right-selectinput' name="home_sale_status" {...register('home_sale_status')} onChange={(e) => {
                                        register('home_sale_status').onChange(e);
                                        handleInputChange(e);
                                    }} value={questionnaireValues.home_sale_status}>
                                        <option value="">select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    <img src="/assets/images/Select-icon.svg" alt='SelectIcon' className='absolute right-6 pointer-events-none' />
                                </div>
                                <p className='text-red-600'>{errors.home_sale_status?.message}</p>
                            </div>
                            <div className="account__orders--right-quesinfo flex gap-4">
                                <img className="account__orders--right-quesicon" src="/assets/images/info-square-icon.svg" alt='QuestionnaireIcon' />
                                <p className="account__orders--right-questext">If Yes please fill in the Home Sale spreasdheet.This can be submitted 2-3 days later after submitting this profile form. </p>
                            </div>
                        </div>

                        <div className="grid gap-4">
                            <h1 className='account__orders--right-ques'>7. Please add any other details that you want to be made aware such as Solar Panels/Electric Car etc</h1>
                            <input className='account__orders--right-quesinput' name="other_details" onChange={(e) => {
                                register('other_details').onChange(e);
                                handleInputChange(e);
                            }} value={questionnaireValues.other_details} type="text" />
                        </div>
                        {/* <div className='flex justify-center'>
                        <Buttons type='submit' save="/update-profile/filling-details" cancel="/updateprofile" />
                    </div> */}
                        <div className='flex justify-center'>
                            <div className="buttons">
                                <Link className="buttons__btn buttons__btn--reset" onClick={() => handleReset()}>Reset</Link>
                                <Link to="/account/dashboard" className="buttons__btn buttons__btn--cancel">Cancel</Link>
                                <button type='submit' className="buttons__btn buttons__btn--save">{userData.qq_id ? 'Update' : 'Save'}</button>
                                <Link to={nextBtnPath} className={`buttons__btn buttons__btn--next ${(!userData.qq_id) ? "disabled" : ""}`}>Next</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Questionnaire 
