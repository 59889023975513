import React from 'react'

const LoginLeft = () => {
  return (
    <div className='login__left'>
       <div className='login__container'>
        <p className='text-[#6D6E71]'>Welcome to <br/><span className='text-4xl font-bold text-[#00599C]'>OMMTAX</span></p>
            <img src="/assets/images/welcome.svg" alt='Login'/>
            <div>
            <p className='text-3xl pb-6 font-light text-[#000104] leading-[60px]'>We are here to manage all your tax matters</p>
            <p className='text-3xl font-bold text-white'>Let's begin with us!</p>
            </div>
       </div>
    </div>
  )
}

export default LoginLeft