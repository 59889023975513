import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from './toaster';
import { FETCH_DEPENDENT_DAYCARE, FETCH_MAILING_ADDRESS, FETCH_TAXPAYER } from '../constants/api.endpoint';
import TaxPayerProfile from './TaxPayerProfile';
import { _get } from '../utills/apiClient';
import MailingAddress from './MailingAddress';
import DependentDaycare from './DependentDaycare';
import BankInfo from './BankInfo';

export default function ProfileRight() {
  const [mail, setMail] = useState(false);
  const [dependent, setDependent] = useState(false);
  const [tax, setTax] = useState(true);
  const [bank, setBank] = useState(false);
  const [isDepedentComplete, setIsDepedentComplete] = useState(false);
  const [isMailingComplete, setIsMailingComplete] = useState(false);
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  const showSnackbar = useSnackbar();
  const [dependentCreate, setDependentCreate] = useState(false);
  const navigate = useNavigate();

  const setProfileSteps = (taxStatus, mailingStatus, dependentStatus, bankStatus) => {
    setBank(bankStatus);
    setTax(taxStatus);
    setDependent(dependentStatus);
    setMail(mailingStatus);
  }

  const handleMailChange = (e) => {
    const user = JSON.parse(sessionStorage.getItem('taxData'));
    setUserData(user);
    if (user.profile_id) {
      setProfileSteps(false, true, false, false);
    } else {
      e.preventDefault();
    }
  }

  const handleDependentChange = (e) => {
    const user = JSON.parse(sessionStorage.getItem('taxData'));
    setUserData(user);
    if (user.profile_id && isMailingComplete) {
      setProfileSteps(false, false, true, false);
    } else {
      e.preventDefault();
    }
  }

  useEffect(() => {

    const taxData = JSON.parse(sessionStorage.getItem('taxData'));
    if (taxData) {

      if (taxData?.dependent_status == 'Yes') {
        setDependentCreate(true);
      } else {
        setDependentCreate(false);
      }
    }
  }, [sessionStorage.getItem('taxData')])



  const handleTaxChange = (e) => {
    setProfileSteps(true, false, false, false);
  }

  const handleBankChange = (e) => {
    const user = JSON.parse(sessionStorage.getItem('taxData'));
    setUserData(user);
    if (user.profile_id && isMailingComplete) {
      setProfileSteps(false, false, false, true);
    } else {
      e.preventDefault();
    }
  }

  const location = useLocation();
  const path = location.pathname;
  const { id } = useParams();
  const isAddMode = !id;

  const handleTaxCompleted = (status) => {
    const user = JSON.parse(sessionStorage.getItem('taxData'));
    setUserData(user);
    setProfileSteps(false, true, false, false);
  }

  const handleMailCompleted = (status) => {
    getTaxPayer(userData?.profile_id, status);
    // if (isDepedentYes) {
    //   if (userData?.file_status == 'Revoke') {
    //     navigate('/account/orders/questionnaire');
    //   } else {
    //     navigate('/account/dashboard');
    //   }
    // } else {
    //   setIsMailingComplete(true);
    //   if (status == 'Next') {
    //     dependentCreate ? setProfileSteps(false, false, true, false) : setProfileSteps(false, false, false, true);
    //   }
    // }
  }

  const handleDependentDaycareCompleted = (status) => {
    setIsDepedentComplete(true);
    setProfileSteps(false, false, false, true);
  }

  useEffect(() => {
    (async () => {
      if (userData.profile_id) {
        await getDependentDayCare(userData?.profile_id);
        await getMailingAddress(userData?.profile_id);
      }
    })();
  }, []);

  const getDependentDayCare = async (profile_id) => {
    try {
      const response = await _get(`${FETCH_DEPENDENT_DAYCARE}${profile_id}`);
      if (response.data?.Dependent && response.data?.Dependent.length > 0) {
        setIsDepedentComplete(true);
      }
    } catch (error) {
      showSnackbar("Getting error in getDependentDayCare", 'error');
      console.error('Error adding data:', error);
    }
  };

  const getMailingAddress = async (profile_id) => {
    try {
      const response = await _get(`${FETCH_MAILING_ADDRESS}${profile_id}`);
      if (response.status == 200 && response.data?.MailAddress) {
        setIsMailingComplete(true);
      }
    } catch (error) {
      showSnackbar("Getting error in getMailingAddress", 'error');
      console.error('Error adding data:', error);
    }
  };

  const getTaxPayer = async (profile_id, status) => {
    try {
      const response = await _get(`${FETCH_TAXPAYER}${profile_id}`);
      if (response.status == 200 && response.data?.Taxpayer) {
        console.log('response.data?.Taxpayer', response.data?.Taxpayer);
        if (response.data?.Taxpayer?.dependent_status == "Yes") {
          if (status == 'Next') {
            dependentCreate ? setProfileSteps(false, false, true, false) : setProfileSteps(false, false, false, true);
          }
        } else {
          if (userData?.file_status == 'Revoke') {
            navigate('/account/orders/questionnaire');
          } else {
            navigate('/account/dashboard');
          }
        }
      }
    } catch (error) {
      showSnackbar("Getting error in getTaxPayer", 'error');
      console.error('Error adding data:', error);
    }
  };

  return (
    <>
      <div className='account__left'>
        <div className='account__profile--left'>
          <ul className='account__profile--left-ul'>
            <li onClick={(e) => handleTaxChange(e)}><Link className={`${tax ? "active" : ""} ${!tax && userData.profile_id ? "completed" : ""}`}><span>1</span>Tax Payer</Link></li>
            <li onClick={(e) => handleMailChange(e)}><Link className={`${mail ? "active" : ""} ${!mail && isMailingComplete ? "completed" : ""}`}><span>2</span>Mailing Address</Link></li>
            {dependentCreate ? <li onClick={(e) => handleDependentChange(e)}><Link className={`${dependent ? "active" : ""} ${!dependent && isDepedentComplete ? " completed" : ""}`}><span>3</span>Dependent</Link></li> : null}
            {/* <li onClick={(e) => handleBankChange(e)}><Link className={`${bank ? "active" : ""} ${!bank && userData.bank_id ? "completed" : ""}`}><span>{dependentCreate? 4 : 3}</span>Bank Information</Link></li> */}
          </ul>
        </div>
      </div>
      <div className='account__right'>
        <div className="account__profile--right w-full">
          {tax && <TaxPayerProfile isAddMode={isAddMode} tax={tax} onTaxCompleted={handleTaxCompleted} />}
          {mail && <MailingAddress isAddMode={isAddMode} mail={mail} onMailCompleted={handleMailCompleted} />}
          {dependent && <DependentDaycare onDependentDaycareCompleted={handleDependentDaycareCompleted} />}
          {/* {bank && <BankInfo />} */}
        </div>
      </div>
    </>
  );
}
