import React, { useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

const TopNavBar = () => {
  const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
  return (
    <>
      <div className='topnav'>
        {userData?.account_type != 'agent' && <ul className='topnav__ul'>
          <li><NavLink to="/account/dashboard"><img src="/assets/images/dashboard-icon.svg" />Dashboard</NavLink></li>
          <li><NavLink to="/account/profile" ><img src="/assets/images/profile-icon.svg" />Profile</NavLink></li>
          <li><NavLink to="/account/chats" ><img width={24} height={24} src="/assets/images/chat-svgrepo-com.svg" />Chats</NavLink></li>
          {/* <li><NavLink to="/account/orders/questionnaire"><img src="/assets/images/orders-icon.svg" />Orders</NavLink></li> */}
          {/* <li><NavLink to="/account/uploads"><img src="/assets/images/uploads-icon.svg" />Uploads</NavLink></li> */}
        </ul>}
        {userData?.account_type == 'agent' && <ul className='topnav__ul'>
          <li><NavLink to="/agent/dashboard"><img src="/assets/images/dashboard-icon.svg" />Dashboard</NavLink></li>
          <li><NavLink to="/agent/orders-list" ><img src="/assets/images/orders-icon.svg" />Tax Profiles</NavLink></li>
          <li><NavLink to="/agent/chats" ><img width={24} height={24} src="/assets/images/chat-svgrepo-com.svg" />Chats</NavLink></li>
          {/* <li><NavLink to="/account/orders/questionnaire"><img src="/assets/images/orders-icon.svg" />Orders</NavLink></li> */}
          {/* <li><NavLink to="/account/uploads"><img src="/assets/images/uploads-icon.svg" />Uploads</NavLink></li> */}
        </ul>}

      </div>
    </>
  )
}

export default TopNavBar