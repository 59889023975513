import { useContext, useEffect, useState } from "react";
import { CREATE_BUSINESS, CREATE_RENTAL, FETCH_BUSINESS, FETCH_RENTAL } from "../constants/api.endpoint";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from "./toaster";
import { _get, _post } from "../utills/apiClient";
import { AppContext } from "../utills/AppContext";
import { formatSSNForDisplay, CreateNotification } from "./commonFunction";
import Loader from "./Loader";
import { useNavigate, useParams } from "react-router-dom";

const schema = yup.object().shape({
    rental_property_owner: yup.string().required('Rental Property Owner is required'),
    EIN_SSN: yup.string()
        .matches(/^\d{3}-\d{2}-\d{4}$/, "SSN/ITIN must be in the format ***-**-****")
        .required('SSN is required'),
    property_name: yup.string(),
    property_address: yup.string().required('Property Address 1 is required'),
    property_address2: yup.string(),
    property_address3: yup.string(),
    zipcode: yup.string().required('Zipcode is required').matches(/^[0-9]+$/, "Must be only digits"),
    started_current_year: yup.string().required('Started Current Year Status is required'),
    gross_rent: yup.string().required('Gross Rent is required').matches(/^[0-9]+$/, "Must be only digits"),
    other_income: yup.string().nullable().test("is-digits", "Must be only digits", value =>!value || /^[0-9]+$/.test(value)),
});

export default function RentalRight(props) {

    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const showSnackbar = useSnackbar();
    let [updateFieldsData, setUpdateFieldsData] = useState({});
    const { setValueFromChild } = useContext(AppContext);
    const currentYear = new Date().getFullYear();
    const [isFocused, setIsFocused] = useState(false);

    let rentalIncomeAndExpenses = {
        RentalInfo: {
            rental_property_owner: "",
            EIN_SSN: "",
            property_name: "",
            property_address: "",
            property_address2: "",
            property_address3: "",
            zipcode: "",
            started_current_year: "",
            gross_rent: "",
            other_income: "",
        },
        Expenses: {
            advertising: "",
            commission_fees: "",
            mortgage_interest: "",
            property_tax: "",
            hoa: "",
            pmi: "",
            landscapping: "",
            painting: "",
            other_taxes: "",
            home_upgrade: "",
            travel_expenses: "",
            meals: "",
            utilities: "",
        },
        AutoMobileExpenses: {
            purchase_date: "",
            dateplaced_service: "",
            purchase_price: "",
            business_meal: "",
            communte_miles: "",
            businessuse_percentage: "",
        },
        HomeOfficeExpenses: {
            purchase_price: "",
            purchase_price_hoe: "",
            land_value: "",
            purchase_date: "",
            purchase_date_hoe: "",
            office_squarefoot: "",
            home_squarefoot: "",
            home_office_percentage: "",
        }
    }

    const [rentalIncAndExpValues, setRentalIncAndExpValues] = useState(rentalIncomeAndExpenses);
    const [isLoading, setIsLoading] = useState(false);
    let { id } = useParams();

    useEffect(()=>{
        if(props?.selectedRentalId) id = props?.selectedRentalId
    },[props?.selectedRentalId])
    
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (userData?.file_no && props?.selectedRentalId) {
                try {
                    setIsLoading(true);
                    const response = await _get(`${FETCH_RENTAL}${userData?.file_no}`);
                    if (response.data?.RentalIncomeAndExpenses && response.data?.RentalIncomeAndExpenses.length > 0) {
                        let allRental = response.data?.RentalIncomeAndExpenses;
                        let selectedRental = allRental.find((data) => data.RentalInfo.rental_id == props.selectedRentalId);
                        rentalIncomeAndExpenses = { ...selectedRental };
                        rentalIncomeAndExpenses.AutoMobileExpenses.purchase_date = formatDate(rentalIncomeAndExpenses.AutoMobileExpenses.purchase_date);
                        rentalIncomeAndExpenses.AutoMobileExpenses.dateplaced_service = formatDate(rentalIncomeAndExpenses.AutoMobileExpenses.dateplaced_service);
                        rentalIncomeAndExpenses.HomeOfficeExpenses.purchase_date_hoe = formatDate(rentalIncomeAndExpenses.HomeOfficeExpenses.purchase_date);
                        rentalIncomeAndExpenses.HomeOfficeExpenses.purchase_price_hoe = rentalIncomeAndExpenses.HomeOfficeExpenses.purchase_price;

                        rentalIncomeAndExpenses.Expenses.hoa = selectedRental.Expenses.HOA;
                        rentalIncomeAndExpenses.Expenses.pmi = selectedRental.Expenses.PMI;

                        setRentalIncAndExpValues(rentalIncomeAndExpenses);
                        setUpdateFieldsData(rentalIncomeAndExpenses);
                        Object.entries(rentalIncomeAndExpenses).map(([key, value]) => {
                            Object.entries(value).map(([key1, value1]) => {
                                setValue(key1, value1)
                            });
                        });
                    }
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    showSnackbar('Getting Error on FETCH_RENTAL', 'error');
                    console.error('Error fetching data:', error);
                }
            }

        })();
    }, []);

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
        resolver: yupResolver(schema),
        mode: 'all'
    });


    const handleBlur = (value) => {
        setIsFocused(false);
    };

    const handleFocus = (value) => {
        setIsFocused(true);
    };

    const getFormattedValue = (value) => {
        if (value) {
            const formatted = value.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
            return formatted;
        } else {
            return value;
        }
    }

    const handleRentalInfoChange = (e, i) => {
        const { name, value } = e.target;
        if (name == 'EIN_SSN') {
            const raw = value.replace(/\D/g, '');
            if (raw.length <= 9) {
                let rentalIncAndExp = { ...rentalIncAndExpValues }
                rentalIncAndExp.RentalInfo[e.target.name] = getFormattedValue(raw);
                setRentalIncAndExpValues(rentalIncAndExp);
            }
        } else {
            let rentalIncAndExp = { ...rentalIncAndExpValues }
            rentalIncAndExp.RentalInfo[e.target.name] = e.target.value
            setRentalIncAndExpValues(rentalIncAndExp);
        }
    }

    const handleExpensesChange = (e, i) => {
        let rentalIncAndExp = { ...rentalIncAndExpValues }
        rentalIncAndExp.Expenses[e.target.name] = e.target.value
        setRentalIncAndExpValues(rentalIncAndExp);
    }

    const handleAutoMobileExpensesChange = (e, i) => {
        let rentalIncAndExp = { ...rentalIncAndExpValues }
        rentalIncAndExp.AutoMobileExpenses[e.target.name] = parseFloat(e.target.value);
        const BusinessMiles = rentalIncAndExpValues.AutoMobileExpenses.business_meal || 0;
        const CommuteandLeisureMiles = rentalIncAndExpValues.AutoMobileExpenses.communte_miles || 0;
        const businessuse_percentage = BusinessMiles > 0
            ? (BusinessMiles / (BusinessMiles + CommuteandLeisureMiles)) : 0;
        rentalIncAndExpValues.AutoMobileExpenses.businessuse_percentage = businessuse_percentage.toFixed(2);
        setRentalIncAndExpValues(rentalIncAndExp);
    }


    const handleHomeOfficeExpensesChange = (e) => {
        const { name, value } = e.target;
        let rentalIncAndExp = { ...rentalIncAndExpValues };

        rentalIncAndExp.HomeOfficeExpenses[name] = value;
        const { office_squarefoot, home_squarefoot } = rentalIncAndExp.HomeOfficeExpenses;
        if (home_squarefoot && office_squarefoot) {
            rentalIncAndExp.HomeOfficeExpenses.home_office_percentage = (office_squarefoot / home_squarefoot).toFixed(2);
        } else {
            rentalIncAndExp.HomeOfficeExpenses.home_office_percentage = 0;
        }
        setRentalIncAndExpValues(rentalIncAndExp);
    }


    const handleReset = () => {
        setRentalIncAndExpValues(rentalIncomeAndExpenses);
        reset();
    };

    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    const onSubmit = (data) => {
        console.log('rentalIncAndExpValues', rentalIncAndExpValues);
        if (rentalIncAndExpValues) {
            for (const key of Object.keys(rentalIncAndExpValues.HomeOfficeExpenses)) {
                if (key.includes('_hoe')) {
                    rentalIncAndExpValues.HomeOfficeExpenses[key.replace('_hoe', '')] = rentalIncAndExpValues.HomeOfficeExpenses[key];
                    // delete rentalIncAndExpValues.HomeOfficeExpenses[key];
                }
            }
        }

        const payload = {
            file_no: userData?.file_no,
            cus_id: userData?.cus_id,
            RentalIncomeAndExpenses: [rentalIncAndExpValues],
        }

        if (!updateFieldsData?.RentalInfo?.rental_id) {
            createOrUpdateRental(payload, 'Create');
        } else {
            if (!('rental_id' in payload.RentalIncomeAndExpenses[0].RentalInfo)) {
                payload.RentalIncomeAndExpenses[0].RentalInfo.rental_id = updateFieldsData.RentalInfo.rental_id;
            }
            createOrUpdateRental(payload, 'Update');
        }

    };


    const createOrUpdateRental = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_RENTAL, payload);
            console.log('createOrUpdateRental', response);
            if (response.status == 200 && response.data?.rental_id) {
                if (type == "Create") {
                    CreateNotification("Rental Created Successfully", 'success');
                    showSnackbar('Rental Created Successfully', 'success');
                    const user = JSON.parse(sessionStorage.getItem('taxData'));
                    user.rental_id = response.data?.rental_id[0].LV_NEWRENTAL_ID;
                    sessionStorage.setItem('taxData', JSON.stringify(user));
                    setUserData(user);
                } else {
                    CreateNotification("Rental Updated Successfully", 'success');
                    showSnackbar('Rental Updated Successfully', 'success');
                }
                // setValueFromChild('fillingCompleted');
                setValueFromChild(prevState => ({
                    ...prevState,
                    value: 'fillingCompleted'
                }));
                props.onRentalBack();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateRental", 'error');
            console.error('Error adding data:', error);
        }
    };

    const handleBack = (e) => {
        if (id) {
            navigate('/account/dashboard');
        } else {
            props.onRentalBack();
        }
    }

    return (
        <>
            {isLoading && <Loader />}

            <div className="flex flex-col gap-8">
                <h1 className="account__profile--right-formtitle"><img src="/assets/images/left-arrow.svg" onClick={(e) => handleBack(e)} className="pr-4 cursor-pointer" />Rental Income and Expenses Details 1</h1>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
                    <div className="w-[80%] mx-auto">

                        <div className='flex flex-col gap-8'>
                            <div className='flex flex-col gap-6'>
                                <h2 className='updateprofile__title'>Rental Info</h2>
                                <div className='updateprofile__form'>

                                    <div className="updateprofile__formfield">
                                        <p className="updateprofile__inputlabel">Rental Property Owner's Name*</p>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Property Owner's Name" name="rental_property_owner" {...register('rental_property_owner')}
                                            onChange={(e) => {
                                                register('rental_property_owner').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value={rentalIncAndExpValues.RentalInfo.rental_property_owner} />
                                        <p className='text-red-600'>{errors.rental_property_owner?.message}</p>
                                    </div>

                                    <div className="updateprofile__formfield">
                                        <p className="updateprofile__inputlabel">EIN/SSN*</p>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="EIN/SSN" name="EIN_SSN" {...register('EIN_SSN')}
                                            onChange={(e) => {
                                                register('EIN_SSN').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            onBlur={(e) => {
                                                register('EIN_SSN').onBlur(e);
                                                handleBlur(rentalIncAndExpValues.RentalInfo.EIN_SSN);
                                            }}
                                            onFocus={(e) => {
                                                handleFocus(rentalIncAndExpValues.RentalInfo.EIN_SSN);
                                            }}
                                            value={isFocused ? rentalIncAndExpValues.RentalInfo.EIN_SSN : formatSSNForDisplay(rentalIncAndExpValues.RentalInfo.EIN_SSN)}
                                        // value={rentalIncAndExpValues.RentalInfo.EIN_SSN} 
                                        />
                                        <p className='text-red-600'>{errors.EIN_SSN?.message}</p>
                                    </div>

                                    <div className="updateprofile__formfield">
                                        <p className="updateprofile__inputlabel">Property Name (if any)</p>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Property Name" name="property_name" {...register('property_name')}
                                            onChange={(e) => {
                                                register('property_name').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value={rentalIncAndExpValues.RentalInfo.property_name} />
                                        {/* <p className='text-red-600'>{errors.property_name?.message}</p> */}
                                    </div>

                                    <div className="updateprofile__formfield">
                                        <p className="updateprofile__inputlabel">Property Address*</p>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Property Address" name="property_address" {...register('property_address')}
                                            onChange={(e) => {
                                                register('property_address').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value={rentalIncAndExpValues.RentalInfo.property_address} />
                                        <p className='text-red-600'>{errors.property_address?.message}</p>
                                    </div>

                                    <div className="updateprofile__formfield">
                                        <p className="updateprofile__inputlabel">Address 2</p>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Property Address 2" name="property_address2" {...register('property_address2')}
                                            onChange={(e) => {
                                                register('property_address2').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value={rentalIncAndExpValues.RentalInfo.property_address2} />
                                        <p className='text-red-600'>{errors.property_address2?.message}</p>
                                    </div>

                                    <div className="updateprofile__formfield">
                                        <p className="updateprofile__inputlabel">Address 3</p>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Property Address 3" name="property_address3" {...register('property_address3')}
                                            onChange={(e) => {
                                                register('property_address3').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value={rentalIncAndExpValues.RentalInfo.property_address3} />
                                        <p className='text-red-600'>{errors.property_address3?.message}</p>
                                    </div>


                                    <div className="updateprofile__formfield">
                                        <p className="updateprofile__inputlabel">Zipcode*</p>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="zipcode" name="zipcode" {...register('zipcode')}
                                            onChange={(e) => {
                                                register('zipcode').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value={rentalIncAndExpValues.RentalInfo.zipcode} />
                                        <p className='text-red-600'>{errors.zipcode?.message}</p>
                                    </div>



                                </div>

                                <div className='flex gap-4'>
                                    <p className="text-[#6D6E71]">Started this Rental in {currentYear}</p>
                                    <div className='flex gap-3'>
                                        <input type='radio' disabled={id && userData?.file_status != "Revoke" ? true : false} name='started_current_year' {...register('started_current_year')}
                                            onChange={(e) => {
                                                register('started_current_year').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value="y" checked={rentalIncAndExpValues.RentalInfo.started_current_year == 'y'} /> <label>Yes</label>
                                    </div>
                                    <div className='flex gap-3'>
                                        <input type='radio' disabled={id && userData?.file_status != "Revoke" ? true : false} name='started_current_year' {...register('started_current_year')}
                                            onChange={(e) => {
                                                register('started_current_year').onChange(e);
                                                handleRentalInfoChange(e);
                                            }}
                                            value="n" checked={rentalIncAndExpValues.RentalInfo.started_current_year == 'n'} /> <label>No</label>
                                    </div>
                                    <p className='text-red-600'>{errors.started_current_year?.message}</p>
                                </div>

                                <div className='flex flex-col gap-6 updateprofile__formrow--bdrbtm'>
                                    <h2 className='updateprofile__title'>Income</h2>
                                    <div className='updateprofile__form'>
                                        <div className='updateprofile__formfield'>
                                            <label className="updateprofile__inputlabel">Gross receipts / sales</label>
                                            <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Gross receipts / sales*" name="gross_rent" {...register('gross_rent')}
                                                onChange={(e) => {
                                                    register('gross_rent').onChange(e);
                                                    handleRentalInfoChange(e);
                                                }}
                                                value={rentalIncAndExpValues.RentalInfo.gross_rent} />
                                            <p className='text-red-600'>{errors.gross_rent?.message}</p>
                                        </div>

                                        <div className='updateprofile__formfield'>
                                            <label className="updateprofile__inputlabel">Other income</label>
                                            <input type='text' disabled={id && userData?.file_status != "Revoke" ? true : false} name="other_income" {...register('other_income')} onChange={(e) => {
                                                register('other_income').onChange(e);
                                                handleRentalInfoChange(e);
                                            }} value={rentalIncAndExpValues.RentalInfo.other_income} placeholder="Amount in USD" />
                                            <p className='text-red-600'>{errors.other_income?.message}</p>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div className='flex flex-col gap-6'>
                                <h2 className='updateprofile__title'>Expenses</h2>
                                <table className='updateprofile__table'>
                                    <thead>
                                        <tr>
                                            <th>Expenses</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Advertising</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="advertising" {...register('advertising')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.advertising} /></td>
                                        </tr>
                                        <tr>
                                            <td>Commision and fees</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="commission_fees" {...register('commission_fees')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.commission_fees} /></td>
                                        </tr>
                                        <tr>
                                            <td>Mortgage Interest</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="mortgage_interest" {...register('mortgage_interest')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.mortgage_interest} /></td>
                                        </tr>
                                        <tr>
                                            <td>Property/Real EstateTax</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="property_tax" {...register('property_tax')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.property_tax} /></td>
                                        </tr>
                                        <tr>
                                            <td>HOA</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="hoa" {...register('hoa')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.hoa} /></td>
                                        </tr>
                                        <tr>
                                            <td>PMI</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="pmi" {...register('pmi')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.pmi} /></td>
                                        </tr>
                                        <tr>
                                            <td>Landscapping</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="landscapping" {...register('landscapping')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.landscapping} /></td>
                                        </tr>
                                        <tr>
                                            <td>Painting</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="painting" {...register('painting')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.painting} /></td>
                                        </tr>
                                        <tr>
                                            <td>Other Taxes and licenses </td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="other_taxes" {...register('other_taxes')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.other_taxes} /></td>
                                        </tr>
                                        <tr>
                                            <td>Home Upgrades (For Rental Purposes)</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="home_upgrade" {...register('home_upgrade')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.home_upgrade} /></td>
                                        </tr>
                                        <tr>
                                            <td>Travel Expenses (For Rental Purposes)</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="travel_expenses" {...register('travel_expenses')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.travel_expenses} /></td>
                                        </tr>
                                        <tr>
                                            <td>Meals (For Rental Purposes)</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="meals" {...register('meals')} onChange={handleExpensesChange} value={rentalIncAndExpValues.Expenses.meals} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <h2 className='updateprofile__title'>Automobile Expenses</h2>
                                <table className='updateprofile__table'>
                                    <thead>
                                        <tr>
                                            <th>Expenses</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Purchase Date</td>
                                            <td>
                                                <input type="date" disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="DD/MM/YYYY" name="purchase_date" {...register('purchase_date')} onChange={(e) => {
                                                    register('purchase_date').onChange(e);
                                                    handleAutoMobileExpensesChange(e);
                                                }} value={rentalIncAndExpValues.AutoMobileExpenses.purchase_date} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Date Placed in Service</td>
                                            <td>
                                                <input type="date" disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="DD/MM/YYYY" name="dateplaced_service" {...register('dateplaced_service')} onChange={(e) => {
                                                    register('dateplaced_service').onChange(e);
                                                    handleAutoMobileExpensesChange(e);
                                                }} value={rentalIncAndExpValues.AutoMobileExpenses.dateplaced_service} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Price (For Depreciation)</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="purchase_price" {...register('purchase_price')} onChange={handleAutoMobileExpensesChange} value={rentalIncAndExpValues.AutoMobileExpenses.purchase_price} /></td>
                                        </tr>
                                        <tr>
                                            <td>Business Miles</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="business_meal" {...register('business_meal')} onChange={handleAutoMobileExpensesChange} value={rentalIncAndExpValues.AutoMobileExpenses.business_meal} /></td>
                                        </tr>
                                        <tr>
                                            <td>Commute and Leisure Miles</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="communte_miles" {...register('communte_miles')} onChange={handleAutoMobileExpensesChange} value={rentalIncAndExpValues.AutoMobileExpenses.communte_miles} /></td>
                                        </tr>
                                        <tr>
                                            <td>Percentage of Business Use </td>
                                            <td><input type='number' disabled={true} placeholder="Amount in USD" name="businessuse_percentage" {...register('businessuse_percentage')} onChange={handleAutoMobileExpensesChange} value={rentalIncAndExpValues.AutoMobileExpenses.businessuse_percentage} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <h2 className='updateprofile__title'>Home Office Expenses</h2>
                                <table className='updateprofile__table'>
                                    <thead>
                                        <tr>
                                            <th>Expenses</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Purchase Price (For Depreciation)</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="purchase_price_hoe" {...register('purchase_price_hoe')} onChange={handleHomeOfficeExpensesChange} value={rentalIncAndExpValues.HomeOfficeExpenses.purchase_price_hoe} /></td>
                                        </tr>
                                        <tr>
                                            <td>Land Value (if Known)</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="land_value" {...register('land_value')} onChange={handleHomeOfficeExpensesChange} value={rentalIncAndExpValues.HomeOfficeExpenses.land_value} /></td>
                                        </tr>
                                        <tr>
                                            <td>Purchase Date</td>
                                            <td>
                                                <input type="date" disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="DD/MM/YYYY" name="purchase_date_hoe"  {...register('purchase_date_hoe')} onChange={(e) => {
                                                    register('purchase_date_hoe').onChange(e);
                                                    handleHomeOfficeExpensesChange(e);
                                                }} value={rentalIncAndExpValues.HomeOfficeExpenses.purchase_date_hoe} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Square Foot of Home Office</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="office_squarefoot" {...register('office_squarefoot')} onChange={handleHomeOfficeExpensesChange} value={rentalIncAndExpValues.HomeOfficeExpenses.office_squarefoot} /></td>
                                        </tr>
                                        <tr>
                                            <td>Square foot of Whole House</td>
                                            <td><input type='number' disabled={id && userData?.file_status != "Revoke" ? true : false} placeholder="Amount in USD" name="home_squarefoot" {...register('home_squarefoot')} onChange={handleHomeOfficeExpensesChange} value={rentalIncAndExpValues.HomeOfficeExpenses.home_squarefoot} /></td>
                                        </tr>
                                        <tr>
                                            <td>Percentage of Home office</td>
                                            <td><input type='number' disabled={true} placeholder="Amount in USD" name="home_office_percentage" {...register('home_office_percentage')} onChange={handleHomeOfficeExpensesChange} value={rentalIncAndExpValues.HomeOfficeExpenses.home_office_percentage} /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className="buttons">
                            {(userData?.file_status == "Revoke" || userData?.file_status == "Account Created") && (<button type='button' onClick={() => handleReset()} className="buttons__btn buttons__btn--reset">
                                Reset
                            </button>)}
                            <button type='button' onClick={(e) => handleBack(e)} className="buttons__btn buttons__btn--cancel">
                                Cancel
                            </button>
                            {(userData?.file_status == "Revoke" || userData?.file_status == "Account Created") && (<button type='submit' className="buttons__btn buttons__btn--save">
                                Save
                            </button>)}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}