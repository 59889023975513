
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { DELETE_DOCUMENTS, FETCH_DOCUMENTS } from '../constants/api.endpoint';
import { _delete, _get } from '../utills/apiClient';
import { useSnackbar } from './toaster';


export default function DocumentListTable(props) {
    const showSnackbar = useSnackbar();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const [rows, setRows] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('taxData'));
        getDocuments(user?.file_no);
    }, [props.uploadStatus]);

    const getDocuments = async (file_no) => {
        try {
            const response = await _get(`${FETCH_DOCUMENTS}${file_no}`);
            if (response.status == 200 && response.data?.getDocumentList && response.data?.getDocumentList.length > 0) {
                const filteredArray = response.data?.getDocumentList.filter(obj => props.data.includes(obj.document_type));
                setRows([...filteredArray]);
            } else {
                setRows([]);
            }
        } catch (error) {
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };

    const columns = [
        { id: 'document_id', label: 'DocumentID', minWidth: 170 },
        { id: 'document_name', label: 'DocumentName', minWidth: 100 },
        { id: 'document_type', label: 'DocumentType', minWidth: 100 },
        { id: 'action', label: 'Action', minWidth: 100 },
    ];

    const deleteDoc = (row, index) => {
        deleteDocument(row);
    }

    const deleteDocument = async (row) => {
        try {
            const response = await _delete(`${DELETE_DOCUMENTS}${row?.document_id}`);
            if (response?.data?.document) {
                showSnackbar("Document Deleted Successfully", 'success');
                getDocuments(userData?.file_no);
                props.deleteCompleted(row);
            }
        } catch (error) {
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {(rows && rows.length) ?
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column, index) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id != 'action' ? value : <button type='button' onClick={() => deleteDoc(row, index)} className="buttons__btn delete-btn buttons__btn--delete">Delete</button>}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody> :
                        <div className='flex items-center justify-center py-4 w-full text-sm'>No Records</div>}


                </Table>
            </TableContainer>
            {rows?.length > 0 && <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />}
        </Paper>
    );
}