import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { _post } from '../utills/apiClient';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from '../components/toaster';
import { SIGNIN } from '../constants/api.endpoint';
import Loader from '../components/Loader';

const Signin = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const loginInitState = {
    email: "",
    password: "",
  };
  const [loginValues, setloginValues] = useState(loginInitState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setloginValues({
      ...loginValues,
      [name]: value,
    });
  };

  const onSubmit = (data) => {
    login(loginValues);
  };

  const login = async (payload) => {
    setIsLoading(true);
    try {
      const response = await _post(SIGNIN, payload);
      console.log('response', response);
      if (response.status == 200 && response.data?.user?.return_message == "Login Success and OTP sent Successfully") {
        sessionStorage.setItem("createdFileno", response.data?.user?.data[0]?.cus_id);
        navigate(`/login/otp-verification/L`);
      } else {
        showSnackbar('Incorrect userName or password', 'error');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error adding data:', error);
      showSnackbar(error, 'error');
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className='user__container'>
        <form className='flex flex-col gap-8 w-full' onSubmit={handleSubmit(onSubmit)}>
          <p className='text-2xl'>Login</p>
          <div className="user__inputgroup">
            <div className='relative'>
              <input type='email' {...register('email', { required: "Email ID is required" })} name="email" onChange={handleInputChange} value={loginValues.email} required />
              <label>Email ID</label>
            </div>
            <p className='text-red-600'>{errors.firstName?.message}</p>
          </div>
          <div className="user__inputgroup">
            <div className='relative'>
              <input type='password' {...register('password', { required: "Password is required" })} name="password" onChange={handleInputChange} value={loginValues.password} required />
              <label>Password</label>
            </div>
            <p className='text-red-600'>{errors.firstName?.message}</p>
          </div>
          <button type='submit' className='btn btn__primary'>Login</button>
          <p className='text-sm text-center'>Create an account? <Link to="/" className='text-[#00599C] font-bold'>SignUp</Link></p>
          {/* <Button link="/login/otp-verification" text="Get OTP" /> */}
          <p className='text-sm text-center'> <Link to="/forgot-password" className='text-[#00599C] font-bold'>Forgot Password?</Link> </p>
        </form>
      </div>
    </>
  )
}

export default Signin