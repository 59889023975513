
import { useContext, useEffect, useState } from "react";
import { CREATE_HOME, FETCH_HOME } from "../constants/api.endpoint";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from "./toaster";
import { _get, _post } from "../utills/apiClient";
import { AppContext } from "../utills/AppContext";
import Loader from "./Loader";
import { CreateNotification } from "./commonFunction";
import { useNavigate, useParams } from "react-router-dom";

const schema = yup.object().shape({
    purchased_date: yup.string().required('Date of Home was Purchased is required'),
    occupied_date: yup.string().required('Date of Home Occupied is required'),
    rented_date: yup.string(),
    rental_end_date: yup.string(),
    sold_date: yup.string().required('Date of Home Sold is required'),
    purchase_price: yup.string().required('Purchase price of home is required').matches(/^[0-9]+$/, "Must be only digits"),
    closing_cost: yup.string().matches(/^[0-9]+$/, "Must be only digits"),
    upgrade_cost: yup.string().matches(/^[0-9]+$/, "Must be only digits"),
    selling_price: yup.string().required('Selling price of home is required').matches(/^[0-9]+$/, "Must be only digits"),
    selling_closing_cost: yup.string().required('Selling Closing Cost is required').matches(/^[0-9]+$/, "Must be only digits"),
});


export default function HomeSaleDetails(props) {

    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const showSnackbar = useSnackbar();
    let [updateFieldsData, setUpdateFieldsData] = useState({});
    const { setValueFromChild } = useContext(AppContext);
    let { id } = useParams();

    useEffect(()=>{
        if(props?.selectedHomeId) id = props?.selectedHomeId
    },[props?.selectedHomeId])


    const navigate = useNavigate();

    let homeSaleDetails = {

        "purchased_date": "",
        "occupied_date": "",
        "rented_date": "",
        "rental_end_date": "",
        "sold_date": "",
        "purchase_price": "",
        "closing_cost": "",
        "upgrade_cost": "",
        "selling_price": "",
        "selling_closing_cost": "",

    }
    const [homeValues, setHomeValues] = useState(homeSaleDetails);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (userData?.file_no && props?.selectedHomeId) {
                try {
                    setIsLoading(true);
                    const response = await _get(`${FETCH_HOME}${userData?.file_no}`);
                    if (response.data?.homeSaleDetails && response.data?.homeSaleDetails.length > 0) {
                        let allHome = response.data?.homeSaleDetails;
                        let selectedHome = allHome.find((data) => data.home_id == props.selectedHomeId);
                        selectedHome.occupied_date = formatDate(selectedHome.occupied_date);
                        selectedHome.purchased_date = formatDate(selectedHome.purchased_date);
                        selectedHome.rental_end_date = formatDate(selectedHome.rental_end_date);
                        selectedHome.rented_date = formatDate(selectedHome.rented_date);
                        selectedHome.sold_date = formatDate(selectedHome.sold_date);
                        homeSaleDetails = { ...selectedHome };
                        setHomeValues(homeSaleDetails);
                        setUpdateFieldsData(homeSaleDetails);

                        Object.entries(homeSaleDetails).map(([key, value]) => {
                            setValue(key, value)
                        });
                    }
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    showSnackbar('Getting Error on Gethome', 'error');
                    console.error('Error fetching data:', error);
                }
            }

        })();
    }, []);

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });


    const handleHomeChange = (e, i) => {
        let homeData = { ...homeValues }
        homeData[e.target.name] = e.target.value
        setHomeValues(homeData);
    }

    const handleReset = () => {
        setHomeValues(homeSaleDetails);
        reset();
    };

    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    const onSubmit = (data) => {
        console.log('homeValues', homeValues);


        const payload = {
            file_no: userData?.file_no,
            cus_id: userData?.cus_id,
            homeSaleDetails: [homeValues],
        }

        if (!updateFieldsData?.home_id) {
            createOrUpdateHome(payload, 'Create');
        } else {
            if (!('home_id' in payload.homeSaleDetails[0])) {
                payload.homeSaleDetails[0].home_id = updateFieldsData.home_id;
            }
            createOrUpdateHome(payload, 'Update');
        }

    };


    const createOrUpdateHome = async (payload, type) => {
        try {
            setIsLoading(true);
            const response = await _post(CREATE_HOME, payload);
            console.log('createOrUpdateHome', response);
            if (response.status == 200 && response.data?.home_status) {
                if (type == "Create") {
                    CreateNotification("Home Details Created Successfully",'success');
                    showSnackbar('Home Details Created Successfully', 'success');
                    const user = JSON.parse(sessionStorage.getItem('taxData'));
                    user.rental_id = response.data?.home_status[0].LV_HOMESALE_ID;
                    sessionStorage.setItem('taxData', JSON.stringify(user));
                    setUserData(user);
                } else {
                    CreateNotification("Home Details Updated Successfully",'success');
                    showSnackbar('Home Details Updated Successfully', 'success');
                }
                // setValueFromChild('fillingCompleted');
                setValueFromChild(prevState => ({
                    ...prevState,
                    value: 'fillingCompleted'
                }));

                props.onHomeBack();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showSnackbar("Getting error in createOrUpdateHome", 'error');
            console.error('Error adding data:', error);
        }
    };

    const handleBack = (e) => {
        if (id) {
            navigate('/account/dashboard');
        } else {
            props.onHomeBack();
        }
    }

    return (
        <>
            {isLoading && <Loader />}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-8">
                    <h1 className="account__profile--right-formtitle"><img src="/assets/images/left-arrow.svg" onClick={(e) => handleBack(e)} className="pr-4 cursor-pointer" />Home Sale Details</h1>
                    <div className="w-[80%] mx-auto">
                        <div className='flex flex-col gap-8'>
                            <div className='flex flex-col gap-6'>
                                <h2 className='updateprofile__title'>Home Info</h2>
                                <div className='updateprofile__form'>
                                    <div className='updateprofile__formfield'>
                                        <label className="updateprofile__inputlabel">Date of Home was Purchased*</label>
                                        <input type='date' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Date of Home was Purchased' {...register('purchased_date')} name="purchased_date" onChange={(e) => {
                                            register('purchased_date').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.purchased_date} />
                                        <p className='text-red-600'>{errors.purchased_date?.message}</p>
                                    </div>
                                    <div className='updateprofile__formfield'>
                                        <label className="updateprofile__inputlabel">Date of Home Occupied*</label>
                                        <input type='date' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Date of Home Occupied' name="occupied_date" {...register('occupied_date')} onChange={(e) => {
                                            register('occupied_date').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.occupied_date} />
                                        <p className='text-red-600'>{errors.occupied_date?.message}</p>
                                    </div>
                                    <div className='updateprofile__formfield'>
                                        <label className="updateprofile__inputlabel">Date of Home Rented</label>
                                        <input type='date' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Date of Home Rented' {...register('rented_date')} name="rented_date" onChange={(e) => {
                                            register('rented_date').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.rented_date} />
                                        <p className='text-red-600'>{errors.rented_date?.message}</p>
                                    </div>
                                    <div className='updateprofile__formfield'>
                                        <label className="updateprofile__inputlabel">Date of Home Rental Ended</label>
                                        <input type='date' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Date of Home Rental Ended (If Applicable)' {...register('rental_end_date')} name="rental_end_date" onChange={(e) => {
                                            register('rental_end_date').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.rental_end_date} />
                                        <p className='text-red-600'>{errors.rental_end_date?.message}</p>
                                    </div>
                                    <div className='updateprofile__formfield'>
                                        <label className="updateprofile__inputlabel">Date of Home Sold*</label>
                                        <input type='date' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Date of Home Sold' {...register('sold_date')} name="sold_date" onChange={(e) => {
                                            register('sold_date').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.sold_date} />
                                        <p className='text-red-600'>{errors.sold_date?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <h2 className='updateprofile__title'>Basis of Home</h2>
                                <div className='updateprofile__form'>
                                    <div className='flex flex-col gap-3'>
                                        <label>Purchase price of home</label>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Purchase price of home' name="purchase_price" {...register('purchase_price')} onChange={(e) => {
                                            register('business_address').onChange(e); 
                                            handleHomeChange(e);
                                        }} value={homeValues.purchase_price} />
                                        <p className='text-red-600'>{errors.purchase_price?.message}</p>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <label>Purchase Closing Cost (do not include Seller/Developer portion)</label>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Purchase Closing Cost' name="closing_cost" {...register('closing_cost')} onChange={(e) => {
                                            register('business_address').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.closing_cost} />
                                        <p className='text-red-600'>{errors.closing_cost?.message}</p>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <label>Cost of Capital Improvements or Home Upgrades </label>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Cost of Capital' name="upgrade_cost" {...register('upgrade_cost')} onChange={(e) => {
                                            register('business_address').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.upgrade_cost} />
                                        <p className='text-red-600'>{errors.upgrade_cost?.message}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <h2 className='updateprofile__title'>Basis of Sale</h2>
                                <div className='updateprofile__form'>
                                    <div className='flex flex-col gap-3'>
                                        <label>Selling price of home</label>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Selling price of home' name="selling_price" {...register('selling_price')} onChange={(e) => {
                                            register('business_address').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.selling_price} />
                                        <p className='text-red-600'>{errors.selling_price?.message}</p>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <label>Selling Closing Cost (Do not include Buyer portions)</label>
                                        <input type='text' disabled={id && userData?.file_status != "Revoke" ?   true : false} placeholder='Selling Closing Cost' name="selling_closing_cost" {...register('selling_closing_cost')} onChange={(e) => {
                                            register('business_address').onChange(e);
                                            handleHomeChange(e);
                                        }} value={homeValues.selling_closing_cost} />
                                        <p className='text-red-600'>{errors.selling_closing_cost?.message}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className="buttons">
                            {(userData?.file_status == "Revoke" || userData?.file_status == "Account Created") && (<button type='button' onClick={() => handleReset()} className="buttons__btn buttons__btn--reset">
                                Reset
                            </button>)}
                            <button type='button' onClick={(e) => handleBack(e)} className="buttons__btn buttons__btn--cancel">
                                Cancel
                            </button>
                            {(userData?.file_status == "Revoke" || userData?.file_status == "Account Created") && (<button type='submit' className="buttons__btn buttons__btn--save">
                                Save
                            </button>)}
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
