import { TextField } from '@mui/material';
import React, { useState } from 'react';
import Button from './Button';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { _post } from '../utills/apiClient';
import { CREATE_ACCOUNT } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import { phoneNoFOrmat } from './commonFunction';
import Loader from './Loader';

const schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().email('Please enter valid email').required('Email is required'),
  phone: yup.string()
    .required('Phone No. is required')
    .matches(/^[0-9-]+$/, "Must be only digits"),
  password: yup.string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#\-])[A-Za-z\d@$!%*?&^#]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  // password: yup.string().required('Set password is required').min(4, 'Minimum 8 characters required').max(15, 'Maximum 15 characters only')
});

const UserRegistration = () => {

  const registrationInitState = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
  };

  const { register, handleSubmit, formState: { errors, isValid, isDirty } } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [registrationValues, setRegistrationValues] = useState(registrationInitState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRegistrationValues({
      ...registrationValues,
      [name]: value,
    });
  };

  const onSubmit = (data) => {
    console.log('data', data);
    console.log('errors', errors);
    console.log('registrationValues', registrationValues);
    registrationValues.phone = registrationValues.phone.replace(/-/g, '').slice(0, 10);
    createAccount(registrationValues);
  };

  const createAccount = async (payload) => {
    setIsLoading(true);
    try {
      const response = await _post(CREATE_ACCOUNT, payload);
      console.log('response', response);
      if (response.status == 200 && response.data?.cus_id) {
        if (response.data?.cus_id == 'email already exists') {
          showSnackbar('Email Already Exists', 'error');
        } else {
          console.log('Account Created Successfully', response.data?.cus_id);
          sessionStorage.setItem("createdFileno", response.data?.cus_id);
          navigate(`login/otp-verification/R`);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error adding data:', error);
      if (error?.status == 500 && error?.response?.data?.message?.includes("Duplicate entry")) {
        showSnackbar('Email Already Exists', 'error');
      }
      // Handle errors
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <div className='user__container'>
        <form className='user__form' onSubmit={handleSubmit(onSubmit)}>
          <p className='text-2xl pb-8'>Create an <b>OMMTAX</b> Account</p>
          <div className='flex justify-between gap-6 flex-col lg:flex-row'>
            <div className="user__inputgroup flex-1">
              <div className='relative'>
                <input type='text' {...register('first_name')} name="first_name" onChange={(e) => {
                  register('first_name').onChange(e);
                  handleInputChange(e);
                }} value={registrationValues.first_name} required />
                <label>First Name</label>
              </div>
              <p className='text-red-600'>{errors.first_name?.message}</p>
            </div>
            <div className="user__inputgroup flex-1">
              <div className='relative'>
                <input type='text' {...register('last_name')} name="last_name" onChange={(e) => {
                  register('last_name').onChange(e);
                  handleInputChange(e);
                }} value={registrationValues.last_name} required />
                <label>Last Name</label>
              </div>
              <p className='text-red-600'>{errors.last_name?.message}</p>

            </div>
          </div>
          <div className='flex flex-col gap-6'>
            <div className="user__inputgroup">
              <div className='relative'>
                <input type='email' {...register('email')} name="email" onChange={(e) => {
                  register('email').onChange(e);
                  handleInputChange(e);
                }} value={registrationValues.email} required />
                <label>Email ID</label>
              </div>
              <p className='text-red-600'>{errors.email?.message}</p>

            </div>
          </div>
          <div className='flex flex-col gap-6'>
            <div className="user__inputgroup">
              <div className='relative'>
                <input type='phone' {...register('phone')} name="phone" onChange={(e) => {
                  register('phone').onChange(e);
                  handleInputChange(e);
                }}
                  value={phoneNoFOrmat(registrationValues.phone)}
                  required />
                <label>Mobile No</label>
              </div>
              <p className='text-red-600'>{errors.phone?.message}</p>

            </div>
          </div>
          <div className='flex flex-col gap-6'>
            <div className="user__inputgroup">
              <div className='relative'>
                <input type='password' {...register('password')} name="password" onChange={(e) => {
                  register('password').onChange(e);
                  handleInputChange(e);
                }} value={registrationValues.password} required />
                <label>Password</label>
              </div>
              <p className='text-xs'>Must be at least 8 characters</p>
              <p className='text-red-600'>{errors.password?.message}</p>

            </div>
          </div>
          <button type='submit' className='btn btn__primary'>Create Account</button>
          <p className='text-sm text-center'>Already have an account? <Link to="/login" className='text-[#00599C] font-bold'>Log in</Link></p>
          <span className='login__splitter'></span>
          <p className='text-xs text-center'>By clicking on Sign up, you agree to our <Link className='text-[#00599C]'>Terms & Conditions</Link> and that you have read our <br /><Link className='text-[#00599C]' href="/privacy">Privacy Policy</Link></p>
        </form>
      </div>
    </>
  )
}

export default UserRegistration