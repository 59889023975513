import React, { useEffect, useState } from 'react'
import DashboardRight from '../components/DashboardRight'
import DashboardLeft from '../components/DashboardLeft'
import { FETCH_DASHBOARD } from '../constants/api.endpoint';
import { useSnackbar } from '../components/toaster';
import { _get } from '../utills/apiClient';
import Loader from '../components/Loader';

const Dashboard = () => {
  const showSnackbar = useSnackbar();
  const [dashboardData, setDashboardData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const user = JSON.parse(sessionStorage.getItem('taxData'));
      await getDashboardData(user?.cus_id);
    })();
  }, []);

  const getDashboardData = async (cus_id) => {
    setIsLoading(true);
    try {
      const response = await _get(`${FETCH_DASHBOARD}${cus_id}`);
      if (response.status == 200 && response.data?.getUserDetails && response.data?.getUserDetails.length > 0) {
        sessionStorage.setItem('taxData', JSON.stringify(response.data?.getUserDetails[0]));
        setDashboardData(response.data?.getUserDetails[0]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in createProfile", 'error');
      console.error('Error adding data:', error);
    }
  };


  return (
    <>
      {isLoading && <Loader />}
      <div className='account__left dashboard'>
        <DashboardLeft data={dashboardData} />
      </div>
      <div className='account__right'>
        <DashboardRight data={dashboardData} />
      </div>
    </>
  )
}

export default Dashboard