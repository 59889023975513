import React, { useEffect, useState } from 'react'
import { _get } from '../utills/apiClient';
import { FETCH_RENTAL } from '../constants/api.endpoint';
import { useSnackbar } from './toaster';
import { ssnPinFOrmat } from './commonFunction';


const ReviewRental = ({ onLoaded }) => {
    const showSnackbar = useSnackbar();
    const [rentalIncAndExpValues, setRentalIncAndExpValues] = useState();
    const currentYear = new Date().getFullYear();
    useEffect(() => {
        const user = JSON.parse(sessionStorage.getItem('taxData'));
        getRental(user?.file_no);
    }, []);


    const getRental = async (file_no) => {
        try {
            const response = await _get(`${FETCH_RENTAL}${file_no}`);
            if (response.data?.RentalIncomeAndExpenses && response.data?.RentalIncomeAndExpenses.length > 0) {
                let accValues = [];
                response.data?.RentalIncomeAndExpenses.map((data) => {
                    accValues.push(false);
                });
                setInnerAccordion([...accValues]);
                setRentalIncAndExpValues(response.data?.RentalIncomeAndExpenses);
            }
            onLoaded();
        } catch (error) {
            onLoaded();
            showSnackbar('Getting Error on getRental', 'error');
            console.error('Error fetching data:', error);
        }
    };
    const [accordion, setAccordion] = useState(true);
    const handleAccordion = (status) => {
        setAccordion(!status);
    }
    const [innerAccordion, setInnerAccordion] = useState([]);
    const handleInnerAccordion = (status, index) => {
        const newItems = innerAccordion.map((item, i) =>
            i === index ? !status : item
        );
        setInnerAccordion(newItems);
    }
    const formatDate = (isoDate) => {
        return isoDate.split('T')[0];
    };

    return (
        <>
            {rentalIncAndExpValues && rentalIncAndExpValues.length > 0 && (
                <div className='order__tabscontent accordion'>
                    <div className={accordion ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                        <div className='order__contentcard accordion'>
                            <div className='order__contentcard--header' onClick={() => handleAccordion(accordion)}>
                                <div className='flex gap-8'>
                                    <p className='order__contentcard--title'>Rental Details</p>
                                </div>
                                {accordion && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' /></button>}
                                {!accordion && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' /></button>}
                            </div>
                            {accordion &&
                                <>
                                    {rentalIncAndExpValues.map((rentalData, index) => (
                                        <div className='order__tabscontent accordion'>
                                            <div className={innerAccordion[index] ? 'order__tabcontent' : 'order__tabcontent accordion-close'}>
                                                <div className='order__contentcard accordion'>
                                                    <div className='order__contentcard--header' onClick={() => handleInnerAccordion(innerAccordion[index], index)}>
                                                        <div className='flex gap-8'>
                                                            <p className='order__contentcard--title'>{rentalData?.RentalInfo?.property_name}</p>
                                                        </div>
                                                        {innerAccordion[index] && <button type='button'><img src='/assets/images/chevron-down-blue.svg' alt='open' /></button>}
                                                        {!innerAccordion[index] && <button type='button'><img src='/assets/images/chevron-up-blue.svg' alt='close' /></button>}
                                                    </div>
                                                    {innerAccordion[index] &&
                                                        <>
                                                            <div className='order__contentcard--content accordion'>

                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.rental_property_owner}</span>
                                                                    <span className='text-sm italic font-light'>Rental Property Owner's Name</span>
                                                                </div>

                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{ssnPinFOrmat(rentalData?.RentalInfo?.EIN_SSN)}</span>
                                                                    <span className='text-sm italic font-light'>EIN/SSN</span>
                                                                </div>

                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_name}</span>
                                                                    <span className='text-sm italic font-light'>Property Name (if any)</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_address}</span>
                                                                    <span className='text-sm italic font-light '>Property Address</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_address2}</span>
                                                                    <span className='text-sm italic font-light'>Address 2</span>
                                                                </div>
                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.property_address3}</span>
                                                                    <span className='text-sm italic font-light'>Address 3</span>
                                                                </div>

                                                                <div className='order__contentcard--details'>
                                                                    <span className='text-sm'>{rentalData?.RentalInfo?.started_current_year}</span>
                                                                    <span className='text-sm italic font-light'>Started this Rental in {currentYear}</span>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Income</p>
                                                                <div className='order__contentcard--content accordion'>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{rentalData?.RentalInfo?.gross_rent}</span>
                                                                        <span className='text-sm italic font-light'>Gross receipts / Sales</span>
                                                                    </div>
                                                                    <div className='order__contentcard--details'>
                                                                        <span className='text-sm'>{rentalData?.RentalInfo?.other_income}</span>
                                                                        <span className='text-sm italic font-light'>Other Income</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Expenses</p>
                                                                <div className='order__contentcard--content grid-cols-1'>
                                                                    <table className='order__contentcard--table'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Expenses</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Advertising</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.advertising} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Commision and fees</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.commission_fees} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Mortgage Interest</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.mortgage_interest} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Property/Real EstateTax</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.property_tax} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>HOA</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.HOA} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>PMI</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.PMI} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Landscapping</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.landscapping} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Painting</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.painting} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Other Taxes and licenses</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.other_taxes} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Home Upgrades (For Rental Purposes)</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.home_upgrade} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Travel Expenses (For Rental Purposes)</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.travel_expenses} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Meals (For Rental Purposes)</td>
                                                                                <td><input type='text' value={rentalData?.Expenses?.meals} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Automobile Expenses</p>
                                                                <div className='order__contentcard--content grid-cols-1'>
                                                                    <table className='order__contentcard--table'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Expenses</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Purchase Date</td>
                                                                                <td><input type='text' value={formatDate(rentalData?.AutoMobileExpenses?.purchase_date)} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Date Placed in Service</td>
                                                                                <td><input type='text' value={formatDate(rentalData?.AutoMobileExpenses?.dateplaced_service)} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Purchase Price (For Depreciation)</td>
                                                                                <td><input type='text' value={rentalData?.AutoMobileExpenses?.purchase_price} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Business Miles</td>
                                                                                <td><input type='text' value={rentalData?.AutoMobileExpenses?.business_meal} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Commute and Leisure Miles</td>
                                                                                <td><input type='text' value={rentalData?.AutoMobileExpenses?.communte_miles} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Percentage of Business Use</td>
                                                                                <td><input type='text' value={rentalData?.AutoMobileExpenses?.businessuse_percentage} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            <div className='order__contentcard--content-inner'>
                                                                <p className='order__contentcard--title px-4'>Home Office Expenses</p>
                                                                <div className='order__contentcard--content grid-cols-1'>
                                                                    <table className='order__contentcard--table'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Expenses</th>
                                                                                <th>Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Purchase Price (For Depreciation)</td>
                                                                                <td><input type='text' value={rentalData?.HomeOfficeExpenses?.purchase_price} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Land Value (if Known)</td>
                                                                                <td><input type='text' value={rentalData?.HomeOfficeExpenses?.land_value} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Purchase Date</td>
                                                                                <td><input type='text' value={formatDate(rentalData?.HomeOfficeExpenses?.purchase_date)} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Square Foot of Home Office</td>
                                                                                <td><input type='text' value={rentalData?.HomeOfficeExpenses?.office_squarefoot} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Square foot of Whole House</td>
                                                                                <td><input type='text' value={rentalData?.HomeOfficeExpenses?.home_squarefoot} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Percentage of Home office</td>
                                                                                <td><input type='text' value={rentalData?.HomeOfficeExpenses?.home_office_percentage} disabled /><img src='/assets/images/table-copy.svg' alt='copy' className='order__contentcard--table-copy' /></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>}
                        </div>
                    </div>
                </div >
            )}
        </>
    )
}

export default ReviewRental