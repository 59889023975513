import React from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import PdfPreview from "./PdfPreview";

const FileViewer = ({ file, fileExt, handleClose }) => {

  const documents = [
    {
      uri: file, fileType: fileExt, // URI to the document
    },
  ];
  
  return (
    <div className="relative z-[99999]" aria-labelledby="modal-title" role="dialog" aria-modal="true">


      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">

          <div className="relative h-[96vh] transform overflow-hidden rounded-lg bg-white text-left w-[50%] shadow-xl transition-all ">

            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button type="button" onClick={handleClose} className="mt-3 inline-flex w-full justify-center rounded-md bg-red px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset bg-red-600  sm:mt-0 sm:w-auto">CLOSE</button>
            </div>
            <div className="fileviewer__content">
              <div className="flex justify-center items-center h-full">

                {fileExt == "pdf" ? (
                  <>
                    <PdfPreview signedUrl={file} />
                  </>
                ) : ["jpeg", "JPEG", "png", "PNG", "jpg", "JPG", "svg", "SVG"].includes(fileExt) ? (
                  <>
                    <div className="max-h-[680px] relative overflow-auto flex items-center justify-center">
                      <img
                        src={file}
                        className=" h-auto object-contain"
                        alt="Image Viewer"
                      />
                    </div>
                  </>
                ) : (
                  <DocViewer
                    documents={documents}
                    pluginRenderers={DocViewerRenderers}
                    style={{ width: '100%' }}
                    className="h-full"
                  />
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileViewer;
