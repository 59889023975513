import React, { useContext, useEffect, useState } from "react";
import { useSnackbar } from "./toaster";
import { _post } from "../utills/apiClient";
import { UPDATE_FINAL_STATUS } from "../constants/api.endpoint";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../utills/AppContext";
import ReviewProfile from "./ReviewProfile";
import ReviewBusiness from "./ReviewBusiness";
import ReviewRental from "./ReviewRental";
import ReviewHome from "./ReviewHome";
import Loader from "./Loader";
import { CreateNotification } from "./commonFunction";

const Submit = () => {

   const [isChecked, setIsChecked] = useState(false);
   const showSnackbar = useSnackbar();
   const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
   const navigate = useNavigate();
   const { setValueFromChild } = useContext(AppContext);
   const [isSubmitted, setIsSubmitted] = useState(false);

   const [profileLoaded, setProfileLoaded] = useState(false);
   const [busniessLoaded, setBusniessLoaded] = useState(false);
   const [rentalLoaded, setRentalLoaded] = useState(false);
   const [homeLoaded, setHomeLoaded] = useState(false);

   const handleCheckboxChange = (e) => {
      setIsChecked(e.target.checked);
   };

   useEffect(() => {
      const user = JSON.parse(sessionStorage.getItem('taxData'));
      if (user && user?.file_status == 'New') {
         setIsSubmitted(true);
         setIsChecked(true);
      }
   }, []);

   const handleSubmit = async (e) => {
      e.preventDefault();
      if (isChecked) {
         try {
            let payload = {
               file_no: userData?.file_no,
               cus_id: userData?.cus_id,
               file_status: 'New',
            }
            const response = await _post(`${UPDATE_FINAL_STATUS}`, payload);
            console.log('createDocument', response);
            if (response.data) {
               CreateNotification("Submitted Successfully",'success');
               showSnackbar(`Submitted successfully`, 'success');
               const user = JSON.parse(sessionStorage.getItem('taxData'));
               user.file_status = 'New';
               sessionStorage.setItem('taxData', JSON.stringify(user));
               navigate(`/account/dashboard`);
               // setValueFromChild('submitCompleted');
               setValueFromChild(prevState => ({
                  ...prevState,
                  value: 'submitCompleted'
               }));
            }
         } catch (error) {
            showSnackbar('Getting Error on UPDATE_FINAL_STATUS', 'error');
            console.error('Error fetching data:', error);
         }
      } else {
         showSnackbar('You must agree to the terms before submitting', 'error');
      }
   };

   const handleProfileLoaded = () => {
      setProfileLoaded(true);
   };

   const handleBusinessLoaded = () => {
      setBusniessLoaded(true);
   };

   const handleRentalLoaded = () => {
      setRentalLoaded(true);
   };

   const handleHomeLoaded = () => {
      setHomeLoaded(true);
   };

   return (
      <div>
         {(!profileLoaded && !busniessLoaded && !homeLoaded && !rentalLoaded) ? <Loader /> : null}
         <form onSubmit={handleSubmit}>
            <div className="profile__right--formtitle">Preview & Submit Filing Application</div>
            <div >
               <ReviewProfile onLoaded={handleProfileLoaded} />
               <ReviewBusiness onLoaded={handleBusinessLoaded} />
               <ReviewRental onLoaded={handleRentalLoaded} />
               <ReviewHome onLoaded={handleHomeLoaded} />
            </div>

            <div className="flex gap-3 justify-start items-center py-4">
               <input
                  type="checkbox"
                  id="Terms&Condition" name="Terms&Condition"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  disabled={isSubmitted}
               />
               <div className="submitting__textsubmit">I agree Terms & Conditions</div>
            </div>
            <ul className="ul submitting__termstext">
               <li>Fees are due as soon as the tax draft is approved for e-filing & e-filing will be on hold until payment is received.</li>
               <li>Post 03/01/2024, a $50 fee is collected as a deposit to prepare the draft. The $50 will be included in the final fee if the tax is filed with OMMTAX. Otherwise, it will be forfeited.</li>
            </ul>
            <div className="submitting">

               <button className="submission__box submission__save submission__adjust" type="submit" disabled={isSubmitted}>Submit Form</button>
            </div>
         </form>
      </div>

   )
}
export default Submit;