import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../utills/AppContext';
import { FETCH_DASHBOARD, FETCH_DOCUMENTS, FETCH_QUESTIONNAIRE } from '../constants/api.endpoint';
import { _get } from '../utills/apiClient';
import { useSnackbar } from '../components/toaster';

const Orders = () => {

    const [userData, setUserData] = useState(JSON.parse(sessionStorage.getItem('taxData')) || {});
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();
    const setProfileSteps = (questionnaireStatus, fillingStatus, documentStatus, reviewStatus) => {
        setQuestionnaire(questionnaireStatus);
        setFilling(fillingStatus);
        setDocument(documentStatus);
        setReview(reviewStatus);
    }

    const [questionnaire, setQuestionnaire] = useState(true);
    const [filling, setFilling] = useState(false);
    const [document, setDocument] = useState(false);
    const [review, setReview] = useState(false);


    const [quesBusiness, setQuesBusiness] = useState('');
    const [quesRental, setQuesRental] = useState('');
    const [quesHome, setQuesHome] = useState('');

    const { valueFromChild } = useContext(AppContext);
    const location = useLocation();
    const path = location.pathname;
    const { screenType } = useParams();
    const { id } = useParams();
    const { action } = useParams();

    const handleQuestionnaireChange = (e) => {
        if (id || action) {
            e.preventDefault();
        } else {
            const userData = JSON.parse(sessionStorage.getItem('taxData'));
            setProfileSteps(true, false, false, false);
            navigate("/account/orders/questionnaire");
        }

    }
    const handleFillingChange = (e) => {
        if (id || action) {
            e.preventDefault();
        } else {
            if (isQuestionnaire) {
                getQuestionnaireData();
                setProfileSteps(false, true, false, false);
                navigate("/account/orders/filling-details");
            } else {
                e.preventDefault();
            }
        }
    }
    const handleDocumentChange = (e) => {
        if (id || action) {
            e.preventDefault();
        } else {
            if (isQuestionnaire || (isFillingVisible && isFilling)) {
                if (isFillingVisible) {
                    if (isFilling && isDocumentCompleted) {
                        setProfileSteps(false, false, true, false);
                        navigate("/account/orders/document-checklist");
                    } else {
                        e.preventDefault();
                    }
                    // if (isDocumentCompleted) {
                    //     setProfileSteps(false, false, true, false);
                    //     navigate("/account/orders/document-checklist");
                    // } else {
                    //     e.preventDefault();
                    // }
                } else {
                    setProfileSteps(false, false, true, false);
                    navigate("/account/orders/document-checklist");
                }
            } else {
                e.preventDefault();
            }
        }
    }
    const handleReviewChange = (e) => {
        if (id || action) {
            e.preventDefault();
        } else {
            if (isQuestionnaire && isDocumentCompleted && (isFillingVisible && isFilling)) {
                setProfileSteps(false, false, false, true);
                navigate("/account/orders/review-submit");
            } else {
                e.preventDefault();
            }
        }
    }

    const [isQuestionnaire, setIsQuestionnaire] = useState(false);
    const [isFilling, setIsFilling] = useState(false);
    const [isDocumentCompleted, setIsDocumentCompleted] = useState(false);
    const [isSubmitCompleted, setIsSubmitCompleted] = useState(false);
    const [isFillingVisible, setIsFillingVisible] = useState(false);
    const [businessValue, setBusinessValue] = useState();
    const [rentalValue, setRentalValue] = useState();
    const [homeValue, setHomeValue] = useState();
    const [fileStatus, setFileStatus] = useState();
    const [questionnaireValue, setQuestionnaireValue] = useState();

    useEffect(() => {
        getQuestionnaireData();
        // getDashboardData();
        getDocuments();
    }, []);


    useEffect(() => {
        if (path && path.includes('/questionnaire')) {
            setProfileSteps(true, false, false, false);
        } else if (path && path.includes('/filling-details')) {
            setProfileSteps(false, true, false, false);
        } else if (path && path.includes('/document-checklist')) {
            setProfileSteps(false, false, true, false);
        } else if (path && path.includes('/review-submit')) {
            setProfileSteps(false, false, false, true);
        }
    }, [location.pathname, document, filling, , questionnaire, review]);

    useEffect(() => {
        if (valueFromChild?.value == 'QuestionnaireCompleted') {
            getQuestionnaireData();
        }
        if (valueFromChild?.value == 'fillingCompleted' || valueFromChild?.value == 'submitCompleted' || valueFromChild?.value == 'fillingDeleted') {
            getQuestionnaireData();
        }
        if (valueFromChild?.value == 'documentCompleted' || valueFromChild?.value == 'documentDeleted') {
            getDocuments();
        }
    }, [valueFromChild]);

    useEffect(() => {
        if (quesBusiness == 'Yes' && quesRental == 'Yes' && quesHome == 'Yes') {
            if (businessValue && homeValue && rentalValue) {
                setIsFilling(true);
            } else {
                setIsFilling(false);
            }
        } else if (quesBusiness == 'Yes' && quesRental == 'Yes' && quesHome == 'No') {
            if (businessValue && rentalValue) {
                setIsFilling(true);
            } else {
                setIsFilling(false);
            }
        } else if (quesBusiness == 'No' && quesRental == 'Yes' && quesHome == 'Yes') {
            if (homeValue && rentalValue) {
                setIsFilling(true);
            } else {
                setIsFilling(false);
            }
        }
        else if (quesBusiness == 'Yes' && quesRental == 'No' && quesHome == 'Yes') {
            if (businessValue && homeValue) {
                setIsFilling(true);
            } else {
                setIsFilling(false);
            }
        } else if (quesBusiness == 'No' && quesRental == 'No' && quesHome == 'Yes') {
            if (homeValue) {
                setIsFilling(true);
            } else {
                setIsFilling(false);
            }
        } else if (quesBusiness == 'Yes' && quesRental == 'No' && quesHome == 'No') {
            if (businessValue) {
                setIsFilling(true);
            } else {
                setIsFilling(false);
            }
        } else if (quesBusiness == 'No' && quesRental == 'Yes' && quesHome == 'No') {
            if (rentalValue) {
                setIsFilling(true);
            } else {
                setIsFilling(false);
            }
        } else {
            setIsFilling(false);
        }
        if (fileStatus == 'New') {
            setIsSubmitCompleted(true);
        }
        if (questionnaireValue) {
            setIsQuestionnaire(true);
        }
    }, [quesBusiness, quesRental, quesHome, , businessValue, rentalValue, homeValue, fileStatus, questionnaireValue]);



    const getDashboardData = async () => {
        try {
            const response = await _get(`${FETCH_DASHBOARD}${userData?.cus_id}`);
            if (response.status == 200 && response.data?.getUserDetails && response.data?.getUserDetails.length > 0) {
                const dashboardData = response.data?.getUserDetails[0];
                setBusinessValue(dashboardData?.business_id);
                setRentalValue(dashboardData?.rental_id);
                setHomeValue(dashboardData?.home_id);
                setFileStatus(dashboardData?.file_status);
                setQuestionnaireValue(dashboardData?.qq_id);
                // if (quesBusiness == 'Yes' && quesRental == 'Yes' && quesHome == 'Yes') {
                //     if (dashboardData?.business_id && dashboardData?.home_id && dashboardData?.rental_id) {
                //         setIsFilling(true);
                //     } else {
                //         setIsFilling(false);
                //     }
                // } else if (quesBusiness == 'Yes' && quesRental == 'Yes' && quesHome == 'No') {
                //     if (dashboardData?.business_id && dashboardData?.rental_id) {
                //         setIsFilling(true);
                //     } else {
                //         setIsFilling(false);
                //     }
                // } else if (quesBusiness == 'No' && quesRental == 'Yes' && quesHome == 'Yes') {
                //     if (dashboardData?.home_id && dashboardData?.rental_id) {
                //         setIsFilling(true);
                //     } else {
                //         setIsFilling(false);
                //     }
                // }
                // else if (quesBusiness == 'Yes' && quesRental == 'No' && quesHome == 'Yes') {
                //     if (dashboardData?.business_id && dashboardData?.home_id) {
                //         setIsFilling(true);
                //     } else {
                //         setIsFilling(false);
                //     }
                // } else if (quesBusiness == 'No' && quesRental == 'No' && quesHome == 'Yes') {
                //     if (dashboardData?.home_id) {
                //         setIsFilling(true);
                //     } else {
                //         setIsFilling(false);
                //     }
                // } else if (quesBusiness == 'Yes' && quesRental == 'No' && quesHome == 'No') {
                //     if (dashboardData?.business_id) {
                //         setIsFilling(true);
                //     } else {
                //         setIsFilling(false);
                //     }
                // } else if (quesBusiness == 'No' && quesRental == 'Yes' && quesHome == 'No') {
                //     if (dashboardData?.rental_id) {
                //         setIsFilling(true);
                //     } else {
                //         setIsFilling(false);
                //     }
                // } else {
                //     setIsFilling(false);
                // }
                // if (dashboardData?.business_id && dashboardData?.home_id && dashboardData?.rental_id) {
                //     setIsFilling(true);
                // }
                // if (dashboardData?.file_status == 'New') {
                //     setIsSubmitCompleted(true);
                // }
                // if (dashboardData?.qq_id) {
                //     setIsQuestionnaire(true);
                // }

            }
        } catch (error) {
            showSnackbar("Getting error in getDashboardData", 'error');
            console.error('Error adding data:', error);
        }
    };

    const getDocuments = async () => {
        try {
            const response = await _get(`${FETCH_DOCUMENTS}${userData?.file_no}`);
            if (response.status == 200 && response.data?.getDocumentList && response.data?.getDocumentList.length > 0) {
                setIsDocumentCompleted(true);
                setProfileSteps(false, false, true, false);
            } else {
                setIsDocumentCompleted(false);
            }
        } catch (error) {
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };
    const getQuestionnaireData = async () => {
        try {
            const response = await _get(`${FETCH_QUESTIONNAIRE}${userData?.file_no}`);
            if (response.status == 200 && response.data?.Questionnaire) {
                let questionnaire = response.data?.Questionnaire;
                setQuesBusiness(questionnaire.business_income);
                setQuesRental(questionnaire.rental_income);
                setQuesHome(questionnaire.home_sale_status);
                if (questionnaire.business_income == 'Yes' || questionnaire.home_sale_status == 'Yes'
                    || questionnaire.rental_income == 'Yes'
                ) {
                    setIsFillingVisible(true);
                    setProfileSteps(false, true, false, false);
                } else {
                    setIsFillingVisible(false);
                    setProfileSteps(false, false, true, false);
                }


                getDashboardData();

            } else {
                setIsFillingVisible(false);
            }
        } catch (error) {
            showSnackbar("Getting error in getDocuments", 'error');
            console.error('Error adding data:', error);
        }
    };

    return (
        <>
            <div className='account__left'>
                <div className='account__orders--left'>
                    <ul className='account__orders--left-ul'>
                        <li onClick={(e) => handleQuestionnaireChange(e)}><Link className={`${questionnaire ? "active" : ""} ${!questionnaire && isQuestionnaire ? "completed" : ""}`}><span>1</span>Questionnaire</Link></li>
                        {isFillingVisible && <li onClick={(e) => handleFillingChange(e)}><Link className={`${filling ? "active" : ""} ${!filling && isFilling ? "completed" : ""}`}><span>2</span>Filling Details</Link></li>}
                        <li onClick={(e) => handleDocumentChange(e)}><Link className={`${document ? "active" : ""} ${!document && isDocumentCompleted ? "completed" : ""}`}><span>{isFillingVisible ? 3 : 2}</span>Document Checklist</Link></li>
                        <li onClick={(e) => handleReviewChange(e)}><Link className={`${review ? "active" : ""} ${!review && isSubmitCompleted ? "completed" : ""}`}><span>{isFillingVisible ? 4 : 3}</span>Review & Submit</Link></li>
                    </ul>
                </div>
            </div>
            <div className='account__right'>
                <div className='account__orders--right'>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default Orders