import React from 'react';
 
export function Avatar({ children, className }) {
  return (
    <div className={`relative inline-flex items-center justify-center rounded-full overflow-hidden ${className}`}>
      {children}
    </div>
  );
}
 
export function AvatarImage({ src, alt }) {
  return src ? (
    <img
      src='/assets/images/user-circle-svgrepo-com.svg'
      alt={alt}
      className="h-full w-full object-cover"
      onError={(e) => {
        console.log("Image failed to load:", src);
        e.currentTarget.style.display = 'none';
      }}
    />
  ) : null;
}
 
export function AvatarFallback({ children }) {
  return (
    <div className="h-full w-full flex items-center justify-center bg-gray-200 text-gray-600">
      {children}
    </div>
  );
}