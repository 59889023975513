import React, { useEffect, useState } from 'react'
import AgentDashboardLeft from '../components/AgentDashboardLeft'
import AgentDashboardRight from '../components/AgentDashboardRight'
import { FETCH_AGENT_DASHBOARD } from '../constants/api.endpoint';
import { useSnackbar } from '../components/toaster';
import { _get } from '../utills/apiClient';
import { useDropdown } from '../reducer/DropdownReducer';
import Loader from '../components/Loader';

const AgentDashboard = () => {
  const currentYear = new Date().getFullYear();
  const showSnackbar = useSnackbar();
  const [dashboardData, setDashboardData] = useState();
  const { state } = useDropdown();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAgentDashboardData();
  }, [state]);


  const getAgentDashboardData = async () => {
    setIsLoading(true);
    try {
      const response = await _get(`${FETCH_AGENT_DASHBOARD}${currentYear}`);
      if (response.status == 200 && response.data?.fetchAgentDashboard && response.data?.fetchAgentDashboard.length > 0) {
        let res = response.data?.fetchAgentDashboard.filter((data) => data.fiscal_year == state.selectedOption && data.account_type != 'agent');
        if (res && res.length > 0) {
          res.map((data) => {
            if (data.file_status == 'Account Created' || data.file_status == 'New') {
              data.statusClassName = 'agent__card--status-label-new';
              data.statusLabelClassName = 'agent__card--status-new';
            } else if (data.file_status == 'Under Review') {
              data.statusClassName = 'agent__card--status-label-review';
              data.statusLabelClassName = 'agent__card--status-review';
            } else if (data.file_status == 'Upload Pending') {
              data.statusClassName = 'agent__card--status-label-pending';
              data.statusLabelClassName = 'agent__card--status-pending';
            }
            else if (data.file_status == 'Claim Initiated') {
              data.statusClassName = 'agent__card--status-label-initiated';
              data.statusLabelClassName = 'agent__card--status-initiated';
            }
            else if (data.file_status == 'Claim Refunded') {
              data.statusClassName = 'agent__card--status-label-refunded';
              data.statusLabelClassName = 'agent__card--status-refunded';
            }
            else if (data.file_status == 'Revoke') {
              data.statusClassName = 'agent__card--status-label-revoke';
              data.statusLabelClassName = 'agent__card--status-revoke';
            }
            if ((data.business_id == null && data.rental_id == null && data.home_id == null)) {
              data.isIndividualTax = true;
            } else {
              data.isIndividualTax = false;
            }
            if (data.business_id && (data.rental_id == null && data.home_id == null)) {
              data.isBusinessTax = true;
            } else {
              data.isBusinessTax = false;
            }
            if (data.rental_id && data.home_id) {
              data.isRentalHousingTax = true;
            } else {
              data.isRentalHousingTax = false;
            }
          });
          let dashboardObj = {
            taxInfo: res?.filter((data) => data.file_status != 'Account Created'),
            totalCount: res.length,
            newCount: res?.filter((data) => data.file_status == 'New').length,
            accountCreatedCount: res?.filter((data) => data.file_status == 'Account Created').length,
            uploadPendingCount: res?.filter((data) => data.file_status == 'Upload Pending').length,
            underReviewCount: res?.filter((data) => data.file_status == 'Under Review').length,
            claimsInitiatedCount: res?.filter((data) => data.file_status == 'Claim Initiated').length,
            claimsRefundedCount: res?.filter((data) => data.file_status == 'Claim Refunded').length,
            revokeCount: res?.filter((data) => data.file_status == 'Revoke').length,
            individualTaxCount: res?.filter((data) => data.isIndividualTax == true).length,
            businessTaxCount: res?.filter((data) => data.isBusinessTax == true).length,
            rentalHousingTaxCount: res?.filter((data) => data.isRentalHousingTax == true).length,
            orderByStatusPie: [
              { id: 0, value: res?.filter((data) => data.file_status == 'Account Created').length, label: 'Account Created' },
              { id: 1, value: res?.filter((data) => data.file_status == 'New').length, label: 'Submitted' },
              { id: 2, value: res?.filter((data) => data.file_status == 'Under Review').length, label: 'Under Review' },
              { id: 3, value: res?.filter((data) => data.file_status == 'Upload Pending').length, label: 'Upload Pending' },
              { id: 4, value: res?.filter((data) => data.file_status == 'Claim Refunded').length, label: 'Claim Refunded' },
              { id: 5, value: res?.filter((data) => data.file_status == 'Claim Initiated').length, label: 'Claim Initiated' },
              { id: 6, value: res?.filter((data) => data.file_status == 'Revoke').length, label: 'Revoke' },

            ]
          };
          dashboardObj.orderByStatusPie = dashboardObj.orderByStatusPie.filter((data) => data.value != '0');
          setDashboardData(dashboardObj);
          setIsLoading(false);
        } else {
          let dashboardObj = {
            taxInfo: [],
            underReviewCount: 0,
            claimsInitiatedCount: 0,
            claimsRefundedCount: 0,
            revokeCount:0,
            individualTaxCount: 0,
            businessTaxCount: 0,
            rentalHousingTaxCount: 0,
            orderByStatusPie: [

            ]
          };
          setDashboardData(dashboardObj);
          setIsLoading(false);
        }

      }
    } catch (error) {
      setIsLoading(false);
      showSnackbar("Getting error in getDashboardData", 'error');
      console.error('Error adding data:', error);
    }
  };


  return (
    <div className='agent'>
      {isLoading && <Loader />}
      <AgentDashboardLeft data={dashboardData} />
      {/* <AgentDashboardRight data={dashboardData} /> */}
    </div>
  )
}

export default AgentDashboard