import React from 'react'
import DocChecklist from '../components/DocChecklist'

const DocumentChecklist = () => {
  return (
    <>
      <div className='profile__right'>
        <DocChecklist />
      </div>
    </>
  )
}

export default DocumentChecklist