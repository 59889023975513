import React from 'react'
import { Link } from 'react-router-dom'
import { phoneNoFOrmat } from './commonFunction'

const DashboardLeft = (props) => {
  return (
    <div className='account__dashboard--profile'>
      <div className='account__dashboard--profiledetails'>
        <div className='account__dashboard--profiledetailscontainer'>
          <p className='account__dashboard--profilename'>{props?.data?.first_name} {props?.data?.last_name}</p>
          <div className='flex flex-col gap-4'>
            <p className='text-white'>{props?.data?.email}</p>
            <p className='text-white'>{phoneNoFOrmat(props?.data?.phone)}</p>
          </div>
          <div className='account__dashboard--profilepic'>
            <p>{props?.data?.first_name?.charAt(0)}{props?.data?.last_name?.charAt(0)}</p>
          </div>
          {/* <img src='/images/profile-pic.jpg' alt='profile pic' className='account__left--img'/> */}
        </div>
      </div>
      <div className='flex flex-col gap-4'>
        <div className='account__dashboard--profilestatus'>
          <span className='account__dashboard--profilestatusbar' style={{ width: `${props?.data?.profile_completion_per}%` }}></span>
        </div>
        <div className='flex justify-between'>
          <p>Your Profile</p>
          <p className='text-[#8C8989] font-semibold'>{props?.data?.profile_completion_per ? props?.data?.profile_completion_per : 0}%</p>
        </div>
      </div>
      <Link to="/account/profile" className='account__btn account__btn--primary w-full'>Update your profile</Link>
    </div>
  )
}

export default DashboardLeft